import '../Styles/Order.css';
import bckg from "../Images/order.svg"
import Navbar from '../Components/Navbar';
import { useTranslation } from 'react-i18next';
import ErrorModal from '../Components/ErrorModal';
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { RiArrowRightSFill,RiArrowLeftSFill } from 'react-icons/ri';
import {useRef,useState,useEffect} from 'react'
import { Link } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { addComplaint } from '../api';
function AddComplaint(props) {
  const  customerPhone=  localStorage.getItem("customerPhone");


    const { t, i18n } = useTranslation();
const [about,setAbout]=useState("time");
const [loading,setLoading]=useState(false);
const[arMsg,setArMsg]=useState()
const[enMsg,setEnMsg]=useState()
const[errModal,setErrModal]=useState(false)
const[notesValue,setNotesValue]=useState("")
 const notesRef=useRef();
 let history= useNavigate();
     
 const customerId= localStorage.getItem("customerId");




 

 //takes complaint type and message
 //redirects to customer's complaints screen if success
 function handleComplaint(){
  setLoading(true)
  const token= localStorage.getItem("token");

   if (notesRef.current.value === '') {


    setArMsg( "من فضلك أدخل تفاصيل الشكوي")
    setEnMsg( "Please enter the complaint details")
      setErrModal(true);
      setLoading(false)
      setTimeout(function() {
    
          setErrModal(false)
        }, 2500)
   

}
else{
  let values = {
    about: about,
    msg: notesRef.current.value,
};


addComplaint(token,customerId,values).then((response) => {
  if (response.data.message === "success") {
     history("/customer-complaints")               
  } else {
    setArMsg(   "حدث خطأ")
    setEnMsg( "Something went wrong")
      setErrModal(true);
      setLoading(false)
      setTimeout(function() {
      
        setErrModal(false)
      }, 2500)
  } 

}).catch(function (error) {
  if (error.response.status === 401) {
    history("/")
  }
  else if(error.response.status === 403){
    props.setErr(true)
    props.setMsg(t('validation.noPermissionAction'))
    props.setStyle({top:"50%"})

    setTimeout(function() {
        
      props.setErr(false)
      setLoading(false)

  
      }, 2500)    }
      else{
  setArMsg(   "حدث خطأ")
  setEnMsg( "Something went wrong")
    setErrModal(true);
    setLoading(false)
    setTimeout(function() {
    
      setErrModal(false)
    }, 2500)}
      
})
}
};

//to stop user from typing arabic numbers
function handleNotes(e){

if(!e.target.value.includes("٠") && !e.target.value.includes("١") && !e.target.value.includes("٢") && !e.target.value.includes("٣") && !e.target.value.includes("٤") && !e.target.value.includes("٥") && !e.target.value.includes("٦") && !e.target.value.includes("٧") && !e.target.value.includes("٨") && !e.target.value.includes("٩") ){

setNotesValue(e.target.value)

}

}

    return (
      <div >
       
          {errModal?<ErrorModal setModal={setErrModal} msg={i18n.language=="ar"?arMsg:enMsg}/>:null}
<div className='compContaienr'>

  <div className='navigateOrder'>
<a href='/'>{t("addOrder.search")}</a>
{i18n.language=="ar"?
<RiArrowLeftSFill />: <RiArrowRightSFill/>}
<a href='/customer-orders' className='userData'> {t("profile.cstmr")} &nbsp; ( {customerPhone} )</a>
{i18n.language=="ar"?
<RiArrowLeftSFill/>: <RiArrowRightSFill/>}
<a>{t("profile.addNewComp")}</a>

  </div>
  <p style={{fontWeight:"700"}} className='orderTitle'>{t("profile.addNewComp")}</p>


<div style={{marginBottom:"5em"}} className='row gy-4'>
<div className='col-sm-7 '>
<div className='p-3 complaintBody'>
<p style={{marginTop:"0"}}> {t("profile.c-type")}</p>

<div className='row gy-2'>
<div className='col'>
<input type="radio" name="about" value="time" defaultChecked={true} onChange={(e)=>setAbout(e.target.value)}/>
<label>{t("profile.time")}</label>

</div>
<div className='col'>
<input type="radio" name="about" value="clean"  onChange={(e)=>setAbout(e.target.value)}/>
<label>{t("profile.cleaness")}</label>

</div>

<div className='col'>
<input type="radio" name="about" value="quality"  onChange={(e)=>setAbout(e.target.value)}/>
<label>{t("profile.quality")}</label>

</div>

</div>

<p>{t("profile.c-details")}</p>
<textarea value={notesValue} ref={notesRef} placeholder={t("profile.enterNote")} onChange={(e)=>handleNotes(e)}/>
</div>


</div>
<div className='col-sm-5 '>

    <div className='p-3 complaintSend'>
    {loading?
               <div style={{height:"40vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
      <>
    <button onClick={handleComplaint} className=' sendComp'>
  
    {t("profile.send")}</button>
    <Link to="/customer-complaints">
<button  className='prevComp'>{t("profile.previous")}</button></Link>

</>}


    </div>


</div>
</div>
  </div>
  </div>

    );
  }
  
  export default AddComplaint;
  
  