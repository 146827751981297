import '../Styles/Dashboard.css';
import { AiOutlineSearch } from 'react-icons/ai';
import Navbar from '../Components/Navbar';
import { useTranslation } from 'react-i18next';
import { IoCloseSharp } from "react-icons/io5";
import bckg from "../Images/order.svg"
import {RiShoppingCartFill } from 'react-icons/ri';
function FreeMeals(props) {
   
    const { t, i18n } = useTranslation();


    return (
        <div > 
         
        <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
        <div  className="modal-content" style={{height:"auto",width:"40vw ",minWidth:"337px",paddingTop:"2em",position:"fixed"}}>
        <ul style={{textAlign:"start"}}>
        {props.freeMeals && props.freeMeals.map((data, index)=>

<li  className='alrt'>{t("menu.free")} &nbsp;
{i18n.language=="ar"? data.arName:data.enName}</li>

        )}


        </ul>

        {props.button?
       <div className='modalBtns' style={{marginTop:"2em"}}>
<button  onClick={()=>{props.createOrder(); props.setModal(false)}}  className='del'>{t("menu.continue")}</button>
<button  onClick={()=>{props.setSubmitLoading(false); props.setModal(false); }}  className='cls'>{t("profile.cancel")}</button>

</div>:null}


        </div>
      </div>
  
    );
  }
  
  export default FreeMeals;
  
  