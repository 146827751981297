import { useState, useEffect } from "react";
import { product, products, categories } from "../api";

const CacheDataComponent = ({ setProgress, setLoadingCache }) => {
  const getProduct = (productId, token, branchId) => {
    return product(
      token,
      productId,
      branchId,
      localStorage.getItem("orderSource"),
      parseInt(localStorage.getItem("customerId"))
    );
  };

  const fetchProducts = async (categoryId, token, branchId) => {
    try {
      const res = await products(
        token,
        categoryId,
        branchId,
        localStorage.getItem("orderSource")
      );
      const productPromises = res.data.data.map((item) =>
        getProduct(item.id, token, branchId)
      );
      return Promise.all(productPromises);
    } catch (error) {
      console.error("Error fetching products:", error);
      throw error;
    }
  };

  const fetchAndCacheData = async () => {
    if (localStorage.getItem("type") === "cashier") {
      try {
        const token = localStorage.getItem("token");
        const branchId = parseInt(localStorage.getItem("branchId"));
        

        const response = await categories(
          token,
          parseInt(
            localStorage.getItem("orderType") == null
              ? 1
              : localStorage.getItem("orderType")
          )
        );

        categories(token, 5);

        const totalCategories = response.data.data.length;
        let completedCategories = 0;

        const productPromises = response.data.data.map(async (category) => {
          await fetchProducts(category.id, token, branchId);
          completedCategories += 1;
          setProgress((completedCategories / totalCategories) * 100);
        });

        await Promise.all(productPromises);

        console.log("All data fetched and cached successfully");
      } catch (error) {
        console.error("Error fetching and caching data:", error);
      } finally {
        setLoadingCache(false);
      }
    } else {
      setLoadingCache(false);
    }
  };

  const checkCacheAndFetchData = async () => {
    if ("caches" in window) {
      try {
        const cache = await caches.open("api-cache");
        const cachedRequests = await cache.keys();
        console.log(cachedRequests)

        const categoriesRequest = cachedRequests.find((request) =>
          request.url.includes("/categories")
        );
        const productsRequest = cachedRequests.find((request) =>
          request.url.includes("/products")
        );
        const productRequest = cachedRequests.find((request) =>
          request.url.includes("/product")
        );

        if (!categoriesRequest || !productsRequest || !productRequest) {
          await fetchAndCacheData();
        } else {
          setLoadingCache(false);
        }
      } catch (error) {
        console.error("Error checking cache and fetching data:", error);
        setLoadingCache(false);
      }
    } else {
      setLoadingCache(false);
    }
  };

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/service-worker.js")
        .then((registration) => {
          console.log("Service worker registered:", registration);

          navigator.serviceWorker.addEventListener("controllerchange", () => {
            console.log("New service worker controlling this page");
            setLoadingCache(false);
          });

          checkCacheAndFetchData();
        })
        .catch((error) => {
          console.error("Service worker registration failed:", error);
          setLoadingCache(false);
        });
    } else {
      setLoadingCache(false);
    }
  }, []);

  return null;
};

export default CacheDataComponent;
