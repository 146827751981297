import '../Styles/Dashboard.css';
import { useTranslation } from 'react-i18next';
import { useRef,useState } from 'react';
import { changeStatus } from '../api';
import { BsUpload } from 'react-icons/bs';
import { uploadDocument,requestDoc } from '../api';
import { BeatLoader } from 'react-spinners';

function UploadFile(props) {
  const token= localStorage.getItem("token");
   
    const { t, i18n } = useTranslation();
    const[type,setType]=useState("")
    const[msgAr,setMsgAr]=useState("")
    const[msgEn,setMsgEn]=useState("")
    const [loading,setLoading]=useState(false);
    const [success,setSuccess]=useState(false);

    const[err,setErr]=useState(false)
    const typeRef=useRef()
    const noteRef=useRef()

const imgRef=useRef()

//changeStatus api takes data depending on request type
//rejected=> type (approved/rejected)
//approved=> type (approved/rejected), external user type (postpaid/prepayment),code
function handleUpload(){
 if(imgRef.current.value==""){
    setMsgAr("من فضلك اختر مستند")
    setMsgEn("Please choose document")
    setErr(true)
    setSuccess(false);

  }
  else{
    setLoading(true)

  
    const formData = new FormData()

    
    const files = imgRef.current.files;
    
    for (let i = 0; i < files.length; i++) {
      formData.append('attachments[]', files[i]);
    }

         // formData.append('attachments',imgRef.current.files)
  
    
  uploadDocument(token,props.id,formData,i18n.language).then((response) => {
    setMsgAr( "تم تحميل المستندات بنجاح");
    setMsgEn("Attachments are uploaded successfully");
    setSuccess(true);
    setErr(false);
    props.setUpdate(!props.update)
    setLoading(false)

    imgRef.current.value=""
  }).catch((error)=>{

   setMsgAr(error.response.data.error);
    setMsgEn(error.response.data.error);
    setErr(true);
    setSuccess(false);

    setLoading(false)
  }
    );
    
  }
 
}
function handleUpload2(){
  if(imgRef.current.value==""){
    setMsgAr("من فضلك اختر مستند")
    setMsgEn("Please choose document")
    setErr(true)
    setSuccess(false);

  }
  else{
  setLoading(true)

  const formData = new FormData()

    
  const files = imgRef.current.files;
  
  for (let i = 0; i < files.length; i++) {
    formData.append('attachments[]', files[i]);
  }
  formData.append('type', typeRef.current.value);
  formData.append('note', noteRef.current.value);


  requestDoc(token,localStorage.getItem("externalId"),formData,i18n.language).then((response) => {

    setMsgAr( "تم تحميل المستندات بنجاح");
    setMsgEn("Attachments are uploaded successfully");
    setSuccess(true);
    setErr(false);
    props.setUpdate(!props.update)
    setLoading(false)

    imgRef.current.value=""

  }).catch((error)=>{

   

      if(error.response.data.errors.type){
        setMsgAr(error.response.data.errors.type[0])
        setMsgEn(error.response.data.errors.type[0])
        setErr(true);
        setSuccess(false);
      
        setLoading(false)
      }
      else  if(error.response.data.errors.note){
        setMsgAr(error.response.data.errors.note[0])
        setMsgEn(error.response.data.errors.note[0])
        setErr(true);
        setSuccess(false);
      
        setLoading(false)
      }
  

  else{
 setMsgAr(error.response.data.error);
  setMsgEn(error.response.data.error);
  setErr(true);
  setSuccess(false);

  setLoading(false)}
}
  );}
}
    return (
        <div >
         
        <div onClick={props.type=="upload"? ()=>props.setUpload(false):  ()=>props.setModal(false)}  className="modalOverlay"></div>
        <div style={{width:"max-content",maxWidth:"90vw"}} className="modal-content errModal usrType" >
      
        {success?
        <p className='alrt' style={{color:"green"}}> {i18n.language=="ar"?msgAr:msgEn}</p>:
  <div className='clientCode'>

{
  props.type=="upload"?
  <>

  <label >{t("docs.docType")}</label>

<select className='selectType' defaultValue=""   ref={typeRef}>
<option value="" disabled>{t("profile.choose")}</option>

  <option value="association request">{t("docs.type2")}</option>
  <option value="account statement">{t("docs.type3")}</option>
  <option value="other">{t("docs.type1")}</option>
</select>


<label >{t("docs.notes")}</label>
<textarea ref={noteRef} className='textNote'/>
  </>:null
}

<label >{t("docs.upload")}</label>
<div style={{width:"100%"}} className={i18n.language=="ar"?'uploadInp':'uploadInp uploadInpEn'}> 
<input style={{height:"unset",padding:"7px"}} type="file" multiple ref={imgRef} placeholder="test" />
<BsUpload size={20} color="#ACACAC"/>

</div>
 
 </div>}
 

 {i18n.language=="ar"?
<span style={err?{textAlign:"center",margin:"1em 0"}:null}  className='validation'>{err? msgAr:null}</span>:
<span style={err?{textAlign:"center",margin:"1em 0"}:null} className='validation' >{err? msgEn:null}</span>}
{loading?
<div style={{height:"70px"}} className='loader'>
<BeatLoader color={'#E47100'} loading={loading}  size={25} /></div>:
<>
{success?

<button    onClick={ props.type=="upload"? ()=>props.setUpload(false):()=>props.setModal(false)}   className='confirm' style={{width:"100%"}}>{t("Login.confirm")}</button>
:

<>
 <button   onClick={ props.type=="upload"?()=>handleUpload2(): ()=>handleUpload()}   className='confirm' style={err?{width:"20%",marginTop:"0"}:{width:"20%"}}>{t("Login.confirm")}</button>
 <span style={{margin:"0 1em",display:"inline-block"}}></span>
 <button  onClick={ props.type=="upload"? ()=>props.setUpload(false):  ()=>props.setModal(false)}  className="cancelEdit" >{t("menu.cancel")}</button>
 </>}</>
 
}
        </div>
      </div>
  
    );
  }
  
  export default UploadFile;
  
  