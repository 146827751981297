import "../Styles/CustomerOrders.css";

import Navbar from "../Components/Navbar";
import { useTranslation } from "react-i18next";

import ErrorModal from "../Components/ErrorModal";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import Autocomplete from "react-google-autocomplete";

import CustomerData from "../Components/CustomerData";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  branches,
  cities,
  addAddress,
  branachByArea,
  areaByBranch,
} from "../api";
import { BeatLoader } from "react-spinners";
import Select from "react-select";
import { useFormik } from "formik";
import * as yup from "yup";
import { countries } from "../api";
import icn1 from "../Images/customerProfile.svg";
import icn2 from "../Images/orderDetailsIconInactive.svg";
import icn3 from "../Images/menuIconUnactive.svg";
import icn4 from "../Images/basketIconUnactive.svg";
import { Link } from "react-router-dom";
export const MAP_LIBRARIES = ["places"];

function AddAddress() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDPInNXxJ-Drq0RXS45apFP3snnhX5VTpw",

    libraries: MAP_LIBRARIES,
  });
  const markerRef = useRef();
  const lat = 23.8859;
  const lng = 45.0792;

  const [position, setPostion] = useState({
    lat: lat,
    lng: lng,
  });

  const { t, i18n } = useTranslation();

  const [arMsg, setArMsg] = useState();
  const [enMsg, setEnMsg] = useState();
  const [errModal, setErrModal] = useState(false);
  const [city, setCity] = useState([]);
  const [country, setCountry] = useState();

  const [selectedCity, setSelectedCity] = useState({
    arName: "",
    enName: "",
  });
  const [selectedCountry, setSelectedCountry] = useState({
    arName: "",
    enName: "",
  });
  const [bh, setBh] = useState(false);
  const [areaValue, setAreaValue] = useState("");
  const [areas, setAreas] = useState([]);
  const [branch, setBranch] = useState([]);
  const [selectedArea, setSelectedArea] = useState(null);


  const [selectedBranch, setSelectedBranch] = useState("");
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const streetRef = useRef();
  const noteRef = useRef();
  const floorRef = useRef();
  const appartRef = useRef();
  const buildRef = useRef();
  const titleRef = useRef();
  const [title, setTitle] = useState("");

  const customerId = localStorage.getItem("customerId");
  let history = useNavigate();

  //countries=> takes user token and returns countries and the cities inside them
  //set initial chosen country
  useEffect(() => {
    const token = localStorage.getItem("token");

    countries(token)
      .then((response) => {
        setCountry(response.data.data);
        setCity(response.data.data[0].cities);

        setSelectedCountry({
          arName: response.data.data[0].arName,
          enName: response.data.data[0].enName,
        });

        setLoading(false);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          history("/");
        }
      });
  }, []);

  //on changing chosen country
  // selected city, branch and area are reset to initial value
  // branch options are cleared (because they change according to selected city)
  ////area options are cleared (because they change according to selected branch)
  //setBh is used to show more fields if bahrain is selected
  //countries api is called to fetch cities of selected country
  function handleCountryChange(e) {
    var dropDown = document.getElementById("city");
    dropDown.selectedIndex = 0;
    var dropDown2 = document.getElementById("branch");
    dropDown2.selectedIndex = 0;
    setAreas([]);
    setBranch([]);
    setSelectedArea("");
    const token = localStorage.getItem("token");

    const index = JSON.parse(e.target.value).index;
    setSelectedCountry({
      arName: JSON.parse(e.target.value).arName,
      enName: JSON.parse(e.target.value).enName,
    });
    if (index == 0) {
      setBh(false);
    } else {
      setBh(true);
    }

    countries(token)
      .then((response) => {
        setCity(response.data.data[index].cities);
        setLoading(false);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          history("/");
        }
      });
  }

  //on changing city
  //selected area and branch are reset to initial value
  //area options are cleared because they change according to selected branch
  //branches api is called with city id to get branches of selected city
  function handleCityChange(e) {
    const token = localStorage.getItem("token");
    let selectElement = document.getElementById("branch");
    selectElement.selectedIndex = null;
    setSelectedArea(null);
    setAreas([]);
    let value = JSON.parse(e.target.value);
    setSelectedCity({
      arName: value.arName,
      enName: value.enName,
    });

    branches(token, value.id)
      .then((response) => {
        setBranch(response.data.data);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          history("/");
        }
      });
  }

  //on change area
  //setSelectedArea => changes selected area shown in select menu
  //setAreaValue => sets the value of the selected area
  function handleAreaChange(e) {
    const token = localStorage.getItem("token");
    setSelectedArea(e);
    setAreaValue(e.value);
  }

  //on change branch
  //removes selected area
  //areaByBranch api is called, takes brancg id to get areas related to that branch
  function handleBranchChange(e) {
    console.log(e)
    const token = localStorage.getItem("token");
    setSelectedArea(null);
    setSelectedBranch(e.target.value);

    areaByBranch(token, e.target.value)
      .then((response) => {
        let items = [];

        for (let i = 0; i < response.data.data.length; i++) {
          let obj = {};
          obj.label =
            i18n.language == "ar"
              ? response.data.data[i].arName
              : response.data.data[i].enName +
                " - (" +
                t("areas.ordinary") +
                response.data.data[i].ordinaryDeliveryPrice +
                " ) - (   " +
                t("areas.fast") +
                response.data.data[i].fastDeliveryPrice +
                " )";
          obj.value = {
            id: response.data.data[i].id,
            enName: response.data.data[i].enName,
            arName: response.data.data[i].arName,
          };

          items.push(obj);
          //creating  options dynamically based on what props are currently passed to the parent component
        }
        setAreas(items);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          history("/");
        }
      });
  }

  //takes(title, area id, branch id (required))
  //and (floor no., building no., street name, appartment no. and address notes (not required)) => bahrian requires more fields
  //if success user is redirected to customer addresses
  function onSubmit(e) {
    e.preventDefault();

    setSubmitLoading(true);

    const token = localStorage.getItem("token");

    if (titleRef === "") {
      setArMsg("أدخل اسم العنوان");
      setEnMsg("Enter Address Title");
      setErrModal(true);
      setSubmitLoading(false);
      setTimeout(function () {
        setErrModal(false);
      }, 2500);
    } else if (areaValue === "") {
      setArMsg("اختر الحي اولا");
      setEnMsg("Select Area First");
      setErrModal(true);
      setSubmitLoading(false);
      setTimeout(function () {
        setErrModal(false);
      }, 2500);
    } else if (selectedBranch === "" || selectedBranch === null) {
      setArMsg("اختر الفرع اولا");
      setEnMsg("Select Bracnh First");
      setErrModal(true);
      setSubmitLoading(false);
      setTimeout(function () {
        setErrModal(false);
      }, 2500);
    } else {
      let values = {};

      if (bh) {
        values = {
          arAddress:
            "البلد: " +
            selectedCountry.arName +
            "- المدينة: " +
            selectedCity.arName +
            "شارع: " +
            streetRef.current.value +
            " - رقم المبني:" +
            buildRef.current.value +
            " - رقم الدور: " +
            floorRef.current.value +
            " - رقم الشقة: " +
            appartRef.current.value +
            "- الحي: " +
            areaValue.arName +
            " - ملاحظات: " +
            noteRef.current.value,
          enAddress:
            "Country: " +
            selectedCountry.enName +
            "- City: " +
            selectedCity.enName +
            "Street: " +
            streetRef.current.value +
            " - Building No.:" +
            buildRef.current.value +
            " - Floor No.: " +
            floorRef.current.value +
            " - Appartment No.: " +
            appartRef.current.value +
            "- Area: " +
            areaValue.enName +
            " - Notes: " +
            noteRef.current.value,
          title: titleRef.current.value,
          branchId: selectedBranch,
          countryId: 2,
          areaId: areaValue.id,
          latitude: position.lat !== lat ? position.lat.toString() : "",
          longitude: position.lng !== lng ? position.lng.toString() : "",
        };
      } else {
        values = {
          arAddress:
            "البلد: " +
            selectedCountry.arName +
            "- المدينة: " +
            selectedCity.arName +
            "- الحي: " +
            areaValue.arName +
            " - ملاحظات: " +
            noteRef.current.value,
          enAddress:
            "Country: " +
            selectedCountry.enName +
            "- City: " +
            selectedCity.enName +
            "- Area: " +
            areaValue.enName +
            " - Notes: " +
            noteRef.current.value,
          title: titleRef.current.value,
          countryId: 1,
          branchId: selectedBranch,
          areaId: areaValue.id,
          latitude: position.lat !== lat ? position.lat.toString() : "",
          longitude: position.lng !== lng ? position.lng.toString() : "",
        };
      }

      addAddress(token, customerId, values)
        .then((response) => {
          if (response.data.message === "success") {
            if (localStorage.getItem("prev") == "address") {
              history("/customer-addresses");
            } else {
              history("/add-order");
            }
          } else {
            setArMsg("حدث خطأ");
            setEnMsg("Something went wrong");
            setErrModal(true);
            setSubmitLoading(false);
            setTimeout(function () {
              setErrModal(false);
            }, 2500);
          }
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            history("/");
          }
        });
    }
  }

  function onMarkerDragEnd(evt) {
    var newLat = evt.latLng.lat();
    var newLng = evt.latLng.lng();
    setPostion({
      lat: newLat,
      lng: newLng,
    });
  }

  function handleAutoComplete(place) {
    setPostion({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
  }

  const handleChange = (selectedOption) => {
    console.log(selectedOption.id)
    handleBranchChange({ target: { value: selectedOption.id } });
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "#c4efff"
        
        : "white",
      color: state.isFocused
        ? "black"
        : state.data.value !== 1
        ? "red"
        : "black",
      cursor: state.isDisabled ? "not-allowed" : "default",
     
      borderRadius: "4px",
      fontWeight: "normal",
      fontSize: "14px",
      textTransform: "uppercase",
      textDecoration: "none",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      transition: "background-color 0.3s ease",
    }),
  };

  const options = branch.map((data) => {
    let label = i18n.language === "ar" ? data.arName : data.enName;
    if (data.deliveryOption !== 1) {
      label += ` (${t("addOrder.noDelivery")})`;
    }
    return {
      value: data.deliveryOption,
      id:data.id,
      label: label,
    };
  });

  return (
    <div>
      <div className="processNav">
        <Link to="/customer-orders">
          <div>
            <img style={{ cursor: "pointer" }} src={icn1} />

            <p>{t("addOrder.profile")}</p>
          </div>
        </Link>
        <div className="dottedLine"></div>
        <Link to="/add-order">
          <div>
            <img style={{ cursor: "pointer" }} src={icn2} />
            <p style={{ color: "white" }}>{t("addOrder.details")}</p>
          </div>
        </Link>
        <div className="dottedLine"></div>
        <div>
          <img src={icn3} />
          <p style={{ color: "white" }}>{t("addOrder.menu")}</p>
        </div>

        <div className="dottedLine"></div>
        <div>
          <img src={icn4} />
          <p style={{ color: "white" }}>{t("addOrder.send")}</p>
        </div>
      </div>
      {errModal ? (
        <ErrorModal
          setModal={setErrModal}
          msg={i18n.language == "ar" ? arMsg : enMsg}
        />
      ) : null}
      {loading ? (
        <div style={{ height: "80vh" }} className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <div
          style={{ marginTop: "2em", paddingBottom: "2em" }}
          className="container"
        >
          <CustomerData loading={loading} active={1} />

          <div className="customerBody addressAdd">
            {submitLoading ? (
              <div style={{ height: "80vh" }} className="loader">
                <BeatLoader
                  color={"#E47100"}
                  loading={submitLoading}
                  size={30}
                />
              </div>
            ) : null}

            <div style={submitLoading ? { display: "none" } : null}>
              <p>{t("profile.add_address")}</p>
              <form onSubmit={(e) => onSubmit(e)}>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 ">
                      <div className="row">
                        <div className="col-12">
                          <label>{t("profile.title")}</label>
                          <select
                            defaultValue="المنزل"
                            id="title"
                            style={{ width: "100%" }}
                            ref={titleRef}
                            name="title"
                            required={true}
                          >
                            <option value="المنزل">المنزل</option>
                            <option value="الاستراحة">الاستراحة</option>
                            <option value="العمل">العمل</option>
                          </select>
                        </div>

                        <div className="col-6">
                          <label>{t("dashboard.country")}</label>
                          <select onChange={(e) => handleCountryChange(e)}>
                            {country &&
                              country.map((data, index) => (
                                <option
                                  value={JSON.stringify({
                                    index: index,
                                    arName: data.arName,
                                    enName: data.enName,
                                  })}
                                  key={data.id}
                                >
                                  {i18n.language == "ar"
                                    ? data.arName
                                    : data.enName}
                                </option>
                              ))}
                          </select>
                        </div>

                        <div className="col-6">
                          <label>{t("profile.city")}</label>
                          <select
                            defaultValue=""
                            id="city"
                            onChange={(e) => handleCityChange(e)}
                          >
                            <option value="" disabled>
                              {t("profile.choose")}
                            </option>
                            {city &&
                              city.map((data, index) => (
                                <option
                                  value={JSON.stringify({
                                    id: data.id,
                                    arName: data.arName,
                                    enName: data.enName,
                                  })}
                                  key={data.id}
                                >
                                  {i18n.language == "ar"
                                    ? data.arName
                                    : data.enName}
                                </option>
                              ))}
                          </select>
                        </div>

                        <div className="col-6">
                          <label>{t("profile.branch")}</label>
                          <Select
                            options={options}
                            
                            id="branch"
                            styles={customStyles}
                            onChange={handleChange}
                            placeholder={t("profile.choose")}
                          />
                        </div>

                        <div className="col-6">
                          <label>{t("profile.area")}</label>
                          <Select
                            isClearable
                            required={true}
                            onChange={(e) => handleAreaChange(e)}
                            value={selectedArea}
                            options={areas}
                          />
                        </div>

                        <div className="col-12">
                          <label>{t("profile.address_details")}</label>
                          <textarea ref={noteRef} />
                        </div>
                        {bh ? (
                          <>
                            <div className="col-6">
                              <label>{t("profile.street")}</label>
                              <input ref={streetRef} />
                            </div>
                            <div className="col-6">
                              <label>{t("profile.building")}</label>
                              <input ref={buildRef} type="number" />
                            </div>

                            <div className="col-6">
                              <label>{t("profile.floor")}</label>
                              <input ref={floorRef} type="number" />
                            </div>
                            <div className="col-6">
                              <label>{t("profile.appartment")}</label>
                              <input ref={appartRef} type="number" />
                            </div>
                          </>
                        ) : null}
                        <button type="submit" id="addAddressBtn">
                          {t("profile.save")}
                        </button>
                      </div>
                    </div>

                    <div className="col-md-6 alignVertMap">
                      <label>{t("profile.searchMap")}</label>
                      <Autocomplete
                        style={{ width: "100%", marginBottom: "14px" }}
                        onPlaceSelected={(place) => handleAutoComplete(place)}
                        options={{
                          types: ["geocode"],
                          componentRestrictions: { country: ["sa", "bh"] },
                        }}
                        placeholder={t("profile.enterName")}
                        id="place"
                        name="place"
                      />

                      {isLoaded ? (
                        <GoogleMap
                          zoom={10}
                          center={{ lat: position.lat, lng: position.lng }}
                          mapContainerClassName="map"
                          options={{
                            mapTypeControl: false,
                          }}
                        >
                          <Marker
                            draggable={true}
                            ref={markerRef}
                            position={{ lat: position.lat, lng: position.lng }}
                            onDragEnd={(e) => onMarkerDragEnd(e)}
                          />
                        </GoogleMap>
                      ) : null}
                      <button type="submit">{t("profile.save")}</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddAddress;
