
import React, {useState,useRef,useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { BeatLoader } from 'react-spinners';
import { RiArrowRightSFill,RiArrowLeftSFill } from 'react-icons/ri';
import { CiSquareRemove } from 'react-icons/ci';
import * as yup from "yup"
import {useFormik} from "formik";
import { useNavigate } from "react-router-dom";
import { register2,sendCode } from '../../api';
import Verify from '../../Components/Verify';
/*import TermsModal from '../Components/TermsModal';*/
import { Link } from 'react-router-dom';
import { propTypes } from 'react-bootstrap/esm/Image';
function RegisterStep2(props) {

  const { t, i18n } = useTranslation();
  const [loading,setLoading]=useState(false);
const [msgAr, setMsgAr]=useState();
const [msgEn, setMsgEn]=useState();
const [errorMsgModel, setErrorMsgModel]=useState(false);
const [editForm,setEditForm]=useState(false);

const [modal,setModal]=useState(false);
const [verify,setVerify]=useState(false);

const [authId,setAuthId]=useState();

const [arr, setArr] = useState(props.data.delegates);
const [data,setData]=useState(
{
  owner: props.data.ownerName,
  mobile: props.data.ownerPhone,
  id:props.data.IdentityNo,
  idSource:props.data.IdentitySource,
  manager: props.data.companyManagerName,
  purchasing: props.data.purchasingOfficierName,

}
);
const [validation,setValidation]=useState(
  {
    ownerName: {
      err:false,
      text:""
    },
    ownerPhone: {
      err:false,
      text:""
    },
    identityNo: {
      err:false,
      text:""
    },
    identitySource: {
      err:false,
      text:""
    },
    companyManagerName: {
      err:false,
      text:""
    },
    purchasingOfficierName: {
      err:false,
      text:""
    }
  }
);

    const history=useNavigate();
    const maxD= `${new Date().getFullYear()}-${parseInt(new Date().getMonth()+1)<10?`0${new Date().getMonth()+1}`:new Date().getMonth()+1}-${parseInt(new Date().getDate()-1)<10?`0${new Date().getDate()-1}`:new Date().getDate()-1}` //to set maximum date in datePicker which is 3 days after today's date
    let phoneRegEx =/^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;
    let nameRegEx= /^[\u0621-\u064Aa-zA-Z\-_\s]+$/;

    const Schema= yup.object().shape({
      owner: yup.string().required(("register2.required_owner")).matches(nameRegEx,{message:("validation.char")}),
      ownerMobile: yup.string().required(("register2.required_mobile")).matches(phoneRegEx,("register2.invalid_mobile")),
      id: yup.string().required(("register2.required_id")).max(12,("register2.max_id")).min(11,("register2.min_id")),
      idSource: yup.string().required(("register2.required_idSource")).max(150,("register2.max_idSource")),
   
      manager: yup.string().required(("register2.required_manager")).max(150,("register2.max_manager")).matches(nameRegEx,{message:("validation.char")}),
   
      purchasing: yup.string().required(("register2.required_purchasing")).max(150,("register2.max_purchasing")).matches(nameRegEx,{message:("validation.char")}),
  
    
   
    })
    



    const formik = useFormik({
    initialValues:{
      owner: data.owner,
      ownerMobile:  data.mobile,
      id: data.id,
      idSource: data.idSource,
    
      manager:  data.manager,
      purchasing:  data.purchasing,
   
   
    },
    validationSchema: Schema,
    enableReinitialize: true,

    onSubmit
    });
 
    const ownerRef=useRef();
    const mobileRef=useRef();
    const idRef=useRef();
    const idSourceRef=useRef();
    const managerRef=useRef();
    const purchasingRef=useRef();
 
 

     



function addField(){

let indexArr=arr; 
indexArr.push({name:"",mobile:"",role:""});
setArr(indexArr);
localStorage.setItem("fieldsArr",JSON.stringify(indexArr))
setTimeout(function() {
  
}, 100)
}


function removeIndex(index){



let indexArr=arr; 
 indexArr.splice(index,1);
 
  
 setArr(indexArr);

 
 if(!(index== indexArr.length)){
 document.getElementsByName("authName")[index].value = indexArr[index].name;

 }
 localStorage.setItem("fieldsArr",JSON.stringify(indexArr))
 setTimeout(function() {
   

 }, 100)



} 

    async function onSubmit(event){
      const token = localStorage.getItem("token")

      setLoading(true);
      const data={
        ownerName: ownerRef.current.value,
        ownerPhone:  mobileRef.current.value,
        identityNo: parseInt( idRef.current.value),
        identitySource: idSourceRef.current.value,
        companyManagerName: managerRef.current.value,
        purchasingOfficierName:purchasingRef.current.value,
        delegates:arr,
     
      
      }
      register2(token,localStorage.getItem("externalId"),data,i18n.language).then((response) => {
     setLoading(false)
     props.setUpdate(!props.update)
setEditForm(false)

}).catch(function (error) {
           
  let errorArr=error.response.data.errors;

  Object.keys(errorArr).forEach((key) => {
 
   if (validation.hasOwnProperty(key)) {
     setValidation((prevState) => ({
       ...prevState,
       [key]: {
         err: true,
         text: errorArr[key][0],
       },
     }));
   }
 });
 setLoading(false)   }
                                  
                                  )



  
                               }




function handleChange(index,e,type){
if(type=="name"){
if(e.target.value!==""){
  document.getElementsByName("authName")[index].classList.remove("invalid");

  document.getElementsByClassName("nameErr")[index].style.display = "none";
}

let indexArr=arr; 
 indexArr[index].name = e.target.value
setArr(indexArr)

localStorage.setItem("fieldsArr",JSON.stringify(indexArr))
}

else if(type=="mobile"){

if(e.target.value!==""){
  document.getElementsByName("authMobile")[index].classList.remove("invalid");

  document.getElementsByClassName("mobileErr")[index].style.display = "none";
}
if(e.target.value.match(phoneRegEx)!==null){
  document.getElementsByClassName("mobileErr2")[index].style.display = "none";

  document.getElementsByName("authMobile")[index].classList.remove("invalid");
}

let indexArr=arr; 
 indexArr[index].mobile = e.target.value
setArr(indexArr)

localStorage.setItem("fieldsArr",JSON.stringify(indexArr))



}
else{
if(e.target.value!==""){
  document.getElementsByName("position")[index].classList.remove("invalid");

  document.getElementsByClassName("positionErr")[index].style.display = "none";
}

let indexArr=arr; 
 indexArr[index].role = e.target.value
setArr(indexArr)

localStorage.setItem("fieldsArr",JSON.stringify(indexArr))



}
}

function handleValidate(index,e,type){
if(type=="name"){

if(e.target.value==""){
document.getElementsByName("authName")[index].classList.add("invalid");
document.getElementsByClassName("nameErr")[index].style.display = "block";
}}
else if(type=="mobile"){

if(e.target.value==""){
  document.getElementsByName("authMobile")[index].classList.add("invalid");
  document.getElementsByClassName("mobileErr")[index].style.display = "block";
  document.getElementsByClassName("mobileErr2")[index].style.display = "none";

}
else if(e.target.value.match(phoneRegEx)==null && !e.target.value==""){
document.getElementsByName("authMobile")[index].classList.add("invalid");
document.getElementsByClassName("mobileErr2")[index].style.display = "block";
}
}
else{
  if(e.target.value==""){
    document.getElementsByName("position")[index].classList.add("invalid");
    document.getElementsByClassName("positionErr")[index].style.display = "block";
  }
}

}


function handleSendCode(id){
  const token=localStorage.getItem("token");
 
   sendCode(token,id).then((response) => {
     setAuthId(id)
   setModal(true)
    
   
 
 
 }).catch(function (error) {})  
 }

 useEffect(() => {
  
  formik.setValues({
    owner: data.owner,
    ownerMobile:  data.mobile,
    id: data.id,
    idSource: data.idSource,
    manager:  data.manager,
    purchasing:  data.purchasing,
  });
  setEditForm(false); 

}, [data]);

useEffect(() => {
  setData({
    owner: props.data.ownerName,
    mobile: props.data.ownerPhone,
    id:props.data.IdentityNo,
    idSource:props.data.IdentitySource,
    manager: props.data.companyManagerName,
    purchasing: props.data.purchasingOfficierName,
  
  });

}, [props]);

 return (
  <div className='container-fluid'>
    {modal? <Verify id={authId} verify={verify} setVerify={setVerify} setModal={setModal}/>:null}
    {loading?
    <div style={{height:"110vh"}} className='loader'>
    <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:null}

 <form className={editForm?"row flexEnd":"row flexEnd disabledForm"} style={loading?{display:"none"}:null} onSubmit={formik.handleSubmit}>
 
<div className='col-sm-5 col-6'>

<div className="inputDiv">
<label>  {t("register2.owner")}</label>
<input type="text" placeholder={t("register2.owner")} value={formik.values.owner} id="owner" name="owner"  ref={ownerRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.owner && formik.touched.owner) || validation.ownerName.err==true ?"invalid":null}/>
<span>{formik.errors.owner && formik.touched.owner?t(`${formik.errors.owner}`):null}</span>
<span>{validation.ownerName.err=true? validation.ownerName.text:null}</span>

</div></div>

<div className='col-1 removeCol'>
</div>

<div className='col-sm-5 col-6'>
<div className="inputDiv">
<label>  {t("register2.mobile")}</label>
<input type="text" placeholder={t("register2.mobile")}  value={formik.values.ownerMobile} id="ownerMobile" name="ownerMobile" ref={mobileRef}  required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.ownerMobile && formik.touched.ownerMobile) || validation.ownerPhone.err==true ?"invalid":null}/>
<span>{formik.errors.ownerMobile && formik.touched.ownerMobile?t(`${formik.errors.ownerMobile}`):null}</span>
<span>{validation.ownerPhone.err=true? validation.ownerPhone.text:null}</span>

</div></div>
<div className='col-sm-5 col-6'>

<div className="inputDiv">
<label>  {t("register2.id")}</label>
<input type="number" placeholder={t("register2.id")} value={formik.values.id} id="id" name="id"  ref={idRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.id && formik.touched.id) || validation.identityNo.err==true ?"invalid":null}/>
<span>{formik.errors.id && formik.touched.id?t(`${formik.errors.id}`):null}</span>
<span>{validation.identityNo.err=true? validation.identityNo.text:null}</span>

</div></div>
<div className='col-1 removeCol'>
</div>
<div className='col-sm-5 col-6'>
<div className="inputDiv">
<label>  {t("register2.idSource")}</label>
<input type="text" placeholder={t("register2.idSource")} value={formik.values.idSource} id="idSource" name="idSource"  ref={idSourceRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.idSource && formik.touched.idSource) || validation.identitySource.err==true ?"invalid":null}/>
<span>{formik.errors.idSource && formik.touched.idSource?t(`${formik.errors.idSource}`):null}</span>
<span>{validation.identitySource.err=true? validation.identitySource.text:null}</span>

</div></div>
<div className='col-sm-5 col-6'>

<div className="inputDiv">
<label>  {t("register2.manager")}</label>
<input type="text" placeholder={t("register2.manager")} value={formik.values.manager} id="manager" name="manager"  ref={managerRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.manager && formik.touched.manager) || validation.companyManagerName.err==true ?"invalid":null}/>
<span>{formik.errors.manager && formik.touched.manager?t(`${formik.errors.manager}`):null}</span>
<span>{validation.companyManagerName.err=true? validation.companyManagerName.text:null}</span>

</div></div>
<div className='col-1 removeCol'>
</div>
<div className='col-sm-5 col-6'>
<div className="inputDiv">
<label>  {t("register2.purchasing")}</label>
<input type="text" placeholder={t("register2.purchasing")} value={formik.values.purchasing} id="purchasing" name="purchasing"  ref={purchasingRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.purchasing && formik.touched.purchasing) || validation.purchasingOfficierName.err==true?"invalid":null}/>
<span>{formik.errors.purchasing && formik.touched.purchasing?t(`${formik.errors.purchasing}`):null}</span>
<span>{validation.purchasingOfficierName.err=true? validation.purchasingOfficierName.text:null}</span>

</div></div>


<div className='col-sm-11'>

<div className='auth' >
<p style={{textAlign:"start",margin:"0"}} className='authTitle'>{t("register2.authorized")} </p>


</div></div>



{arr && arr.map((data,index)=>

<div key={index} className="col-12 authPadding disabledForm">
<div className='container-fluid'>
  <div className='row'>
  <p style={{margin:"0",marginTop:"1em",padding:"0"}}  className='authTitle'>{t("register2.auth")} {index+1} <span style={data.isVerify!==0?{display:"inline-block",margin:"0 1em",color:"#3eb122"}:{display:"inline-block",margin:"0 1em"}}>{data.isVerify!==0?"("+ t("register2.verified") + ")":"("+ t("register2.notVerified")+ ")"}</span></p>

  <div className='col-sm-5 col-6 noPadding'>

<div className="inputDiv">
<label style={{width:"85%"}}>  {t("register2.name")} </label>
<input disabled type="text" placeholder={t("register2.name")} defaultValue={data.name}  name={`authName`}   required={true} onChange={(e)=>{handleChange(index,e,"name")}} onBlur={(e)=>{handleValidate(index,e,"name")}}/>
<span className='nameErr' style={{display:"none"}}>{t(`register2.required_name`)}</span>
</div></div>
<div className='col-1 removeCol'>
</div>
<div className='col-sm-5 col-6 noPadding'>

<div className="inputDiv">
<label>  {t("register2.authMobile")}</label>
<input disabled type="number" placeholder={t("register2.authMobile")} defaultValue={data.mobile}  name={`authMobile`} onChange={(e)=>{handleChange(index,e,"mobile")}} onBlur={(e)=>{handleValidate(index,e,"mobile")}} required={true} />
<span className='mobileErr' style={{display:"none"}}>{t(`register2.required_authMobile`)}</span>
<span className='mobileErr2' style={{display:"none"}}>{t(`register2.invalid_authMobile`)}</span>

</div></div>
<div className='col-1 removeCol'>
</div>
<div className='col-sm-5 col-6 noPadding'>

<div className="inputDiv">
<label>  {t("register2.position")}</label>
<input disabled type="text" placeholder={t("register2.position")} defaultValue={data.role} name={`position`}   required={true} onChange={(e)=>{handleChange(index,e,"position")}} onBlur={(e)=>{handleValidate(index,e,"position")}} />
<span className='positionErr' style={{display:"none"}}>{t(`register2.required_position`)}</span>
</div>
</div>
<div className='col-1 removeCol'>
</div>
{data.isVerify==0?
<div className='col-sm-5 col-6 noPadding verify'>

<button type="button" onClick={()=>handleSendCode(data.id)} className="dataEdit">{t("register2.verify")}</button>
</div>:null}
</div></div></div>
)
} 



{i18n.language=="ar"?
<span>{errorMsgModel? msgAr:null}</span>:
<span>{errorMsgModel? msgEn:null}</span>}


{editForm?

<div style={{marginTop:"2em"}} className="formBtns">
<button type="submit" className="dataEdit">{t("profile.save")}</button>
<span> </span>
<button type="button" className="cancelEdit" onClick={()=> { props.setUpdate(!props.update) }}>  {t("profile.cancel")}</button></div>
:
<div className="formBtns" style={{marginTop:"1em"}}>
  
<button type="button" className="dataEdit"  onClick={(e)=> {e.preventDefault(); setEditForm(true)}}>{t("profile.edit")}</button>   
</div>
}

</form>

</div>

  );
}

export default RegisterStep2;