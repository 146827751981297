
import React, {useState,useRef,useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { BeatLoader } from 'react-spinners';
import { RiArrowRightSFill,RiArrowLeftSFill } from 'react-icons/ri';
import { CiSquareRemove } from 'react-icons/ci';
import * as yup from "yup"
import {useFormik} from "formik";
import { register2,externalUser } from '../../api';
import { useNavigate } from "react-router-dom";
/*import TermsModal from '../Components/TermsModal';*/
import { Link } from 'react-router-dom';
function RegisterStep2() {

  const { t, i18n } = useTranslation();
  const [loading,setLoading]=useState(true);
  const [loadField,setLoadField]=useState(false);

const [msgAr, setMsgAr]=useState();
const [msgEn, setMsgEn]=useState();
const [errorMsgModel, setErrorMsgModel]=useState(false);
const [allData,setAllData]=useState();



const [arr, setArr] = useState(localStorage.getItem("fieldsArr")?JSON.parse(localStorage.getItem("fieldsArr")):[{name:"",mobile:"",role:""}]);
const [data,setData]=useState(
{
  owner: '',
  mobile: '',
  id:'',
  idSource:'',
  manager: '',
  purchasing: '',

}
);
const [validation,setValidation]=useState(
  {
    ownerName: {
      err:false,
      text:""
    },
    ownerPhone: {
      err:false,
      text:""
    },
    identityNo: {
      err:false,
      text:""
    },
    identitySource: {
      err:false,
      text:""
    },
    companyManagerName: {
      err:false,
      text:""
    },
    purchasingOfficierName: {
      err:false,
      text:""
    }
  }
);

    const history=useNavigate();
    const maxD= `${new Date().getFullYear()}-${parseInt(new Date().getMonth()+1)<10?`0${new Date().getMonth()+1}`:new Date().getMonth()+1}-${parseInt(new Date().getDate()-1)<10?`0${new Date().getDate()-1}`:new Date().getDate()-1}` //to set maximum date in datePicker which is 3 days after today's date
    let phoneRegEx =/^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;

    let nameRegEx= /^[\u0621-\u064Aa-zA-Z\-_\s]+$/;

    const Schema= yup.object().shape({
      owner: yup.string().required(("register2.required_owner")).matches(nameRegEx,{message:("validation.char")}),
      ownerMobile: yup.string().required(("register2.required_mobile")).matches(phoneRegEx,("register2.invalid_mobile")),
      id: yup.string().required(("register2.required_id")).max(12,("register2.max_id")).min(11,("register2.min_id")),
      idSource: yup.string().required(("register2.required_idSource")).max(150,("register2.max_idSource")),
   
      manager: yup.string().required(("register2.required_manager")).max(150,("register2.max_manager")).matches(nameRegEx,{message:("validation.char")}),
   
      purchasing: yup.string().required(("register2.required_purchasing")).max(150,("register2.max_purchasing")).matches(nameRegEx,{message:("validation.char")}),
  
    })
    



    useEffect(() => {
      const token = localStorage.getItem("token")

      externalUser(token,localStorage.getItem("externalId")).then(response => {
        setAllData(response.data.data)

if(response.data.data.lastCompletedStep!==null && response.data.data.lastCompletedStep!=="first"){
setData(
  {
    owner: response.data.data.ownerName,
    mobile:  response.data.data.ownerPhone,
    id:response.data.data.IdentityNo,
    idSource:response.data.data.IdentitySource,
    manager:response.data.data.companyManagerName,
    purchasing:response.data.data.purchasingOfficierName,
   }
)
setArr(response.data.data.delegates)
localStorage.setItem("fieldsArr",JSON.stringify(response.data.data.delegates))

    
}
setLoading(false)

      })
       },[]);



    const formik = useFormik({
    initialValues:{
      owner: data.owner,
      ownerMobile:  data.mobile,
      id: data.id,
      idSource: data.idSource,
    
      manager:  data.manager,
      purchasing:  data.purchasing,
   
   
    },
    validationSchema: Schema,
    enableReinitialize: true,

    onSubmit
    });
 
    const ownerRef=useRef();
    const mobileRef=useRef();
    const idRef=useRef();
    const idSourceRef=useRef();
    const managerRef=useRef();
    const purchasingRef=useRef();
 
 

     



function addField(){
setLoadField(true)

let indexArr=arr; 
indexArr.push({name:"",mobile:"",role:""});
setArr(indexArr);
localStorage.setItem("fieldsArr",JSON.stringify(indexArr))
setTimeout(function() {
  
  setLoadField(false)
}, 100)
}


function removeIndex(index){

setLoadField(true)


let indexArr=arr; 
 indexArr.splice(index,1);
 
  
 setArr(indexArr);

 
 if(!(index== indexArr.length)){
 document.getElementsByName("authName")[index].value = indexArr[index].name;

 }
 localStorage.setItem("fieldsArr",JSON.stringify(indexArr))
 setTimeout(function() {
   
  setLoadField(false)

 }, 100)



} 

    async function onSubmit(event){
      const token = localStorage.getItem("token")

      setLoading(true);
      const data={
        ownerName: ownerRef.current.value,
        ownerPhone:  mobileRef.current.value,
        identityNo: parseInt( idRef.current.value),
        identitySource: idSourceRef.current.value,
        companyManagerName: managerRef.current.value,
        purchasingOfficierName:purchasingRef.current.value,
        delegates:arr,
     
      
      }
      register2(token,localStorage.getItem("externalId"),data,i18n.language).then((response) => {
        if( localStorage.getItem("lastStep")!=="completed"){
          localStorage.setItem("lastStep","second")}
          history("/register-3");
       



}).catch(function (error) {
           
  let errorArr=error.response.data.errors;

  Object.keys(errorArr).forEach((key) => {
 
   if (validation.hasOwnProperty(key)) {
     setValidation((prevState) => ({
       ...prevState,
       [key]: {
         err: true,
         text: errorArr[key][0],
       },
     }));
   }
 });
 setLoading(false)   }
                                  
                                  )



  
                               }




function handleChange(index,e,type){
if(type=="name"){
if(e.target.value!==""){
  document.getElementsByName("authName")[index].classList.remove("invalid");

  document.getElementsByClassName("nameErr")[index].style.display = "none";
}

let indexArr=arr; 
 indexArr[index].name = e.target.value
setArr(indexArr)

localStorage.setItem("fieldsArr",JSON.stringify(indexArr))
}

else if(type=="mobile"){

if(e.target.value!==""){
  document.getElementsByName("authMobile")[index].classList.remove("invalid");

  document.getElementsByClassName("mobileErr")[index].style.display = "none";
}
if(e.target.value.match(phoneRegEx)!==null){
  document.getElementsByClassName("mobileErr2")[index].style.display = "none";

  document.getElementsByName("authMobile")[index].classList.remove("invalid");
}

let indexArr=arr; 
 indexArr[index].mobile = e.target.value
setArr(indexArr)

localStorage.setItem("fieldsArr",JSON.stringify(indexArr))



}
else{
if(e.target.value!==""){
  document.getElementsByName("position")[index].classList.remove("invalid");

  document.getElementsByClassName("positionErr")[index].style.display = "none";
}

let indexArr=arr; 
 indexArr[index].role = e.target.value
setArr(indexArr)

localStorage.setItem("fieldsArr",JSON.stringify(indexArr))



}
}

function handleValidate(index,e,type){
if(type=="name"){

if(e.target.value==""){
document.getElementsByName("authName")[index].classList.add("invalid");
document.getElementsByClassName("nameErr")[index].style.display = "block";
}}
else if(type=="mobile"){

if(e.target.value==""){
  document.getElementsByName("authMobile")[index].classList.add("invalid");
  document.getElementsByClassName("mobileErr")[index].style.display = "block";
  document.getElementsByClassName("mobileErr2")[index].style.display = "none";

}
else if(e.target.value.match(phoneRegEx)==null && !e.target.value==""){
document.getElementsByName("authMobile")[index].classList.add("invalid");
document.getElementsByClassName("mobileErr2")[index].style.display = "block";
}
}
else{
  if(e.target.value==""){
    document.getElementsByName("position")[index].classList.add("invalid");
    document.getElementsByClassName("positionErr")[index].style.display = "block";
  }
}

}

 return (
      <div>
        {loading?
    <div style={{height:"110vh"}} className='loader'>
    <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
    <>
     <div className=' container'>
<div className='navigateSteps'>
<Link to='/register-1' >{t("register.step1")} </Link>
{i18n.language=="ar"? 
<RiArrowLeftSFill size={25}/>: <RiArrowRightSFill size={25}/>} 
<Link to='/register-2'style={{color:'#E47100'}} >{t("register2.step2")} </Link>
{i18n.language=="ar"?
<RiArrowLeftSFill size={25}/>: <RiArrowRightSFill size={25}/>}
<Link to={allData.lastCompletedStep!=="second"?'/register-2':'/register-3'} >{t("register3.step3")} </Link>

</div></div>
      <div className='loginContainer container ' style={{paddingTop:"2em",paddingBottom:"0"}}>

<div className=' login' style={{height:"auto"}}>

<div style={loading?{display:"none"}:null}  className='row'>
<div className='col'>
  <div className='loginTitle'>
<p >{t("register2.step2")} </p></div>

<div className='container-fluid userDataExt'style={{padding: "2em 1em"}} >
<form className="row flexEnd" onSubmit={formik.handleSubmit}>
<div className='col-sm-5 col-6'>

<div className="inputDiv">
<label>  {t("register2.owner")}</label>
<input type="text" placeholder={t("register2.owner")} value={formik.values.owner} id="owner" name="owner"  ref={ownerRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.owner && formik.touched.owner) || validation.ownerName.err==true ?"invalid":null}/>
<span>{formik.errors.owner && formik.touched.owner?t(`${formik.errors.owner}`):null}</span>
<span>{validation.ownerName.err=true? validation.ownerName.text:null}</span>

</div>
</div>

<div className='col-1 removeCol'>
</div>

<div className='col-sm-5 col-6'>
<div className="inputDiv">
<label>  {t("register2.mobile")}</label>
<input type="text" placeholder={t("register2.mobile")}  value={formik.values.ownerMobile} id="ownerMobile" name="ownerMobile" ref={mobileRef}  required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.ownerMobile && formik.touched.ownerMobile) || validation.ownerPhone.err==true ?"invalid":null}/>
<span>{formik.errors.ownerMobile && formik.touched.ownerMobile?t(`${formik.errors.ownerMobile}`):null}</span>
<span>{validation.ownerPhone.err=true? validation.ownerPhone.text:null}</span>

</div>
</div>
<div className='col-sm-5 col-6'>

<div className="inputDiv">
<label>  {t("register2.id")}</label>
<input type="number" placeholder={t("register2.id")} value={formik.values.id} id="id" name="id"  ref={idRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.id && formik.touched.id) || validation.identityNo.err==true ?"invalid":null}/>
<span>{formik.errors.id && formik.touched.id?t(`${formik.errors.id}`):null}</span>
<span>{validation.identityNo.err=true? validation.identityNo.text:null}</span>

</div>
</div>
<div className='col-1 removeCol'>
</div>
<div className='col-sm-5 col-6'>
<div className="inputDiv">
<label>  {t("register2.idSource")}</label>
<input type="text" placeholder={t("register2.idSource")} value={formik.values.idSource} id="idSource" name="idSource"  ref={idSourceRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.idSource && formik.touched.idSource) || validation.identitySource.err==true ?"invalid":null}/>
<span>{formik.errors.idSource && formik.touched.idSource?t(`${formik.errors.idSource}`):null}</span>
<span>{validation.identitySource.err=true? validation.identitySource.text:null}</span>

</div>
</div>
<div className='col-sm-5 col-6'>
<div className="inputDiv">
<label>  {t("register2.manager")}</label>
<input type="text" placeholder={t("register2.manager")} value={formik.values.manager} id="manager" name="manager"  ref={managerRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.manager && formik.touched.manager) || validation.companyManagerName.err==true ?"invalid":null}/>
<span>{formik.errors.manager && formik.touched.manager?t(`${formik.errors.manager}`):null}</span>
<span>{validation.companyManagerName.err=true? validation.companyManagerName.text:null}</span>

</div>
</div>
<div className='col-1 removeCol'>
</div>
<div className='col-sm-5 col-6'>

<div className="inputDiv">
<label>  {t("register2.purchasing")}</label>
<input type="text" placeholder={t("register2.purchasing")} value={formik.values.purchasing} id="purchasing" name="purchasing"  ref={purchasingRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.purchasing && formik.touched.purchasing) || validation.purchasingOfficierName.err==true?"invalid":null}/>
<span>{formik.errors.purchasing && formik.touched.purchasing?t(`${formik.errors.purchasing}`):null}</span>
<span>{validation.purchasingOfficierName.err=true? validation.purchasingOfficierName.text:null}</span>

</div></div>


<div className='col-sm-11'>

<div className='auth' >
<p  className='authTitle'>{t("register2.authorized")} </p>

<button  type="button" onClick={addField}  className='cancelEdit addAuth'>{t("register2.add")} </button>

</div></div>


 
{arr && arr.map((data,index)=>

<div key={index} className="col-12 authPadding">
<div className='container-fluid'>
  <div className='row'>
  <div className='col-sm-5 col-6 noPadding'>

<div className="inputDiv">
<label style={{width:"85%"}}><CiSquareRemove style={i18n.language=="ar"?{marginRight:"0"}:{marginLeft:"0"}} size={25} onClick={()=>removeIndex(index)}/>  {t("register2.name")} </label>
<input type="text" placeholder={t("register2.name")} defaultValue={data.name}  name={`authName`}   required={true} onChange={(e)=>{handleChange(index,e,"name")}} onBlur={(e)=>{handleValidate(index,e,"name")}}/>
<span className='nameErr' style={{display:"none"}}>{t(`register2.required_name`)}</span>
</div></div>
<div className='col-1 removeCol'>
</div>
<div className='col-sm-5 col-6 noPadding'>

<div className="inputDiv">
<label>  {t("register2.authMobile")}</label>
<input type="text" placeholder={t("register2.authMobile")} defaultValue={data.mobile}  name={`authMobile`} onChange={(e)=>{handleChange(index,e,"mobile")}} onBlur={(e)=>{handleValidate(index,e,"mobile")}} required={true} />
<span className='mobileErr' style={{display:"none"}}>{t(`register2.required_authMobile`)}</span>
<span className='mobileErr2' style={{display:"none"}}>{t(`register2.invalid_authMobile`)}</span>

</div></div>
<div className='col-1 removeCol'>
</div>
<div className='col-sm-5 col-6 noPadding'>

<div className="inputDiv">
<label>  {t("register2.position")}</label>
<input type="text" placeholder={t("register2.position")} defaultValue={data.role} name={`position`}   required={true} onChange={(e)=>{handleChange(index,e,"position")}} onBlur={(e)=>{handleValidate(index,e,"position")}} />
<span className='positionErr' style={{display:"none"}}>{t(`register2.required_position`)}</span>
</div>
</div></div></div></div>
)
} 

{i18n.language=="ar"?
<span>{errorMsgModel? msgAr:null}</span>:
<span>{errorMsgModel? msgEn:null}</span>}
<div className='navigateReg'>
<Link to="/register-1">
<button className='cancelEdit'> {t("orders.prev")}</button></Link>

<span style={{margin:"0 1em",display:"inline-block"}}></span>
<button type="submit" className="dataEdit"> {t("orders.next")}</button>

</div>
</form>

</div>

</div>

</div>
</div>
</div></>}
<div style={{height:"6em"}}>

</div>
</div>
  );
}

export default RegisterStep2;