import "../Styles/Areas.css";
import Navbar from "../Components/Navbar";
import { useTranslation } from "react-i18next";
import { areas, cities, invoiceData } from "../api";
import { BeatLoader } from "react-spinners";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { generatePath } from "react-router-dom";
import img from "../Images/order-added.svg";

function OrderSuccess() {
  const { t, i18n } = useTranslation();
  let history = useNavigate();
  const [invoice, setInvoice] = useState(null);

  const handleReturn = () => {
    history(generatePath("/customer-orders"));
  };

  const handleReceive = () => {
    history("/dashboard");
  };

  const handlePrint = () => {
    history("/order/invoice", { state: { invoice } });
  };

  const getInvoice = () => {
    const orderId = parseInt(localStorage.getItem("orderId"));
    const token = localStorage.getItem("token");
    invoiceData(orderId, token).then((res) => {
      setInvoice(res.data.data);
    });
  };

  useEffect(() => {
    localStorage.removeItem("userAwaifiBalance")
    getInvoice();
  }, []);

  return (
    <div className="orderSuccess">
      <div className="alignCenter">
        <img src={img} />
        <p>{t("menu.success")}</p>

        

        <div>
          
          {localStorage.getItem("type") === "cashier" && (
            <>
            <button onClick={handlePrint} className="success-btn1">
            {t("print")}
          </button>
          <span></span>
          </>
          ) }
          {localStorage.getItem("type") === "cashier" ? null :  
          (
            <>
           
            <button onClick={handleReturn} className="success-btn2">
            {t("menu.return")}
          </button>
          <span></span>
          </>
          )
          }
          

          <button onClick={handleReceive} className="success-btn1">
            {t("menu.receive")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default OrderSuccess;
