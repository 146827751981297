import '../Styles/Dashboard.css';
import { AiOutlineSearch } from 'react-icons/ai';
import Navbar from '../Components/Navbar';
import { useTranslation } from 'react-i18next';
import { IoCloseSharp } from "react-icons/io5";
import bckg from "../Images/order.svg"
import {RiShoppingCartFill } from 'react-icons/ri';
function Discount(props) {
   
    const { t, i18n } = useTranslation();


    return (
        <div > 
         {console.log(props.button)}
        <div onClick={()=>{props.setModal(false);props.setSubmitLoading(false);  }} className="modalOverlay"></div>
        <div  className="modal-content" style={{height:"auto",width:"40vw ",minWidth:"337px",paddingTop:"2em",position:"fixed"}}>
       <p className='alrt' style={{fontSize:"20px"}}>{i18n.language=="ar"?`لقد حصلت على خصم ${props.discount}  ${props.currencyAr} `:`You have a  ${props.discount} ${props.currencyEn} discount`}</p>
       {i18n.language=="ar"?    <p className='alrt'> {t("menu.price")} { props.total } {props.currencyAr} <span style={{margin:"0 5px",fontWeight:"bold"}}> بدلا من</span> { props.original } {props.currencyAr} </p>
:
<p className='alrt'>{t("menu.price")}  {props.total} {props.currencyEn} <span style={{margin:"0 5px",fontWeight:"bold"}}>
  instead of     </span> {props.original} {props.currencyEn}</p>}
{props.button?
       <div className='modalBtns' style={{marginTop:"2em"}}>
<button  onClick={()=>{props.createOrder(); props.setModal(false)}}  className='del'>{t("menu.continue")}</button>
<button  onClick={()=>{props.setSubmitLoading(false); props.setModal(false); }}  className='cls'>{t("profile.cancel")}</button>

</div>:null}

        </div>
      </div>
  
    );
  }
  
  export default Discount;
  
  