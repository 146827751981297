import '../Styles/CustomerOrders.css';

import Navbar from '../Components/Navbar';
import { useTranslation } from 'react-i18next';
import { FiEdit } from 'react-icons/fi';
import { BsGlobe2,BsInfoCircle } from 'react-icons/bs';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import bckg from "../Images/order.svg"
import {useState,useEffect}  from 'react';
import { useNavigate } from "react-router-dom";
import { customer,customerAwafiWallet,customerAwafiBalance } from '../api';
import { BeatLoader } from 'react-spinners';
import { useParams,generatePath } from 'react-router-dom';
import CustomerData from '../Components/CustomerData';
import { MDBDataTable } from 'mdbreact';
import icn1 from "../Images/customerProfile.svg"
import icn2 from "../Images/orderDetailsIconInactive.svg"
import icn3 from "../Images/menuIconUnactive.svg"
import icn4 from "../Images/basketIconUnactive.svg"
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import PaginationItem from '@mui/material/PaginationItem';

function CustomerAwafiWallet(props) {
     
  const { t, i18n } = useTranslation();

  const[loading,setLoading]=useState(true);
  const[page,setPage]=useState(1)
  const[resp,setResp]=useState()
  const[tableLoading,setTableLoading]=useState(false)

  const [wallet,setWallet]=useState([])
  const [balance,setBalance]=useState(0)
  const customerId= localStorage.getItem("customerId");
 let history= useNavigate();
   



   //customerAwafiBalance=> takes customer id and user token and returns customer balance
  //customerAwafiWallet=> takes customer id and user token and returns customer transactions
 
useEffect(() => {

const token= localStorage.getItem("token");
window.scrollTo(0, 0);
setLoading(true)

customerAwafiBalance(token,customerId).then((response) => {
  setBalance(response.data.data)


}).catch(function(error){
if (error.response.status === 401) {
 history("/")
}

});

customerAwafiWallet(token,customerId,page).then((response) => {
 // setWallet(response.data.data)
 if( response.data.data.toString()==[]){
  setWallet([])

 }
 else{
 const result = Object.values(response.data.data.data);
console.log(response.data.data.last_page)
 setResp(response.data.data)
  let walletArr = [];
  for (let i = 0; i < result.length; i++) {  
      let obj = {};
         
      obj.id = result[i].id
      obj.amount = result[i].amount      
      obj.type = result[i].type;        
      obj.createdAt =  result[i].createdAt; 
      obj.orderId =  result[i].orderId;
      obj.notes =  result[i].notes;    
      walletArr.push(obj);  } 

      setWallet(walletArr)}



   
setLoading(false)


}).catch(function(error){
if (error.response.status === 401) {
 history("/")
}
else if(error.response.status === 403){
  props.setErr(true)
  props.setMsg(t('validation.noPermission'))
  props.setStyle({top:"50%"})
  }
      
  
  history("/customer-orders")
});

},[]) 

function chnagePage(page){
  setTableLoading(true)
  setPage(page)
     const token= localStorage.getItem("token");
     window.scrollTo(0, 0);

  customerAwafiWallet(token,customerId,page).then((response) => {
    // setWallet(response.data.data)
    if( response.data.data.toString()==[]){
     setWallet([])
     setTableLoading(false)

    }
    else{
    const result = Object.values(response.data.data.data);
   console.log(response.data.data.last_page)
    setResp(response.data.data)
     let walletArr = [];
     for (let i = 0; i < result.length; i++) {  
         let obj = {};
            
         obj.id = result[i].id
         obj.amount = result[i].amount      
         obj.type = result[i].type;        
         obj.createdAt =  result[i].createdAt; 
         obj.orderId =  result[i].orderId;
         obj.notes =  result[i].notes;    
         walletArr.push(obj);  } 
   
         setWallet(walletArr)
         setTableLoading(false)
        }
   
   
   
      

   
   
   }).catch(function(error){
   if (error.response.status === 401) {
    history("/")
   }
   
   });
   

}



const data = {
  columns: [
    {
      label: t("profile.t-number"),
      field: 'id',
      sort: 'asc',
 
    },
    {
      label: t("profile.amount"),
      field: 'amount',
      sort: 'asc',
    
    },
    {
      label: t("profile.t-type"),
      field: 'type',
      sort: 'asc',
    
    },

    {
      label: t("profile.t-date"),
      field: 'createdAt',
      sort: 'asc',
    
    },
    {
      label:t("profile.orderId"),
      field: 'orderId',
      sort: 'asc',
     
    },
    {
      label: t("menu.notes"),
      field: 'notes',
      sort: 'asc',
     
    }
  
   
  ],    rows: wallet
  
};



  
    return (
      <div >
        <div className='processNav' >
          <Link to="/customer-orders">
<div>
<img style={{cursor:"pointer"}} src={icn1}/>

<p>{t("addOrder.profile")}</p>
</div></Link>
<div className='dottedLine'></div>
<Link to="/add-order">
<div>
<img style={{cursor:"pointer"}}  src={icn2}/>
<p  style={{color:"white"}}>{t("addOrder.details")}</p></div>
</Link>
<div className='dottedLine'></div>
<div>
<img src={icn3}/>
<p style={{color:"white"}}>{t("addOrder.menu")}</p></div>

<div className='dottedLine'></div>
<div>
<img src={icn4}/>
<p style={{color:"white"}}>{t("addOrder.send")}</p></div>
  </div>
          {loading?
               <div style={{height:"80vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
          <div style={{marginTop:"2em"}} className='container-fluid'>
          
          <CustomerData loading={loading}  active={3}/>
      
<div className='customerBody customerOrders'>


<div className='row gy-4'>
<div className='col-md-9 order-md-first order-last'>
{tableLoading?
               <div style={{height:"30vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={tableLoading}  size={30} /></div>:
      <>
<div className={i18n.language=="ar"?'container-fluid pagAr':"container-fluid"}>
          <div className={i18n.language=="ar"?"row orderTable walletTable":"row tableEn orderTable walletTableEn"}>
          <div className={ wallet.length==0?i18n.language=="ar"?" emptyAr empty col-lg-12 col-md-12 col-sm-12 col-xs-12":"  empty col-lg-12 col-md-12 col-sm-12 col-xs-12":"col-lg-12 col-md-12 col-sm-12 col-xs-12" }>
                                <MDBDataTable
                                entries={25}
                                entriesOptions={[25]}
                                infoLabel={[t("orders.info1"), t("orders.info2"), t("orders.info3"), t("orders.info4")]} 
                                searchLabel={t("dashboard.search")}
                                paginationLabel={ [t("orders.prev"), t("orders.next")]}
                                entriesLabel={t("orders.show")}
                                    striped
                                    hover
                                    noBottomColumns={true}
                                    data={data}
                                />  
                            </div>
                        </div>
                        </div> 
                        {wallet.length==0?null:

                   <Stack spacing={0}>
      <Pagination 
      renderItem={(item) => (
        <PaginationItem
          slots={{ previous: i18n.language=="ar"?'prevAr':"prevEn" , next: i18n.language=="ar"?'nextAr':"nextEn" }}
          {...item}
        />)}
 
      className={i18n.language=="ar"?'arArrow':null} defaultPage={1} page={page}  count={resp.last_page} onChange={(e,value)=>{ chnagePage(value)}} shape="rounded" variant="outlined" 
      
      />
      </Stack>}</>}
  {/*
<table className="table align-middle  table-hover table-borderless orders-table">
  <thead>
    <tr>
 
    
 
 
   
   
      <th >{t("profile.t-number")}</th>
      <th >{t("profile.amount")}</th>
      <th >{t("profile.t-type")}</th>
      <th  >{t("profile.t-date")}</th>
      <th  >{t("profile.orderId")}</th>
      <th  >{t("menu.notes")}</th>
    </tr>
  </thead>
  <tbody>


  {wallet && wallet.map((data, index) => 
   <tr key={index}>
      
     
   <td>{data.id}</td>
   <td>{data.amount}</td>
   <td>{data.type}</td>
   <td  >{data.createdAt} </td>
   <td  >{data.orderId} </td>
   <td  >{data.notes} </td>
   
   </tr>

)
  }


  

   
  </tbody>
</table>
*/}
</div>
<div className='col-md-3' >
<div className='currentCredit'>
<p>{ t("profile.current")}</p>
<p className='credit'>{balance.balance? balance.balance.toFixed(2):0}</p>
<p>{i18n.language=="ar"?balance.arCurrency:balance.enCurrency}</p>
</div>


</div>

</div>


</div>

       </div>}</div>
    );
  }
  
  export default CustomerAwafiWallet;
  
  