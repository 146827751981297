import "../Styles/CustomerOrders.css";
import Navbar from "../Components/Navbar";
import { useTranslation } from "react-i18next";
import { FiEdit } from "react-icons/fi";
import { BsGlobe2, BsInfoCircle } from "react-icons/bs";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import bckg from "../Images/order.svg";
import img from "../Images/defaultImage.jpeg";
import { useState, useEffect } from "react";
import { customer, block } from "../api";
import { useNavigate } from "react-router-dom";
import { HiOutlineChatBubbleLeftRight } from "react-icons/hi2";
import { generatePath } from "react-router-dom";
import { MdBlockFlipped, MdLockOpen } from "react-icons/md";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import Toast from "react-bootstrap/Toast";

import { styled } from "@mui/system";
import { GlobalStyles } from "@mui/material";
import axios from "axios";

const useStyles = styled((theme) => ({
  dialogTitle: {
    textAlign: "center",
  },
  dialogActions: {
    justifyContent: "center",
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
}));

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 10,
    width: "50vw",
    fontFamily: "Cairo", // Add this line
  },
  "& .MuiDialogTitle-root, & .MuiDialogContent-root, & .MuiDialogActions-root":
    {
      fontFamily: "Cairo", // Ensure all parts of the dialog use the Cairo font
    },
}));

function CustomerData(props) {
  const customerName = localStorage.getItem("customerName");
  const customerPhone = localStorage.getItem("customerPhone");
  const customerPhoto = localStorage.getItem("customerPhoto");
  const customerNotes = localStorage.getItem("customerNotes");
  const customerId = localStorage.getItem("customerId");
  const [classification, setClassification] = useState([]);
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [reason, setReason] = useState("");
  const [notify, setNotify] = useState(false);
  const [toast, setToast] = useState(null);

  const [isUserBlocked, setIsUserBlocked] = useState(false);

  const handleProceed = (e) => {
    navigate(generatePath("/chat/:id", { id: customerId }));
  };

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleSubmit = () => {
    const token = localStorage.getItem("token");
    const data = {
      comment: reason,
    };
    block(token, parseInt(localStorage.getItem("customerId")), data)
      .then((res) => {
        console.log(res.data.data);
        setToast("تم حظر العميل بنجاح");
        setNotify(true);
        setIsUserBlocked(true);
        setReason("")
      })
      .catch((e) => {
        console.log(e);
        setToast(e.response.data)
      });

    console.log("Reason for blocking:", reason);
    setOpen(false);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    customer(token, customerId)
      .then((response) => {
        setClassification(response.data.data.classifications || []);
        setLoading(false);
      })

      .catch(function (error) {
        if (error.response.status === 401) {
          navigate("/");
        }
      });
  }, [customerId, navigate, isUserBlocked]);

  useEffect(() => {
    checkUserBlock();
  }, [classification]);

  const checkUserBlock = () => {
    console.log(classification);
    const isBlocked = classification.some((e) => e.enName === "blocked");
    setIsUserBlocked(isBlocked);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const HandleUnBlock = () => {
    const token = localStorage.getItem("token");
    const data = {
      comment: "unblock",
    };
    block(token, parseInt(localStorage.getItem("customerId")), data)
      .then((res) => {
        console.log(res.data.data);
        setToast("تم تفعيل العميل بنجاح");
        setNotify(true);
        setIsUserBlocked(false);
      })
      .catch((e) => {
        console.log(e);
        setToast(e.response.data)
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Toast
        onClose={() => setNotify(false)}
        show={notify}
        delay={5000}
        autohide
        className="bg-success"
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1050,
        }}
      
      >
        <Toast.Body>
          <p style={{ fontFamily: "Cairo", color: "white" }}>{toast}</p>{" "}
        </Toast.Body>
      </Toast>
      <GlobalStyles
        styles={{
          "textarea:focus": {
            borderColor: "transparent !important",
            boxShadow: "none !important",
            outline: "none !important",
          },
        }}
      />
      <CustomDialog
        style={{ alignItems: "center" }}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle
          className={useStyles.dialogTitle}
          style={{ textAlign: "center" }}
        >
          {t("reasonToBlock")}
        </DialogTitle>
        <DialogContent>
          <TextField
            style={{ marginTop: "10px", borderColor: "#E47100" }}
            className={useStyles.marginTop}
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={reason}
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#E47100",
                },
              },
            }}
            onChange={handleReasonChange}
            InputLabelProps={{
              style: {
                direction: i18n.language === "ar" ? "rtl" : "ltr",
                textAlign: i18n.language === "ar" ? "right" : "left",
              },
            }}
            inputProps={{
              style: {
                direction: i18n.language === "ar" ? "rtl" : "ltr",
                textAlign: i18n.language === "ar" ? "right" : "left",
              },
            }}
          />
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", color: "#E47100" }}
          className={useStyles.dialogActions}
        >
          <Button
            onClick={handleSubmit}
            variant="contained"
            disabled={!reason.trim()}
            style={{
              backgroundColor: "#E47100",
              color: "#fff",
              fontFamily: "cairo",
              fontWeight: "bold",
            }}
          >
            {t("block")}
          </Button>
        </DialogActions>
      </CustomDialog>

      {loading ? null : (
        <div className="row gy-4 customerTop">
          <div
            style={{ display: "flex", alignItems: "center" }}
            className="col-md-6"
          >
            <div
              style={
                i18n.language === "ar"
                  ? { marginLeft: "1em" }
                  : { marginRight: "1em" }
              }
              className="customerBorder"
            >
              <div
                className="customerImg"
                style={{
                  backgroundImage: `url(${
                    customerPhoto ? customerPhoto : img
                  })`,
                }}
              ></div>
            </div>
            <div style={{ display: "inline-block", lineHeight: "30px" }}>
              <p className="customerName">{customerName}</p>
              <Link to="/edit-customer" style={{ display: "inline-block" }}>
                <button className="edit">
                  <FiEdit size={20} />
                </button>
              </Link>

              {localStorage.getItem("type") === "cashier" ? null : (
                <button
                  onClick={handleProceed}
                  className="showChat chatBtn"
                  data-count={3}
                >
                  <HiOutlineChatBubbleLeftRight color="#E47100" size={25} />
                </button>
              )}
              {!isUserBlocked ? (
                <button onClick={handleClickOpen} className="edit">
                  <MdBlockFlipped size={20} />
                </button>
              ) : (
                <button onClick={HandleUnBlock} className="edit">
                  <MdLockOpen size={20} />
                </button>
              )}

              <p className="customerPhone">
                {customerPhone} {customerNotes ? "-" + customerNotes : null}
              </p>
              <div className="tags">
                {classification.length > 0 &&
                  classification.map((data, index) => (
                    <p
                      key={index}
                      style={
                        i18n.language === "ar"
                          ? { marginLeft: "1em" }
                          : { marginRight: "1em" }
                      }
                    >
                      #{i18n.language === "ar" ? data.arName : data.enName}
                    </p>
                  ))}
              </div>
            </div>
          </div>
          <div
            className={
              i18n.language === "ar"
                ? "col-md-6 customerBtns"
                : "col-md-6 customerBtns customerBtnsEn"
            }
          >
            <div>
              <Link to="/add-complaint">
                <button className="customerComplaint">
                  {" "}
                  <span>
                    <BsInfoCircle size={20} />
                  </span>
                  {t("profile.addComp")}
                </button>
              </Link>
              <span style={{ margin: "0 0.5em" }}></span>
              <Link to="/customer-orders">
                <button className="customerTrack">
                  <span>
                    <BsGlobe2 size={20} />
                  </span>
                  {t("singleOrder.trackOrder")}
                </button>
              </Link>
              <span style={{ margin: "0 0.5em" }}></span>
              <Link to="/add-order">
                <button className="customerAdd">
                  {" "}
                  <span>
                    <AiOutlinePlusCircle size={20} />
                  </span>
                  {t("profile.addOrder")}
                </button>
              </Link>
            </div>
          </div>
          <div className="customerNav">
            <Link to="/customer-orders">
              <p className={props.active === 0 ? "activeScreen" : null}>
                {t("profile.orders")}
              </p>
            </Link>
            <span></span>
            <Link to="/customer-addresses">
              <p className={props.active === 1 ? "activeScreen" : null}>
                {" "}
                {t("profile.address")}
              </p>
            </Link>
            <span></span>
            <Link to="/customer-wallet">
              <p className={props.active === 2 ? "activeScreen" : null}>
                {t("profile.wallet")}
              </p>
            </Link>
            <span></span>
            <Link to="/customer-awafi-wallet">
              <p className={props.active === 3 ? "activeScreen" : null}>
                {t("profile.awafiWallet")}
              </p>
            </Link>
            <span></span>
            <Link to="/customer-points">
              <p className={props.active === 4 ? "activeScreen" : null}>
                {t("profile.points")}
              </p>
            </Link>
            <span></span>
            <Link to="/customer-complaints">
              <p className={props.active === 6 ? "activeScreen" : null}>
                {t("profile.complaints")}
              </p>
            </Link>
            {JSON.parse(localStorage.getItem("accountType")) ? (
              <>
                <span></span>

                <Link to="/customer-invoice">
                  <p className={props.active === 5 ? "activeScreen" : null}>
                    {t("addOrder.organization")}
                  </p>
                </Link>
              </>
            ) : null}
          </div>{" "}
        </div>
      )}
    </>
  );
}

export default CustomerData;
