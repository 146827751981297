import '../Styles/CustomerOrders.css';

import Navbar from '../Components/Navbar';
import { useTranslation } from 'react-i18next';
import { FiEdit } from 'react-icons/fi';
import { BsGlobe2,BsInfoCircle } from 'react-icons/bs';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import bckg from "../Images/order.svg"
import {useState,useEffect}  from 'react';
import { useNavigate } from "react-router-dom";
import { customer,customerComplaints } from '../api';
import { BeatLoader } from 'react-spinners';
import { useParams,generatePath } from 'react-router-dom';
import CustomerData from '../Components/CustomerData';
import { MDBDataTable } from 'mdbreact';
import icn1 from "../Images/customerProfile.svg"
import icn2 from "../Images/orderDetailsIconInactive.svg"
import icn3 from "../Images/menuIconUnactive.svg"
import icn4 from "../Images/basketIconUnactive.svg"
function CustomerComplaints(props) {
    
    const { t, i18n } = useTranslation();

    const[loading,setLoading]=useState(true);
   const[dataLoad,setDataLoad]=useState(true);
    const [complaints,setComplaints]=useState([])
    const customerId= localStorage.getItem("customerId");
   let history= useNavigate();
     
  
   //customerComplaints=> takes customer id and user token and returns customer complaints
useEffect(() => {

  const token= localStorage.getItem("token");

  
customerComplaints(token,customerId).then((response) => {

  let compArr = [];
  for (let i = 0; i < response.data.data.length; i++) {  
      let obj = {};
         
      obj.id = response.data.data[i].id
      obj.createdAt = response.data.data[i].createdAt      
      obj.about = response.data.data[i].about=="time"?t("profile.time"):response.data.data[i].about=="quality"?t("profile.quality"):t("profile.cleaness");        
      obj.msg =   response.data.data[i].msg; 
 
      compArr.push(obj);  } 

      setComplaints(compArr)


setLoading(false)
}).catch(function(error){
if (error.response.status === 401) {
   history("/")
}

else if(error.response.status === 403){
  props.setErr(true)
  props.setMsg(t('validation.noPermission'))
  props.setStyle({top:"50%"})
  }
      
  
  history("/customer-orders")
});
 
},[]) 









const data = {
  columns: [
    {
      label: t("profile.c-id"),
      field: 'id',
      sort: 'asc',
 
    },
    {
      label: t("profile.c-date"),
      field: 'createdAt',
      sort: 'asc',
    
    },
    {
      label: t("profile.c-type"),
      field: 'about',
      sort: 'asc',
    
    },

    {
      label: t("profile.c-details"),
      field: 'msg',
      sort: 'asc',
      
    
    },
  
  
  
   
  ],    rows: complaints
  
};
    return (
      <div >
    <div className='processNav' >
          <Link to="/customer-orders">
<div>
<img style={{cursor:"pointer"}} src={icn1}/>

<p>{t("addOrder.profile")}</p>
</div></Link>
<div className='dottedLine'></div>
<Link to="/add-order">
<div>
<img style={{cursor:"pointer"}}  src={icn2}/>
<p  style={{color:"white"}}>{t("addOrder.details")}</p></div>
</Link>
<div className='dottedLine'></div>
<div>
<img src={icn3}/>
<p style={{color:"white"}}>{t("addOrder.menu")}</p></div>

<div className='dottedLine'></div>
<div>
<img src={icn4}/>
<p style={{color:"white"}}>{t("addOrder.send")}</p></div>
  </div>
          {loading?
        
               <div style={{height:"80vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading||dataLoad}  size={30} /></div>:
          <div style={{marginTop:"2em"}} className='container'>
          
          <CustomerData setDataLoad={setDataLoad} active={6}/>
      

 
<div className='customerBody'>

 
<div className={i18n.language=="ar"?'container-fluid pagAr':"container-fluid"}>
          <div className={i18n.language=="ar"?"row orderTable compTable":"row tableEn orderTable compTableEn"}>
          <div className={ complaints.length==0?i18n.language=="ar"?" emptyAr empty col-lg-12 col-md-12 col-sm-12 col-xs-12":"  empty col-lg-12 col-md-12 col-sm-12 col-xs-12":"col-lg-12 col-md-12 col-sm-12 col-xs-12" }>
                                <MDBDataTable
                                infoLabel={[t("orders.info1"), t("orders.info2"), t("orders.info3"), t("orders.info4")]} 
                                searchLabel={t("dashboard.search")}
                                paginationLabel={ [t("orders.prev"), t("orders.next")]}
                                entriesLabel={t("orders.show")}
                                    striped
                                    
                                    hover
                                    noBottomColumns={true}
                                    data={data}
                                />  
                            </div>
                        </div>
                        </div>
{/*
<table className="table align-middle  table-hover table-borderless orders-table">
  <thead>


    <tr>
 
    

 
   
   
      <th >{t("profile.c-id")}</th>
      <th >{t("profile.c-date")}</th>
      <th >{t("profile.c-type")}</th>
      <th className='utility-55percent' >{t("profile.c-details")}</th>
 
    </tr>
  </thead>
  <tbody>
 

  {complaints && complaints.map((data, index) => 
    <tr key={index}>
      
     
      <td>{data.id}</td>
      <td>{data.createdAt}</td>
      <td>{data.about=="time"?t("profile.time"):data.about=="quality"?t("profile.quality"):t("profile.cleaness") }</td>
      <td className='utility-55percent' >{data.msg}</td>
      
      
      </tr>

)
  }

   

   
  </tbody>
</table>*/}

</div>

       </div>}</div>
    );
  }
  
  export default CustomerComplaints;
  
  