import "../Styles/Offers.css";
import { useTranslation } from "react-i18next";

import { useState, useEffect, useRef } from "react";
import { BeatLoader } from "react-spinners";
import { getPromoCodes } from "../api";
import { MDBDataTable } from "mdbreact";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import PaginationItem from "@mui/material/PaginationItem";
import Select from "react-select";
import { GrPowerReset } from "react-icons/gr";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function PromoCodes() {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState([]);
  const [page, setPage] = useState(1);
  const [promos, setPromos] = useState([]);
  const [products, setProducts] = useState([]);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [rest, setRest] = useState();

  const [selectedName, setSelectedName] = useState({ value: "" });

  const [tableLoading, setTableLoading] = useState(false);
  const [filter, setFilter] = useState();
  const nameRef = useRef();

  const categRef = useRef();
  const sizeRef = useRef();
  const addRef = useRef();
  const prodRef = useRef();

  //filterMeals api call gets data used in filteration
  //calories api  call gets data of meals
  useEffect(() => {
    const token = localStorage.getItem("token");
    let params = {
      page: page,
      applicationId: 4,
    };
    setLoading(true);

    getPromoCodes(token, params).then((response) => {
      setPromos(response.data.data);
      if (response.data.data.toString() == []) {
        setLoading(false);
      } else {
        const result = Object.values(response.data.data.data);

        let promoArr = [];

        for (let i = 0; i < result.length; i++) {
          let obj = {};
          obj.id = result[i].id;
          obj.code = result[i].code;
          obj.amount = result[i].amount;
          obj.type = result[i].type;
          obj.from = formatDateTime(result[i].from);
          obj.expireDate = formatDateTime(result[i].expireDate);
          obj.noOfUses = result[i].noOfUses;
          obj.limit = result[i].limit;
          obj.offerName = result[i].offer == null ? "" : result[i].offer.name;

          //   obj.additionalEnName = result[i].additionalEnName;
          //   obj.code = result[i].code;
          //   obj.price = result[i].price;
          //   obj.aggregatorsPrice = result[i].aggregatorsPrice;
          //   obj.calories = result[i].calories;
          promoArr.push(obj);
        }

        setMenu(promoArr);
        setLoading(false);
      }
    });
  }, [rest]);

  const data = {
    columns: [
      {
        label: t("offer.id"),
        field: "id",
        sort: "asc",
      },

      {
        label: t("code"),
        field: "code",
        sort: "asc",
      },
      {
        label: t("amount"),
        field: "amount",
        sort: "asc",
      },

      {
        label: t("type"),
        field: "type",
        sort: "asc",
      },

      {
        label: t("from"),
        field: "from",
        sort: "asc",
      },
      {
        label: t("expireDate"),
        field: "expireDate",
        sort: "asc",
      },
      {
        label: t("noOfUses"),
        field: "noOfUses",
        sort: "asc",
      },

      {
        label: t("limit"),
        field: "limit",
        sort: "asc",
      },
      {
        label: t("offerName"),
        field: "offerName",
        sort: "asc",
      },
    ],
    rows: menu,
  };

  //on page change or on filtering
  //on page change => changes page
  //on filtering => set page to page number 1
  //calories api call can filter by (categoryId,productId,additionalId and sizeId)
  function filterData(page) {
    window.scrollTo(0, 0);
    setTableLoading(true);
    setPage(page);

    console.log(fromDate);
    console.log(toDate);
    console.log(promos);

    if (!Array.isArray(promos.data)) {
      console.error("promos is not an array");
      setTableLoading(false);
      return;
    }

    // Clone the original promos data
    let filteredPromos = [...promos.data];

    // Normalize dates by setting the time to midnight
    const normalizeDate = (date) => {
      const newDate = new Date(date);
      newDate.setHours(0, 0, 0, 0);
      return newDate;
    };

    const normalizedFromDate = fromDate ? normalizeDate(fromDate) : null;
    const normalizedToDate = toDate ? normalizeDate(toDate) : null;

    // Filter based on fromDate and toDate if both are selected
    if (normalizedFromDate && normalizedToDate) {
      filteredPromos = filteredPromos.filter((promo) => {
        const promoFrom = normalizeDate(promo.from);
        const promoTo = normalizeDate(promo.expireDate);
        return promoFrom >= normalizedFromDate && promoTo <= normalizedToDate;
      });
    }
    // Filter based on fromDate only if it's selected
    else if (normalizedFromDate) {
      filteredPromos = filteredPromos.filter((promo) => {
        const promoFrom = normalizeDate(promo.from);
        return promoFrom >= normalizedFromDate;
      });
    }
    // Filter based on toDate only if it's selected
    else if (normalizedToDate) {
      filteredPromos = filteredPromos.filter((promo) => {
        const promoTo = normalizeDate(promo.expireDate);
        return promoTo <= normalizedToDate;
      });
    }

    setMenu(filteredPromos);
    setTableLoading(false);
  }

  //clears input fields
  //calories api is called to get areas with no filtres
  //sets page to page number 1
  function resetData() {
    setFromDate(null);
    setToDate(null);
    setRest((dev) => dev + 1);
    setTableLoading(false);
  }
  function formatDateTime(dateTimeStr) {
    const date = new Date(dateTimeStr);

    // Get the day, month, and year
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();

    // Get the hours and minutes
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Format the date and time as DD-MM-YYYY HH:MM
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }
  return (
    <div className="questions">
      <p style={{ marginBottom: "0" }} className="screenTitle">
        {t("promoCode")}
      </p>

      {loading ? (
        <div style={{ height: "60vh" }} className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <div
          className={
            i18n.language == "ar" ? "container-fluid  pagAr" : "container-fluid"
          }
        >
          <div
            className="container-fluid filtering"
            style={{ marginBottom: "2em", padding: "1em 0" }}
          >
            <div className="row ">
              <div className="col-12">
                <div className="container-fluid">
                  <div className="row gy-3">
                    <div className="col-12 filterTitile">
                      <p>{t("orders.filter")}</p>
                    </div>

                    <div className="col-lg-2 col-md-3 col-sm-6">
                      <label>{t("from")}</label>
                      <DatePicker
                        selected={fromDate}
                        onChange={(date) => setFromDate(date)}
                        dateFormat="dd-MM-yyyy"
                        placeholderText={t("from")}
                        className="calSelect"
                      />
                    </div>

                    <div className="col-lg-2 col-md-3 col-sm-6">
                      <label>{t("to")}</label>
                      <DatePicker
                        selected={toDate}
                        onChange={(date) => setToDate(date)}
                        dateFormat="dd-MM-yyyy"
                        placeholderText={t("to")}
                        className="calSelect"
                      />
                    </div>

                    <div
                      className="col-lg-4 col-md-6 alignVertEnd "
                      style={{ textAlign: "end" }}
                    >
                      <button
                        style={{ width: "35%" }}
                        disabled={tableLoading}
                        onClick={() => filterData(1)}
                        className="filter"
                      >
                        {t("orders.filter")}
                      </button>
                      <span style={{ margin: "0 0.5em" }}></span>
                      <button
                        style={{ width: "65%" }}
                        onClick={resetData}
                        className="reset"
                      >
                        {t("offer.reset")} <GrPowerReset color="#ffff" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {tableLoading ? (
            <div style={{ height: "30vh" }} className="loader">
              <BeatLoader color={"#E47100"} loading={tableLoading} size={30} />
            </div>
          ) : (
            <>
              <div
                style={{ marginTop: "1em" }}
                className={
                  i18n.language == "ar"
                    ? "row orderTable"
                    : "row tableEn orderTable"
                }
              >
                <div
                  className={
                    menu.length == 0
                      ? i18n.language == "ar"
                        ? " emptyAr empty col-lg-12 col-md-12 col-sm-12 col-xs-12"
                        : "  empty col-lg-12 col-md-12 col-sm-12 col-xs-12"
                      : "col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  }
                >
                  <MDBDataTable
                    entries={50}
                    entriesOptions={[50]}
                    infoLabel={[
                      t("orders.info1"),
                      t("orders.info2"),
                      t("orders.info3"),
                      t("orders.info4"),
                    ]}
                    searchLabel={t("dashboard.search")}
                    paginationLabel={[t("orders.prev"), t("orders.next")]}
                    entriesLabel={t("orders.show")}
                    striped
                    hover
                    noBottomColumns={true}
                    data={data}
                  />
                </div>
              </div>
              <Stack spacing={0}>
                <Pagination
                  renderItem={(item) => (
                    <PaginationItem
                      slots={{
                        previous: i18n.language == "ar" ? "prevAr" : "prevEn",
                        next: i18n.language == "ar" ? "nextAr" : "nextEn",
                      }}
                      {...item}
                    />
                  )}
                  className={i18n.language == "ar" ? "arArrow" : null}
                  defaultPage={1}
                  page={page}
                  count={promos.last_page}
                  onChange={(e, value) => {
                    filterData(value);
                  }}
                  shape="rounded"
                  variant="outlined"
                />
              </Stack>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default PromoCodes;
