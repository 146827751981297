import '../Styles/Chat.css';
import { useEffect,useRef,useCallback} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { HiOutlineChatBubbleLeftRight } from 'react-icons/hi2';
import { BsSearch } from 'react-icons/bs';
import { BsCircleFill } from 'react-icons/bs';
import { useState } from 'react';
import {AiOutlineCloseCircle} from 'react-icons/ai';
import { TbSend } from 'react-icons/tb';
import {GrPowerReset} from 'react-icons/gr';
import {FiLink} from 'react-icons/fi';
import io from 'socket.io-client';
import { sendMessage,chatHistory,chatList,singleUser } from '../api';
import debounce from 'lodash.debounce';
import moment from "moment";
import InfiniteScroll from 'react-infinite-scroll-component';
import img from "../Images/defaultImage.jpeg";
import logo from "../Images/csLogo.png"
import ImageModal from "../Components/ImageModal"
import { DotLoader, FadeLoader,BeatLoader } from 'react-spinners';
import { type } from '@testing-library/user-event/dist/type';
import {  useParams } from "react-router-dom";
import { changeOnlineStatus,autoRepliesList } from '../api';
import ChangeStatus from '../Components/ChangeStatus';
import ErrorModal from '../Components/ErrorModal';
import noWifi from "../Images/noWifi.gif"
/*
const socket =io('157.175.125.16:3000',{
  transports:["polling","websocket"],
  auth:{userName:localStorage.getItem("userName")? localStorage.getItem("userName").toUpperCase():null, userID:localStorage.getItem("userId"),userType:"cs"}  })
*/
  const socket =io('https://dev.alromansiah.com/',{
  transports:["polling","websocket"],
  auth:{userName:localStorage.getItem("userName")? localStorage.getItem("userName").toUpperCase():null, userID:localStorage.getItem("userId"),userType:"cs"}  })



  function SingleUserChat(props) {
    const token= localStorage.getItem("token");
    const chatContainerRef = useRef(null);
    const { id } = useParams();
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);

let history= useNavigate()
const msgRef=useRef()
const [connectionLost,setConnectionLost]=useState(false)
const [hasMore, setHasMore] = useState(true);
const [imgModal, setImgModal] = useState(false);
const [imgOpen, setImgOpen] = useState();
const [chatLoading, setChatLoading] = useState(false);
const [sending, setSending] = useState(false);
const [autoReplies,setAutoReplies]=useState([])

const [loading, setLoading] = useState(true);
const [online, setOnline]=useState(localStorage.getItem("isOnline")==1?true:false);

      const { t, i18n } = useTranslation();
const [user,setUser]=useState({})

const [chatLoad,setChatLoad]=useState(true)
const [page,setPage]=useState(1)
const [changeStatus,setChangeStatus]=useState(false)

const [reply,setReply]=useState(false)
const [text,setText]=useState("")
const [chat,setChat]=useState([])
const [preview,setPreview]=useState()
const [image,setImage]=useState()
const [disabled,setDisabled]=useState(false)
const sessionID = localStorage.getItem("sessionID");
const fileRef= useRef()
let typeRef =useRef()
let timeFromRef =useRef()
let timeToRef =useRef() 

var today = new Date();
var today2 = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

today =  dd+ '-' + mm + '-' + yyyy.toString().slice(2, 5);

today2 =  yyyy + '-' + mm+ '-' + dd;

const handleRoom = useCallback((data) => { 

  if (data.name == "alromansiah") {
    setText("")
      setDisabled(false)
          setChatLoading(false)
          setSending(false)

      setImage(null)
      setPreview(null)
    }

    if(user.id== data.chatID ){
    setChat((prev)=>[data,...prev])}

},[user])

useEffect(() => {
  autoRepliesList(token).then((response) => {

    setAutoReplies(response.data.data)
  })
  singleUser(token,{userId:id}).then((response) => {
    setUser(response.data.data)
    setChat(response.data.data.messages.data)
    setHasMore(!(response.data.data.messages.last_page==1)) 
    setLoading(false)
    setChatLoad(false)

  }).catch((err)=>{
 if(err.response.status === 403){
      props.setErr(true)
      props.setMsg(t('validation.noPermission'))
      props.setStyle({top:"50%"})
      
      setTimeout(function() {
          
        props.setErr(false)
  
        history(-1)
      }, 2500)}
  })
  

 
}, []);


useEffect(() => {
console.log(user)
  if(user!==undefined){
    socket.on(`room-${user.id}`,handleRoom);
 
  return () => {

    socket.off(`room-${user.id}`,handleRoom);
  
}}
}, [socket,user]);

useEffect(() => {

  if (sessionID) {
    socket.auth.sessionID = sessionID ;
    socket.connect();
  }

  socket.on("session", ({ sessionID, userID }) => {
   
    socket.auth.sessionID = sessionID ;
  // store it in the localStorage
  localStorage.setItem("sessionID", sessionID);
  socket.userID = userID;})

  
}, []);




socket.onAny((event, ...args) => {
 console.log(event, args);
 console.log(args[0].connected)
 if(args[0].connected==true){
  setConnectionLost(false)
 
  }
});
socket.on("connect_error", (err) => {
  if (err.message === "invalid username") {
console.log(err)    }
else{

  setConnectionLost(true)
}
});
//  socket.off("connect_error");

useEffect(() => {
 

  // Event handler for reconnection
  const handleReconnect = () => {
    setConnectionLost(false)
    console.log('reconnect')
    // Additional logic for handling reconnection
  };

  // Subscribe to the 'disconnect' and 'reconnect' events
  socket.on('reconnect', handleReconnect);

  // Clean up the event listeners when the component unmounts
  return () => {
    socket.off('reconnect', handleReconnect);
  };
}, []);

useEffect(() => {

  if(chatContainerRef.current){
  if (page==1) {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }
  else{

   // chatContainerRef.current.scrollTop = chatContainerRef.scrollHeight
  
  }}
}, [chat,connectionLost]);


 
const handleSendMessage= (e) =>{

  e.preventDefault()


  if((text=="" && preview==null) ){
    return
      }
      else if(!online){
        setError(true)
        setErrorMsg(i18n.language=="ar"?" انت الان غير متصل، من فضلك قم بتغيير حالتك الي اون لاين حتي تتمكن من ارسال الرسائل ":"You are now offline, Please change your status to Online to be able to send messages")
        setTimeout(function() {
                 
          setError(false);
         }, 3000)
          }
  else{
    if(preview){
      setChatLoading(true)
      setSending(true)

      }
  setDisabled(true)
  const formData = new FormData()
formData.append('msg',text)
if(preview){
formData.append('photo',preview)}
  formData.append('msgType','cs')
  formData.append('userId',user.userId)

  sendMessage(token,formData).then((response) => {


  }).catch(function(error){
    setDisabled(false)
    setChatLoading(false)
    setSending(false)
console.log(error)

if (error.code == "ERR_NETWORK" && preview) {
  setDisabled(false)
  setChatLoading(false)
  setSending(false)
  setError(true)
  setErrorMsg(i18n.language=="ar"?"تأكد من حجم الصورة و سرعة الانترنت، حجم الصورة لا يتخطى ١ ميجا":"Make sure your internet is stable and image size doesn't exceed 1MB")
  setTimeout(function() {
                 
    setError(false);
   }, 5000)
}

else if(error.code == "ERR_NETWORK"){
   setConnectionLost(true)

}


  })

}
}



useEffect(() => {
  console.log(socket);

  if (preview) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(preview);
  } 

}, [preview]);


document.addEventListener('click', (e) =>
  {
    // Retrieve id from clicked element
    let elementId = e.target.id;
    // If element has id
    if (elementId !== '') {
        if(!(elementId== 'replyList' || elementId== 'replyBtn')){
          setReply(false)
        }
    }
    // If element has no id
    else { 
      setReply(false)    }
  }
);
 


function fetchData(){
  chatHistory(token,user.userId,page+1).then((response) => {
    const newData = chat.concat(response.data.data)// append mock data to existing data
    setPage((prev)=>prev+1)

    setChat(newData);
    setHasMore(!(response.data.last_page==page+1))

  
})
  
}

function dateToString(date,type){
  if(type=="users"){
  let newDate= new Date(date).toString()
  return newDate.slice(4,21)}
  else{
    let newDate= new Date(date).toString()
    return newDate.slice(3,24)}
  }


 
  function handleOnlineStatus(num){
    const value={
      isOnline:online?0:1
    }
    changeOnlineStatus(value,localStorage.getItem("token")).then((response) => {
      localStorage.setItem("isOnline",response.data.isOnline)
      setChangeStatus(false)

      setOnline(response.data.isOnline==1? true:false)
    })
  }


  function convertUrlsToLinks(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g; // Regular expression to match URLs
    if(text==null){
      return text
 }
    else{
      return text.replace(urlRegex, function(url) {
        return '<a target="_blank" href="' + url + '">' + url + '</a>';
    
        
      });
    }
  }

    return ( <>
     
     {changeStatus?<ChangeStatus online={online} setModal={setChangeStatus} handleOnlineStatus={handleOnlineStatus}/>:null}

      
    
        <div style={{paddingBottom:"6em"}}  className={i18n.language=="en"?' chatContainer singleChat':'chatContainer singleChat chatContainerAr'  }>
      <div style={{width:"90vw", marginBottom:"1em"}}>
        <div className={i18n.language=="en"?'onlineStatus onlineStatusEn':'onlineStatus'}>
        <button onClick={()=>!online? setChangeStatus(true):null} className={online?"online":"online dull"}>{t("chat.online")}</button>
  <button onClick={()=>online?setChangeStatus(true):null} className={!online?"offline":"offline dull"}>{t("chat.offline")}</button>
</div></div>

{imgModal?<ImageModal img={imgOpen} setModal={setImgModal}/>:null}
{error? <ErrorModal setModal={setError} msg={errorMsg}/>:null}


     {loading?
        <div style={{height:"60vh"}} className='loader'>
<BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
     
     

          <div className='chat' >


            <div className='chatTitle'>
              <div  style={{display:"flex",alignItems:"center"}}>
              <div className='contactImg'>
              <div style={user.userPhoto!==""?{backgroundImage: `url(${user.userPhoto})`}:{ backgroundImage: `url(${img})`}}></div></div>
<div style={{margin:"0 0.5em"}}>
<p className='cntctName'>{user.userName}</p>
<p>{user.userPhone}</p></div>
</div>

{user.lastOrder!==null?

user.lastOrder.status==4?
null:
<div className='container-fluid' style={{marginTop:"0.5em"}}>
              

              <div className='row orderDetails'>
<div className='col-md-4 col-sm-6'>
<ul>
<li>
<span>{t("profile.orderId")+":"}</span> { "#" + user.lastOrder.id}
</li>

<li>
<span>{t("orders.platform")+":"}</span> {user.lastOrder.platform}
</li>

              </ul>
</div>

<div className='col-md-4 col-sm-6'>
<ul>
<li>
<span>{t("singleOrder.branch")+":"}</span> {i18n.language=="ar"?user.lastOrder.branch.arName:user.lastOrder.branch.enName}
</li>



<li> 
<span>{t("addOrder.status")+":"}</span> {user.lastOrder.statusId==1?t("singleOrder.order_accepted"):user.lastOrder.statusId==2? user.lastOrder.typeId==1? t("singleOrder.order_InProgressBranch"):t("singleOrder.order_InProgress"):user.lastOrder.statusId==3? t("singleOrder.order_with_driver"):user.lastOrder.statusId==6?t("singleOrder.waiting"):user.lastOrder.typeId==1?t("singleOrder.done_orderBranch")  :t("singleOrder.done_order")}
</li>

              </ul>
</div>


<div className='col-md-4 col-sm-12'>
<ul>

<li>
<span>{t("addOrder.type")+":"}</span> { user.lastOrder.typeId==1? t("addOrder.branch"):user.lastOrder.typeId==5?t("menu.tables"):t("addOrder.home")}
</li> 




<li>
<span>{ t("orders.paymentMethod")+":"} </span> 

{user.lastOrder.paymentMethod=="cash"?  t("menu.cash"): user.lastOrder.paymentMethod=="tap"? t("menu.credit"): user.lastOrder.paymentMethod=="paid"? t("singleOrder.paid"):user.lastOrder.paymentMethod=="checkout"? "Checkout": t("profile.wallet")} {user.lastOrder.paymentMethod=="tap"?  user.lastOrder.statusId!==6?"-" + t("singleOrder.paid") : "-" + t("singleOrder.unpaid") :null}
{user.lastOrder.paymentMethod=="wallet" &&user.lastOrder.remainPaymentMethod!==""?user.lastOrder.remainPaymentMethod=="cash"?  "/ "+t("menu.cash"): "/ "+t("menu.credit") :null}

</li> 

{user.lastOrder.typeId==2?
<li>
<span>{t("singleOrder.driver")}</span> {user.lastOrder.driverPhone}
</li> :null}




              </ul>
           
            
</div>


              </div>
             


         </div>:null}

{/*
<button><AiOutlineStop /></button>*/}
        
          </div>

<div className='chatDiv' id="chatScroll">
{connectionLost?

<>   
<p className='connectP2'>{t("chat.noWifi2")}</p>

  <p className='connectP'>{t("chat.noWifi1")}</p>
     <div className='noWifi' style={{ backgroundImage: `url(${noWifi})`}}></div>

     </>
     :chatLoad?

<div style={{height:"60vh"}} className='loader'>
<DotLoader color={'#E47100'} loading={chatLoad}  size={50} /></div>
:

<>

<div
 ref={chatContainerRef}
  id="scrollableDiv"
  style={{
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column-reverse',
  }}
>
<InfiniteScroll
  dataLength={chat.length} //This is important field to render the next data
  next={fetchData}
  hasMore={hasMore}
  direction="up"
  inverse={true} //

  scrollableTarget="scrollableDiv"
  loader={<div style={{height:"60px"}} className='loader'>
           <FadeLoader color={'#E47100'} loading={true} width={4} height={10} margin={0}/>
</div>}
  endMessage={
    <p style={{ textAlign: 'center' }}>
      <b>{t("chat.end")}</b>
    </p>
  }
 
  hasChildren={true}
  scrollThreshold={1}

 
  
>
  <div style={{flexDirection: "column-reverse",   display: "flex"}}>

 { chatLoading?
<div className='textDiv2' >
 
 <div>
 <div className='Text'>
 <div className='contactData txtTop'>
 <p className='contactName'>alromansiah</p>
 <p></p></div>
 <div style={{height:"30px"}} className='loader'>
<BeatLoader color={'#E47100'} size={10} /></div>
 <p style={{textAlign:"center"}}>

{t("chat.upload")} </p>

 
 </div>
 </div>
 <div className='contactImg csLogo' >
             <div style={{ backgroundImage: `url(${logo})`}}></div>
             </div>
 
 
 </div>
 :null}

{chat.map((data,index)=>
<div key={index}>
{data.name=="alromansiah"?

<div className='textDiv2' key={index} >
 
<div>
<div className='Text'>
<div className='contactData txtTop'>
<p className='contactName'>{data.name}</p>
<p></p></div>
<p dangerouslySetInnerHTML={{ __html: convertUrlsToLinks(data.message) }}>
</p>

{data.photo!==""?<div onClick={()=>{setImgOpen(data.photo); setImgModal(true)}} className={i18n.language=="ar"?'attachedImg':' attachedImg attachedImgEn'} style={{ backgroundImage: `url(${data.photo})`,cursor:"pointer"}}>
<img src={data.photo} style={{visibility: "hidden"}} />
</div>:null}


</div>
<p  style={i18n.language=="ar"?{direction:"ltr",textAlign:"end"}:null} className={i18n.language=="ar"?'textDate textDateAr' : 'textDate'}>{ data.createdAt}</p>
</div>
<div className='contactImg csLogo' >
            <div style={{ backgroundImage: `url(${logo})`}}></div>
            </div>


</div>
:
  <div className='textDiv' key={index}>
  <div className='contactImg' >
              <div style={user.userPhoto!==""?{backgroundImage: `url(${user.userPhoto})`}:{ backgroundImage: `url(${img})`}}></div>
              </div>

    <div>
  <div className='Text'>
  <div className='contactData txtTop'>
  <p className='contactName'>{data.name}</p>
  <p>{user.userName}</p></div>

  <p dangerouslySetInnerHTML={{ __html: convertUrlsToLinks(data.message) }}>
</p>

{data.photo!==""?<div onClick={()=>{setImgOpen(data.photo); setImgModal(true)}} className={i18n.language=="ar"?'attachedImg':' attachedImg attachedImgEn'} style={{ backgroundImage: `url(${data.photo})`,cursor:"pointer"}}>
<img src={data.photo} style={{visibility: "hidden"}} />
</div>:null}
</div>
<p className='textDate' style={i18n.language=="ar"?{direction:"ltr",textAlign:"start"}:null}>{ data.createdAt}</p>
</div>


  </div>}</div>
  
  
  
  )}</div>
</InfiniteScroll>

</div>
{/*
  <div>

  {chat.map((data,index)=>
<div key={index}>
{data.name=="alromansiah"?

<div className='textDiv2' key={index} >
 
<div>
<div className='Text'>
<div className='contactData txtTop'>
<p className='contactName'>{data.name}</p>
<p>{data.phone}</p></div>
<p>
{data.message}</p>
</div>
<p className={i18n.language=="ar"?'textDate textDateAr' : 'textDate'}>{ dateToString(data.createdAt)}</p>
</div>
<div className='contactImg' >
            <div style={{ backgroundImage: `url(${img})`}}></div>
            </div>


</div>
:
  <div className='textDiv' key={index}>
  <div className='contactImg' >
              <div style={{ backgroundImage: `url(${img})`}}></div>
              </div>

    <div>
  <div className='Text'>
  <div className='contactData txtTop'>
  <p className='contactName'>{data.name}</p>
  <p>{data.phone}</p></div>
  <p>
{data.message}</p>

</div>
<p className='textDate'>{ dateToString(data.createdAt)}</p>
</div>


  </div>}</div>
  
  
  
  )}


 

  
 
<div id="chatBottom">

</div>

  </div>*/}</> }
</div>
<form onSubmit={(e)=>handleSendMessage(e)}>

<div className='chatInput'>
  {reply?
  <div onClick={()=>setReply(false)} className=''>
  <div className={i18n.language=="ar"?'reply replyAr':'reply '} id="replyList">
  {autoReplies && autoReplies.map((data,index)=>

<p onClick={()=>{setText(data); setReply(false)}}>{data}</p>

  )}
  </div>
  </div>
  
  :null}
  <button type='button' onClick={()=>setReply(!reply)} className='chooseReply' id="replyBtn">{t("chat.choose")}</button>
 <div className='chatInputDiv' > 
<input style={sending?{color: '#f7f7f7bd'}:null} placeholder={t("chat.enter")} ref={msgRef} value={text} onChange={(e)=>setText(e.target.value)}/>
{image && sending==false?<div className={i18n.language=="ar"?'attachedImg':' attachedImg attachedImgEn'} style={{ backgroundImage: `url(${image})`}}>
<img src={image} style={{visibility: "hidden"}} />

  <button type='button' onClick={()=>{setPreview("");setImage("");  fileRef.current.value =""}}>X</button>
</div>:null}


</div><button type='button' className='sendBtn' style={{margin:"0 1em"}}  onClick={()=> fileRef.current.click()}>

<FiLink size={25} /></button>
<input type="file" ref={fileRef}
  onChange={(e)=>
    {
      const file= e.target.files[0];
      if(file){
        setPreview(file);
      }
    }}

style={{position:"absolute",height:"0",width:"0",zIndex:"-1"}}/>

<button className='sendBtn' type='submit' disabled={disabled || connectionLost}>
<TbSend size={25} />


</button>

</div></form>

          </div>}
      </div>
      </>
    );
  }
  
  export default SingleUserChat;
  
  