import '../Styles/Dashboard.css';
import { AiOutlineSearch } from 'react-icons/ai';
import Navbar from '../Components/Navbar';
import { useTranslation } from 'react-i18next';
import { IoCloseSharp } from "react-icons/io5";
import bckg from "../Images/order.svg"
import {RiShoppingCartFill } from 'react-icons/ri';
function ErrorModal(props) {
   
    const { t, i18n } = useTranslation();

  
    return (
        <div >
         
        <div onClick={()=>props.setModal(false)} style={{background:"transparent"}} className="modalOverlay"></div>
        <div style={props.style}  className="modal-content errModal" >
        
<p className='alrt'>{props.msg}</p>
 
        </div>
      </div>
  
    );
  }
  
  export default ErrorModal;
  
  