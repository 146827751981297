import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';

import { FiEdit } from 'react-icons/fi';
import { RiDeleteBin5Line } from 'react-icons/ri';

function Address(props) {
    const { t, i18n } = useTranslation();
    const [timeRemaining, setTimeRemaining] = useState(null);
    const targetDate = new Date(props.data.branch.deliveryOffTimeTo);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);


  

    useEffect(() => {
        if(props.data.branch.deliveryOption==0){
           // console.log( localStorage.getItem("orderDate") + " " + localStorage.setItem("orderTime"))
           //console.log( localStorage.getItem("orderDate") +localStorage.getItem("orderTime") )

          const now = localStorage.getItem("now")== 'false' &&  !localStorage.getItem("orderDate")=="" ?   new Date(( localStorage.getItem("orderDate") + " " +localStorage.getItem("orderTime") )): new Date();
  
          const timeDiff = targetDate.getTime() - now.getTime();


          if (timeDiff > 0) {
            const remainingHours = Math.floor(timeDiff / (1000 * 60 * 60));
            const remainingMinutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    
            const formattedTime = `${remainingHours} hours, ${remainingMinutes} minutes`;
            setTimeRemaining(formattedTime);
            setHours(remainingHours)
            setMinutes(remainingMinutes)

          } else {
            setTimeRemaining("");
          }
    
      
    }
      },[props.addressLoad]);

    
    

    
     
   return(
<div className='col-6'>


<div>
    
<input disabled={(props.data.branch.deliveryOption !== 1)?true:false}  id={"address"+props.data.id} className={i18n.language=="ar"?'mrginAr':'mrginEn'}  style={i18n.language=="ar"?{marginLeft:"1em"}:{marginRight:"1em"}}  value={props.data.id} type="radio"  name="orderAddress" checked={parseInt(localStorage.getItem("addressId"))==props.data.id}  onChange={()=>{localStorage.setItem("clearBasket",true); props.selectAddress(props.data.id,props.data.branch.id,props.data.branch.enName,props.data.branch.arName,props.data.branch.city.country.enCurrency,props.data.branch.city.country.arCurrency,props.data.area.ordinaryDeliveryPrice,props.data.area.fastDeliveryPrice, props.data.arAddress,  props.data.enAddress); }}  defaultChecked={parseInt(props.addressId) === props.data.id ? true: false}/>
<label  htmlFor={"address"+props.data.id} >  {props.data.title} {(props.data.branch.deliveryOption !== 1)? <span  style={{color:"red"}}>{t("addOrder.noDelivery")}</span>  :null}  </label>
</div> 

{timeRemaining!=="" && timeRemaining!==null ?
<p style={{marginBottom:"0"}}>   
<span style={i18n.language=="ar"?{marginLeft:"5px",fontWeight:"600"}:{marginRight:"5px",fontWeight:"600"}}>{t("addOrder.timeLeft")}</span>
<span style={{fontWeight:"bold",color:"#331900e0"}}>
{ `${(hours < 10 ? `0${hours}` : hours)}` + ":" + `${(minutes < 10 ? `0${minutes}` : minutes)}` }</span> 
<span style={i18n.language=="ar"?{marginRight:"5px",fontWeight:"600"}:{marginLeft:"5px",fontWeight:"600"}}>{t("addOrder.timeLeft2")}</span>

</p>: null}
{/*
<p style={{color:"green"}}>{deliveryTimer(props.data.branch.deliveryOffTimeFrom)}</p>*/}
<p style={{marginBottom:"0"}}>{i18n.language=="ar"? props.data.arAddress  : props.data.enAddress }</p>
<p  style={{marginBottom:"0"}}> {t("profile.branch")} : {i18n.language=="ar"? props.data.branch.arName: props.data.branch.enName}</p>
<p  style={{margin:"0"}}> {t("areas.area")} : {i18n.language=="ar"? props.data.area.arName: props.data.area.enName}</p>

<p style={{marginTop:"0"}}> {t("menu.deliveryPrice")} : { props.data.area.ordinaryDeliveryPrice} {t("profile.sar")}</p>

<div style={{flex:"1",alignItems:"end"}}>
<button className='editBtn' style={{color:"#db095b"}} onClick={()=>{props.setModal(true)
  props.setDelId(props.data.id)
  }} > <RiDeleteBin5Line/> {t("profile.del")}</button>  
<button className='editBtn' style={{color:"#E47100"}} onClick={()=>{props.editAddress(props.data.id) }} > <FiEdit/> {t("profile.edit")}</button> 

</div>



</div>

);
}

export default Address;