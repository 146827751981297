    

 
import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import login from "../Images/chicken.svg"
function ImageModal(props) {

const { t, i18n } = useTranslation();
const [phone,setPhone]=useState("");
const history= useNavigate();


    return ( 
      
 
 
        <div  className="foodModal">
    
          <div onClick={()=>props.setModal(false)}  className="modalOverlay"></div>
    <div style={{display:"flex",alignItems:"center",justifyContent:"center",height:"100vh"}}>
       <img src={props.img} style={{maxHeight:"90vh"}}/>
       </div>
     
   
        </div>
    

    );
}

export default ImageModal;
 