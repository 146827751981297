import "../Styles/Dashboard.css";
import { AiOutlineSearch } from "react-icons/ai";
import Navbar from "../Components/Navbar";
import { useTranslation } from "react-i18next";
import { IoCloseSharp } from "react-icons/io5";
import bckg from "../Images/order.svg";
import { RiShoppingCartFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
function LogoutModal(props) {
  const { t, i18n } = useTranslation();
  let history = useNavigate();
  function logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("userName");
    localStorage.removeItem("userPhone");
    localStorage.removeItem("userPhoto");

    localStorage.removeItem("customerId");
    localStorage.removeItem("customerName");
    localStorage.removeItem("customerNotes");
    localStorage.removeItem("customerPhone");
    localStorage.removeItem("customerPhoto");
    localStorage.removeItem("customerAwafi");
    localStorage.removeItem("CartId");

    localStorage.removeItem("basketMeals");
    localStorage.removeItem("now");
    localStorage.removeItem("branchId");
    localStorage.removeItem("accountType");
    localStorage.removeItem("organizationId");

    localStorage.removeItem("branchOrderTime");
    localStorage.removeItem("addressId");
    localStorage.removeItem("orderType");
    localStorage.removeItem("orderSource");
    localStorage.removeItem("orderDate");
    localStorage.removeItem("orderTime");
    localStorage.clear()

    history("/");

    props.setModal(false);
  }
  return (
    <div>
      <div onClick={() => props.setModal(false)} className="modalOverlay"></div>
      <div
        className="modal-content"
        style={{
          height: "auto",
          width: "40vw ",
          minWidth: "337px",
          paddingTop: "2em",
          position: "fixed",
        }}
      >
        <p className="alrt">{t("profile.logout-modal")}</p>
        <div className="modalBtns">
          <button onClick={logout} className="del">
            {t("profile.logout")}
          </button>
          <button onClick={() => props.setModal(false)} className="cls">
            {t("profile.cancel")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default LogoutModal;
