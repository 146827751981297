import '../Styles/Chat.css';
import { useEffect,useRef,useCallback} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'; 
import { HiOutlineChatBubbleLeftRight } from 'react-icons/hi2';
import { BsSearch } from 'react-icons/bs';
import { BsCircleFill } from 'react-icons/bs';
import { useState } from 'react';
import {AiOutlineCloseCircle} from 'react-icons/ai';
import { TbSend } from 'react-icons/tb';
import {GrPowerReset} from 'react-icons/gr';
import {FiLink} from 'react-icons/fi';
import io from 'socket.io-client';
import { useParams } from 'react-router-dom';
import { sendMessage,chatHistory,chatList } from '../api';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import PaginationItem from '@mui/material/PaginationItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import img from "../Images/defaultImage.jpeg";
import logo from "../Images/csLogo.png"
import noWifi from "../Images/noWifi.gif"
import ImageModal from "../Components/ImageModal"
import { DotLoader, FadeLoader,BeatLoader } from 'react-spinners';
import { changeOnlineStatus,autoRepliesList } from '../api';
import ErrorModal from '../Components/ErrorModal';
import { BackgroundImage } from 'react-image-and-background-image-fade'
import { generatePath } from 'react-router-dom';
import ChangeStatus from '../Components/ChangeStatus';
const socket =io('https://dev.alromansiah.com/',{
  transports:["polling","websocket"],
  auth:{userName:localStorage.getItem("userName")? localStorage.getItem("userName").toUpperCase():null, userID:localStorage.getItem("userId"),userType:"cs"}  })

  function Chat(props) {
    const token= localStorage.getItem("token");
    const chatContainerRef = useRef(null);
const history= useNavigate()
const msgRef=useRef()
const [hasMore, setHasMore] = useState(true);
const [imgModal, setImgModal] = useState(false);
const [imgOpen, setImgOpen] = useState();
const [error, setError] = useState(false);
const [errorMsg, setErrorMsg] = useState(false);
const [chatLoading, setChatLoading] = useState(false);
const [sending, setSending] = useState(false);

const { id } = useParams();

const [loading, setLoading] = useState(true);
const [open, setOpen] = useState(false);
const [online, setOnline]=useState(localStorage.getItem("isOnline")==1?true:false);

      const { t, i18n } = useTranslation();
const [contacts,setContacts]=useState([1,2,3,4,5,6,7,8,9])
const [users,setUsers]=useState([])
const [usersResp,setUsersResp]=useState()
const filterValue=localStorage.getItem("filterChat")? JSON.parse(localStorage.getItem("filterChat")) : null

const [searchTerm,SetSearchTerm]=useState('');
const [changeStatus,setChangeStatus]=useState(false)
const [connectionLost,setConnectionLost]=useState(false)

const [channelId,setChannelId]=useState()

const [activeUsr,setActiveUsr]=useState({
  id:"",
  name:"",
  phone:"",
  photo:"",
  lastOrder:null
})
const [autoReplies,setAutoReplies]=useState([])

const [chatLoad,setChatLoad]=useState(false)
const [userId,setUserId]=useState()
const [page,setPage]=useState()
const [pageUsr,setPageUsr]=useState( localStorage.getItem("pageUsr")?localStorage.getItem("pageUsr"):1)

const [reply,setReply]=useState(false)
const [text,setText]=useState("")
const [chat,setChat]=useState([])
const [preview,setPreview]=useState()
const [image,setImage]=useState()
const [disabled,setDisabled]=useState(false)
const sessionID = localStorage.getItem("sessionID");
const fileRef= useRef()
let typeRef =useRef()
let timeFromRef =useRef()
let timeToRef =useRef() 
let nameRef =useRef() 
let phoneRef=useRef()
var today = new Date();
var today2 = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

today =  dd+ '-' + mm + '-' + yyyy.toString().slice(2, 5);

today2 =  yyyy + '-' + mm+ '-' + dd;

const handleRoom = useCallback((data) => { 
  socket.emit("chatIDsArr", users.map(obj => obj.id));
  if(channelId== data.chatID ){
    setChat((prev)=>[data,...prev])}

    if (data.name == "alromansiah") {
      setText("")
        setDisabled(false)
        setChatLoading(false)
        setSending(false)

        setImage(null)
        setPreview(null)
      }
    
/*
if(pageUsr==1){
  console.log("if")
  if (data.name == "alromansiah") {
  setText("")
    setDisabled(false)
    setImage(null)
    setPreview(null)
  }

    if(channelId== data.chatID ){
     
    setChat((prev)=>[data,...prev])}


    setUsers((prevUsers) => {
      const sortedData = [...prevUsers].sort((a, b) => {
        if (a.id === data.chatID ) {
          return -1; // a comes before b
        } else if (b.id === data.chatID ) {
          return 1; // b comes before a
        } else {
          return 0; // no change in order
        }
      });
      sortedData[0].lastMsgReply = data.message;
      sortedData[0].lastMsgTime = data.createdAt;
    
      if (data.name !== "alromansiah") {
        sortedData[0].lastMsgType = "user";
     
            } else {
        sortedData[0].lastMsgType = "cs";
      }

      return sortedData;
    });
    
}
else{
  console.log("else")
  if(users.some((obj) => obj.id === data.chatID)){

    chatList(token,localStorage.getItem("userId"),{page:1}).then((response) => {
     
      if (data.name == "alromansiah") {
        setText("")
          setDisabled(false)
          setImage(null)
          setPreview(null)
        }
      setUsersResp(response.data)
      setPageUsr(1)
          setUsers(response.data.data)

if(channelId== data.chatID ){
  setChat((prev)=>[data,...prev])}


    })
  }

}
 */
},[users,channelId])


useEffect(() => {
 

  // Event handler for reconnection
  const handleReconnect = () => {
    setConnectionLost(false)
    // Additional logic for handling reconnection
  };

  // Subscribe to the 'disconnect' and 'reconnect' events
  socket.on('reconnect', handleReconnect);

  // Clean up the event listeners when the component unmounts
  return () => {
    socket.off('reconnect', handleReconnect);
  };
}, []);

const handleNew = useCallback((data) => {
if(pageUsr==1){


  if(users.some((obj) => obj.userId === data.userId)){

    setUsers((prevUsers) => {
      const sortedData = [...prevUsers].sort((a, b) => {
        if (a.id === data.id ) {
          return -1; // a comes before b
        } else if (b.id === data.id ) {
          return 1; // b comes before a
        } else {
          return 0; // no change in order
        }
      });
      sortedData[0].lastMsgReply = data.lastMsgReply;
      sortedData[0].lastMsgTime = data.lastMsgTime;
      sortedData[0].lastOrder = data.lastOrder;
    
      if (data.lastMsgType !== "cs") {
        sortedData[0].lastMsgType = "user";
     
            } else {
        sortedData[0].lastMsgType = "cs";
      }

      return sortedData;
    });
  }
  else{
if(usersResp){


    if(users.length == usersResp.per_page){

      setUsers(prevArray => [data, ...prevArray.slice(0, -1)])
      chatList(token,localStorage.getItem("userId"), {page:1}).then((response) => {
        setUsersResp(response.data)

      }).catch((err)=>{
        
      })
    }
    else{
   
      setUsers(prevArray => [data, ...prevArray])
    
    }

  }
  else{
    setUsers(prevArray => [data, ...prevArray])
    chatList(token,localStorage.getItem("userId"),{page:1}).then((response) => {
      setUsersResp(response.data)
    }).catch((err)=>{
      
    })
  
  }

}
 
}
else{

  if(data.id == channelId){
    chatList(token,localStorage.getItem("userId"),{page:1}).then((response) => {
     
      setUsersResp(response.data)
      setPageUsr(1)
          setUsers(response.data.data)

    })
  }
else{
  chatList(token,localStorage.getItem("userId"),{page:pageUsr}).then((response) => {
     
    setUsersResp(response.data)
        setUsers(response.data.data)

  })
}

  
}
 
  },[users,channelId,pageUsr,usersResp]);

  useEffect(() => {
   // console.log(users)
  },[users])
useEffect(() => {

  autoRepliesList(token).then((response) => {

    setAutoReplies(response.data.data)
  })
  chatList(token,localStorage.getItem("userId"), filterValue? filterValue:{page:pageUsr}).then((response) => {
    setPageUsr(pageUsr)
    if(!(response.data.data == undefined)){
      setUsers(response.data.data)
      setUsersResp(response.data)

if(id ==0){

      
const user=response.data.data[0]
      setUserId(user.userId)
      setChannelId(user.id)
      setActiveUsr(
        {
          id:user.userId,
          name:user.userName,
          phone:user.userPhone,
          photo:user.userPhoto,
          lastOrder:user.lastOrder
        }
      )

      chatHistory(token,response.data.data[0].userId,1).then((response) => {

      setChat(response.data.data)
       setChatLoad(false)
       setHasMore(!(response.data.last_page==1))
     
   
       })
      }



  else{

    const user=response.data.data.find(item => item.userId.toString() === id);
    setUserId(user.userId)
    setChannelId(user.id)
    setActiveUsr(
      {
        id:user.userId,
        name:user.userName,
        phone:user.userPhone,
        photo:user.userPhoto,
        lastOrder:user.lastOrder

      }
    )

    chatHistory(token,id,1).then((response) => {

    setChat(response.data.data)
     setChatLoad(false)
     setHasMore(!(response.data.last_page==1))
   
 
     })
    

  }
      
    }
   
    setLoading(false)
  }).catch((err)=>{
     if(err.response.status === 403){
      props.setErr(true)
      props.setMsg(t('validation.noPermission'))
      props.setStyle({top:"50%"})
   
      setTimeout(function() {
          
        props.setErr(false)
  
      history(-1)
        }, 2500)}
  })
   

 
}, []);



useEffect(() => {


  for (let i = 0; i < users.length; i++) {
    socket.on(`room-${users[i].id}`,handleRoom);
  //  console.log(`room-${data[i].userID}`)
   }
  return () => {
    for (let i = 0; i < users.length; i++) {

    socket.off(`room-${users[i].id}`,handleRoom);
  }
}
}, [socket,users,channelId]);

useEffect(() => {
  socket.auth.userName = localStorage.getItem("userName").toUpperCase() ;
  socket.auth.userID = localStorage.getItem("userId");

  if (sessionID) {
    socket.auth.sessionID = sessionID ;
  }

  socket.on("session", ({ sessionID, userID }) => {
    socket.auth.sessionID = sessionID ;
  localStorage.setItem("sessionID", sessionID);
  socket.userID = userID;})
  socket.connect();
  
}, []);




socket.onAny((event, ...args) => {
 if(event== `delete-chats-1026400` ){
 console.log(event, args);
  }

  if(event==`delete-chats-662102`  ){
    console.log(event, args);
     }

 if(args[0].connected==true){
 setConnectionLost(false)

 }

});
socket.on("connect_error", (err) => {
  if (err.message === "invalid username") {
  }
else{

  setConnectionLost(true)
}

});
//  socket.off("connect_error");




const handleListUsrs = (data) => {
  /*
   data.forEach((user) => {
    user.self = user.userID === socket.id;
    initReactiveProperties(user);
  });

 
  let allUsrs= data.sort((a, b) => {
    if (a.self) return -1;
    if (b.self) return 1;
    if (a.username < b.username) return -1;
    return a.username > b.username ? 1 : 0;})
     console.log(allUsrs)
     debouncedSetUsers(allUsrs);*/
}





/*
useEffect(() => {
  
  const userId= localStorage.getItem("userId")
  // Set up the event listener for "messageResponse" event
  socket.on(`users-${userId}`,handleUsers);

  // Clean up the event listener
  return () => {
    socket.off(`users-${userId}`,handleUsers);

    socket.disconnect();
  };
}, [socket]);*/


const handleUser = useCallback((data) => {
  //setUsers((prevUsers) => [...prevUsers, data]);
 //console.log(data)
 }, []);
/*
useEffect(() => {
  socket.off("user connected",handleUser);

  // Set up the event listener for "messageResponse" event
  socket.on("user connected",handleUser);

  // Clean up the event listener
 
}, [socket]);*/


useEffect(() => {

  if(chatContainerRef.current){
  if (page==1) {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }
  else{

   // chatContainerRef.current.scrollTop = chatContainerRef.scrollHeight
  
  }}
}, [chat,connectionLost]);

const handleSelectUser= (userID,channelId) =>{
 localStorage.setItem("pageUsr",pageUsr)



  if((userID !== userId) && userId !== null){
  
  window.open( generatePath("/chats/:id", { id:userID }), '_blank');
  }
 // history(generatePath("/chat/:id", { id:userID }));

else{

if(`${userId}` == `null`){
  let user = users.filter((item) => {

    return item.id == channelId
});

  setActiveUsr({
    id:user[0].userId,
    name:user[0].userName,
    phone:user[0].userPhone,
    photo:user[0].userPhoto,
    lastOrder:user[0].lastOrder

  })
}
  setChatLoad(true)
  setText("")
  setDisabled(false)
  setImage(null)
  setPreview(null)

 // socket.emit("chat-history",  userID);
 chatHistory(token,userID,1).then((response) => {
  localStorage.setItem("session",userID)
  setPage(1)
  setUserId(userID)
  setChannelId(channelId)
setChat(response.data.data)
 setChatLoad(false)
 setHasMore(!(response.data.last_page==1))
 

 })}
}

/*
const handleChat = useCallback((data) => {
 console.log("on chat-hsitory",data)
 setChat(data)
 setChatLoad(false)

 }, []);
 
useEffect(() => {
  socket.on("chat-history",handleChat);

  return () => {
    socket.off("chat-history",handleChat);

    socket.disconnect();
  };
}, [socket]);
*/


 /*useEffect(() => {
   socket.on("add-chat",handleNew);
 
   return () => {
     socket.off("add-chat",handleNew);
 
     socket.disconnect();
   };
 }, [socket]);*/




 useEffect(() => {
  socket.on(`add-chat-${localStorage.getItem("userId")}`,handleNew);

  return () => {
    socket.off(`add-chat-${localStorage.getItem("userId")}`,handleNew);

  };
}, [socket,channelId,users,pageUsr]);

useEffect(() => {


  socket.on(`delete-chats-${localStorage.getItem("userId")}`,handleDel);

  return () => {     socket.off(`delete-chats-${localStorage.getItem("userId")}`,handleDel);

 socket.disconnect();
  };
}, [socket]);



 const handleDel = useCallback((data) => {

  console.log(data)

  if(data.length!==0){
 // const filteredArray = users.filter(obj => !data.includes(obj.id.toString()));



 setUsers((prevUsers)=> prevUsers.filter(obj =>
   
  data.includes(obj.id)

))

if(channelId !== undefined && `${channelId}` !== 'null'){




 if(data.includes(channelId) ){
setChannelId(null)
setUserId(null)
setChat([])
setHasMore(true)

setActiveUsr({
  id:"",
  name:"",
  phone:"",
  photo:"",
  lastOrder:null

})
}

}
else{
  setChannelId(null)
  setUserId(null)
  setChat([])
  setHasMore(true)

  setActiveUsr({
    id:"",
    name:"",
    phone:"",
    photo:"",
    lastOrder:null
  })
}

}
else{
  setUsers([])
}
  }, [users,channelId]);
  


 


 const handleUnassign = useCallback((data) => {

  if(data.length!==0){
 // const filteredArray = users.filter(obj => !data.includes(obj.id.toString()));

 setUsers((prevUsers)=>prevUsers.filter(obj =>
   
    !data.includes(obj.id)
  
  ))

if(channelId !== undefined && `${channelId}` !== 'null'){




 if(!data.includes(channelId) ){
setChannelId(null)
setUserId(null)
setChat([])
setHasMore(true)

setActiveUsr({
  id:"",
  name:"",
  phone:"",
  photo:"",
  lastOrder:null
})
}

}
else{
  setChannelId(null)
  setUserId(null)
  setChat([])
  setHasMore(true)

  setActiveUsr({
    id:"",
    name:"",
    phone:"",
    photo:"",
    lastOrder:null
  })
}

}
  }, [users,channelId]);
  


 useEffect(() => {



  socket.on(`unassigned-chats-${localStorage.getItem("userId")}`,handleUnassign);

  return () => {     socket.off(`unassigned-chats-${localStorage.getItem("userId")}`,handleUnassign);

 socket.disconnect();
  };
}, [socket]);

/*

 useEffect(() => {
  socket.on("user disconnected",handleDisconnect);

  return () => {     socket.off("user disconnected",handleDisconnect);

 socket.disconnect();
  };
}, [socket]);
const handleDisconnect = useCallback((data) => {
  console.log("connected")
if(data.toString()== localStorage.getItem("userId")){
setConnected(false)
}
}, []);

useEffect(() => {
  socket.on("user connected",handleConnect);

  return () => {     socket.off("user connected",handleConnect);

 socket.disconnect();
  };
}, [socket]);
const handleConnect = useCallback((data) => {
  console.log("connected")
  if(data.toString()== localStorage.getItem("userId")){
    setConnected(true)
    }

}, []);*/

const handleSendMessage= (e) =>{


 e.preventDefault()
   if((text=="" && preview==null) || channelId==null){
return
  }
  else if(!online){
    setError(true)
    setErrorMsg(i18n.language=="ar"?" انت الان غير متصل، من فضلك قم بتغيير حالتك الي اون لاين حتي تتمكن من ارسال الرسائل ":"You are now offline, Please change your status to Online to be able to send messages")
    setTimeout(function() {
             
      setError(false);
     }, 3000)
      }

  else{
    if(preview){
      setChatLoading(true)
      setSending(true)

      }
  setDisabled(true)
  const formData = new FormData()
formData.append('msg',text)
if(preview){
formData.append('photo',preview)}
  formData.append('msgType','cs')
  formData.append('userId',userId)

  sendMessage(token,formData).then((response) => {
  

  }).catch(function(error){
    setDisabled(false)
    setDisabled(false)
    setChatLoading(false)
    setSending(false)

if (error.code == "ERR_NETWORK" && preview) {
  setDisabled(false)
  setChatLoading(false)
  setSending(false)
  setError(true)
  setErrorMsg(i18n.language=="ar"?"تأكد من حجم الصورة و سرعة الانترنت، حجم الصورة لا يتخطى ١ ميجا":"Make sure your internet is stable and image size doesn't exceed 1MB")
  setTimeout(function() {
                 
    setError(false);
   }, 5000)
}

else if(error.code == "ERR_NETWORK"){
   setConnectionLost(true)

}

  })

}
}









useEffect(() => {
  //console.log(socket);

  if (preview) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(preview);
  } 

}, [preview]);


document.addEventListener('click', (e) =>
  {
    // Retrieve id from clicked element
    let elementId = e.target.id;
    // If element has id
    if (elementId !== '') {
        if(!(elementId== 'replyList' || elementId== 'replyBtn')){
          setReply(false)
        }
    }
    // If element has no id
    else { 
      setReply(false)    }
  }
);
 



/*
socket.on("connect", () => {
  //console.log("connect")

  
  this.users.forEach((user) => {
    if (user.self) {
      user.connected = true;
    }
  });
});*/
 /*
socket.on("disconnect", () => {
  console.log("disconnect")

  this.users.forEach((user) => {
    if (user.self) {
      user.connected = false;
    }
  });
});*/



/*
useEffect(() => {

  socket.on("sendChatToClient", handleChatReceived);

  return () => {
    socket.off("sendChatToClient", handleChatReceived);
  }
  
}, [socket]);

const handleChatReceived = (data) => {
  setChat(data);
  console.log(data);
}
/\*/

function fetchData(){
  chatHistory(token,userId,page+1).then((response) => {
    const newData = chat.concat(response.data.data)// append mock data to existing data
    setPage((prev)=>prev+1)

    setChat(newData);
    setHasMore(!(response.data.last_page==page+1))

  
})
  
}

function dateToString(date,type){
  if(type=="users"){
  let newDate= new Date(date).toString()
  return newDate.slice(4,21)}
  else{
    let newDate= new Date(date).toString()
    return newDate.slice(3,24)}
  }


 
  function filter(page){
  setLoading(true)
  
let params={
  msgType: typeRef.current.value==""?null:typeRef.current.value,
  dateFrom: timeFromRef.current.value,
  dateTo: timeToRef.current.value,
  name: nameRef.current.value,
  phone:phoneRef.current.value,
  page: page,
}
localStorage.setItem("filterChat",JSON.stringify(params))
  chatList(token,localStorage.getItem("userId"),params).then((response) => {
setUsersResp(response.data)
setPageUsr(page)


    if(response.data.data!== undefined){
      setUsers(response.data.data)

    if(!response.data.data.some((obj) => obj.userId === userId)){
      setChannelId(null)
      setUserId(null)
      setChat([])
      setHasMore(true)
      setActiveUsr({
        id:"",
        name:"",
        phone:"",
        photo:"",
        lastOrder:null
      })

      if(!(response.data.data == undefined)){

   
        const user=response.data.data[0]
        setUserId(user.userId)
        setChannelId(user.id)
        setActiveUsr(
          {
            id:user.userId,
            name:user.userName,
            phone:user.userPhone,
            photo:user.userPhoto,
            lastOrder:user.lastOrder
          }
        )
  
        chatHistory(token,response.data.data[0].userId,1).then((response) => {
  
        setChat(response.data.data)
         setChatLoad(false)
         setHasMore(!(response.data.last_page==1))
       
     
         })

      }
     }}
     else{
      setUsers([])

      setChannelId(null)
      setUserId(null)
      setChat([])
      setHasMore(true)
      setActiveUsr({
        id:"",
        name:"",
        phone:"",
        photo:"",
        lastOrder:null
      })
     }



  })
  setLoading(false)

  }


  function resetData(){

    setLoading(true)

    var selectElement = document.getElementsByClassName("areaSelect");
  
    for (var i = 0; i < selectElement.length; i++) {
       selectElement[i].selectedIndex =null
  
    } 
    timeFromRef.current.value=null;
    timeToRef.current.value=null;
    nameRef.current.value="";
    phoneRef.current.value="";

    chatList(token,localStorage.getItem("userId")).then((response) => {
      setUsers(response.data.data)
      setUsersResp(response.data)
      localStorage.removeItem("filterChat")

      setLoading(false)
    }).catch((err)=>{
    })
  }
  

  function handleOnlineStatus(){
    const value={
      isOnline:online?0:1
    }
    changeOnlineStatus(value,localStorage.getItem("token")).then((response) => {
      localStorage.setItem("isOnline",response.data.isOnline)
      setChangeStatus(false)
      setOnline(response.data.isOnline==1? true:false)
    })
  }


  function convertUrlsToLinks(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g; // Regular expression to match URLs
    if(text==null){
      return text
 }
    else{
      return text.replace(urlRegex, function(url) {
        return '<a target="_blank" href="' + url + '">' + url + '</a>';
    
        
      });
    }
  }


    return ( <>
     
     
      
    
        <div  className={i18n.language=="en"?' chatContainer':'chatContainer chatContainerAr'  }>

       {changeStatus?<ChangeStatus online={online} setModal={setChangeStatus} handleOnlineStatus={handleOnlineStatus}/>:null}
       {error? <ErrorModal setModal={setError} msg={errorMsg}/>:null}
{imgModal?<ImageModal img={imgOpen} setModal={setImgModal}/>:null}
<div className='container-fluid filtering'>

     <div className='row '>
     
     <div className='col-12'>
     <div className='container-fluid'>
     <div className='row gy-3'>
     <div className='col-6 filterTitile'>
    <p>{t("chat.onlineStatus")}</p> 
     </div>
     <div className='col-6 '>
     <div className={i18n.language=="en"?'onlineStatus onlineStatusEn':'onlineStatus'}>
  <button onClick={()=>!online? setChangeStatus(true):null} className={online?"online":"online dull"}>{t("chat.online")}</button>
  <button onClick={()=>online?setChangeStatus(true):null} className={!online?"offline":"offline dull"}>{t("chat.offline")}</button>
</div>

</div>
     
     
     <div className='col-md-3 col-sm-4 col-6'>
     <label>{t("chat.status")}</label>

     <select id="branch" ref={typeRef}  className='areaSelect' defaultValue={filterValue? filterValue.msgType? filterValue.msgType: "": ""}>
     <option value=""  >{t("areas.all")}</option> 
     <option value="user" >{t("chat.unread")}</option> 
     <option value="cs" >{t("chat.read")}</option> 

</select> 
     </div>
     <div className='col-md-3 col-sm-4 col-6'>
<label>{t("orders.from")}</label>
<input type="date" ref={timeFromRef} id="dateFrom" defaultValue={filterValue? filterValue.dateFrom? filterValue.dateFrom: "": ""}/>
</div> 

<div className='col-md-3 col-sm-4 col-6'>
<label>{t("orders.to")}</label>
<input type="date" ref={timeToRef} defaultValue={filterValue? filterValue.dateTo? filterValue.dateTo: "": ""}/>
</div>


<div className='col-md-3 col-sm-4 col-6'>
<label>{t("advPayment.client")}</label>
<input ref={nameRef}  defaultValue={filterValue? filterValue.name? filterValue.name: "": ""}/>
</div> 
<div className='col-md-3 col-sm-4 col-6'>
<label>{t("advPayment.clientMobile")}</label>
<input ref={phoneRef}  defaultValue={filterValue? filterValue.phone? filterValue.phone: "": ""}/>
</div> 

     <div className='col-md-2 col-sm-4 col-6 alignBottom'>

    <button  className='filter areaFilter' onClick={()=>filter(1)}>{t("orders.filter")}</button>

     </div>
     <div className='col-md-3 col-sm-5 col-6 alignBottom' style={{textAlign:"start"}}>
       
     <button onClick={resetData}  className='reset'>{t("offer.reset")} <GrPowerReset color='#ffff'/></button>

     </div>
     </div></div></div></div></div>


     {loading?
        <div style={{height:"60vh"}} className='loader'>
<BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
     
          <div  className={i18n.language=="ar"?'chatBorder chatBorderAr':'chatBorder'}>
       <button onClick={()=>setOpen(true)}  style={open?{display:"none"}:null} className='contactMobileBtn'><HiOutlineChatBubbleLeftRight size={35} color='#E47100'/></button>

          <div className={open?'contactList open':'contactList'} >
<p className='contactTitle'><span>{t("chat.title")}</span> <span className='mobileHide'>

  <HiOutlineChatBubbleLeftRight  size={25} color='#E47100'/>
  
  
  </span>
  <span onClick={()=>setOpen(false)} className='mobileClose' >

  <AiOutlineCloseCircle size={25} color='#E47100'/>
  
  </span>
  
  </p>

<div className='contactSrch'>
  <input  placeholder={t("dashboard.search")} value={searchTerm} onChange={(e)=>SetSearchTerm(e.target.value)}/> 
  <BsSearch  size={22}/>
  </div>
  <div className='contactContainer'>
  {users.length>0?
    <>

  {users && users.filter((data)=>{
    if(searchTerm===""){ //for filtering products on search
        return data;
    } 
    else if(data.userName.toLowerCase().includes(searchTerm.toLowerCase())){
   
   return data
   }
  
  
 }).map((data,index)=>
<div  key={index}>
<div className='contact' onClick={connectionLost?null:()=>{handleSelectUser(data.userId,data.id);  if(index==0){
  setActiveUsr( {id:data.userId,name:data.userName,phone:data.userPhone,photo:data.userPhoto,        
      lastOrder:data.lastOrder

})} }} style={activeUsr.id== data.userId?{backgroundColor:"#f2850042"}:null}>
  <div style={{display:"flex"}}>
<div className='contactImg'>
<div style={data.userPhoto!==""?{ backgroundImage: `url(${data.userPhoto})`}:{ backgroundImage: `url(${img})`}}></div>
</div>
<div className='contactData'>
  <p className='contactName' style={activeUsr.id== data.userId?{fontWeight:"bold",color:"red"}:null}>{data.userName}</p>
  <p className="lastMsg">{data.lastMsgReply}</p>
  <p>{ dateToString(data.lastMsgTime,"users")}</p>
  </div></div>
<div>
{data.lastMsgType=="user"?
  <BsCircleFill size={10} color='#E47100'/>:null
}
  </div>
</div>
</div>
)}
</>:
  <div className='noProd'>
  <p>{t("addOrder.noResult")}</p>
    </div>
  }


  </div>
  {users.length>0 && usersResp?

  <Stack spacing={0}>
      <Pagination 
       boundaryCount={0}
       siblingCount={0}
      renderItem={(item) => (
        <PaginationItem
          slots={{ previous: i18n.language=="ar"?'prevAr':"prevEn" , next: i18n.language=="ar"?'nextAr':"nextEn" }}
          {...item}
        />)}
    
      className={i18n.language=="ar"?'arArrow':null}   page={parseInt(pageUsr)}  count={usersResp.last_page} onChange={(e,value)=>{filter(value);}} shape="rounded" variant="outlined" 
      
      />
    </Stack>:null

  }
</div>

    <div className='chat' >


            <div className='chatTitle'>
              <div  style={{display:"flex",alignItems:"center"}}>
              <div className='contactImg'>
              {userId?
              <div style={activeUsr.photo!==""?{backgroundImage: `url(${activeUsr.photo})`}:{ backgroundImage: `url(${img})`}}></div>
              :null}
              </div>
<div style={{margin:"0 0.5em"}}>
<p className='cntctName'>{activeUsr.name}</p>
<p>{activeUsr.phone}</p></div>

</div>
{activeUsr.lastOrder!==null?

activeUsr.lastOrder.status==4?
null:
<div className='container-fluid' style={{marginTop:"0.5em"}}>
              

              <div className='row orderDetails'>
<div className='col-md-4 col-sm-6'>
<ul>
<li>
<span>{t("profile.orderId")+":"}</span> { "#" + activeUsr.lastOrder.id}
</li>

<li>
<span>{t("orders.platform")+":"}</span> {activeUsr.lastOrder.platform}
</li>

              </ul>
</div>

<div className='col-md-4 col-sm-6'>
<ul>
<li>
<span>{t("singleOrder.branch")+":"}</span> {i18n.language=="ar"?activeUsr.lastOrder.branch.arName:activeUsr.lastOrder.branch.enName}
</li>



<li> 
<span>{t("addOrder.status")+":"}</span> {activeUsr.lastOrder.statusId==1?t("singleOrder.order_accepted"):activeUsr.lastOrder.statusId==2? activeUsr.lastOrder.typeId==1? t("singleOrder.order_InProgressBranch"):t("singleOrder.order_InProgress"):activeUsr.lastOrder.statusId==3? t("singleOrder.order_with_driver"):activeUsr.lastOrder.statusId==6?t("singleOrder.waiting"): activeUsr.lastOrder.typeId==1? t("singleOrder.done_orderBranch") :t("singleOrder.done_order")}
</li>

              </ul>
</div>


<div className='col-md-4 col-sm-12'>
<ul>

<li>
<span>{t("addOrder.type")+":"}</span> { activeUsr.lastOrder.typeId==1? t("addOrder.branch"):activeUsr.lastOrder.typeId==5?t("menu.tables"):t("addOrder.home")}
</li> 




<li>
<span>{ t("orders.paymentMethod")+":"} </span> 

{activeUsr.lastOrder.paymentMethod=="cash"?  t("menu.cash"): activeUsr.lastOrder.paymentMethod=="tap"? t("menu.credit"): activeUsr.lastOrder.paymentMethod=="paid"? t("singleOrder.paid"):activeUsr.lastOrder.paymentMethod=="checkout"? "Checkout": t("profile.wallet")} {activeUsr.lastOrder.paymentMethod=="tap"?  activeUsr.lastOrder.statusId!==6?"-" + t("singleOrder.paid") : "-" + t("singleOrder.unpaid") :null}
{activeUsr.lastOrder.paymentMethod=="wallet" &&activeUsr.lastOrder.remainPaymentMethod!==""?activeUsr.lastOrder.remainPaymentMethod=="cash"?  "/ "+t("menu.cash"): "/ "+t("menu.credit") :null}

</li> 

{activeUsr.lastOrder.typeId==2?
<li>
<span>{t("singleOrder.driver")}</span> {activeUsr.lastOrder.driverPhone}
</li> :null}




              </ul>
           
            
</div>


              </div>
             


         </div>:null}
          </div>

<div className='chatDiv' id="chatScroll">
{ connectionLost?

<>   
<p className='connectP2'>{t("chat.noWifi2")}</p>

  <p className='connectP'>{t("chat.noWifi1")}</p>
     <div className='noWifi' style={{ backgroundImage: `url(${noWifi})`}}></div>

     </>
     :
     chatLoad?

<div style={{height:"60vh"}} className='loader'>
<DotLoader color={'#E47100'} loading={chatLoad}  size={50} /></div>
:

<>

<div
 ref={chatContainerRef}
  id="scrollableDiv"
  style={{
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column-reverse',
  }}
>
<InfiniteScroll
  dataLength={chat.length} //This is important field to render the next data
  next={fetchData}
  hasMore={hasMore}
  direction="up"
  inverse={true} //

  scrollableTarget="scrollableDiv"
  loader={
  <> {channelId?
  <div style={{height:"60px"}} className='loader'>
           <FadeLoader color={'#E47100'} loading={true} width={4} height={10} margin={0}/>
</div>:null}
</>
}
  endMessage={
    <p style={{ textAlign: 'center' }}>
      <b>{t("chat.end")}</b>
    </p>
  }
 
  hasChildren={true}
  scrollThreshold={1}

 
  
>
  <div style={{flexDirection: "column-reverse",   display: "flex"}}>
  { chatLoading?
<div className='textDiv2' >
 
 <div>
 <div className='Text'>
 <div className='contactData txtTop'>
 <p className='contactName'>alromansiah</p>
 <p></p></div>
 <div style={{height:"30px"}} className='loader'>
<BeatLoader color={'#E47100'} size={10} /></div>
 <p style={{textAlign:"center"}}>

{t("chat.upload")} </p>

 
 </div>
 </div>
 <div className='contactImg csLogo' >
             <div style={{ backgroundImage: `url(${logo})`}}></div>
             </div>
 
 
 </div>
 :null}

{chat.map((data,index)=>
<div key={index}>
{data.name=="alromansiah"?

<div className='textDiv2' key={index} >
 
<div>
<div className='Text'>
<div className='contactData txtTop'>
<p className='contactName'>{data.name}</p>
<p></p></div>
<p dangerouslySetInnerHTML={{ __html: convertUrlsToLinks(data.message) }}>
</p>

{data.photo!==""?<BackgroundImage onClick={()=>{setImgOpen(data.photo); setImgModal(true)}} className={i18n.language=="ar"?'attachedImg':' attachedImg attachedImgEn'} src={data.photo} >
<img src={data.photo} style={{visibility: "hidden"}} />
</BackgroundImage>:null}


</div>
<p style={i18n.language=="ar"?{direction:"ltr",textAlign:"end"}:null} className={i18n.language=="ar"?'textDate textDateAr' : 'textDate'}>{ data.createdAt}</p>
</div>
<div className='contactImg csLogo' >
            <div style={{ backgroundImage: `url(${logo})`}}></div>
            </div>


</div>
:
  <div className='textDiv' key={index}>
  <div className='contactImg' >
              <div style={activeUsr.photo!==""?{backgroundImage: `url(${activeUsr.photo})`}:{ backgroundImage: `url(${img})`}}></div>
              </div>

    <div>
  <div className='Text'>
  <div className='contactData txtTop'>
  <p className='contactName'>{data.name}</p>
  <p>{activeUsr.phone}</p></div>
  <p dangerouslySetInnerHTML={{ __html: convertUrlsToLinks(data.message) }}></p>

{data.photo!==""?<BackgroundImage onClick={()=>{setImgOpen(data.photo); setImgModal(true)}} className={i18n.language=="ar"?'attachedImg':' attachedImg attachedImgEn'} src={data.photo} >
<img src={data.photo} style={{visibility: "hidden"}} />
</BackgroundImage>:null}

</div>
<p style={i18n.language=="ar"?{direction:"ltr",textAlign:"start"}:null} className='textDate'>{ data.createdAt}</p>
</div>


  </div>}</div>
  
  
  
  )}</div>
</InfiniteScroll>

</div></> }
</div>
{userId?
<form onSubmit={(e)=>handleSendMessage(e)}>
<div className='chatInput'>
  {reply?
  <div onClick={()=>setReply(false)} className=''>
  <div className={i18n.language=="ar"?'reply replyAr':'reply '} id="replyList">

  {autoReplies && autoReplies.map((data,index)=>

<p onClick={()=>{setText(data); setReply(false)}}>{data}</p>

  )}
 </div>
  </div>
  
  :null}
  <button  type='button' onClick={()=>setReply(!reply)} className='chooseReply' id="replyBtn">{t("chat.choose")}</button>
 <div className='chatInputDiv' > 
<input style={sending?{color: '#f7f7f7bd'}:null}  placeholder={t("chat.enter")} ref={msgRef} value={text} onChange={(e)=>setText(e.target.value)}/>
{image && sending==false?<div className={i18n.language=="ar"?'attachedImg':' attachedImg attachedImgEn'} style={{ backgroundImage: `url(${image})`}}>
<img src={image} style={{visibility: "hidden"}} />

  <button  type='button' onClick={()=>{setPreview("");setImage("");  fileRef.current.value =""}}>X</button>
</div>:null}


</div><button type='button' className='sendBtn' style={{margin:"0 1em"}}  onClick={()=> fileRef.current.click()}>

<FiLink size={25} /></button>
<input type="file" ref={fileRef}
  onChange={(e)=>
    {
      const file= e.target.files[0];
      if(file){
        setPreview(file);
      }
    }}

style={{position:"absolute",height:"0",width:"0",zIndex:"-1"}}/>

<button className='sendBtn' type='submit'  disabled={disabled || connectionLost}>
<TbSend size={25} />


</button>

</div>
</form>:null}

</div>


          </div>}
      </div>
      </>
    );
  }
  
  export default Chat;
  
  