import '../Styles/CustomerOrders.css';

import Navbar from '../Components/Navbar';
import { useTranslation } from 'react-i18next';

import ErrorModal from '../Components/ErrorModal';

import CustomerData from '../Components/CustomerData';
import {useState,useRef}  from 'react';
import { useNavigate } from "react-router-dom";
import { saveLoyalty} from '../api';
import { BeatLoader } from 'react-spinners';
import countriesList from '../Components/Countries';
import icn1 from "../Images/customerProfile.svg"
import icn2 from "../Images/orderDetailsIconInactive.svg"
import icn3 from "../Images/menuIconUnactive.svg"
import icn4 from "../Images/basketIconUnactive.svg"
import { Link } from 'react-router-dom';
import countryList from 'react-select-country-list';

function RegAwafi() {

    const { t, i18n } = useTranslation();

    const[arMsg,setArMsg]=useState()
    const[enMsg,setEnMsg]=useState()
const[errModal,setErrModal]=useState(false)
const [ selectedOption,setSelectedOption]=useState("");

const [selectedBranch,setSelectedBranch]=useState("");
 
    const[submitLoading,setSubmitLoading]=useState(false);

    const customerId= localStorage.getItem("customerId");
   let history= useNavigate();
   let emailRegx =/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

   const emailRef=useRef();
      
        const nationalityRef=useRef(); 
        const birthdayRef=useRef();
    const nameRef=useRef(localStorage.getItem("customerName"));
  
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy =  parseInt(today.getFullYear())-16


    if (dd < 10) {
       dd = '0' + dd;
    }
    
    if (mm < 10) {
       mm = '0' + mm;
    } 
        
    today = yyyy + '-' + mm + '-' + dd;
   
    var maxDate = new Date();
maxDate.setFullYear(maxDate.getFullYear() - 80);

var maxYear = maxDate.getFullYear();
var maxMonth = maxDate.getMonth() + 1;
var maxDay = maxDate.getDate();

if (maxMonth < 10) {
   maxMonth = '0' + maxMonth;
}

if (maxDay < 10) {
   maxDay = '0' + maxDay;
}

var maxAllowedDate = maxYear + '-' + maxMonth + '-' + maxDay;

   const arabCountry = [
    {
     value:"sa",
     label:countryList().getLabel("sa"),
 },
{
   value:"bh",
   label:countryList().getLabel("bh"),
},
{
   value:"eg",
   label:countryList().getLabel("eg"),
},
{
   value:"ae",
   label:countryList().getLabel("ae"),
},
{
   value:"kw",
   label:countryList().getLabel("kw"),
},
{
   value:"qa",
   label:countryList().getLabel("qa"),
},
{
   value:"om",
   label:countryList().getLabel("om"),
},
{
   value:"sy",
   label:countryList().getLabel("sy"),
},
{
   value:"iq",
   label:countryList().getLabel("iq"),
},
{
   value:"ye",
   label:countryList().getLabel("ye"),
}

]

let otherCountries = countryList().getData();
otherCountries.map((obj) => {
    return(
        arabCountry.push(obj)
    )
})


//takes(title, area id, branch id (required)) 
//and (floor no., building no., street name, appartment no. and address notes (not required))
//if success user is redirected to customer addresses
function onSubmit(e)

{
  e.preventDefault()
  
  setSubmitLoading(true)
   
    const token= localStorage.getItem("token");

if (nameRef.current.value == "") {
  
        setArMsg( "أدخل اسم العميل")
      setEnMsg( "Enter customer name")
        setErrModal(true);
        setSubmitLoading(false)
        setTimeout(function() {
      
            setErrModal(false)
          }, 2500)
}
else if (emailRef.current.value == "") {
    setArMsg(  "أدخل البريد الإلكتروني للعميل")
    setEnMsg( "Enter customer email")
      setErrModal(true);
      setSubmitLoading(false)
      setTimeout(function() {
      
        setErrModal(false)
      }, 2500)
  
}
else if (!(emailRef.current.value.match(emailRegx))) {
    setArMsg(  " البريد الإلكتروني غير صحيح")
    setEnMsg( "Email is not valid")
      setErrModal(true);
      setSubmitLoading(false)
      setTimeout(function() {
      
        setErrModal(false)
      }, 2500)
  
}
else if (birthdayRef.current.value=="") {
    setArMsg(   "اختر تاريخ الميلاد اولا")
    setEnMsg( "Select birthdate first")
      setErrModal(true);
      setSubmitLoading(false)
      setTimeout(function() {
      
        setErrModal(false)
      }, 2500)
}
else if (nationalityRef.current.value=="") {
    setArMsg(   "اختر الجنسية اولا")
    setEnMsg( "Select nationality first")
      setErrModal(true);
      setSubmitLoading(false)
      setTimeout(function() {
      
        setErrModal(false)
      }, 2500)
}
else if (selectedOption=="") {
    setArMsg(   "اختر النوع اولا")
    setEnMsg( "Select gender first")
      setErrModal(true);
      setSubmitLoading(false)
      setTimeout(function() {
      
        setErrModal(false)
      }, 2500)
}

else {


 
    let values = {
        name: nameRef.current.value,
        email:emailRef.current.value,
        gender:selectedOption,
        birthDate: birthdayRef.current.value,
        nationality:nationalityRef.current.value
    }
    
 

    saveLoyalty(token,customerId,values).then((response) => {
        if (response.data.message === "success") {
            setErrModal(true)
            setArMsg(   " تم التسجيل في عوافي بنجاح ")
            setEnMsg( "Customer has been registered to Awafi successfully")

            setTimeout(function() {
                history("/customer-points")  
              
              }, 2500)

 

        } else {
            setArMsg(   "حدث خطأ")
    setEnMsg( "Something went wrong")
      setErrModal(true);
      setSubmitLoading(false)
      setTimeout(function() {
       
        setErrModal(false)
      }, 2500)
        
        } 

    }).catch(function (error) {
        if (error.response.status === 401) {
            history("/")
        }
      


    })
}
};

const handleOptionChange = (changeEvent) => {
    setSelectedOption( changeEvent.target.value);
  
    }
    return (
      <div >
         <div className='processNav' >
          <Link to="/customer-orders">
<div>
<img style={{cursor:"pointer"}} src={icn1}/>

<p>{t("addOrder.profile")}</p>
</div></Link>
<div className='dottedLine'></div>
<Link to="/add-order">
<div>
<img style={{cursor:"pointer"}}  src={icn2}/>
<p  style={{color:"white"}}>{t("addOrder.details")}</p></div>
</Link>
<div className='dottedLine'></div>
<div>
<img src={icn3}/>
<p style={{color:"white"}}>{t("addOrder.menu")}</p></div>

<div className='dottedLine'></div>
<div>
<img src={icn4}/>
<p style={{color:"white"}}>{t("addOrder.send")}</p></div>
  </div>
          {errModal?<ErrorModal setModal={setErrModal} msg={i18n.language=="ar"?arMsg:enMsg}/>:null}
    

<div style={{marginTop:"2em",paddingBottom:"3em"}} className='container'>
          
<CustomerData />

     

<div className='customerBody addressAdd'>
   
{submitLoading?
               <div style={{height:"80vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={submitLoading}  size={30} /></div>:null}

<div style={submitLoading?{display:"none"}:null}>
<p>{t("profile.awafiReg")}</p>
<form onSubmit={(e)=>onSubmit(e)}>
<div className='row'>

<div className='col-12'>
<label>{t("singleOrder.name")}</label>
<input defaultValue={localStorage.getItem("customerName")} ref={nameRef} />
</div>
<div className='col-12'>
<label>{t("singleOrder.email")}</label>
<input ref={emailRef}/>
</div>
<div className='col-12'>
<label>{t("singleOrder.birth_date")}</label>
<input className="awafiDate" type="date" ref={birthdayRef} max={today} min={maxAllowedDate}/>
</div>
<div className='col-12'>
<label>{t("singleOrder.nationality")}</label>
<select defaultValue={""} id="nationality" name="nationality"  ref={nationalityRef}  >
<option value={""} disabled> {t("singleOrder.nationality")}</option>

{countriesList.map((data,index)=>
  <option key={index} value={data.code}>{i18n.language=="ar"? data.nameAr:data.name}</option>


)}

</select>
</div>
<div className='col-12'>
<label>{t("singleOrder.gender")}</label>


<div className='row'>

<div className='col-6 gender'>

<input  type="radio" name="gender"    value="male" id="male" onChange={handleOptionChange}/>
<label   >{t("singleOrder.male")}</label>
</div>
<div className='col-6 gender'>
<input   type="radio" name="gender"    value="female" id="female" onChange={handleOptionChange}/>

<label >{t("singleOrder.female")}</label>

</div>
</div>
</div>




<button type='submit'>{t("Login.register")}</button>


</div>
</form></div>
</div>

  
       </div></div>
    );
  }
   
  export default RegAwafi;
  
  