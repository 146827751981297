import "../Styles/Order.css";
import bckg from "../Images/order.svg";
import Navbar from "../Components/Navbar";
import { useTranslation } from "react-i18next";
import icn1 from "../Images/customerProfile.svg";
import icn2 from "../Images/orderDetailsIconActive.svg";
import icn3 from "../Images/menuIconUnactive.svg";
import icn4 from "../Images/basketIconUnactive.svg";
import DatePicker from "react-datepicker";
import ErrorModal from "../Components/ErrorModal";
import {
  types,
  cities,
  tables,
  customerAddresses,
  sources,
  orderBranches,
  edit_Address,
  edit_Type,
  district,
  delAddresses,
  allBranches,
  getOrganizations,
} from "../api";
import "react-datepicker/dist/react-datepicker.css";
import { AiOutlineSearch } from "react-icons/ai";
import { ImInfo } from "react-icons/im";

import DelAddress from "../Components/DelAddress";
import { RiArrowRightSFill, RiArrowLeftSFill } from "react-icons/ri";
import { useRef, useState, useEffect } from "react";
import { Link, unstable_HistoryRouter } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import CleartCart from "../Components/CleartCart";
import { Clear_cart, Get_Basket } from "../api";
import { FiEdit } from "react-icons/fi";
import { generatePath } from "react-router-dom";
import { RiDeleteBin5Line } from "react-icons/ri";
import { scryRenderedDOMComponentsWithClass } from "react-dom/test-utils";
import { BsCalendar } from "react-icons/bs";
import Address from "../Components/Address";
import InvoiceCard from "../Components/InvoiceCard";
function AddOrder() {
  const { t, i18n } = useTranslation();
  let history = useNavigate();
  const [modal, setModal] = useState(false);
  const [delId, setDelId] = useState();
  const [del, setDel] = useState(true);
  const [loading, setLoading] = useState(true);
  const [addressLoad, setAddressLoad] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [tbls, setTbls] = useState([]);
  const [tableNum, setTableNum] = useState(
    localStorage.getItem("tableNum") ? localStorage.getItem("tableNum") : ""
  );

  const [clearModal, setClearModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(true);
  const [now, setNow] = useState(
    localStorage.getItem("now") ? localStorage.getItem("now") : true
  );
  const [datePicker, setDatePicker] = useState(false);

  const [startDate, setStartDate] = useState(getValidDate("orderDate"));

  // const[startDate,setStartDate]=useState( (localStorage.getItem("orderDate") !== "" && localStorage.getItem("orderTime") !== "") ?  new Date(localStorage.getItem("orderDate")+' '+localStorage.getItem("orderTime")).getTime() : new Date());

  const [minTime, setMinTime] = useState(
    new Date().setHours(new Date().getHours())
  );
  const [date, setDate] = useState(
    new Date().getFullYear() +
      "-" +
      (new Date().getMonth() + 1) +
      "-" +
      new Date().getDate()
  );
  const [arMsg, setArMsg] = useState();
  const [enMsg, setEnMsg] = useState();
  const [errModal, setErrModal] = useState(false);
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
  const [orderTypes, setOrderTypes] = useState([]);
  const [orderType, setOrderType] = useState(
    localStorage.getItem("orderType") ? localStorage.getItem("orderType") : 1
  );
  const [addressId, setAddressId] = useState(localStorage.getItem("addressId"));
  const [districts, setDistricts] = useState([]);
  const [districtId, setDistrictId] = useState(
    localStorage.getItem("orderDistrictId")
  );
  const [externalOrderId, setExternalOrderId] = useState(
    localStorage.getItem("externalOrderId")
  );
  const [basket, setBasket] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const customerId = localStorage.getItem("customerId");
  const [showDistricts, setShowDistricts] = useState(true);
  const [showBranches, setShowBranches] = useState(true);
  const [showDelivery, setShowDelivery] = useState(
    localStorage.getItem("orderType")
      ? localStorage.getItem("orderType") == 2
        ? true
        : false
      : false
  );
  const [orderSources, setOrderSources] = useState([]);
  const [chosenOrderSource, setChoseSource] = useState(
    localStorage.getItem("orderSource")
  );
  const [clear, setClear] = useState();
  const [allBranchesArr, setAllBranchesArr] = useState([]);

  const [deliveryFee, setDeliveryFee] = useState(
    localStorage.getItem("deliveryFee")
  );
  const [fastDeliveryFee, setFastDeliveryFee] = useState(
    localStorage.getItem("fastDeliveryFee")
  );
  const [arAddress, setArAddress] = useState(localStorage.getItem("arAddress"));
  const [activeTax, setActiveTax] = useState(
    localStorage.getItem("organizationId")
      ? localStorage.getItem("organizationId")
      : null
  );

  const [enAddress, setEnAddress] = useState(localStorage.getItem("enAddress"));

  const [showOrder, setShowOrder] = useState(false);
  const [branches, setBranches] = useState([]);
  const [branchId, setBranchId] = useState(localStorage.getItem("branchId"));
  const [branchData, setBranchData] = useState({
    branchNameEn: localStorage.getItem("branchNameEn"),
    branchNameAr: localStorage.getItem("branchNameAr"),
    enCurrency: localStorage.getItem("enCurrency"),
    arCurrency: localStorage.getItem("arCurrency"),
    branchOrderTime: localStorage.getItem("branchOrderTime"),
  });
  const [organizationArr, setOrganizationArr] = useState([]);
  const [organizationLoad, setOrganizationLoad] = useState(false);
  const [invoiceType, setInvoiceType] = useState(
    localStorage.getItem("accountType")
      ? localStorage.getItem("accountType")
      : false
  );

  const maxD = `${
    new Date().getMonth() + 2
  }-${new Date().getDate()}-${new Date().getFullYear()} `; //to set maximum date in datePicker which is 3 days after today's date

  //types api call takes token()=> fetch order types
  //sources api call takes token()=> fetch order sources
  //if order time is now datePicker is not displayed
  //changeOrderType function is called with order type index as argument
  //changeOrderSource function is called with order source data as argument
  //getBranches function is called with district id as argument
  //selectAddress function is called if chosen order type is from home (order type id=2) with address data and branch data as argument
  //external source id input field is displayed if order source is not agent (order source id!=5)
  //getDistricts function is called
  useEffect(() => {
    const token = localStorage.getItem("token");
    localStorage.setItem("clearBasket", false);

    if (!localStorage.getItem("now")) {
      localStorage.setItem("now", true);
    }

    if (orderType == 2) {
      customerAddresses(token, customerId, localStorage.getItem("now"))
        .then((response) => {
          setAddresses(response.data.data);

          // setLoading(false)
          setShowDelivery(true);
          setShowDistricts(false);
          setShowBranches(false);
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            history("/");
          }
          setArMsg(error.response.data.error);
          setEnMsg(error.response.data.error);
          setErrModal(true);
          setLoading(false);
          setTimeout(function () {
            setErrModal(false);
          }, 2500);
        });
    }

    Get_Basket(
      token,
      localStorage.getItem("customerId"),
      "",
      localStorage.getItem("type")
    )
      .then((response) => {
        setBasket(response.data.data.cart);
        // localStorage.setItem("orderType",response.data.data.cart.typeId)
        localStorage.setItem("branchId", response.data.data.cart.branchId);
        localStorage.setItem(
          "branchNameEn",
          response.data.data.cart.branch.enName
        );
        localStorage.setItem(
          "branchNameAr",
          response.data.data.cart.branch.arName
        );
        localStorage.setItem(
          "branchOrderTime",
          response.data.data.cart.branch.branchOrderTime
        );
        localStorage.setItem("orderType", response.data.data.cart.typeId);
        setOrderType(response.data.data.cart.typeId);
        setBranchId(response.data.data.cart.branchId);

        if (
          response.data.data.cart.typeId == 2 &&
          localStorage.getItem("type") !== "cashier"
        ) {
          setAddressId(response.data.data.cart.address.id);
          localStorage.setItem("addressId", response.data.data.cart.address.id);

          customerAddresses(token, customerId, localStorage.getItem("now"))
            .then((response) => {
              setAddresses(response.data.data);

              // setLoading(false)
              setShowDelivery(true);
              setShowDistricts(false);
              setShowBranches(false);
            })
            .catch(function (error) {
              if (error.response.status === 401) {
                history("/");
              }
              setArMsg(error.response.data.error);
              setEnMsg(error.response.data.error);
              setErrModal(true);
              setLoading(false);
              setTimeout(function () {
                setErrModal(false);
              }, 2500);
            });
        }

        //   setOrderType(response.data.data.cart.typeId)

        localStorage.setItem("CartId", response.data.data.cart.id);
        localStorage.setItem(
          "basketLength",
          response.data.data.cart.meals.length
        );
        /*
            if(localStorage.getItem("orderType") === "2" || localStorage.getItem("orderType") === "1"){
                
                changeOrderType(localStorage.getItem("orderType"));
            }*/
      })
      .catch((err) => {
        localStorage.setItem("basketLength", 0);

        /*  if(localStorage.getItem("orderType") === "2" || localStorage.getItem("orderType") === "1"){
          
                  changeOrderType(localStorage.getItem("orderType"));
              }*/
      });

    types(token)
      .then((response) => {
        setOrderTypes(response.data.data);

        if (!localStorage.getItem("orderType")) {
          localStorage.setItem("orderType", response.data.data[0].id);
          setOrderType(response.data.data[0].id);
        }

        allBranches(token)
          .then((response) => {
            setAllBranchesArr(response.data.data);

            if (!localStorage.getItem("branchId")) {
              setBranchId(response.data.data[0].id);
              localStorage.setItem("branchId", response.data.data[0].id);
              localStorage.setItem(
                "branchNameEn",
                response.data.data[0].enName
              );
              localStorage.setItem(
                "branchNameAr",
                response.data.data[0].arName
              );
              localStorage.setItem(
                "enCurrency",
                response.data.data[0].enCurrency
              );
              localStorage.setItem(
                "arCurrency",
                response.data.data[0].arCurrency
              );
              localStorage.setItem(
                "branchOrderTime",
                response.data.data[0].branchOrderTime
              );
            }
            tables(token, response.data.data[0].id, "available")
              .then((response) => {
                setTbls(response.data.data);

                setLoading(false);
              })
              .catch(function (error) {
                if (error.response.status === 401) {
                  history("/");
                }
              });
          })
          .catch(function (error) {
            if (error.response.status === 401) {
              history("/");
            }
          });
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          history("/");
        }

        setArMsg(error.response.data.error);
        setEnMsg(error.response.data.error);
        setErrModal(true);
        setLoading(false);
        setTimeout(function () {
          setErrModal(false);
        }, 2500);
      });

    sources(token)
      .then((response) => {
        setOrderSources(response.data.data);
        if (response.data.data[0].code !== 5) {
          setShowOrder(true);
        }
        if (!localStorage.getItem("orderSource")) {
          localStorage.setItem("orderSource", response.data.data[0].code);
          setChoseSource(response.data.data[0].code);

          localStorage.setItem(
            "orderSourceObj",
            JSON.stringify({
              id: response.data.data[0].id,
              name: response.data.data[0].name,
              code: response.data.data[0].code,
              active: response.data.data[0].active,
              allowPaid: response.data.data[0].allowPaid,
              allowCash: response.data.data[0].allowCash,
              allowPaymentOnline: response.data.data[0].allowPaymentOnline,
            })
          );
        }

        setLoading(false);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          history("/");
        }

        setArMsg(error.response.data.error);
        setEnMsg(error.response.data.error);
        setErrModal(true);
        setLoading(false);
        setTimeout(function () {
          setErrModal(false);
        }, 2500);
      });
    if (String(now) === "false") {
      setDatePicker(true);
    }

    if (localStorage.getItem("orderSourceObj")) {
      /*
changeOrderSource(orderSourceObj)
*/
    }

    if (districtId) {
      getBranches(districtId);
    }

    if (localStorage.getItem("orderType") === "2") {
      selectAddress(
        addressId,
        branchId,
        branchData.branchNameEn,
        branchData.branchNameAr,
        branchData.enCurrency,
        branchData.arCurrency,
        deliveryFee,
        fastDeliveryFee,
        arAddress,
        enAddress,
        branchData.branchOrderTime
      );
    }

    if (
      parseInt(localStorage.getItem("orderSource")) !== 5 &&
      parseInt(localStorage.getItem("orderSource"))
    ) {
      setShowOrder(true);
    } else {
      setShowOrder(false);
    }
    getDistricts();

    if (JSON.parse(localStorage.getItem("accountType"))) {
      handleGetOrganizations();
    }
  }, [del]);

  function getValidDate(key) {
    const storedValue = localStorage.getItem(key);

    if (storedValue) {
      const date = new Date(storedValue);
      if (!isNaN(date.getTime())) {
        return date;
      }
    }

    // Default to current date if stored date is invalid or not found
    return new Date();
  }

  //setting date
  //if picked date is todday's date there is a minimum time
  //order time and order date are then stored seperately

  function handleChangeTime(date) {
    setAddressLoad(true);

    if (!(date instanceof Date)) {
      date = new Date(date);
    }

    if (date.toLocaleDateString() === new Date().toLocaleDateString()) {
      setStartDate(date);
      setMinTime(new Date());
    } else {
      setStartDate(new Date(date));
      setMinTime(new Date().setHours(0, 0, 0, 0));
    }
    let d = date.getDate();
    let m = date.getMonth() + 1;
    let y = date.getFullYear();

    if (d < 10) {
      d = "0" + d;
    }
    if (m < 10) {
      m = "0" + m;
    }
    let selectedDate = y + "-" + m + "-" + d;

    let hh = date.getHours();
    let mm = date.getMinutes();

    if (hh < 10) {
      hh = "0" + hh;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    let selectedTime = hh + ":" + mm;

    setDate(selectedDate);
    console.log(selectedDate);
    localStorage.setItem("orderDate", selectedDate);
    localStorage.setItem(
      "orderTime",
      date.toLocaleTimeString("en-GB", { hour12: false })
    );

    if (orderType == 2) {
      const token = localStorage.getItem("token");

      customerAddresses(
        token,
        customerId,
        false,
        selectedDate,
        date.toLocaleTimeString("en-GB", { hour12: false })
      )
        .then((response) => {
          setAddresses(response.data.data);
          setAddressId(null);
          localStorage.removeItem("addressId");
          localStorage.removeItem("arAddress");
          localStorage.removeItem("enAddress");
          localStorage.removeItem("deliveryFee");
          localStorage.removeItem("fastDeliveryFee");
          localStorage.removeItem("branchId");
          localStorage.removeItem("accountType");
          localStorage.removeItem("organizationId");

          localStorage.removeItem("branchOrderTime");
          localStorage.removeItem("branchNameEn");
          localStorage.removeItem("branchNameAr");
          localStorage.removeItem("enCurrency");
          localStorage.removeItem("arCurrency");
          setAddressLoad(false);
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            history("/");
          }
          setArMsg(error.response.data.error);
          setEnMsg(error.response.data.error);
          setErrModal(true);
          setLoading(false);
          setTimeout(function () {
            setErrModal(false);
          }, 2500);
        });
    }
  }

  /*
    function handleChange(e) {
        this.setState({
            [e.target.id]: e.target.value,
        });
        localStorage.setItem("externalOrderId", e.target.value);
    
    };*/

  //toggles datePicker to open and close
  function openDatePicker() {
    setDatePickerIsOpen(!datePickerIsOpen);
  }

  //on changing time (now= true/false) selected value is saved
  //datePicker is displayed if now = false
  function handleOptionChange(e) {
    setAddressLoad(true);
    console.log(typeof e.target.value);

    const val = e.target.value;
    if (val == "true") {
      setStartDate("");
      setSelectedOption(true);
      setDatePicker(false);
      localStorage.setItem("now", true);
      localStorage.setItem("orderDate", "");
      localStorage.setItem("orderTime", "");
    } else {
      setStartDate("");
      setSelectedOption(false);
      setDatePicker(true);
      localStorage.setItem("now", false);
      localStorage.setItem("orderDate", "");
      localStorage.setItem("orderTime", "");
    }

    if (orderType == 2) {
      const token = localStorage.getItem("token");

      customerAddresses(token, customerId, localStorage.getItem("now"))
        .then((response) => {
          setAddresses(response.data.data);

          setAddressId(null);
          localStorage.removeItem("addressId");
          localStorage.removeItem("arAddress");
          localStorage.removeItem("enAddress");
          localStorage.removeItem("deliveryFee");
          localStorage.removeItem("fastDeliveryFee");
          localStorage.removeItem("branchId");
          localStorage.removeItem("accountType");
          localStorage.removeItem("organizationId");

          localStorage.removeItem("branchNameEn");
          localStorage.removeItem("branchNameAr");
          localStorage.removeItem("enCurrency");
          localStorage.removeItem("arCurrency");
          localStorage.removeItem("branchOrderTime");
          setAddressLoad(false);
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            history("/");
          }
          setArMsg(error.response.data.error);
          setEnMsg(error.response.data.error);
          setErrModal(true);
          setLoading(false);
          setTimeout(function () {
            setErrModal(false);
          }, 2500);
        });
    }
  }
  const handleInputChange = (e) => {
    const query = e.target.value;

    setSearchQuery(query);
    setBranches(filterBranches(query));
  };

  const filterBranches = (query) => {
    console.log(query);
    if (query === "" || query == null) {
      // If the search query is empty, return all branches
      return JSON.parse(localStorage.getItem("allBranches"));
    }

    // Filter branches based on the query
    return branches.filter(
      (branch) =>
        branch.arName.toLowerCase().includes(query.toLowerCase()) ||
        branch.enName.toLowerCase().includes(query.toLowerCase())
    );
  };
  //district api takes token ()=>fetch districts
  function getDistricts() {
    const token = localStorage.getItem("token");

    district(token)
      .then((response) => {
        setDistricts(response.data.data);

        setLoading(false);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          history("/");
        }
        setArMsg(error.response.data.error);
        setEnMsg(error.response.data.error);
        setErrModal(true);
        setLoading(false);
        setTimeout(function () {
          setErrModal(false);
        }, 2500);
      });
  }

  //on changing order type
  //if order type value changed data is reset
  //order type id is stored
  //if order type id is 2 (home delivery) customerAddresses api call takes token and customer id()=> fetch customer addresses and display them, and district,branches are not displayed
  //if order type id is not 2 addresses are not displayed, districts and branches are displayed
  function changeOrderType(e) {
    console.log(e);
    const token = localStorage.getItem("token");

    if (localStorage.getItem("clearBasket") == "true" && basket) {
      edit_Type(token, parseInt(e.target.value), localStorage.getItem("CartId"))
        .then((response) => {})
        .catch(function (error) {
          if (error.response.status === 401) {
            history("/");
          }
        });
     
    }

    let orderTypeValue;
    if (e === "2" || e === "1") {
      orderTypeValue = e;
    } else {
      orderTypeValue = e.target.value;
    }

    if (parseInt(orderTypeValue) !== parseInt(orderType)) {
      localStorage.setItem("deliveryFee", 0);
      localStorage.setItem("fastDeliveryFee", 0);
      //  localStorage.setItem("addressId",null);
    }

    setOrderType(orderTypeValue);
    //setAddressId(null)

    localStorage.setItem("orderType", orderTypeValue);
    localStorage.setItem("deliveryType", "ordinary");

    if (parseInt(orderTypeValue) == 2) {
     
      // localStorage.setItem("branchId","")
      // setBranchId("")
      setBranches([]);
      localStorage.setItem("orderDistrictId", "");

      customerAddresses(token, customerId, localStorage.getItem("now"))
        .then((response) => {
          setAddresses(response.data.data);

          setLoading(false)
          setAddressLoad(false)
          setShowDelivery(true);
          setShowDistricts(false);
          setShowBranches(false);
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            history("/");
          }
          setArMsg(error.response.data.error);
          setEnMsg(error.response.data.error);
          setErrModal(true);
          setLoading(false);
          setTimeout(function () {
            setErrModal(false);
          }, 2500);
        });
    } else {
      //    localStorage.setItem("addressId", null);
      //   localStorage.setItem("branchId", "");
      localStorage.setItem("deliveryFee", 0);
      localStorage.setItem("fastDeliveryFee", 0);
      setShowDelivery(false);
      setShowDistricts(true);
      setShowBranches(true);
    }
  }

  //on selecting address
  //address data is stored
  //selectBranch function is called with branch data as arguments
  function selectAddress(
    addressId,
    branchId,
    branchNameEn,
    branchNameAr,
    enCurrency,
    arCurrency,
    ordinaryDeliveryPrice,
    fastDeliveryFee,
    arAddress,
    enAddress,
    branchOrderTime
  ) {
    const token = localStorage.getItem("token");

    if (
      basket &&
      basket.address !== {} &&
      localStorage.getItem("clearBasket") == "true"
    ) {
      if (!(basket.branchId == branchId)) {
        setClear({
          type: "address",
          addressId: addressId,
          branchId: branchId,
          branchNameEn: branchNameEn,
          branchNameAr: branchNameAr,
          enCurrency: enCurrency,
          arCurrency: arCurrency,
          ordinaryDeliveryPrice: ordinaryDeliveryPrice,

          fastDeliveryFee: fastDeliveryFee,
          arAddress: arAddress,
          enAddress: enAddress,
          branchOrderTime: branchOrderTime,
        });

        setClearModal(true);
      } else {
        edit_Address(token, parseInt(addressId), localStorage.getItem("CartId"))
          .then((response) => {})
          .catch(function (error) {
            if (error.response.status === 401) {
              history("/");
            }
          });

        setAddressId(addressId);
        localStorage.setItem("addressId", addressId);
        localStorage.setItem("arAddress", arAddress);
        localStorage.setItem("enAddress", enAddress);
        selectBranch(
          branchId,
          branchNameEn,
          branchNameAr,
          enCurrency,
          arCurrency,
          branchOrderTime
        );
        localStorage.setItem(
          "deliveryFee",
          orderType === "2" ? parseInt(ordinaryDeliveryPrice) : 0
        );
        localStorage.setItem(
          "fastDeliveryFee",
          orderType === "2" ? parseInt(fastDeliveryFee) : 0
        );
      }
      /* if(localStorage.getItem("basketLength")>0 &&  localStorage.getItem("clearBasket")=="true"&& parseInt(branchId)!== basket.branchId){
                
                 setClear({
                     type: "address",
                     addressId:addressId,
                     branchId:branchId,
                     branchNameEn:branchNameEn,
                     branchNameAr:branchNameAr,
                     enCurrency:enCurrency,
                     arCurrency:arCurrency,
                     ordinaryDeliveryPrice:ordinaryDeliveryPrice,
                     fastDeliveryFee:fastDeliveryFee,
                     enAddress:enAddress,
                     arAddress:arAddress,
                         })
         
                 setClearModal(true)
                 }
          */
    } else {
      setAddressId(addressId);

      localStorage.setItem("addressId", addressId);
      localStorage.setItem("arAddress", arAddress);
      localStorage.setItem("enAddress", enAddress);
      selectBranch(
        branchId,
        branchNameEn,
        branchNameAr,
        enCurrency,
        arCurrency,
        branchOrderTime
      );
      localStorage.setItem(
        "deliveryFee",
        orderType === "2" ? parseInt(ordinaryDeliveryPrice) : 0
      );
      localStorage.setItem(
        "fastDeliveryFee",
        orderType === "2" ? parseInt(fastDeliveryFee) : 0
      );
    }
  }
  /*
    function changeOrderSource (e){
        let orderSourceValue;
        if(typeof e.target === "undefined" || parseInt(e.target.value) === 0){
            orderSourceValue = e;
        }else{
            orderSourceValue = e.target.value;
        }
        setChoseSource(orderSourceValue)
       
    
        localStorage.setItem("orderSource", orderSourceValue);
    
        if(orderSourceValue !== "web"){
            setShowOther(true)
          //  document.getElementById("sourceDiv").style.display = "block";
          //  document.getElementById("sourceDiv2").style.display = "block";
        }else{
            setShowOther(false)
          //  document.getElementById("sourceDiv").style.display = "none";
          //  document.getElementById("sourceDiv2").style.display = "none";
        }
    
    } */

  //on changing order source
  /*case 1: typeof e.target === "undefined" (when changeOrderSource fuction is called in useEffect)
    if orderSourceObj has been stored: order data is set to old values
    external order source id is displayed depending on order source code value
    if orderSourceObj has not been stored:  order data is set to inital values
    case 2: typeof e.target !== "undefined" (when changeOrderSource fuction is called on changing order source)
    if input value is not empty: data is stored and external order source id is displayed depending on order source code value
    else order data is set to inital values
    */
  function changeOrderSource(e) {
    let orderSource = "";
    if (typeof e.target === "undefined" || parseInt(e.target.value) === 0) {
      if (JSON.parse(localStorage.getItem("orderSourceObj"))) {
        orderSource = JSON.parse(localStorage.getItem("orderSourceObj"));
        localStorage.setItem("orderSourceObj", JSON.stringify(orderSource));
        localStorage.setItem("orderSource", orderSource.code);
        localStorage.setItem("allowCash", orderSource.allowCash);
        localStorage.setItem("allowPaid", orderSource.allowPaid);
        localStorage.setItem(
          "allowPaymentOnline",
          orderSource.allowPaymentOnline
        );
        if (parseInt(orderSource.code) !== 5) {
          setShowOrder(true);
        } else {
          setShowOrder(false);
          localStorage.setItem("externalOrderId", "");
        }
      } else {
        localStorage.setItem(
          "orderSourceObj",
          JSON.stringify({
            id: 6,
            name: "Agent",
            code: 5,
            active: 1,
            allowPaid: 0,
            allowCash: 1,
            allowPaymentOnline: 1,
          })
        );
        localStorage.setItem("orderSource", 5);
        localStorage.setItem("allowCash", 1);
        localStorage.setItem("allowPaid", 0);
        localStorage.setItem("allowPaymentOnline", 1);
        localStorage.setItem("paymentMethod", "cash");
        setShowOrder(false);
      }
    } else {
      setExternalOrderId("");
      if (typeof e.target !== "undefined") {
        orderSource = JSON.parse(e.target.value);
      } else if (JSON.parse(localStorage.getItem("orderSourceObj"))) {
        orderSource = JSON.parse(localStorage.getItem("orderSourceObj"));
      }

      if (orderSource !== "") {
        setChoseSource(orderSource.code);
        /* this.setState({
                     orderSource: orderSource.code,
            });  */

        localStorage.setItem("orderSourceObj", JSON.stringify(orderSource));
        localStorage.setItem("orderSource", orderSource.code);
        localStorage.setItem("allowCash", orderSource.allowCash);
        localStorage.setItem("allowPaid", orderSource.allowPaid);
        localStorage.setItem(
          "allowPaymentOnline",
          orderSource.allowPaymentOnline
        );

        if (parseInt(orderSource.code) !== 5) {
          setShowOrder(true);
        } else {
          setShowOrder(false);

          localStorage.setItem("externalOrderId", "");
        }
      } else {
        localStorage.setItem(
          "orderSourceObj",
          JSON.stringify({
            id: 6,
            name: "Agent",
            code: 5,
            active: 1,
            allowPaid: 0,
            allowCash: 1,
            allowPaymentOnline: 1,
          })
        );
        localStorage.setItem("orderSource", 5);
        localStorage.setItem("allowCash", 1);
        localStorage.setItem("allowPaid", 0);
        localStorage.setItem("allowPaymentOnline", 1);
        localStorage.setItem("paymentMethod", "cash");
        localStorage.setItem("externalOrderId", "");
        setShowOrder(false);
      }
    }
  }

  //called on choosing district
  //district id is stored
  //orderBranches api call takes token and params (type,districtId) ()=> fetch branches
  function getBranches(e) {
    const token = localStorage.getItem("token");
    // localStorage.setItem("branchId", null);
    let params;
    if (!isNaN(+e)) {
      params = {
        type: "branches",
        districtId: e,
        applicationId: 4,
      };
      setDistrictId(e);

      localStorage.setItem("orderDistrictId", e);
    } else if (typeof e.target.value !== "undefined") {
      params = {
        type: "branches",
        districtId: e.target.value,
        applicationId: 4,
      };

      setDistrictId(e.target.value);
      localStorage.setItem("orderDistrictId", e.target.value);
    } else {
      params = {
        type: "branches",
        applicationId: 4,
      };
    }

    orderBranches(token, params)
      .then((response) => {
        setBranches(response.data.data);
        localStorage.setItem("allBranches", JSON.stringify(response.data.data));
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          history("/");
        }
        setArMsg(error.response.data.error);
        setEnMsg(error.response.data.error);
        setErrModal(true);

        setTimeout(function () {
          setErrModal(false);
        }, 2500);
      });
  }

  //on selecting branch
  //branch data is stored
  //if order type id is 1 (from branch) goToMenu function is called
  function selectBranch(
    branchId,
    branchNameEn,
    branchNameAr,
    enCurrency,
    arCurrency,
    branchOrderTime
  ) {
    if (basket) {
      if (
        localStorage.getItem("clearBasket") == "true" &&
        branchId !== basket.branchId
      ) {
        setClear({
          type: "branch",
          addressId: addressId,
          branchId: branchId,
          branchNameEn: branchNameEn,
          branchNameAr: branchNameAr,
          enCurrency: enCurrency,
          arCurrency: arCurrency,
          branchOrderTime: branchOrderTime,
        });

        setClearModal(true);
      } else {
        setBranchId(branchId);

        setBranchData({
          branchNameEn: branchNameEn,
          branchNameAr: branchNameAr,
          enCurrency: enCurrency,
          arCurrency: arCurrency,
          branchOrderTime: branchOrderTime,
        });

        localStorage.setItem("branchId", branchId);
        localStorage.setItem("branchNameEn", branchNameEn);
        localStorage.setItem("branchNameAr", branchNameAr);
        localStorage.setItem("enCurrency", enCurrency);
        localStorage.setItem("arCurrency", arCurrency);
        localStorage.setItem("branchOrderTime", branchOrderTime);
        if (parseInt(orderType) === 1) {
          goToMenu();
        }
      }
    } else {
      setBranchId(branchId);

      setBranchData({
        branchNameEn: branchNameEn,
        branchNameAr: branchNameAr,
        enCurrency: enCurrency,
        arCurrency: arCurrency,
        branchOrderTime: branchOrderTime,
      });

      localStorage.setItem("branchId", branchId);
      localStorage.setItem("branchNameEn", branchNameEn);
      localStorage.setItem("branchNameAr", branchNameAr);
      localStorage.setItem("enCurrency", enCurrency);
      localStorage.setItem("arCurrency", arCurrency);
      localStorage.setItem("branchOrderTime", branchOrderTime);

      if (parseInt(orderType) === 1) {
        goToMenu();
      }
    }
  }
  function handleGetOrganizations() {
    const token = localStorage.getItem("token");
    localStorage.setItem("accountType", true);
    setOrganizationLoad(true);
    getOrganizations(token, localStorage.getItem("customerId")).then(
      (response) => {
        setInvoiceType(true);
        setOrganizationArr(response.data.data);
        setOrganizationLoad(false);
      }
    );
  }

  function addressExists(id) {
    return addresses.some(function (el) {
      return el.id === id;
    });
  }

  //on selecting branch if order type id is 1 or on clicking continue
  //checks for missing data
  function goToMenu() {
    if (parseInt(orderType) !== 2) {
      localStorage.removeItem("addressId");
    }

    if (window.location.href.split("/")[3] !== "menu") {
      if (
        (String(localStorage.getItem("now")) === "false" &&
          localStorage.getItem("orderDate") === "") ||
        (String(localStorage.getItem("now")) === "false" &&
          localStorage.getItem("orderDate") === null) ||
        (String(localStorage.getItem("now")) === "false" &&
          localStorage.getItem("orderTime") === "") ||
        (String(localStorage.getItem("now")) === "false" &&
          localStorage.getItem("orderTime") === null)
      ) {
        setArMsg("أختر تاريخ وميعاد الطلب");
        setEnMsg("Choose Order Date & Time");
        setErrModal(true);
        setLoading(false);
        setTimeout(function () {
          setErrModal(false);
        }, 2500);
      } else if (
        (String(localStorage.getItem("now")) === "false" &&
          localStorage.getItem("orderDate") !== "") ||
        (String(localStorage.getItem("now")) === "false" &&
          localStorage.getItem("orderTime") !== "")
      ) {
        let pickedDate = new Date(
          localStorage.getItem("orderDate") +
            " " +
            localStorage.getItem("orderTime")
        ).getTime();

        let todaysDate = new Date();

        let day = todaysDate.getDate();
        if (day < 10) {
          day = "0" + day;
        }

        let month = todaysDate.getMonth() + 1;
        if (month < 10) {
          month = "0" + month;
        }

        let hours = todaysDate.getHours();
        if (hours < 10) {
          hours = "0" + hours;
        }

        let mins = todaysDate.getMinutes();
        if (mins < 10) {
          mins = "0" + mins;
        }

        let todaysDateFormated =
          todaysDate.getFullYear() + "-" + month + "-" + day;
        let currentDate = new Date(
          todaysDateFormated + " " + hours + ":" + mins + ":00"
        ).getTime();

        if (parseInt(pickedDate) < parseInt(currentDate)) {
          setArMsg("أختر تاريخ و وقت صحيح");
          setEnMsg("Choose a valid date & time");
          setErrModal(true);
          setLoading(false);
          setTimeout(function () {
            setErrModal(false);
          }, 2500);
        } else {
          if (orderType === "" || orderType === null) {
            setArMsg("أختر نوع الطلب");
            setEnMsg("Choose Order Type");
            setErrModal(true);
            setLoading(false);
            setTimeout(function () {
              setErrModal(false);
            }, 2500);
          } else if (
            chosenOrderSource === "" ||
            localStorage.getItem("orderSource") === null
          ) {
            setArMsg("أختر مصدر الطلب");

            setEnMsg("Choose Order Source");
            setErrModal(true);
            setLoading(false);
            setTimeout(function () {
              setErrModal(false);
            }, 2500);
          } else if (
            (parseInt(localStorage.getItem("orderSource")) !== 5 &&
              externalOrderId === null) ||
            (parseInt(localStorage.getItem("orderSource")) !== 5 &&
              externalOrderId === "")
          ) {
            setArMsg("ادخل رقم مصدر الطلب الخارجي");
            setEnMsg("Enter External Order Source ID");
            setErrModal(true);
            setLoading(false);
            setTimeout(function () {
              setErrModal(false);
            }, 2500);
          } else if (
            (parseInt(orderType) === 2 && addressId === null) ||
            (parseInt(orderType) === 2 && !addressExists(parseInt(addressId)))
          ) {
            setArMsg("أختر عنوان الطلب");
            setEnMsg("Choose Order Address");
            setErrModal(true);
            setLoading(false);
            setTimeout(function () {
              setErrModal(false);
            }, 2500);
          } else if (
            parseInt(orderType) == 1 &&
            (localStorage.getItem("orderDistrictId") == null ||
              localStorage.getItem("orderDistrictId") == "")
          ) {
            setArMsg("أختر المنطقة أولا");
            setEnMsg("Choose district First");
            setErrModal(true);
            setLoading(false);
            setTimeout(function () {
              setErrModal(false);
            }, 2500);
          } else if (
            localStorage.getItem("branchId") === "" ||
            localStorage.getItem("branchId") === null
          ) {
            setArMsg("أختر الفرع أولا");
            setEnMsg("Choose Branch First");
            setErrModal(true);
            setLoading(false);
            setTimeout(function () {
              setErrModal(false);
            }, 2500);
          } else if (
            parseInt(orderType) == 5 &&
            (localStorage.getItem("tableNum") === "" ||
              localStorage.getItem("tableNum") === null)
          ) {
            setArMsg("أختر رقم الجلسة");
            setEnMsg("Choose table number first");
            setErrModal(true);
            setLoading(false);
            setTimeout(function () {
              setErrModal(false);
            }, 2500);
          } else if (JSON.parse(invoiceType) && activeTax == null) {
            setArMsg("يرجي اختيار الشركة المراد اصدار فاتورة ضريبية لها");
            setEnMsg(
              "Please select the organization for which you would like to issue a tax invoice"
            );
            setErrModal(true);
            setLoading(false);
            setTimeout(function () {
              setErrModal(false);
            }, 2500);
          } else {
            history("/menu");
            localStorage.removeItem("offer");
          }
        }
      } else if (orderType === "" || orderType === null) {
        setArMsg("أختر نوع الطلب");
        setEnMsg("Choose Order Type");
        setErrModal(true);
        setLoading(false);
        setTimeout(function () {
          setErrModal(false);
        }, 2500);
      } else if (
        chosenOrderSource === "" ||
        localStorage.getItem("orderSource") === null
      ) {
        setArMsg("أختر مصدر الطلب");

        setEnMsg("Choose Order Source");
        setErrModal(true);
        setLoading(false);
        setTimeout(function () {
          setErrModal(false);
        }, 2500);
      } else if (
        (parseInt(localStorage.getItem("orderSource")) !== 5 &&
          externalOrderId === null) ||
        (parseInt(localStorage.getItem("orderSource")) !== 5 &&
          externalOrderId === "")
      ) {
        setArMsg("ادخل رقم مصدر الطلب الخارجي");
        setEnMsg("Enter External Order Source ID");
        setErrModal(true);
        setLoading(false);
        setTimeout(function () {
          setErrModal(false);
        }, 2500);
      } else if (
        (parseInt(orderType) === 2 && addressId === null) ||
        (parseInt(orderType) === 2 && !addressExists(parseInt(addressId)))
      ) {
        setArMsg("أختر عنوان الطلب");
        setEnMsg("Choose Order Address");
        setErrModal(true);
        setLoading(false);
        setTimeout(function () {
          setErrModal(false);
        }, 2500);
      } else if (
        parseInt(orderType) == 1 &&
        (localStorage.getItem("orderDistrictId") == null ||
          localStorage.getItem("orderDistrictId") == "")
      ) {
        setArMsg("أختر المنطقة أولا");
        setEnMsg("Choose district First");
        setErrModal(true);
        setLoading(false);
        setTimeout(function () {
          setErrModal(false);
        }, 2500);
      } else if (
        localStorage.getItem("branchId") === "" ||
        localStorage.getItem("branchId") === null
      ) {
        setArMsg("أختر الفرع أولا");
        setEnMsg("Choose Branch First");
        setErrModal(true);
        setLoading(false);
        setTimeout(function () {
          setErrModal(false);
        }, 2500);
      } else if (JSON.parse(invoiceType) == true && activeTax == null) {
        setArMsg("يرجي اختيار الشركة المراد اصدار فاتورة ضريبية لها");
        setEnMsg(
          "Please select the organization for which you would like to issue a tax invoice"
        );
        setErrModal(true);
        setLoading(false);
        setTimeout(function () {
          setErrModal(false);
        }, 2500);
      } else if (
        parseInt(orderType) == 5 &&
        (localStorage.getItem("tableNum") === "" ||
          localStorage.getItem("tableNum") === null)
      ) {
        setArMsg("أختر رقم الجلسة");
        setEnMsg("Choose table number first");
        setErrModal(true);
        setLoading(false);
        setTimeout(function () {
          setErrModal(false);
        }, 2500);
      } else {
        history("/menu");
        localStorage.removeItem("offer");
      }
    }
  }

  function checkPointer() {
    if (window.location.href.split("/")[3] !== "menu") {
      if (parseInt(orderType) !== 2) {
        localStorage.removeItem("addressId");
      }

      if (
        (String(localStorage.getItem("now")) === "false" &&
          localStorage.getItem("orderDate") === "") ||
        (String(localStorage.getItem("now")) === "false" &&
          localStorage.getItem("orderDate") === null) ||
        (String(localStorage.getItem("now")) === "false" &&
          localStorage.getItem("orderTime") === "") ||
        (String(localStorage.getItem("now")) === "false" &&
          localStorage.getItem("orderTime") === null)
      ) {
        return null;
      } else if (
        (String(localStorage.getItem("now")) === "false" &&
          localStorage.getItem("orderDate") !== "") ||
        (String(localStorage.getItem("now")) === "false" &&
          localStorage.getItem("orderTime") !== "")
      ) {
        let pickedDate = new Date(
          localStorage.getItem("orderDate") +
            " " +
            localStorage.getItem("orderTime")
        ).getTime();

        let todaysDate = new Date();

        let day = todaysDate.getDate();
        if (day < 10) {
          day = "0" + day;
        }

        let month = todaysDate.getMonth() + 1;
        if (month < 10) {
          month = "0" + month;
        }

        let hours = todaysDate.getHours();
        if (hours < 10) {
          hours = "0" + hours;
        }

        let mins = todaysDate.getMinutes();
        if (mins < 10) {
          mins = "0" + mins;
        }

        let todaysDateFormated =
          todaysDate.getFullYear() + "-" + month + "-" + day;
        let currentDate = new Date(
          todaysDateFormated + " " + hours + ":" + mins + ":00"
        ).getTime();

        if (parseInt(pickedDate) < parseInt(currentDate)) {
          return null;
        } else {
          if (orderType === "" || orderType === null) {
            return null;
          } else if (
            chosenOrderSource === "" ||
            localStorage.getItem("orderSource") === null
          ) {
            return null;
          } else if (
            (parseInt(localStorage.getItem("orderSource")) !== 5 &&
              externalOrderId === null) ||
            (parseInt(localStorage.getItem("orderSource")) !== 5 &&
              externalOrderId === "")
          ) {
            return null;
          } else if (parseInt(orderType) === 2 && addressId === null) {
            return null;
          } else if (
            parseInt(orderType) == 1 &&
            (localStorage.getItem("orderDistrictId") == null ||
              localStorage.getItem("orderDistrictId") == "")
          ) {
            return null;
          } else if (
            localStorage.getItem("branchId") === "" ||
            localStorage.getItem("branchId") === null
          ) {
            return null;
          } else if (JSON.parse(invoiceType) == true && activeTax == null) {
            return null;
          } else if (
            (parseInt(orderType) == 5 &&
              localStorage.getItem("tableNum") === "") ||
            localStorage.getItem("tableNum") === null
          ) {
            return null;
          } else {
            history("/menu");
            localStorage.removeItem("offer");
          }
        }
      } else if (orderType === "" || orderType === null) {
        return null;
      } else if (
        chosenOrderSource === "" ||
        localStorage.getItem("orderSource") === null
      ) {
        return null;
      } else if (
        (parseInt(localStorage.getItem("orderSource")) !== 5 &&
          externalOrderId === null) ||
        (parseInt(localStorage.getItem("orderSource")) !== 5 &&
          externalOrderId === "")
      ) {
        return null;
      } else if (parseInt(orderType) === 2 && addressId === null) {
        return null;
      } else if (
        parseInt(orderType) == 1 &&
        (localStorage.getItem("orderDistrictId") == null ||
          localStorage.getItem("orderDistrictId") == "")
      ) {
        return null;
      } else if (
        localStorage.getItem("branchId") === "" ||
        localStorage.getItem("branchId") === null
      ) {
        return null;
      } else if (JSON.parse(invoiceType) == true && activeTax == null) {
        return null;
      } else if (
        (parseInt(orderType) == 5 && localStorage.getItem("tableNum") === "") ||
        localStorage.getItem("tableNum") === null
      ) {
        return null;
      } else {
        history("/menu");
        localStorage.removeItem("offer");
      }
    }
  }

  function handleTable(e) {
    let data = JSON.parse(e.target.value);
    setTableNum(data.id);
    localStorage.setItem("tableNum", data.id);

    localStorage.setItem("displayId", data.display);
  }

  //on confirming clear in ClearCart component
  //Clear_cart api call takes cart id and token
  function handleClear() {
    const token = window.localStorage.getItem("token");
    Clear_cart(localStorage.getItem("CartId"), token)
      .then((response) => {
        localStorage.removeItem("editNotes");
        localStorage.removeItem("cancelOrderId");
        localStorage.removeItem("editPaymentMethod");

        localStorage.setItem("basketLength", 0);
        setBasket(null);
        setClearModal(false);
        localStorage.setItem("clearBasket", false);

        if (clear.type == "branch") {
          selectBranch(
            clear.branchId,
            clear.branchNameEn,
            clear.branchNameAr,
            clear.enCurrency,
            clear.arCurrency,
            clear.branchOrderTime
          );
        } else if (clear.type == "address") {
          selectAddress(
            clear.addressId,
            clear.branchId,
            clear.branchNameEn,
            clear.branchNameAr,
            clear.enCurrency,
            clear.arCurrency,
            clear.ordinaryDeliveryPrice,
            clear.fastDeliveryFee,
            clear.arAddress,
            clear.enAddress,
            clear.branchOrderTime
          );
        } else {
          changeOrderType(clear.value);
        }
      })
      .catch((err) => {});
  }

  //on clicking delete the id of the chosen address, and confirmation message appears
  //on confirming delete =>  delAddresses is called (takes token and address id) and deletes address
  //setDel(!del) is used to rerender useEffect to update display
  function handleDel() {
    const token = localStorage.getItem("token");

    setLoading(true);
    delAddresses(token, delId)
      .then((response) => {
        if (response.data.message === "success") {
          setDel(!del);
        } else {
          setErrModal(true);
          setArMsg("حدث خطأ");
          setEnMsg("Something went wrong");
          setLoading(false);
          setTimeout(function () {
            setErrModal(false);
          }, 2500);
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          history("/");
        } else {
          setLoading(false);
        }
      });
  }

  // takes address id and redirects to that specific address
  const editAddress = (id) => {
    localStorage.setItem("prevPath", "order");
    history(generatePath("/edit-address/:id", { id }));
  };

  return (
    <div>
      {errModal ? (
        <ErrorModal
          setModal={setErrModal}
          msg={i18n.language == "ar" ? arMsg : enMsg}
        />
      ) : null}
      {clearModal ? (
        <CleartCart handleClear={handleClear} setModal={setClearModal} />
      ) : null}
      {modal ? <DelAddress handleDel={handleDel} setModal={setModal} /> : null}
      <div className="processNav">
        <Link to="/customer-orders">
          <div>
            <img style={{ cursor: "pointer" }} src={icn1} />

            <p>{t("addOrder.profile")}</p>
          </div>
        </Link>
        <div className="dottedLine"></div>

        <div>
          <img style={{ cursor: "pointer" }} src={icn2} />
          <p>{t("addOrder.details")}</p>
        </div>

        <div className="dottedLine"></div>

        <div onClick={checkPointer} style={{ cursor: "pointer" }}>
          <img src={icn3} />
          <p style={{ color: "white" }}>{t("addOrder.menu")}</p>
        </div>

        <div className="dottedLine"></div>
        <div>
          <img src={icn4} />
          <p style={{ color: "white" }}>{t("addOrder.send")}</p>
        </div>
      </div>
      <div className="container addOrderContain">
        <div className="navigateOrder">
          <a>{t("addOrder.search")}</a>
          {i18n.language == "ar" ? <RiArrowLeftSFill /> : <RiArrowRightSFill />}
          <Link to="/customer-orders" className="userData">
            {t("addOrder.profile")} ({" "}
            {localStorage.getItem("customerPhone") +
              " - " +
              localStorage.getItem("customerName")}{" "}
            {localStorage.getItem("customerAwafi") !== "null"
              ? `- ${t("addOrder.awafi")}`
              : ""}{" "}
            ){" "}
          </Link>
          {i18n.language == "ar" ? <RiArrowLeftSFill /> : <RiArrowRightSFill />}
          <a>{t("addOrder.details")}</a>
        </div>
        <p style={{ marginTop: "1em" }} className="orderTitle">
          {t("addOrder.details")}
        </p>
        <div className="row gy-2" style={{ paddingBottom: "5em" }}>
          <div className="col-md-9">
            <div className="orderData ">
              <p style={{ marginTop: "0" }}>{t("addOrder.time")}</p>
              <div className="row">
                <div className="col-12" style={{ marginBottom: "0.5em" }}>
                  <input
                    id="now"
                    defaultChecked={
                      now === true || now === "true" ? true : false
                    }
                    className={i18n.language == "ar" ? "mrginAr" : "mrginEn"}
                    style={
                      i18n.language == "ar"
                        ? { marginLeft: "1em" }
                        : { marginRight: "1em" }
                    }
                    value={true}
                    type="radio"
                    name="time"
                    onChange={(e) => handleOptionChange(e)}
                  />
                  <label htmlFor="now">{t("addOrder.now")}</label>
                </div>
                <div className="col-12">
                  <input
                    defaultChecked={
                      now === false || now === "false" ? true : false
                    }
                    id="later"
                    className={i18n.language == "ar" ? "mrginAr" : "mrginEn"}
                    style={
                      i18n.language == "ar"
                        ? { marginLeft: "1em" }
                        : { marginRight: "1em" }
                    }
                    value={false}
                    type="radio"
                    name="time"
                    onChange={(e) => handleOptionChange(e)}
                  />
                  <label
                    htmlFor="later"
                    className={i18n.language == "ar" ? "mrginAr" : "mrginEn"}
                    style={
                      i18n.language == "ar"
                        ? { marginLeft: "1em" }
                        : { marginRight: "1em" }
                    }
                  >
                    {t("addOrder.later")}
                  </label>
                  {datePicker == true ? (
                    <div className=" datePicker">
                      <DatePicker
                        dateFormat=" HH:mm ,yyyy/MM/dd"
                        selected={
                          typeof startDate === "string" && startDate !== ""
                            ? new Date(Date.parse(startDate)) // Parse string to Date
                            : startDate
                        }
                        shouldCloseOnSelect={false}
                        onChange={handleChangeTime}
                        showTimeSelect
                        timeFormat="HH:mm"
                        minTime={minTime}
                        maxTime={new Date(new Date().setHours(23, 59))}
                        timeIntervals={15}
                        timeCaption="time"
                        minDate={new Date()}
                        maxDate={new Date(maxD)}
                        showDisabledMonthNavigation
                      />
                    </div>
                  ) : null}
                </div>
              </div>

              <p> {t("addOrder.type")}</p>
              <div className="row">
                {orderTypes &&
                  orderTypes.map((data) => (
                    <div className="col-6">
                      <input
                        name="orderType"
                        id={"type" + data.id}
                        value={data.id}
                        className={
                          i18n.language == "ar" ? "mrginAr" : "mrginEn"
                        }
                        style={
                          i18n.language == "ar"
                            ? { marginLeft: "1em" }
                            : { marginRight: "1em" }
                        }
                        type="radio"
                        checked={
                          parseInt(localStorage.getItem("orderType")) ===
                          data.id
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          localStorage.setItem("clearBasket", true);
                          changeOrderType(e);
                        }}
                      />
                      <label htmlFor={"type" + data.id}>
                        {" "}
                        {i18n.language == "ar" ? data.arName : data.enName}
                      </label>
                    </div>
                  ))}
              </div>

              {showDelivery ? (
                <>
                  <p> {t("addOrder.address")}</p>
                  <div className="row addAddress">
                    <Link
                      to="/add-address"
                      onClick={() => localStorage.setItem("prev", "add-order")}
                    >
                      <button style={{ width: "100%" }}>
                        {t("addOrder.addAddress")}
                      </button>
                    </Link>

                    {addressLoad ? (
                      <div style={{ height: "150px" }} className="loader">
                        <BeatLoader color={"#E47100"} size={25} />
                      </div>
                    ) : (
                      <>
                        {addresses &&
                          addresses.map((data) => (
                            <Address
                              data={data}
                              addressLoad={addressLoad}
                              selectAddress={selectAddress}
                              addressId={addressId}
                              setModal={setModal}
                              setDelId={setDelId}
                              editAddress={editAddress}
                            />
                          ))}
                      </>
                    )}
                  </div>
                </>
              ) : null}

              <p> {t("addOrder.customerType")}</p>
              <div className="row">
                <div className="col-6">
                  <input
                    checked={JSON.parse(invoiceType) == false}
                    name="customerType"
                    onChange={() => {
                      setInvoiceType(false);
                      localStorage.setItem("accountType", false);
                      localStorage.removeItem("organizationId");
                      setActiveTax(null);
                    }}
                    className={i18n.language == "ar" ? "mrginAr" : "mrginEn"}
                    style={
                      i18n.language == "ar"
                        ? { marginLeft: "1em" }
                        : { marginRight: "1em" }
                    }
                    type="radio"
                  />
                  <label htmlFor="regular"> {t("addOrder.regular")}</label>
                </div>
                <div className="col-6">
                  <input
                    checked={JSON.parse(invoiceType)}
                    name="customerType"
                    onChange={handleGetOrganizations}
                    className={i18n.language == "ar" ? "mrginAr" : "mrginEn"}
                    style={
                      i18n.language == "ar"
                        ? { marginLeft: "1em" }
                        : { marginRight: "1em" }
                    }
                    type="radio"
                  />
                  <label htmlFor="tax"> {t("addOrder.tax")}</label>
                </div>
              </div>

              {JSON.parse(invoiceType) ? (
                <>
                  <p> {t("addOrder.organizations")}</p>

                  <div className="row addAddress">
                    <Link
                      to="/add-tax"
                      onClick={() => localStorage.setItem("prev", "add-tax")}
                    >
                      <button style={{ width: "100%" }}>
                        {t("addOrder.addTax")}
                      </button>
                    </Link>

                    {organizationLoad ? (
                      <div style={{ height: "150px" }} className="loader">
                        <BeatLoader color={"#E47100"} size={25} />
                      </div>
                    ) : (
                      <>
                        {organizationArr &&
                          organizationArr.map((data) => (
                            <InvoiceCard
                              data={data}
                              activeTax={activeTax}
                              setActiveTax={setActiveTax}
                              setModal={setModal}
                            />
                          ))}
                      </>
                    )}
                  </div>
                </>
              ) : null}

              <p> {t("addOrder.source")}</p>

              <div className="row">
                {/*
<div className='col-3'>
<input style={i18n.language=="ar"?{marginLeft:"1em"}:{marginRight:"1em"}} value="web" type="radio" name="orderSource"  onChange={(e)=>changeOrderSource(e)}  defaultChecked={chosenOrderSource === "web" ? true: false}/>
    <label>{t("addOrder.web")}</label>
  
    </div>
    <div className='col-3'>
    <input style={i18n.language=="ar"?{marginLeft:"1em"}:{marginRight:"1em"}}  value="other" type="radio" name="orderSource" onChange={(e)=>changeOrderSource(e)}  defaultChecked={chosenOrderSource !== "web" ? true: false}/>
    <label  style={i18n.language=="ar"?{marginLeft:"1em"}:{marginRight:"1em"}} >  {t("addOrder.another")}</label>

</div>*/}

                <div className="col-sm-3 col-6 orderSource">
                  <select onChange={(e) => changeOrderSource(e)}>
                    {/* <option value="" disabled>{t("profile.choose")}</option>*/}

                    {orderSources &&
                      orderSources.map((data, index) => (
                        <option
                          value={JSON.stringify(data)}
                          selected={parseInt(chosenOrderSource) === data.code}
                          key={data.id}
                        >
                          {data.name}
                        </option>
                      ))}
                  </select>
                </div>
                {showOrder ? (
                  <>
                    <div className="col-4 orderNum">
                      <input
                        placeholder={t("addOrder.orderNo")}
                        className={
                          i18n.language == "ar" ? "mrginAr" : "mrginEn"
                        }
                        value={externalOrderId}
                        style={
                          i18n.language == "ar"
                            ? { marginLeft: "1em" }
                            : { marginRight: "1em" }
                        }
                        type="number"
                        name="time"
                        onChange={(e) => {
                          setExternalOrderId(e.target.value);

                          localStorage.setItem(
                            "externalOrderId",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </>
                ) : null}
              </div>

              {parseInt(orderType) !== 5 ? (
                <>
                  {showDistricts ? (
                    <>
                      <p>{t("addOrder.district")}</p>
                      <div className="row ">
                        <div className="col-sm-3 col-6 orderSource">
                          <select
                            defaultValue=""
                            onChange={(e) => getBranches(e)}
                          >
                            <option value="" disabled>
                              {t("profile.choose")}
                            </option>

                            {districts &&
                              districts.map((data, index) => (
                                <option
                                  selected={
                                    parseInt(
                                      localStorage.getItem("orderDistrictId")
                                    ) === data.id
                                  }
                                  value={data.id}
                                  key={data.id}
                                >
                                  {data.arName}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </>
                  ) : null}

                  {showBranches ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <p> {t("addOrder.branch")}</p>
                        <div
                          style={{
                            textAlign: "end",
                            position: "relative",
                            top: "1vh",
                          }}
                          className="srchPos"
                        >
                          <input
                            className="searchInBranchInput"
                            onChange={(e) => handleInputChange(e)}
                            value={searchQuery}
                            placeholder={t("srchInBranch")}
                          />
                          <AiOutlineSearch
                            className={
                              i18n.language == "ar"
                                ? "srchIcn1"
                                : "srchIcn1 srchIcnEn"
                            }
                          />
                        </div>
                      </div>

                      <div className="row gy-4 branches">
                        {branches.length > 0 ? (
                          branches &&
                          branches.map((data, index) => (
                            <div
                              className={
                                parseInt(branchId) === parseInt(data.id) &&
                                String(data.branchOption) === "1"
                                  ? "branchSelect branch branchSelectActive col-sm-4 col-5"
                                  : String(data.branchOption) === "1"
                                  ? "branchSelect branch col-sm-4 col-5"
                                  : "branchSelect branchSelectClosed branch col-sm-4 col-5"
                              }
                              id={"branch" + data.id}
                              onClick={() =>
                                String(data.branchOption) === "1"
                                  ? localStorage.setItem("clearBasket", true) +
                                    selectBranch(
                                      data.id,
                                      data.enName,
                                      data.arName,
                                      data.enCurrency,
                                      data.arCurrency,
                                      data.branchOrderTime
                                    )
                                  : ""
                              }
                            >
                              <div
                                style={{
                                  'margin': '8px',
                                  padding: '15px',
                                  display: "flex",
                                  flexDirection: "column",
                                  'justifyContent':'center',
                                  'alignItems': 'center'
                                }}
                              >
                                <ImInfo
                                  title={
                                    <p className="pickup">
                                      {t("addOrder.pickup")}{" "}
                                      {data.branchOrderTime}{" "}
                                      {t("addOrder.minutes")}{" "}
                                    </p>
                                  }
                                 
                                  size={25}
                                />
                                <p>
                                  {i18n.language == "ar"
                                    ? data.arName
                                    : data.enName}
                                </p>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p>{t("addOrder.noResult")}</p>
                        )}
                      </div>
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  <p> {t("addOrder.branch")}</p>
                  <div className="row gy-4 branches">
                    {allBranchesArr.length > 0 ? (
                      allBranchesArr &&
                      allBranchesArr.map((data, index) => (
                        <div
                          className={
                            parseInt(branchId) === parseInt(data.id)
                              ? "branchSelect branchSelectActive branch col-sm-4 col-5"
                              : "branchSelect  col-sm-4 col-5"
                          }
                          id={"branch" + data.id}
                          onClick={() =>
                            localStorage.setItem("clearBasket", true) +
                            selectBranch(
                              data.id,
                              data.enName,
                              data.arName,
                              data.enCurrency,
                              data.arCurrency,
                              data.branchOrderTime
                            )
                          }
                        >
                          <div>
                            {i18n.language == "ar" ? data.arName : data.enName}
                            {/* <p className='pickup'>
{t("addOrder.pickup")} {data.branchOrderTime} {t("addOrder.minutes")} </p> */}
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>{t("addOrder.noResult")}</p>
                    )}
                  </div>

                  <>
                    <p>{t("tables.chooseNo")}</p>
                    <div className="row ">
                      <div className="col-3">
                        <select
                          defaultValue={
                            localStorage.getItem("tableNum")
                              ? JSON.stringify({
                                  id: localStorage.getItem("tableNum"),
                                  display: localStorage.getItem("displayId"),
                                })
                              : ""
                          }
                          onChange={(e) => handleTable(e)}
                        >
                          <option value="" disabled>
                            {t("profile.choose")}
                          </option>

                          {tbls &&
                            tbls.map((data, index) => (
                              <option
                                value={JSON.stringify({
                                  id: data.id,
                                  display: data.tableName,
                                })}
                                key={data.id}
                                selected={
                                  parseInt(localStorage.getItem("tableNum")) ===
                                  data.id
                                }
                              >
                                {data.tableName}
                              </option>
                            ))}
                          {tbls.length == 0 ? (
                            <option
                              value={JSON.stringify({ id: 0, display: 0 })}
                              selected={
                                parseInt(localStorage.getItem("tableNum")) === 0
                              }
                            >
                              {t("addOrder.waitingList")}{" "}
                            </option>
                          ) : null}
                        </select>
                      </div>
                    </div>
                  </>
                </>
              )}
            </div>
          </div>

          <div className="col">
            <button onClick={goToMenu} className="toMenu">
              {" "}
              {t("addOrder.goto")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOrder;
