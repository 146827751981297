import '../Styles/CustomerOrders.css';

import Navbar from '../Components/Navbar';
import { useTranslation } from 'react-i18next';
import { FiEdit } from 'react-icons/fi';
import { BsGlobe2,BsInfoCircle } from 'react-icons/bs';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import bckg from "../Images/order.svg"
import arrows from "../Images/switch.svg"
import {useState,useEffect}  from 'react';
import { useNavigate } from "react-router-dom";
import { customer,getAwafiCode,customerPoints,changePoints } from '../api';
import { BeatLoader } from 'react-spinners';
import { useParams,generatePath } from 'react-router-dom';
import CustomerData from '../Components/CustomerData';
import OtpInput from 'react-otp-input';
import icn1 from "../Images/customerProfile.svg"
import icn2 from "../Images/orderDetailsIconInactive.svg"
import icn3 from "../Images/menuIconUnactive.svg"
import icn4 from "../Images/basketIconUnactive.svg"
import { propTypes } from 'react-bootstrap/esm/Image';
function CustomerPoints(props) {
   
   
  const { t, i18n } = useTranslation();
  const [msgAr, setMsgAr]=useState();
  const [msgEn, setMsgEn]=useState();
  const [rotate,setRotate]=useState("rotate");
  const[submitLoading,setSubmitLoading]=useState(false);
  const[submitLoading2,setSubmitLoading2]=useState(false);
const [errorMsgModel, setErrorMsgModel]=useState(false);
  const[loading,setLoading]=useState(true);
 
  const [points,setPoints]=useState()
const[amount,setAmount]=useState(0)
const[verify,setVerify]=useState(false)
const[code,setCode]=useState("")
const[pointEx,setPointEx]=useState(0)
  const customerId= localStorage.getItem("customerId");
 let history= useNavigate();
   
   //customerPoints=> takes customer id and user token and returns customer points balance and the current price pair point
useEffect(() => {

const token= localStorage.getItem("token");



 
customerPoints(token,customerId).then((response) => {
    setPoints(response.data.data)
    setPointEx(response.data.data.pointsBalance )
    setAmount(response.data.data.pointsBalance * response.data.data.pointPrice)
setLoading(false)
}).catch(function(error){
if (error.response.status === 401) {
 history("/")
}
else if(error.response.status === 403){
props.setErr(true)
props.setMsg(t('validation.noPermission'))
props.setStyle({top:"50%"})
}
    

history("/customer-orders")

});

},[]) 
 
 


function handleExchange(){
    const token= localStorage.getItem("token");


    setSubmitLoading(true);
   //rotates arrow by changing its class
 
    if(rotate=="rotate"){
    setRotate("rotate2");}
    else {
        setRotate("rotate");  
    } 

    if (pointEx <= 0) {
      
            setErrorMsgModel(true)
            setMsgAr( "يجب أن يكون عدد النقاط اكتر من صفر")
            setMsgEn( "Number of points must be more than zero")
       setSubmitLoading(false)

   
 
    }else if (parseInt(pointEx) > parseInt(points.pointsBalance)) {
     
        setErrorMsgModel(true)
        setMsgAr( "يجب أن يكون عدد النقاط اقل من او يساوي عدد نقاطك")
        setMsgEn(  "Number of points must be less than your points balance")
   setSubmitLoading(false)
     
    } 
    
    
    
    else {
        let values = {
            points: pointEx
        };




        getAwafiCode(token,customerId,values).then((response) => {  
    if (response.data.message === "success") {
        setVerify(true)
        setErrorMsgModel(false)
} else {
    setErrorMsgModel(true)
    setMsgAr(  "حدث خطأ")
    setMsgEn( "Something went wrong")
    setSubmitLoading(false)
 
} 

}).catch(function(error){
    setErrorMsgModel(true)
    setMsgAr(error.response.data.error,)
    setMsgEn(  error.response.data.error)
    setSubmitLoading(false)
   


});}

}
function handleVerify(){
    const token= localStorage.getItem("token");


    setSubmitLoading2(true)



    if (pointEx <= 0) {
      
        setErrorMsgModel(true)
        setMsgAr( "يجب أن يكون عدد النقاط اكتر من صفر")
        setMsgEn( "Number of points must be more than zero")
   setSubmitLoading2(false)



}else if (parseInt(pointEx) > parseInt(points.pointsBalance)) {
   
    setErrorMsgModel(true)
    setMsgAr( "يجب أن يكون عدد النقاط اقل من او يساوي عدد نقاطك")
    setMsgEn(  "Number of points must be less than your points balance")
setSubmitLoading2(false)
 
} 
else if (code === "") {
    setErrorMsgModel(true)
    setMsgAr( "من فضلك ادخل كود الاستبدال")
    setMsgEn(  "Please enter replacement code")
setSubmitLoading2(false)
 
}
else {

    let values = {
        points: pointEx,
        code: code,
    };

    changePoints(token,customerId,values).then((response) => {
        if (response.data.message === "success") {
          history("/customer-wallet")                
        } else {
            setErrorMsgModel(true)
            setMsgAr( "حدث خطأ")
            setMsgEn( "Something went wrong")
        setSubmitLoading2(false)
          
        } 
    }).catch(function (error) {
        setErrorMsgModel(true)
        setMsgAr( error.response.data.error)
        setMsgEn(  error.response.data.error)
    setSubmitLoading2(false)
      
    })
}
};
    return (
      <div >
           <div className='processNav' >
          <Link to="/customer-orders">
<div>
<img style={{cursor:"pointer"}} src={icn1}/>

<p>{t("addOrder.profile")}</p>
</div></Link>
<div className='dottedLine'></div>
<Link to="/add-order">
<div>
<img style={{cursor:"pointer"}}  src={icn2}/>
<p  style={{color:"white"}}>{t("addOrder.details")}</p></div>
</Link>
<div className='dottedLine'></div>
<div>
<img src={icn3}/>
<p style={{color:"white"}}>{t("addOrder.menu")}</p></div>

<div className='dottedLine'></div>
<div>
<img src={icn4}/>
<p style={{color:"white"}}>{t("addOrder.send")}</p></div>
  </div>
          {loading?
               <div style={{height:"80vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:

      <>
      {points.joinLoyalty==1?
          <div style={{marginTop:"2em"}} className='container-fluid'>
          
          <CustomerData loading={loading}  active={4}/>

<div className='customerBody'>

<div style={{marginTop:"2em"}} className='row gy-4'>
<div className='col-md-9 order-md-first order-last'>
<div className='pointExchange'>
<p  className='pointExchange-title'> {verify? t("profile.enterReplacementCode"): t("profile.exchange")}</p>
<div className='pointExchange-exchange'>
    {verify?
    <input value={code}  onChange={(e)=>setCode(e.target.value)}
        />

        
    : null}
    < >
<div style={verify?{display:"none"}:null}>
<p>{t("profile.points")}</p>
<input type="number"  value={pointEx} onChange={(e)=>{
    
    if(e.target.value>=0){
    setAmount(e.target.value*points.pointPrice)

setPointEx(e.target.value)}
}}/>
</div>

 
<img src={arrows} className={rotate} style={verify?{display:"none"}:null}/>
<div style={verify?{display:"none"}:null}>
<p>{t("profile.SAR")}</p>
<input value={amount.toFixed(2)} type="number" disabled/>
</div>
</>


</div>
<div style={{textAlign:"start"}}>

{i18n.language=="ar"?
<span style={{textAlign:"center",fontSize:"1rem"}}  className='validation'>{errorMsgModel? msgAr:null}</span>:
<span style={{textAlign:"center",fontSize:"1rem"}} className='validation' >{errorMsgModel? msgEn:null}</span>}
{verify? 
    <button disabled={submitLoading2} onClick={handleVerify}>{ t("profile.submit")}</button>
:
<button disabled={submitLoading} onClick={handleExchange}>{ t("profile.exchange")}</button>
}
</div>

</div> 

</div>
<div className='col-md-3'>
<div className='currentCredit'>
<p>{ t("profile.pointBalance")}</p>
<p className='credit'>{points.pointsBalance}</p>
<p>{ t("profile.point")}</p>
</div>
 

</div>

</div>



</div>

       </div>:
       <div>
  <div style={{marginTop:"2em"}} className='container'>
          
          <CustomerData loading={loading}  active={3}/>

<div className='customerBody'>
<div className='notAwafi'>
<p>{t("profile.notAwafi")}</p>
<Link to="/awafi-register">
<button>{t("profile.awafiReg")}</button></Link>
    </div>
    </div></div>
       </div>}
       </>}</div>
    );
  }
  
  export default CustomerPoints;
  
  