import '../Styles/Dashboard.css';

import { useTranslation } from 'react-i18next';

function Invoice(props) {
   
    const { t, i18n } = useTranslation();

  
    return (
        <div >
         
        <div onClick={()=>props.setModal(false)}  className="modalOverlay"></div>
        <div  className="modal-content " id="invoice" >
        <p>{t("orders.invoice")}</p>
        <hr/>
        <ul>
          <li><span>{t("menu.price")}</span>  {props.data.price}</li>
          <li><span>{t("menu.disc")}: </span>  {props.data.discount}</li>
          <li><span>{t("menu.deliveryPrice")}:</span>  {props.data.deliveryPrice}</li>
          <li><span>{t("tablePrice")}:</span>  {props.data.tablePrice}</li>
          <li><span>{t("singleOrder.totalPrice")}:</span>  {props.data.totalPrice}</li>
          <li><span>{t("profile.wallet")}:</span>  {props.data.walletPay}</li>
          <li><span>{t("orders.remain")}:</span>  {props.data.remainPrice}</li>
          <li><span>{t("singleOrder.tax")}:</span>  {props.data.taxPerc}</li>

        </ul>
 
        </div>
      </div>
  
    );
  }
  
  export default Invoice;
  
  