import "../Styles/Offers.css";
import { useTranslation } from "react-i18next";

import { useState, useEffect, useRef } from "react";
import { BeatLoader } from "react-spinners";
import {
  calories,
  agnetBranches,
  productionList,
  saveNewQuan,
  getCategoryByBranch,
} from "../api";
import { MDBDataTable } from "mdbreact";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import PaginationItem from "@mui/material/PaginationItem";
import Select from "react-select";
import { GrPowerReset } from "react-icons/gr";
import { setNestedObjectValues } from "formik";
import QuantityInput from "../Components/QuantityInput";
function Quantity() {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState([]);
  const [branches, setBranches] = useState([]);
  const [activeBranch, setActiveBranch] = useState(
    localStorage.getItem("activeBranch")
      ? localStorage.getItem("activeBranch")
      : null
  );
  const menuRef = useRef(menu);

  const [page, setPage] = useState(1);
  const [resp, setResp] = useState();
  const [changeQuan, setChangeQuan] = useState(false);
  const [selectedName, setSelectedName] = useState({ value: "" });
  const [categArr, setCategArr] = useState([]);

  const [tableLoading, setTableLoading] = useState(false);
  const [filter, setFilter] = useState(false);
  const nameRef = useRef();

  const categRef = useRef();
  const sizeRef = useRef();
  const addRef = useRef();
  const prodRef = useRef();
  const token = localStorage.getItem("token");

  //filterMeals api call gets data used in filteration
  //calories api  call gets data of meals
  useEffect(() => {
    setTableLoading(true);

    agnetBranches(token).then((response) => {
      setActiveBranch(
        localStorage.getItem("activeBranch")
          ? localStorage.getItem("activeBranch")
          : response.data.data[0].id
      );
      setBranches(response.data.data);
      getCategoryByBranch(
        token,
        localStorage.getItem("activeBranch")
          ? localStorage.getItem("activeBranch")
          : response.data.data[0].id
      ).then((response) => {
        setCategArr(response.data.data);
      });
      productionList(
        token,
        localStorage.getItem("activeBranch")
          ? localStorage.getItem("activeBranch")
          : response.data.data[0].id,
        page
      ).then((response) => {
        setResp(response.data.data);
        if (response.data.data.toString() == []) {
          setMenu([]);
          setLoading(false);
          setTableLoading(false);
        } else {
          const result = Object.values(response.data.data.data);

          let mealArr = [];

          for (let i = 0; i < result.length; i++) {
           
            let obj = {};
            obj.mealId = result[i].id;
            obj.productArName = (
              <p style={{ marginBottom: "0" }}>{result[i].arName} </p>
            );
            obj.productEnName = (
              <p style={{ marginBottom: "0" }}>{result[i].enName} </p>
            );
            obj.availableQuantity = result[i].availableQuantity;
            obj.categoryNameAr = (
              <p style={{ marginBottom: "0" }}>{result[i].categoryNameAr} </p>
            );
            obj.categoryNameEn = (
              <p style={{ marginBottom: "0" }}>{result[i].categoryNameEn} </p>
            );
            obj.defaultSizeNameEn = (
              <p style={{ marginBottom: "0" }}>{result[i].defualtSize ? result[i].defualtSize.name_en : ""} </p>
            )
            obj.defaultSizeNameAr = (
              <p style={{ marginBottom: "0" }}>{result[i].defualtSize ? result[i].defualtSize.name_ar : ""} </p>
            )

            obj.quantity = (
              <QuantityInput
                availableQuantity={result[i].availableQuantity}
                id={result[i].id}
                handleChangeQuan={handleChangeQuan}
              />
            );
            mealArr.push(obj);
          }

          setMenu(mealArr);
          setLoading(false);
          setTableLoading(false);
        }
      });
    });
  }, [loading]);
  useEffect(() => {
    menuRef.current.value = menu;
  }, [menu]);

  const data = {
    columns: [
      {
        label: t("offer.id"),
        field: "mealId",
        sort: "asc",
      },

      {
        label: t("offer.prodName"),
        field: i18n.language == "ar" ? "productArName" : "productEnName",
        sort: "asc",
      },

      {
        label: t("menu.category"),
        field: i18n.language == "ar" ? "categoryNameAr" : "categoryNameEn",
        sort: "asc",
      },

      {
        label: t("menu.defaultSize"),
        field: i18n.language == "ar" ? "defaultSizeNameAr" : "defaultSizeNameEn",
        sort: "asc",
      },

      {
        label: t("singleOrder.quantity"),
        field: "quantity",
        sort: "asc",
      },
    ],
    rows: menu,
  };
  function handleChangeQuan(value, id) {
  
    const exist = menuRef.current.value.find((data) => data.mealId === id);

    const newMenu = menuRef.current.value.map((data) =>
      data.mealId === id
        ? { ...exist, availableQuantity: parseFloat(value) }
        : data
    );

   

    setMenu(newMenu);
  }

  function handleSave() {
   
    setLoading(true)

    const data = menu.map((data) => ({
      branchProductId: data.mealId,
      availableQuantity: data.availableQuantity,
    }));

    console.log(data)



    saveNewQuan(token, data).then((response) => {
      if(response.status ==200) {
        setLoading(false)

        setChangeQuan(!changeQuan);

      }
      
    });
  }

  //on page change or on filtering
  //on page change => changes page
  //on filtering => set page to page number 1
  //calories api call can filter by (categoryId,productId,additionalId and sizeId)
  function filterData(page, type) {
    if (type == "filter") {
      setFilter(true);
    }
    window.scrollTo(0, 0);
    setTableLoading(true);
    setPage(page);
    let data = {
      categoryId: categRef.current.value,
      productName: nameRef.current.value,
    };

    productionList(
      token,
      activeBranch,
      page,
      type == "filter" || filter == true ? data : null
    ).then((response) => {
      setResp(response.data.data);
      if (response.data.data.toString() == []) {
        setMenu([]);
        setTableLoading(false);
      } else {
        const result = Object.values(response.data.data.data);

        let mealArr = [];

        for (let i = 0; i < result.length; i++) {
         
          let obj = {};
          obj.mealId = result[i].id;
          obj.productArName = (
            <p style={{ marginBottom: "0" }}>{result[i].arName} </p>
          );
          obj.productEnName = (
            <p style={{ marginBottom: "0" }}>{result[i].enName} </p>
          );
          obj.availableQuantity = result[i].availableQuantity;
          obj.categoryNameAr = (
            <p style={{ marginBottom: "0" }}>{result[i].categoryNameAr} </p>
          );
          obj.categoryNameEn = (
            <p style={{ marginBottom: "0" }}>{result[i].categoryNameEn} </p>
          );
          obj.defaultSizeNameEn = (
            <p style={{ marginBottom: "0" }}>{result[i].defualtSize ? result[i].defualtSize.name_en : ""} </p>
          )
          obj.defaultSizeNameAr = (
            <p style={{ marginBottom: "0" }}>{result[i].defualtSize ? result[i].defualtSize.name_ar : ""} </p>
          )

          obj.quantity = (
            <QuantityInput
              availableQuantity={result[i].availableQuantity}
              id={result[i].id}
              handleChangeQuan={handleChangeQuan}
            />
          );
          mealArr.push(obj);
        }

        setMenu(mealArr);
        setTableLoading(false);
      }
    });
  }

  //clears input fields
  //calories api is called to get areas with no filtres
  //sets page to page number 1
  function resetData() {
    setFilter(false);
    var selectElement = document.getElementsByClassName("calSelect");

    for (var i = 0; i < selectElement.length; i++) {
      selectElement[i].selectedIndex = null;
    }
    nameRef.current.value = null;

    window.scrollTo(0, 0);
    setTableLoading(true);
    setPage(1);

    productionList(token, activeBranch, 1).then((response) => {
      setResp(response.data.data);
      if (response.data.data.toString() == []) {
        setMenu([]);
        setTableLoading(false);
      } else {
        const result = Object.values(response.data.data.data);

        let mealArr = [];

        for (let i = 0; i < result.length; i++) {
          let obj = {};
          obj.mealId = result[i].id;
          obj.productArName = (
            <p style={{ marginBottom: "0" }}>{result[i].arName} </p>
          );
          obj.productEnName = (
            <p style={{ marginBottom: "0" }}>{result[i].enName} </p>
          );
          obj.availableQuantity = result[i].availableQuantity;
          obj.categoryNameAr = (
            <p style={{ marginBottom: "0" }}>{result[i].categoryNameAr} </p>
          );
          obj.categoryNameEn = (
            <p style={{ marginBottom: "0" }}>{result[i].categoryNameEn} </p>
          );

          obj.defaultSizeNameEn = (
            <p style={{ marginBottom: "0" }}>{result[i].defualtSize ? result[i].defualtSize.name_en : ""} </p>
          )
          obj.defaultSizeNameAr = (
            <p style={{ marginBottom: "0" }}>{result[i].defualtSize ? result[i].defualtSize.name_ar : ""} </p>
          )

          obj.quantity = (
            <QuantityInput
              availableQuantity={result[i].availableQuantity}
              id={result[i].id}
              handleChangeQuan={handleChangeQuan}
            />
          );
          mealArr.push(obj);
        }

        setMenu(mealArr);
        setTableLoading(false);
      }
    });
  }

  function handleBranch(data) {
    setPage(1);
    localStorage.setItem("activeBranch", data);
    var selectElement = document.getElementsByClassName("calSelect");

    for (var i = 0; i < selectElement.length; i++) {
      selectElement[i].selectedIndex = null;
    }
    nameRef.current.value = null;

    setTableLoading(true);
    setActiveBranch(data);
    getCategoryByBranch(token, data).then((response) => {
      setCategArr(response.data.data);
    });

    productionList(token, data).then((response) => {
      setResp(response.data.data);
      if (response.data.data.toString() == []) {
        setMenu([]);
        setTableLoading(false);
      } else {
        const result = Object.values(response.data.data.data);

        let mealArr = [];

        for (let i = 0; i < result.length; i++) {
      
          let obj = {};
          obj.mealId = result[i].id;
          obj.productArName = (
            <p style={{ marginBottom: "0" }}>{result[i].arName} </p>
          );
          obj.productEnName = (
            <p style={{ marginBottom: "0" }}>{result[i].enName} </p>
          );
          obj.availableQuantity = result[i].availableQuantity;
          obj.categoryNameAr = (
            <p style={{ marginBottom: "0" }}>{result[i].categoryNameAr} </p>
          );
          obj.categoryNameEn = (
            <p style={{ marginBottom: "0" }}>{result[i].categoryNameEn} </p>
          );
          obj.defaultSizeNameEn = (
            <p style={{ marginBottom: "0" }}>{result[i].defualtSize ? result[i].defualtSize.name_en : ""} </p>
          )
          obj.defaultSizeNameAr = (
            <p style={{ marginBottom: "0" }}>{result[i].defualtSize ? result[i].defualtSize.name_ar : ""} </p>
          )

          obj.quantity = (
            <QuantityInput
              availableQuantity={result[i].availableQuantity}
              id={result[i].id}
              handleChangeQuan={handleChangeQuan}
            />
          );
          mealArr.push(obj);
        }

        setMenu(mealArr);
        setTableLoading(false);
      }
    });
  }
  return (
    <div className="questions">
      <p style={{ marginBottom: "0" }} className="screenTitle">
        {t("profile.quantity")}
      </p>

      {loading ? (
        <div style={{ height: "60vh" }} className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <div
          className={
            i18n.language == "ar" ? "container-fluid  pagAr" : "container-fluid"
          }
        >
          <div
            className="container-fluid filtering"
            style={{ marginBottom: "2em", padding: "1em 0" }}
          >
            <div className="row ">
              <div className="col-12">
                <div className="container-fluid">
                  <div className="row gy-3">
                    <div className="col-12 filterTitile">
                      <p>{t("orders.filter")}</p>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-6">
                      <label>{t("menu.product")}</label>
                      <input ref={nameRef} placeholder={t("offer.search")} />
                    </div>

                    <div className="col-lg-2 col-md-3 col-sm-6">
                      <label>{t("menu.category")}</label>

                      <select
                        defaultValue=""
                        ref={categRef}
                        className="calSelect"
                      >
                        <option value="" disabled>
                          {t("profile.choose")}
                        </option>
                        {categArr &&
                          categArr.map((data, index) => (
                            <option key={index} value={data.id}>
                              {i18n.language == "ar"
                                ? data.arName
                                : data.enName}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div
                      className="col-lg-4 col-md-6 alignVertEnd "
                      style={{ textAlign: "end" }}
                    >
                      <button
                        style={{ width: "35%" }}
                        disabled={tableLoading}
                        onClick={() => filterData(1, "filter")}
                        className="filter"
                      >
                        {t("orders.filter")}
                      </button>
                      <span style={{ margin: "0 0.5em" }}></span>
                      <button
                        style={{ width: "65%" }}
                        onClick={resetData}
                        className="reset"
                      >
                        {t("offer.reset")} <GrPowerReset color="#ffff" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="branchScroll">
            {branches &&
              branches.map((data, index) => (
                <p
                  className={data.id == activeBranch ? "activeBranch" : null}
                  style={
                    i18n.language == "ar"
                      ? { marginLeft: "2em" }
                      : { marginRight: "2em" }
                  }
                  key={index}
                  onClick={() => handleBranch(data.id)}
                >
                  {i18n.language == "ar" ? data.arName : data.enName}
                </p>
              ))}
          </div>
          <div className="saveBtn">
            <button
              onClick={handleSave}
              style={{ width: "35%" }}
              className="filter"
            >
              {t("profile.save")}
            </button>
          </div>
          {tableLoading ? (
            <div style={{ height: "30vh" }} className="loader">
              <BeatLoader color={"#E47100"} loading={tableLoading} size={30} />
            </div>
          ) : (
            <>
              <div
                style={{ marginTop: "1em" }}
                className={
                  i18n.language == "ar"
                    ? "row orderTable"
                    : "row tableEn orderTable"
                }
              >
                <div
                  className={
                    menu.length == 0
                      ? i18n.language == "ar"
                        ? " emptyAr empty col-lg-12 col-md-12 col-sm-12 col-xs-12"
                        : "  empty col-lg-12 col-md-12 col-sm-12 col-xs-12"
                      : "col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  }
                >
                  <MDBDataTable
                    entries={50}
                    entriesOptions={[50]}
                    infoLabel={[
                      t("orders.info1"),
                      t("orders.info2"),
                      t("orders.info3"),
                      t("orders.info4"),
                    ]}
                    searchLabel={t("dashboard.search")}
                    paginationLabel={[t("orders.prev"), t("orders.next")]}
                    entriesLabel={t("orders.show")}
                    striped
                    hover
                    noBottomColumns={true}
                    data={data}
                  />
                </div>
              </div>
              <Stack spacing={0}>
                <Pagination
                  renderItem={(item) => (
                    <PaginationItem
                      slots={{
                        previous: i18n.language == "ar" ? "prevAr" : "prevEn",
                        next: i18n.language == "ar" ? "nextAr" : "nextEn",
                      }}
                      {...item}
                    />
                  )}
                  className={i18n.language == "ar" ? "arArrow" : null}
                  defaultPage={1}
                  page={page}
                  count={resp.last_page}
                  onChange={(e, value) => {
                    filterData(value);
                  }}
                  shape="rounded"
                  variant="outlined"
                />
              </Stack>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default Quantity;
