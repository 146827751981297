import '../Styles/Dashboard.css';
import { AiOutlineSearch } from 'react-icons/ai';
import Navbar from '../Components/Navbar';
import { useTranslation } from 'react-i18next';
import { IoCloseSharp } from "react-icons/io5";
import bckg from "../Images/order.svg"
import {RiShoppingCartFill } from 'react-icons/ri';
function DelAddress(props) {
   
    const { t, i18n } = useTranslation();

  function handleDel(){
props.setModal(false)
props.handleDel()
  }
    return (
        <div >
         
        <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
        <div  className="modal-content" style={{height:"auto",width:"300px",paddingTop:"2em",position:"fixed"}}>
        
<p className='alrt'>{t("profile.alert")}</p>
<p className='alrt'>{t("profile.sure")}</p>
<div className='modalBtns'>
<button  onClick={handleDel}  className='del'>{t("profile.del")}</button>
<button  onClick={()=>props.setModal(false)}  className='cls'>{t("profile.close")}</button>

</div>
        </div>
      </div>
  
    );
  }
  
  export default DelAddress;
  
  