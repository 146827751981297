
import React, {useState,useEffect,useRef} from 'react';
 
import { useTranslation } from 'react-i18next';
import { countries,branches,updatePrepayment,companyActivities,externalUser} from '../../api';
import { BeatLoader } from 'react-spinners';
import * as yup from "yup"
import {useFormik} from "formik";
import { useNavigate } from "react-router-dom";

function AdvPaymentReg() {
  const token=localStorage.getItem("token")
 
    const { t, i18n } = useTranslation();
    const [loading,setLoading]=useState(false);
const [msgAr, setMsgAr]=useState();
const [msgEn, setMsgEn]=useState();
const [errorMsgModel, setErrorMsgModel]=useState(false);
const [city,setCity]=useState([]);
const [activities,setActivities]=useState([]);
const [chosenBranches,setChosenBranches]=useState([]);
const [values,setValues]=useState(
  {
    client:  '',
    clientMobile: '',
    location:'',
    companyActiv:'',
    city:'',
  }
);
const [validation,setValidation]=useState(
  {
    facilityName: {
      err:false,
      text:""
    },
    activityId: {
      err:false,
      text:""
    }, cityId: {
      err:false,
      text:""
    }, branches: {
      err:false,
      text:""
    }
  
  })
const [branchArr,setBranchArr]=useState([]);

      const history=useNavigate();
      let phoneRegEx =/^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;

      const Schema= yup.object().shape({
        client: yup.string().required(("advPayment.required_client")).max(150,("advPayment.max_client")),
        clientMobile: yup.string().required(("advPayment.required_clientMobile")).matches(phoneRegEx,{message:("advPayment.invalid_authMobile")}),
        companyActiv: yup.string().required(("advPayment.required_companyActiv")),
        city: yup.string().required(("Login.required_city")),

        
      })
      


      useEffect(() => {
        companyActivities(token).then(response => {

          setActivities(response.data)
               
               })
        countries(token).then(response => {
     
     
    setCity(response.data.data[0].cities)

         
         })
   
         externalUser(token,localStorage.getItem("externalId")).then(response => {
          setValues({
            client: response.data.data.user.name,
            clientMobile:  response.data.data.user.phone,
            location:'',
            companyActiv: response.data.data.clientActivity.id,
            city: response.data.data.city.id,
          })
          branches(token,response.data.data.city.id).then(response => {
         
         
            setBranchArr(response.data.data)
                 
                 })
    
              setLoading(false)
                   
                   })

                },[]);
      const formik = useFormik({
      initialValues:{
        client: values.client,
        clientMobile: values.clientMobile,
        companyActiv:values.companyActiv,
        city:values.city,

     
      },
      validationSchema: Schema,
      enableReinitialize: true,

      onSubmit
      });
      const cityRef=useRef();

      const clientRef=useRef();
      const clientMobileRef=useRef();
      const locationRef=useRef();
      const companyActivRef=useRef();
   

     function handleChangeCity(id){
      setChosenBranches([])

      branches(token,id).then(response => {
     
     
        setBranchArr(response.data.data)
             
             }) 
     }


     function onSubmit(){
      if(chosenBranches.length==0){
        setMsgAr("برجاء اختيار الفروع")
        setMsgEn("Please choose from branches list")
        setErrorMsgModel(true)
      }
       else{
 
     setLoading(true);
     const data={
       facilityName:clientRef.current.value,
       activityId:parseInt(companyActivRef.current.value),
       cityId:parseInt(cityRef.current.value),
       branches: chosenBranches
     }
     updatePrepayment(token,localStorage.getItem("externalId"),data,i18n.language).then((response) => {
     
       history("/external-user"); 
      
}).catch(function (error) {

  let errorArr=error.response.data.errors;

  Object.keys(errorArr).forEach((key) => {
 
   if (validation.hasOwnProperty(key)) {
     setValidation((prevState) => ({
       ...prevState,
       [key]: {
         err: true,
         text: errorArr[key][0],
       },
     }));
   }
 });
 setLoading(false)

})
    }
  }

    var checkList;

    function dropDown(){
  
  
      checkList = document.getElementById('list1');
      if (checkList.classList.contains('visible')){
      checkList.classList.remove('visible');}
      else{
        checkList.classList.add('visible');}
     
    
    
}

function handleBranches(e){
  var checkArr=[]
  var check = document.getElementsByClassName('checkBranches');

  for(var i=0; i<check.length; i++) { 
    if(check[i].checked){
   
      checkArr.push(parseInt(check[i].value))
  
  }
  }
 
  setChosenBranches(checkArr)



}


   return (
        <div>
  
        <div className='loginContainer container' style={{margin:"2em auto",paddingBottom:"0"}} >
 
<div className='login' style={{height:"unset"}}>
{loading?
      <div style={{height:"110vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:null}
<div style={loading?{display:"none"}:null}  >
<div >
    <div className='loginTitle'>
<p >{t("advPayment.advPayment")} </p></div>

<div className='container-fluid userDataExt'style={{padding: "2em 1em"}} >
   <form className="row" onSubmit={formik.handleSubmit}>
   <div className='col-sm-5 col-6'>

   <div className="inputDiv">
<label>  {t("advPayment.client")}</label>
<input type="text" placeholder={t("advPayment.client")} value={formik.values.client} id="client" name="client"  ref={clientRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.client && formik.touched.client) || validation.facilityName.err==true ?"invalid":null}/>
<span>{formik.errors.client && formik.touched.client?t(`${formik.errors.client}`):null}</span>
<span>{validation.facilityName.err=true? validation.facilityName.text:null}</span>

</div></div>
<div className='col-1 removeCol'>
   </div>
<div className='col-sm-5 col-6'>

<div className="inputDiv">
<label>  {t("advPayment.clientMobile")}</label>
<input type="text" placeholder={t("advPayment.clientMobile")} value={formik.values.clientMobile} id="clientMobile" name="clientMobile"  ref={clientMobileRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.clientMobile && formik.touched.clientMobile ?"invalid":null}/>
<span>{formik.errors.clientMobile && formik.touched.clientMobile?t(`${formik.errors.clientMobile}`):null}</span>

</div></div>
<div className='col-1 removeCol'>
   </div>
<div className='col-sm-5 col-6'>

<div className="inputDiv">
<label>  {t("Login.companyActiv")}</label>

<select defaultValue={parseInt(formik.values.companyActiv)}  value={formik.values.companyActiv} id="companyActiv" name="companyActiv"  ref={companyActivRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.companyActiv && formik.touched.companyActiv) || validation.activityId.err==true ?"invalid":formik.values.companyActiv==""?null:"chosenSelect"}>
<option selected disabled value="">{t("Login.companyActivChoose")}</option>

{activities && activities.map((data,index)=>


<option key={index}  value={data.id}  >{i18n.language=="ar"?data.name_ar:data.name_en} </option>

  
  )
}

</select>
<span>{formik.errors.companyActiv && formik.touched.companyActiv?t(`${formik.errors.companyActiv}`):null}</span>
<span>{validation.activityId.err=true? validation.activityId.text:null}</span>

</div></div>
<div className='col-1 removeCol'>
   </div>
<div className='col-sm-5 col-6'>

<div className="inputDiv">
<label>  {t("Login.city")}</label>
 


<select  defaultValue={parseInt(formik.values.city)} value={formik.values.city} id="city" name="city"  ref={cityRef} required={true} onChange={  (e)=>{ handleChangeCity(e.target.value); formik.handleChange(e)}} onBlur={formik.handleBlur}  className={(formik.errors.city && formik.touched.city) || validation.cityId.err==true?"invalid":formik.values.city==""?null:"chosenSelect"}>
<option selected disabled value="">{t("Login.cityChoose")}</option>
{city && city.map((data,index)=>


<option key={index}  value={data.id}  >{i18n.language=="ar"?data.arName:data.enName} </option>

  
  )
} 

</select>

<span>{formik.errors.city && formik.touched.city?t(`${formik.errors.city}`):null}</span>
<span>{validation.cityId.err=true? validation.cityId.text:null}</span>

</div></div>
<div className='col-1 removeCol'>
   </div>
<div className='col-sm-5 col-6'>

<div className="inputDiv externalList">
<label>  {t("advPayment.location")}</label><div  id="list1" className="dropdown-check-list" tabIndex="100">
  <div style={i18n.language=="ar"?{paddingLeft:"0.5em"}:{paddingRight:"0.5em"}} className='srchNote'>
  <span onClick={dropDown} className="anchor">{ t("profile.choose")}  
  
  </span>


  </div>
 
  <ul className="items"> 
  <li style={{position:"relative"}}>

</li>

{branchArr && branchArr.map((data,index)=>

<li  key={index}  >
<input type="checkbox" className='checkBranches'   value={data.id} onChange={(e)=>handleBranches(e)}/>
        <span> {i18n.language=="ar"?data.arName:data.enName}</span> 
     </li>


  
  )
} 
{branchArr.length==0?
<p className='noBranches'>{ t("advPayment.noBranches")}</p>:null}

        </ul>
</div>

<span>{validation.branches.err=true? validation.branches.text:null}</span>
  <span>{formik.errors.location && formik.touched.location?t(`${formik.errors.location}`):null}</span>
  
</div></div>


{i18n.language=="ar"?
<span>{errorMsgModel? msgAr:null}</span>:
<span>{errorMsgModel? msgEn:null}</span>}
<button type="submit" style={{margin:"auto",marginTop:"2em"}} className="dataEdit"> {t("advPayment.submit")}</button>

</form>
</div>
</div>

</div>
</div>
</div>
<div style={{height:"6em"}}>

</div>
</div>
    );
}

export default AdvPaymentReg;