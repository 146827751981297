  
import '../Styles/ExternalUser.css';

import { useState,useRef,useEffect } from "react";
import { useTranslation } from 'react-i18next';

import * as yup from "yup"
import {useFormik} from "formik";
import { changeLimit,countries,updatePrepayment,changeExternalCategories,branches,externalUser,companyActivities,singleExternalCategories,externalCategories, getExternalProducts, getProducts, updateExternalProducts} from '../api';
import { RiArrowRightSFill,RiArrowLeftSFill } from 'react-icons/ri';
import Documents from './ExternalProfile/Documents';
import { BeatLoader } from "react-spinners";
import { Link } from 'react-router-dom';
import RegisterStep1 from './ExternalProfile/RegisterTsep1';
import RegisterStep2 from './ExternalProfile/RegisterStep2';
import RegisterStep3 from './ExternalProfile/RegisterStep3';
import BasicData from './ExternalProfile/BasicData';
import Select from 'react-select';

function ExternalUser(props) {

  const token= localStorage.getItem("token");
 const [activities,setActivities]=useState([]);
const [facilityName,setFacilityName]=useState("")
const [update,setUpdate]=useState(false)
const [chosenBranches,setChosenBranches]=useState([]);
const [initialChosenBranches,setInitialChosenBranches]=useState([]);
const [editForm,setEditForm]=useState(false);
const [chosenBranchesNames,setChosenBranchesNames]=useState({});
const [initNames,setInitNames]=useState({});
const[products,setProducts]=useState([])
const[chosenProducts,setChosenProducts]=useState([])
const [productNames,setProductNames]=useState([])
const [defProducts,setDefProducts]=useState([])
const [initialProducts,setInitialProducts]=useState([])
const [disabled,setDisabled]=useState(true)

const [categories,setCategories]=useState([])
const [chosenCategs,setChosenCategs]=useState([])
const [defcategories,setDefCategories]=useState([])

const [initialCategs,setInitialCategs]=useState([])
const [categNames,setCategNames]=useState([])

const [limit, setLimit]=useState(0);

    const { t, i18n } = useTranslation();
    const [city,setCity]=useState([]);
    const [data,setData]=useState();
    const [msgAr, setMsgAr]=useState();
const [msgEn, setMsgEn]=useState();
const [errorMsgModel, setErrorMsgModel]=useState(false);
    const[basicData,setBasicData]=useState(
      
      {
        client:'',
        clientMobile: '',
        location: '',
        companyActiv:'',
        city: '',
     
      }
    );
    const [validation,setValidation]=useState(
      {
        facilityName: {
          err:false,
          text:""
        },
        activityId: {
          err:false,
          text:""
        }, cityId: {
          err:false,
          text:""
        }, branches: {
          err:false,
          text:""
        }
      
      })
    const [branchArr,setBranchArr]=useState([]);
   const[loading,setLoading]=useState(true);
   const [active,setActive]=useState(0)
   const [type,setType]=useState(0)

 

   const Schema= yup.object().shape({
    companyActiv: yup.string().required(("advPayment.required_companyActiv")),
    city: yup.string().required(("Login.required_city")),

    })
    
//companyActivities api gets company activities
//countries api gets all countries then we store the cities of saudia arabia (index 0
//external user api takes external user id and gets their data
//basic data is set depending on external user type 
//useEffect is rerendered on update (on update basic data or second tab for prepayment users) 
    useEffect(() => {

singleExternalCategories(token,localStorage.getItem("externalId")).then(response => {

       
  let categsArr = [];
  let namesEn = [];
  let namesAr = [];
  let defaultArr = [];
  let idsArr = [];

  for (let i = 0; i < response.data.data.length; i++) {  
    categsArr.push(response.data.data[i].id);
    namesEn.push(response.data.data[i].enName)
    namesAr.push(response.data.data[i].arName)
    let obj = {};
    obj.label =  i18n.language=="ar"?response.data.data[i].arName:response.data.data[i].enName ;
    obj.value = response.data.data[i].id;   
  
    defaultArr.push(obj);
    idsArr.push(response.data.data[i].id);

    }

    setChosenCategs(categsArr)
    setInitialCategs(categsArr)
    setDefCategories(defaultArr)
    setCategNames({
      namesEn:namesEn,
      namesAr:namesAr
    })
    getProductsByCategory(idsArr)


       })

     
       getExternalProducts(token,localStorage.getItem("externalId")).then(response => {
      
        let productsArr = [];
        let prodNamesEn = [];
        let prodNamesAr = [];
        let defaultProdArr = [];
 
        for (let i = 0; i < response.data.data.length; i++) {  
          productsArr.push(response.data.data[i].id);
          prodNamesEn.push(response.data.data[i].enName)
          prodNamesAr.push(response.data.data[i].arName)
          let obj = {};
          obj.label =  i18n.language=="ar"?response.data.data[i].arName:response.data.data[i].enName ;
          obj.value = response.data.data[i].id;   
        
          defaultProdArr.push(obj);
          }

        setChosenProducts(defaultProdArr)
        setInitialProducts(productsArr)
        setDefProducts(defaultProdArr)
        setProductNames({
          namesEn:prodNamesEn,
          namesAr:prodNamesAr  })

          })
      


       externalCategories(token).then(response => {
        setDisabled(true)
        let items = [];  
        let itemsIds = [];  
        for (let i = 0; i < response.data.data.length; i++) {  
            let obj = {};
            obj.label =  i18n.language=="ar"?response.data.data[i].arName:response.data.data[i].enName ;
            obj.value = response.data.data[i].id;   
          
            items.push(obj);  
            itemsIds.push(response.data.data[i].id) 
             //creating  options dynamically based on what props are currently passed to the parent component
        }
        setCategories(items)

      

             
             })
           


      companyActivities(token).then(response => {

        setActivities(response.data)
             
             })
      countries(token).then((response) => {
        setCity(response.data.data[0].cities)
   

       }) 

       externalUser(token,localStorage.getItem("externalId")).then(response => {
        setLimit(response.data.data.limit)
        localStorage.setItem("externalId",response.data.data.id)
        localStorage.removeItem("fieldsArr")
        branches(token, response.data.data.city.id).then(response => {
    
    
          setBranchArr(response.data.data)
               
               })
   
        setData(response.data.data)
        setType(response.data.data.externalSaleType=='postpaid'?0:1)
        setFacilityName(response.data.data.facilityName)
          if(response.data.data.externalSaleType=="prepayment"  ){
          setBasicData({
            client: response.data.data.user.name,
            clientMobile:  response.data.data.user.phone,
            location:response.data.data.branches.length>0?response.data.data.branches[0].id:null,
            companyActiv: response.data.data.clientActivity.id,
            city: response.data.data.city.id,
         
          })
          let brnchArr = [];
          let namesEn = [];
          let namesAr = [];
          for (let i = 0; i < response.data.data.branches.length; i++) {  
            brnchArr.push(response.data.data.branches[i].id);
            namesEn.push(response.data.data.branches[i].enName)
            namesAr.push(response.data.data.branches[i].arName)
            }
            setChosenBranches(brnchArr)
            setInitialChosenBranches(brnchArr)
            setChosenBranchesNames({
              namesEn:namesEn,
              namesAr:namesAr
            })
            setInitNames({
              namesEn:namesEn,
              namesAr:namesAr
            })
        }
          else{
            if(response.data.data.status=="pending"){
              setBasicData({
                client: response.data.data.user.name,
                clientMobile:  response.data.data.user.phone,
                location:null,
                companyActiv:null,
                city: response.data.data.city.id,
             
              })
            }
            else{
              setBasicData({
                client: response.data.data.user.name,
                clientMobile:  response.data.data.user.phone,
                location:response.data.data.branchNo,
                companyActiv:response.data.data.facilityActivity.id,
                city: response.data.data.city.id,
             
              })
            }
           
          }
          setLoading(false)

             
             })
 
              },[update]);



    const formik = useFormik({
      initialValues:{
    
        companyActiv:basicData.companyActiv,
        city: basicData.city,
     
      },
    validationSchema: Schema,
    enableReinitialize: true,
    onSubmit
    
    });
    const cityRef=useRef();
    const limitRef=useRef();
    const locationRef=useRef();
    const companyActivRef=useRef();
 

   function handleChangeCity(id){
    setChosenBranches([])
    setChosenBranchesNames({
      namesEn:[],
      namesAr:[]
    })
    branches(token,id).then(response => {
   
   
      setBranchArr(response.data.data)
           
           })
   }
    
   function limitSubmit(e){
     e.preventDefault()
     setLoading(true);

     if(limitRef.current.value==""){
      setMsgAr("حدث خطأ   ");
      setMsgEn("Please enter limit");
    setErrorMsgModel(true);
                                     
   setLoading(false);
     }
     else{  const formData = new FormData()
     formData.append('limit',limitRef.current.value)
     changeLimit(localStorage.getItem("token"),localStorage.getItem("externalId"),formData).then((response) => {
   setLimit(response.data.data.limit)
   setErrorMsgModel(true);
                            
   setMsgAr(" تم تحديث الحد بنجاح");
   setMsgEn("Limit updated successfully");
      setLoading(false);

    }).catch(function (error) {
 
                                 
      setMsgAr("حدث خطأ");
      setMsgEn("Something went wrong");
    setErrorMsgModel(true);
                                     
   setLoading(false);
                                   
  
        })
  
  
  }
   }

   function onSubmit(){
    const token=localStorage.getItem("token")
    if(chosenBranches.length==0){
      setMsgAr("برجاء اختيار الفروع")
      setMsgEn("Please choose from branches list")
      setErrorMsgModel(true)
    }
     else{
   setLoading(true);
   const values={
    facilityName:facilityName,
     activityId:parseInt(companyActivRef.current.value),
     cityId:parseInt(cityRef.current.value),
     branches: chosenBranches
   }
   updatePrepayment(token, data.id,values,i18n.language).then((response) => {
     setUpdate(!update); 
     setEditForm(false);

}).catch(function (error) {
  let errorArr=error.response.data.errors;

  Object.keys(errorArr).forEach((key) => {
 
   if (validation.hasOwnProperty(key)) {
     setValidation((prevState) => ({
       ...prevState,
       [key]: {
         err: true,
         text: errorArr[key][0],
       },
     }));
   }
 });
 setLoading(false)

})
  }
   }



  var checkList;

  function dropDown(){


    checkList = document.getElementById('list1');
    if (checkList.classList.contains('visible')){
    checkList.classList.remove('visible');}
    else{
      checkList.classList.add('visible');}
   

      var elements = document.getElementsByClassName('checkBranches');
    
    
      for(var i=0; i<elements.length; i++) { 
        for(var j=0; j<chosenBranches.length; j++) { 
        if(elements[i].value == chosenBranches[j].toString()){
       
          elements[i].checked=true;
      }}
      }
  
}


  function handleBranches(e){
    var checkArr=[]
    var check = document.getElementsByClassName('checkBranches');
  
    for(var i=0; i<check.length; i++) { 
      if(check[i].checked){
     
        checkArr.push(parseInt(check[i].value))
    
    }
    }
   
    setChosenBranches(checkArr)
  
  }
  function handleReset(){ 
    
    formik.resetForm();
    checkList = document.getElementById('list1');
    checkList.classList.remove('visible');
    branches(token,basicData.city).then(response => {
   
   
      setBranchArr(response.data.data)
      setChosenBranches(initialChosenBranches)
      setChosenBranchesNames(initNames)
      setEditForm(false);

           })
  }
function handleSubmitCategories(e){
  const token=localStorage.getItem("token")

  e.preventDefault();
  let arr = [];
  for (let i = 0; i < chosenProducts.length; i++) {  
     arr.push(chosenProducts[i].value);}
 
 
  if(chosenCategs.length==0){
    setMsgAr("من فضلك اختر  تصنيفات للمستخدم")
    setMsgEn("Please choose categories for user")
    setErrorMsgModel(true)
    setLoading(false)

  }
  else if(arr.length==0){
    setMsgAr("من فضلك اختر  منتجات للمستخدم")
    setMsgEn("Please choose products for user")
    setErrorMsgModel(true)
    setLoading(false)

  }
 else{
  setLoading(true);

  let categories={
    categories:chosenCategs
  }
  let body={
products:arr
  }
  changeExternalCategories(token,data.id,categories,i18n.language).then((response) => {
    updateExternalProducts(token,data.id,body).then((response) => {

    setUpdate(!update); 
     setEditForm(false);})
  }
  ).catch((error)=>{

    setMsgAr(error.response.data.errors.categories);
    setMsgEn(error.response.data.errors.categories);
    setErrorMsgModel(true)
    setLoading(false)



}
);
}
}
  function handleReset2(){ 
    setChosenProducts(defProducts)

    setChosenCategs(initialCategs)
      setEditForm(false);


  }

  function handleCategs(e){
    setDisabled(true)

    let arr = [];
    for (let i = 0; i < e.length; i++) {  
      arr.push(e[i].value);
    }
      setChosenCategs(arr)
      getProductsByCategory(arr)

  }

  function handleProducts(e){
 
      setChosenProducts(e)

  }


  function getProductsByCategory(arr){

    const token= localStorage.getItem("token");
  
    getProducts(token,`[${arr}]`).then(response => {
      
      let items = [];  
           
      for (let i = 0; i < response.data.data.length; i++) {  
          let obj = {};
          obj.label =  i18n.language=="ar"?response.data.data[i].arName:response.data.data[i].enName ;
          obj.value = response.data.data[i].id;   
        
          items.push(obj);   
           //creating  options dynamically based on what props are currently passed to the parent component
      }
      setProducts(items)
      setChosenProducts(prev => prev.filter(product => items.some(item => item.value == product.value))); 
      setDisabled(false)
    })}

    return (
      <div className='external-container'> 
        

      <div className='container'>
      <div className='navigateOrder' style={{marginTop:"2em"}}>

<Link to='/external-sales' >{t("externalSales.externalSales")} </Link>
{i18n.language=="ar"?
<RiArrowLeftSFill/>: <RiArrowRightSFill/>}
<a className='userData'>{t("externalSales.userData")}</a>

  </div>
      {loading?
      <div className='loader profileLoader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
      
    <>
    {type==0?
<div style={{marginTop:"10px"}} className="aboutusBody profileBody container-fluid fluid">
   <div className='contReg'>
   <p  className="upTable-title"></p>
   { data.externalSaleType==null || data.externalSaleType=="" || ( data.externalSaleType=="prepayment" && data.lastCompletedStep=="completed") || ( data.externalSaleType=="postpaid" && data.lastCompletedStep=="completed")? null:
   <Link to={data.externalSaleType=="prepayment"?"/adv-payment":data.lastCompletedStep=="second"?"/register-3":data.lastCompletedStep=="first"?"/register-2":"/register-1"}>
  <button>{t("externalSales.continue")}</button></Link>
  
  }</div>

    <div className="userDataExt" style={{padding:"2em 1em"}}>
 <div className="usrTabs">
   <p onClick={()=>setActive(0)} className={active==0?"activeTab":null}> {t("externalSales.userData")}</p>
   {  data.externalSaleType===null || ( data.externalSaleType=="prepayment" && data.lastCompletedStep!=="completed") || ( data.externalSaleType=="postpaid" && data.lastCompletedStep!=="completed")?null:
<>
     <p onClick={()=>setActive(1)} className={active==1?"activeTab":null}> {t("register.step1")}</p>
     <p onClick={()=>setActive(2)} className={active==2?"activeTab":null}> {t("register2.step2")}</p>
     <p onClick={()=>setActive(3)} className={active==3?"activeTab":null}> {t("register3.step3")}</p>
     <p onClick={()=>{setActive(6)}} className={active==6?"activeTab":null}> {t("menu.categories")}</p>
     <p onClick={()=>{setActive(4); setErrorMsgModel(false)}} className={active==4?"activeTab":null}> {t("externalSales.limit")}</p>
     <p onClick={()=>{setActive(5)}} className={active==5?"activeTab":null}> {t("docs.docs")}</p>

     </>   }     </div>
{active==0?
  <BasicData setLoading={setLoading} setUpdate={setUpdate} update={update} data={data}/>:null}


{active==1?
<RegisterStep1 data={data} setUpdate={setUpdate} update={update}/>

:null}






{active==2?
<RegisterStep2 setUpdate={setUpdate} update={update} data={data}/>:null}



{active==3?
  <RegisterStep3 setUpdate={setUpdate} update={update} data={data}/>
:null}
{active==5?
  <Documents setUpdate={setUpdate} update={update} data={data}/>
:null}
{active==4?
   <div className='container-fluid'>
   <form className="row" onSubmit={(e)=>limitSubmit(e)}>
    <p className='limitP'> {t("externalSales.current")} {limit}</p>
  <div className=" col-sm-5 col-6">
  <div className="inputDiv">
    <label> {t("externalSales.limit")}</label>
    <input type="number" placeholder= {t("externalSales.enter")} ref={limitRef}/>
    </div></div>
    {i18n.language=="ar"?
<span style={msgEn=="Something went wrong"?null:{color:"#4BB543"}}>{errorMsgModel? msgAr:null}</span>:
<span style={msgEn=="Something went wrong"?null:{color:"#4BB543"}}>{errorMsgModel? msgEn:null}</span>}
  <div style={{marginTop:"2em"}} className="formBtns">
  <button type="submit" className="dataEdit">{t("profile.save")}</button>
  <span> </span>
  <button type="button" className="cancelEdit"onClick={()=>{limitRef.current.value="" }} >  {t("profile.cancel")}</button></div>
    </form></div>
:null}
{active==6?

<div className='container-fluid'>
  
<form className={editForm?"row":"row disabledForm"} onSubmit={(e)=>handleSubmitCategories(e)}>
 


<div className=" col-12">
<div className="inputDiv externalList">
<label>  {t("advPayment.category")}</label>
{editForm?
 <Select required={true}
 isMulti={true}
                              onChange={(e)=>   handleCategs(e)
                          }    
                          defaultValue={defcategories}  
                          placeholder={i18n.language=="ar"?"اختر...":"Select..."}
                                                options={categories}/>:
 categNames.namesAr.length==0?


 <p className='noBranches'>{ t("externalSales.noCategs")}</p>:

<p className="selectedBranches">{i18n.language=="ar"?categNames.namesAr.join(', '):categNames.namesEn.join(', ')}</p>
}


</div></div>

<div className=" col-12">
<div className="inputDiv externalList">
<label>  {t("advPayment.products")}</label>
{editForm?
 <Select required={true}
 isMulti={true}
                              onChange={(e)=>   handleProducts(e)
                          }    
                          value={chosenProducts}
                          isDisabled={disabled}
                          defaultValue={defProducts}  
                          placeholder={i18n.language=="ar"?"اختر...":"Select..."}
                                                options={products}/>:
 productNames.namesAr.length==0?


 <p className='noBranches'>{ t("externalSales.noProd")}</p>:

<p className="selectedBranches">{i18n.language=="ar"?productNames.namesAr.join(', '):productNames.namesEn.join(', ')}</p>
}


</div></div>

{i18n.language=="ar"?
<span>{errorMsgModel? msgAr:null}</span>:
<span>{errorMsgModel? msgEn:null}</span>}
{editForm?
  <div style={{marginTop:"2em"}} className="formBtns">


  <button type="submit" className="dataEdit">{t("profile.save")}</button>
  <span> </span>
  <button type="button" className="cancelEdit" onClick={()=>{setEditForm(false); handleReset2()}}>  {t("profile.cancel")}</button></div>

:
<div className="formBtns" style={{marginTop:"1em"}}>

<button type="button" className="dataEdit"  onClick={(e)=> {e.preventDefault(); setEditForm(true); }}>{t("profile.edit")}</button>   
</div>
}
  

</form></div>

:null}

</div>
   </div>:
    
<div className="aboutusBody profileBody container-fluid fluid">
   <div className='contReg'>
    
    <p  className="upTable-title">  {t("externalSales.userData")}</p>
    {  data.externalSaleType=="" || data.externalSaleType==null || (  data.externalSaleType=="prepayment" && data.lastCompletedStep=="completed") || (  data.externalSaleType=="postpaid" && data.lastCompletedStep=="completed")? null:
      <Link to={ data.externalSaleType=="prepayment"?"/adv-payment":data.lastCompletedStep=="second"?"/register-3":data.lastCompletedStep=="first"?"/register-2":"/register-1"}>  
      
      <button>{t("externalSales.continue")}</button></Link>
  }

</div>

    <div className="userDataExt" style={{padding:"2em 1em"}}>
 <div className="usrTabs" style={{justifyContent:"start"}}>
   <p onClick={()=>setActive(0)} className={active==0?"activeTab":null}> {t("externalSales.userData")}</p>
   { data.lastCompletedStep!=="completed"?null:
   <>
        <p onClick={()=>setActive(1)} style={{margin:"0 1em"}} className={active==1?"activeTab":null}> {t("register.step1")}</p>
        <p onClick={()=>{setActive(6)}} className={active==6?"activeTab":null}> {t("menu.categories")}</p>
     <p onClick={()=>{setActive(5)}} className={active==5?"activeTab":null}> {t("docs.docs")}</p>
   </>

  }
   </div>
{active==0?  <BasicData setUpdate={setUpdate} update={update} setLoading={setLoading} data={data}/>:null}

{active==5?
  <Documents setUpdate={setUpdate} update={update} data={data}/>
:null}

{active==6?

<div className='container-fluid'>
  
<form className={editForm?"row":"row disabledForm"} onSubmit={(e)=>handleSubmitCategories(e)}>
 


<div className=" col-12">
<div className="inputDiv externalList">
<label>  {t("advPayment.category")}</label>
{editForm?
 <Select required={true}
 isMulti={true}
                              onChange={(e)=>   handleCategs(e)
                          }    
                          defaultValue={defcategories}  
                          placeholder={i18n.language=="ar"?"اختر...":"Select..."}
                                                options={categories}/>:
 categNames.namesAr.length==0?


 <p className='noBranches'>{ t("externalSales.noCategs")}</p>:

<p className="selectedBranches">{i18n.language=="ar"?categNames.namesAr.join(', '):categNames.namesEn.join(', ')}</p>
}


</div></div>

<div className=" col-12">
<div className="inputDiv externalList">
<label>  {t("advPayment.products")}</label>
{editForm?
 <Select required={true}
 isMulti={true}
                              onChange={(e)=>   handleProducts(e)
                          }    
                          isDisabled={disabled}

                          value={chosenProducts}
                          defaultValue={defProducts}  
                          placeholder={i18n.language=="ar"?"اختر...":"Select..."}
                                                options={products}/>:
 productNames.namesAr.length==0?


 <p className='noBranches'>{ t("externalSales.noProd")}</p>:

<p className="selectedBranches">{i18n.language=="ar"?productNames.namesAr.join(', '):productNames.namesEn.join(', ')}</p>
}


</div></div>


{i18n.language=="ar"?
<span>{errorMsgModel? msgAr:null}</span>:
<span>{errorMsgModel? msgEn:null}</span>}
{editForm?
  <div style={{marginTop:"2em"}} className="formBtns">


  <button type="submit" className="dataEdit">{t("profile.save")}</button>
  <span> </span>
  <button type="button" className="cancelEdit" onClick={()=>{setEditForm(false); handleReset2()}}>  {t("profile.cancel")}</button></div>

:
<div className="formBtns" style={{marginTop:"1em"}}>

<button type="button" className="dataEdit"  onClick={(e)=> {e.preventDefault(); setEditForm(true); }}>{t("profile.edit")}</button>   
</div>
}
  

</form></div>

:null}
{active==1?
  <div className='container-fluid'>
   <form className={editForm?"row":"row disabledForm"} onSubmit={formik.handleSubmit}>
    
  <div className=" col-sm-5 col-6">
  <div className="inputDiv">
  <label>  {t("Login.companyActiv")}</label>
  
  <select defaultValue={formik.values.companyActiv} value={formik.values.companyActiv} id="companyActiv" name="companyActiv"  ref={companyActivRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.companyActiv && formik.touched.companyActiv) ||  validation.activityId.err==true?"invalid chosenSelect":"chosenSelect"}>
  <option selected disabled value="">{t("Login.companyActivChoose")}</option>
  {activities && activities.map((data,index)=>


<option key={index}  value={data.id}  >{i18n.language=="ar"?data.name_ar:data.name_en} </option>

  
  )
}
  
  </select>
  <span>{formik.errors.companyActiv && formik.touched.companyActiv?t(`${formik.errors.companyActiv}`):null}</span>
  <span>{validation.activityId.err=true? validation.activityId.text:null}</span>

  
  </div>
  </div>
  <div className=" col-sm-5 col-6">
  <div className="inputDiv ">
  <label>  {t("Login.city")}</label>
   
  
  
  <select defaultValue={formik.values.city} value={formik.values.city} id="city" name="city"  ref={cityRef} required={true} onChange={  (e)=>{ handleChangeCity(e.target.value); formik.handleChange(e)}} onBlur={formik.handleBlur}  className={(formik.errors.city && formik.touched.city) || validation.cityId.err==true?"invalid chosenSelect":"chosenSelect"}>
  <option selected disabled value="">{t("Login.cityChoose")}</option>
  {city && city.map((data,index)=>
  
  
  <option key={index}  value={data.id}  >{i18n.language=="ar"?data.arName:data.enName} </option>
  
    
    )
  } 
  
  </select>
  
  <span>{formik.errors.city && formik.touched.city?t(`${formik.errors.city}`):null}</span>
  <span>{validation.cityId.err=true? validation.cityId.text:null}</span>

  
  </div></div><div className=" col-1 removeCol">
  </div>
  <div className=" col-sm-5 col-6">
  
  <div className="inputDiv externalList ">


  <label>  {t("advPayment.location")}</label>
 {editForm?
<div  id="list1" className="dropdown-check-list" tabIndex="100">
  <div style={i18n.language=="ar"?{paddingLeft:"0.5em"}:{paddingRight:"0.5em"}} className='srchNote'>
  <span onClick={dropDown} className="anchor">{ t("profile.choose")}  
  
  </span>


  </div>
 
  <ul className="items"> 
  <li style={{position:"relative"}}>

</li>

{branchArr && branchArr.map((data,index)=>

<li  key={index}  >
<input type="checkbox" className='checkBranches'   value={data.id} onChange={(e)=>handleBranches(e)}/>
        <span> {i18n.language=="ar"?data.arName:data.enName}</span> 
     </li>


  
  )
} 
{branchArr.length==0?
<p className='noBranches'>{ t("advPayment.noBranches")}</p>:null}
        </ul>
</div>:
<p className="selectedBranches">{i18n.language=="ar"?chosenBranchesNames.namesAr.join(', '):chosenBranchesNames.namesEn.join(', ')}</p>
}



  
  <span>{formik.errors.location && formik.touched.location?t(`${formik.errors.location}`):null}</span>
  <span>{validation.branches.err=true? validation.branches.text:null}</span>

  </div></div>

  
  <div> 
  </div>
  {i18n.language=="ar"?
<span>{errorMsgModel? msgAr:null}</span>:
<span>{errorMsgModel? msgEn:null}</span>}
{editForm?
  <div style={{marginTop:"2em"}} className="formBtns">


  <button type="submit" className="dataEdit">{t("profile.save")}</button>
  <span> </span>
  <button type="button" className="cancelEdit" onClick={()=>{setEditForm(false); handleReset()}}>  {t("profile.cancel")}</button></div>

:
<div className="formBtns" style={{marginTop:"1em"}}>

<button type="button" className="dataEdit"  onClick={(e)=> {e.preventDefault(); setEditForm(true)}}>{t("profile.edit")}</button>   
</div>
}
  
  
  </form> </div>:null}

  






</div>
   </div>
   }
   </>
} 

</div>
         </div>

 
    );
  }
  
  export default ExternalUser;