import "../Styles/CustomerOrders.css";

import Navbar from "../Components/Navbar";
import { useTranslation } from "react-i18next";
import { FiEdit } from "react-icons/fi";
import { BsGlobe2, BsInfoCircle } from "react-icons/bs";
import { AiOutlinePlusCircle } from "react-icons/ai";
import CustomerData from "../Components/CustomerData";
import bckg from "../Images/order.svg";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { customer, customerOrders, filters } from "../api";
import { BeatLoader } from "react-spinners";
import { useParams, generatePath } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import icn1 from "../Images/customerProfile.svg";
import icn2 from "../Images/orderDetailsIconInactive.svg";
import icn3 from "../Images/menuIconUnactive.svg";
import icn4 from "../Images/basketIconUnactive.svg";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import PaginationItem from "@mui/material/PaginationItem";
import { GrPowerReset } from "react-icons/gr";

function CustomerOrders(props) {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [resp, setResp] = useState();
  const [filterData, setFilterData] = useState();
  const [tableLoading, setTableLoading] = useState(false);

  const [orders, setOrders] = useState([]);
  const customerId = localStorage.getItem("customerId");
  let history = useNavigate();

  var today = new Date();
  var today2 = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  today = dd + "-" + mm + "-" + yyyy.toString().slice(2, 5);

  today2 = yyyy + "-" + mm + "-" + dd;

  let timeFromRef = useRef();
  let timeToRef = useRef();
  let statusRef = useRef();
  let branchRef = useRef();
  let typeRef = useRef();
  let paymentRef = useRef();
  let platformRef = useRef();
  let orderIdRef = useRef();
  let transactionIdRef = useRef();
  let codeRef = useRef();
  let agentRef = useRef();
  let sourceRef = useRef();
  let syncRef = useRef();
  let externalOrderIdRef = useRef();

  //customerOrders=> takes customer id, user token and page (initially = 1) and returns customer orders
  //filters=> returns the data used in filteration
  //resp state is used to store other used data like last_page
  useEffect(() => {
    const token = localStorage.getItem("token");
    window.scrollTo(0, 0);
    setLoading(true);

    customerOrders(token, customerId, page)
      .then((response) => {
        // setOrders(response.data.data)
        filters(token).then((response) => {
          setFilterData(response.data.data);
          props.setErr(false);

          setLoading(false);
        });
        setResp(response.data.data);

        if (response.data.data.toString() == []) {
          //otherwise causes an error
          setOrders([]);
        } else {
          const result = Object.values(response.data.data.data);

          let ordersArr = [];

          for (let i = 0; i < result.length; i++) {
            let obj = {};

            obj.proceed = (
              <button onClick={() => handleProceed(result[i].id)}>
                {t("profile.show-details")}
              </button>
            );
            obj.id = result[i].id;
            obj.idSrch = result[i].id;
            obj.createdAt = result[i].createdAt;
            obj.typeArName =
              i18n.language == "ar"
                ? result[i].typeArName
                : result[i].typeEnName;
            obj.totalPrice = result[i].totalPrice + " " + t("profile.sar");

            obj.platform = result[i].platform;
            obj.status =
              result[i].statusId == 1
                ? t("singleOrder.order_accepted")
                : result[i].statusId == 2
                ? result[i].type.id == 2
                  ? t("singleOrder.order_InProgress")
                  : t("singleOrder.order_InProgressBranch")
                : result[i].statusId == 3
                ? t("singleOrder.order_with_driver")
                : result[i].statusId == 6
                ? t("singleOrder.waiting")
                : result[i].type.id == 2
                ? t("singleOrder.done_order")
                : t("singleOrder.done_orderBranch");
            obj.source = result[i].source;
            obj.creatorName = result[i].creatorName;

            obj.branchArName =
              i18n.language == "ar"
                ? result[i].branchArName
                : result[i].branchEnName;
            obj.paymentMethod =
              result[i].paymentMethod === "tap"
                ? result[i].paymentMethod === "tap" && result[i].statusId !== 6
                  ? t("singleOrder.paid")
                  : t("singleOrder.unpaid")
                : result[i].paymentMethod === "wallet" &&
                  result[i].remainPaymentMethod !== null
                ? result[i].paymentMethod +
                  " / " +
                  result[i].remainPaymentMethod
                : result[i].paymentMethod;
            console.log(obj.typeArName);
            ordersArr.push(obj);
          }

          setOrders(ordersArr);
        }
      })
      .catch(function (error) {
        console.log(error.response.status);
        if (error.response.status === 401) {
          history("/");
        } else if (error.response.status === 403) {
          props.setErr(true);
          props.setMsg(t("validation.noPermission"));
          props.setStyle({ top: "50%" });
        }
        setTimeout(function () {
          props.setErr(false);

          history(-1);
        }, 2500);
      });
  }, []);

  const data = {
    columns: [
      {
        label: t("profile.orderId"),
        field: "id",
        sort: "asc",
      },
      {
        label: t("profile.date"),
        field: "createdAt",
        sort: "asc",
      },
      {
        label: t("profile.type"),
        field: "typeArName",
        sort: "asc",
      },

      {
        label: t("profile.order-total"),
        field: "totalPrice",
        sort: "asc",
      },
      {
        label: t("profile.branch"),
        field: "branchArName",
        sort: "asc",
      },
      {
        label: t("profile.payment"),
        field: "paymentMethod",
        sort: "asc",
      },
      {
        label: t("orders.platform"),
        field: "platform",
        sort: "asc",
      },
      {
        label: t("orders.source"),
        field: "source",
        sort: "asc",
      },
      {
        label: t("addOrder.status"),
        field: "status",
        sort: "asc",
      },

      {
        label: " ",
        field: "proceed",
        sort: "asc",
      },
    ],
    rows: orders,
  };

  // takes order id and redirects to that specific order
  const handleProceed = (id) => {
    history(generatePath("/my-order/:id", { id }));
  };

  //on page change or on filtering
  //on page change => changes page
  //on filtering => set page to page number 1 and sends filtering data
  //customerOrders api call takes token,customer ID and params(filtering data and page number)
  function filter(page) {
    const token = localStorage.getItem("token");
    window.scrollTo(0, 0);
    setTableLoading(true);
    setPage(page);
    let params = {
      orderId: orderIdRef.current.value,
      transactionId: transactionIdRef.current.value,
      externalOrderId: externalOrderIdRef.current.value,
      statusId: statusRef.current.value,
      codeId: codeRef.current.value,
      agentId: agentRef.current.value,
      branchId: branchRef.current.value,
      sourceCode: sourceRef.current.value,
      typeId: typeRef.current.value,
      paymentMethod: paymentRef.current.value,
      sync: syncRef.current.value,
      platform: platformRef.current.value,

      dateFrom: timeFromRef.current.value,
      dateTo: timeToRef.current.value,

      page: page,
    };
    customerOrders(token, customerId, params)
      .then((response) => {
        setResp(response.data.data);
        if (response.data.data.toString() == []) {
          setOrders([]);
          setTableLoading(false);
        } else {
          const result = Object.values(response.data.data.data);
          let ordersArr = [];

          for (let i = 0; i < result.length; i++) {
            let obj = {};

            obj.proceed = (
              <button onClick={() => handleProceed(result[i].id)}>
                {t("profile.show-details")}
              </button>
            );
            obj.id = result[i].id;
            obj.idSrch = result[i].id;
            obj.createdAt = result[i].createdAt;
            obj.typeArName =
              i18n.language == "ar"
                ? result[i].typeArName
                : result[i].typeEnName;
            obj.totalPrice = result[i].totalPrice + " " + t("profile.sar");
            obj.platform = result[i].platform;
            obj.status =
              result[i].statusId == 1
                ? t("singleOrder.order_accepted")
                : result[i].statusId == 2
                ? result[i].type.id == 2
                  ? t("singleOrder.order_InProgress")
                  : t("singleOrder.order_InProgressBranch")
                : result[i].statusId == 3
                ? t("singleOrder.order_with_driver")
                : result[i].statusId == 6
                ? t("singleOrder.waiting")
                : result[i].type.id == 2
                ? t("singleOrder.done_order")
                : t("singleOrder.done_orderBranch");
            obj.source = result[i].source;
            obj.creatorName = result[i].creatorName;

            obj.branchArName =
              i18n.language == "ar"
                ? result[i].branchArName
                : result[i].branchEnName;
            obj.paymentMethod =
              result[i].paymentMethod === "tap"
                ? result[i].paymentMethod === "tap" && result[i].statusId !== 6
                  ? t("singleOrder.paid")
                  : t("singleOrder.unpaid")
                : result[i].paymentMethod === "wallet" &&
                  result[i].remainPaymentMethod !== null
                ? result[i].paymentMethod +
                  " / " +
                  result[i].remainPaymentMethod
                : result[i].paymentMethod;
            ordersArr.push(obj);
          }
          setOrders(ordersArr);
          setTableLoading(false);
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          history("/");
        }
      });
  }

  //clears input fields
  //customerOrders api is called to get areas with no filtres
  //sets page to page number 1
  function resetData() {
    var selectElement = document.getElementsByClassName("orderSelect");

    for (var i = 0; i < selectElement.length; i++) {
      selectElement[i].selectedIndex = null;
    }

    timeFromRef.current.value = null;
    timeToRef.current.value = null;

    externalOrderIdRef.current.value = null;
    orderIdRef.current.value = null;
    transactionIdRef.current.value = null;

    const token = localStorage.getItem("token");
    window.scrollTo(0, 0);
    setTableLoading(true);
    setPage(1);
    let params = {
      page: 1,
    };
    customerOrders(token, customerId, params)
      .then((response) => {
        setResp(response.data.data);
        if (response.data.data.toString() == []) {
          setOrders([]);
          setTableLoading(false);
        } else {
          const result = Object.values(response.data.data.data);
          let ordersArr = [];

          for (let i = 0; i < result.length; i++) {
            let obj = {};
            obj.proceed = (
              <button onClick={() => handleProceed(result[i].id)}>
                {t("profile.show-details")}
              </button>
            );
            obj.id = result[i].id;
            obj.idSrch = result[i].id;
            obj.createdAt = result[i].createdAt;
            obj.typeArName =
              i18n.language == "ar"
                ? result[i].typeArName
                : result[i].typeEnName;
            obj.totalPrice = result[i].totalPrice + " " + t("profile.sar");
            obj.platform = result[i].platform;
            obj.status =
              result[i].statusId == 1
                ? t("singleOrder.order_accepted")
                : result[i].statusId == 2
                ? result[i].type.id == 2
                  ? t("singleOrder.order_InProgress")
                  : t("singleOrder.order_InProgressBranch")
                : result[i].statusId == 3
                ? t("singleOrder.order_with_driver")
                : result[i].statusId == 6
                ? t("singleOrder.waiting")
                : result[i].type.id == 2
                ? t("singleOrder.done_order")
                : t("singleOrder.done_orderBranch");
            obj.source = result[i].source;
            obj.creatorName = result[i].creatorName;

            obj.branchArName =
              i18n.language == "ar"
                ? result[i].branchArName
                : result[i].branchEnName;
            obj.paymentMethod =
              result[i].paymentMethod === "tap"
                ? result[i].paymentMethod === "tap" && result[i].statusId !== 6
                  ? t("singleOrder.paid")
                  : t("singleOrder.unpaid")
                : result[i].paymentMethod === "wallet" &&
                  result[i].remainPaymentMethod !== null
                ? result[i].paymentMethod +
                  " / " +
                  result[i].remainPaymentMethod
                : result[i].paymentMethod;
            ordersArr.push(obj);
          }
          setOrders(ordersArr);
          setTableLoading(false);
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          history("/");
        }
      });
  }

  return (
    <div>
      <div className="processNav">
        <Link to="/customer-orders">
          <div>
            <img style={{ cursor: "pointer" }} src={icn1} />

            <p>{t("addOrder.profile")}</p>
          </div>
        </Link>
        <div className="dottedLine"></div>
        <Link to="/add-order">
          <div>
            <img style={{ cursor: "pointer" }} src={icn2} />
            <p style={{ color: "white" }}>{t("addOrder.details")}</p>
          </div>
        </Link>
        <div className="dottedLine"></div>
        <div>
          <img src={icn3} />
          <p style={{ color: "white" }}>{t("addOrder.menu")}</p>
        </div>

        <div className="dottedLine"></div>
        <div>
          <img src={icn4} />
          <p style={{ color: "white" }}>{t("addOrder.send")}</p>
        </div>
      </div>
      {loading ? (
        <div style={{ height: "80vh" }} className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <div style={{ marginTop: "2em" }} className="container-fluid">
          <CustomerData loading={loading} active={0} />

          <div className="customerBody customerOrders">
            <div
              className="container-fluid filtering"
              style={{ marginBottom: "2em" }}
            >
              <div className="row ">
                <div className="col-12">
                  <div className="container-fluid">
                    <div
                      className="row gy-3"
                      style={{ display: "flex", alignItems: "flex-end" }}
                    >
                      <div className="col-12 filterTitile">
                        <p>{t("orders.filter")}</p>
                      </div>

                      <div className="col-md-2 col-sm-4 col-6">
                        <label>{t("orders.from")}</label>
                        <input type="date" ref={timeFromRef} id="dateFrom" />
                      </div>
                      <div className="col-md-2 col-sm-4 col-6">
                        <label>{t("orders.to")}</label>
                        <input type="date" ref={timeToRef} />
                      </div>
                      <div className="col-md-2 col-sm-4 col-6">
                        <label>{t("orders.externalOrder")}</label>

                        <input type="number" ref={externalOrderIdRef} />
                      </div>
                      <div className="col-md-2 col-sm-4 col-6">
                        <label>{t("orders.order_id")}</label>

                        <input type="number" ref={orderIdRef} />
                      </div>
                      <div className="col-md-2 col-sm-4 col-6">
                        <label>{t("orders.transactionId")}</label>

                        <input type="number" ref={transactionIdRef} />
                      </div>
                      <div className="col-md-2 col-sm-4 col-6"></div>
                      <div className="col-md-2 col-sm-4 col-6">
                        {/*
     <MultiSelect display="chip" optionLabel={i18n.language=="ar"?"arName":"enName"} optionValue="arName" value={cities} options={branches} onChange={(e) => setCities(e.value)} />
          */}

                        <select
                          defaultValue=""
                          ref={statusRef}
                          className="orderSelect"
                        >
                          <option value="" disabled>
                            {t("orders.statuses")}
                          </option>
                          <option value="">{t("areas.all")}</option>

                          {filterData.statuses &&
                            filterData.statuses.map((data, index) => (
                              <option value={data.id}>
                                {i18n.language == "ar"
                                  ? data.arName
                                  : data.enName}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-md-2 col-sm-4 col-6">
                        <select
                          defaultValue=""
                          ref={branchRef}
                          className="orderSelect"
                        >
                          <option value="" disabled>
                            {t("orders.branches")}
                          </option>
                          <option value="">{t("areas.all")}</option>

                          {filterData.branches &&
                            filterData.branches.map((data, index) => (
                              <option value={data.id}>
                                {i18n.language == "ar"
                                  ? data.arName
                                  : data.enName}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-md-2 col-sm-4 col-6">
                        <select
                          defaultValue=""
                          ref={typeRef}
                          className="orderSelect"
                        >
                          <option value="" disabled>
                            {t("addOrder.type")}
                          </option>
                          <option value="">{t("areas.all")}</option>

                          {filterData.types &&
                            filterData.types.map((data, index) => (
                              <option value={data.id}>
                                {i18n.language == "ar"
                                  ? data.arName
                                  : data.enName}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-md-2 col-sm-4 col-6">
                        <select
                          defaultValue=""
                          ref={paymentRef}
                          className="orderSelect"
                        >
                          <option value="" disabled>
                            {t("orders.paymentMethod")}
                          </option>
                          <option value="">{t("areas.all")}</option>

                          {filterData.payments &&
                            filterData.payments.map((data, index) => (
                              <option value={data}>{data}</option>
                            ))}
                        </select>
                      </div>

                      <div className="col-md-2 col-sm-4 col-6">
                        <select
                          defaultValue=""
                          ref={syncRef}
                          className="orderSelect"
                        >
                          <option value="" disabled>
                            {t("orders.sync")}
                          </option>
                          <option value="">{t("areas.all")}</option>

                          <option value="1">{t("orders.synced")}</option>
                          <option value="0">{t("orders.not-synced")}</option>
                        </select>
                      </div>

                      <div className="col-md-2 col-sm-4 col-6">
                        <select
                          defaultValue=""
                          ref={codeRef}
                          className="orderSelect"
                        >
                          <option value="" disabled>
                            {t("orders.codes")}
                          </option>
                          <option value="">{t("areas.all")}</option>

                          {filterData.codes &&
                            filterData.codes.map((data, index) => (
                              <option value={data.id}>{data.code}</option>
                            ))}
                        </select>
                      </div>
                      <div className="col-md-2 col-sm-4 col-6">
                        <select
                          defaultValue=""
                          ref={agentRef}
                          className="orderSelect"
                        >
                          <option value="" disabled>
                            {t("orders.agent")}
                          </option>
                          <option value="">{t("areas.all")}</option>
                          <option value={localStorage.getItem("userId")}>
                            {localStorage.getItem("userName")}
                          </option>
                        </select>
                      </div>

                      <div className="col-md-2 col-sm-4 col-6">
                        <select
                          defaultValue=""
                          ref={platformRef}
                          className="orderSelect"
                        >
                          <option value="" disabled>
                            {t("orders.platforms")}
                          </option>
                          <option value="">{t("areas.all")}</option>

                          {filterData.platforms &&
                            filterData.platforms.map((data, index) => (
                              <option value={data}>{data}</option>
                            ))}
                        </select>
                      </div>

                      <div className="col-md-2 col-sm-4 col-6">
                        <select
                          defaultValue=""
                          ref={sourceRef}
                          className="orderSelect"
                        >
                          <option value="" disabled>
                            {t("orders.sources")}
                          </option>
                          <option value="">{t("areas.all")}</option>

                          {filterData.sources &&
                            filterData.sources.map((data, index) => (
                              <option value={data.code}>{data.name}</option>
                            ))}
                        </select>
                      </div>

                      <div
                        className="col-md-2 col-sm-4 col-6"
                        style={{ textAlign: "end" }}
                      >
                        <button
                          onClick={() => filter(1)}
                          disabled={tableLoading}
                          className="filter"
                        >
                          {t("orders.filter")}
                        </button>
                      </div>
                      <div
                        className="col-md-3 col-sm-5 col-6 "
                        style={{ textAlign: "start" }}
                      >
                        <button onClick={resetData} className="reset">
                          {t("offer.reset")} <GrPowerReset color="#ffff" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {tableLoading ? (
              <div style={{ height: "30vh" }} className="loader">
                <BeatLoader
                  color={"#E47100"}
                  loading={tableLoading}
                  size={30}
                />
              </div>
            ) : (
              <>
                <div
                  className={
                    i18n.language == "ar"
                      ? "container-fluid pagAr"
                      : "container-fluid"
                  }
                >
                  <div
                    className={
                      i18n.language == "ar"
                        ? "row orderTable"
                        : "row tableEn orderTable"
                    }
                  >
                    <div
                      className={
                        orders.length == 0
                          ? i18n.language == "ar"
                            ? " emptyAr empty col-lg-12 col-md-12 col-sm-12 col-xs-12"
                            : "  empty col-lg-12 col-md-12 col-sm-12 col-xs-12"
                          : "col-lg-12 col-md-12 col-sm-12 col-xs-12"
                      }
                    >
                      <MDBDataTable
                        entries={25}
                        entriesOptions={[25]}
                        infoLabel={[
                          t("orders.info1"),
                          t("orders.info2"),
                          t("orders.info3"),
                          t("orders.info4"),
                        ]}
                        searchLabel={t("dashboard.search")}
                        paginationLabel={[t("orders.prev"), t("orders.next")]}
                        entriesLabel={t("orders.show")}
                        striped
                        hover
                        noBottomColumns={true}
                        data={data}
                      />
                    </div>
                  </div>
                </div>
                {orders.length == 0 ? null : (
                  <Stack spacing={0}>
                    <Pagination
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{
                            previous:
                              i18n.language == "ar" ? "prevAr" : "prevEn",
                            next: i18n.language == "ar" ? "nextAr" : "nextEn",
                          }}
                          {...item}
                        />
                      )}
                      className={i18n.language == "ar" ? "arArrow" : null}
                      defaultPage={1}
                      page={page}
                      count={resp.last_page}
                      onChange={(e, value) => {
                        filter(value);
                      }}
                      shape="rounded"
                      variant="outlined"
                    />
                  </Stack>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default CustomerOrders;
