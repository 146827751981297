import '../Styles/Dashboard.css';

import { useTranslation } from 'react-i18next';

function ChangeStatus(props) {
   
    const { t, i18n } = useTranslation();

 
    return (
        <div >
         
        <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
        <div  className="modal-content" style={{height:"auto",width:"300px",paddingTop:"2em",position:"fixed"}}>
      
<p className='alrt'>{props.online? t("chat.changeOffline") :t("chat.changeOnline")}</p>
<div className='modalBtns'>
<button  onClick={props.handleOnlineStatus}  className='del'>{t("menu.confirm")}</button>
<button  onClick={()=>props.setModal(false)}  className='cls'>{t("menu.cancel")}</button>

</div>
        </div>
      </div>
  
    );
  }
  
  export default ChangeStatus;
  
  