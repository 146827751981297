import '../Styles/CustomerOrders.css';

import Navbar from '../Components/Navbar';
import { useTranslation } from 'react-i18next';

import ErrorModal from '../Components/ErrorModal';
import { GoogleMap,useLoadScript,Marker} from "@react-google-maps/api"
import Autocomplete from 'react-google-autocomplete';

import CustomerData from '../Components/CustomerData';
import {useState,useEffect,useRef}  from 'react';
import { useNavigate } from "react-router-dom";
import { branches,cities,addAddress,branachByArea,areaByBranch, addOrganization} from '../api';
import { BeatLoader } from 'react-spinners';
import Select from "react-select";
import {useFormik} from "formik";
import * as yup from "yup"
import { countries } from '../api';
import icn1 from "../Images/customerProfile.svg"
import icn2 from "../Images/orderDetailsIconInactive.svg"
import icn3 from "../Images/menuIconUnactive.svg"
import icn4 from "../Images/basketIconUnactive.svg"
import { Link } from 'react-router-dom';
export const MAP_LIBRARIES = ['places'];

function AddTax() {


    const { t, i18n } = useTranslation();

    const[arMsg,setArMsg]=useState()
    const[enMsg,setEnMsg]=useState()
const[errModal,setErrModal]=useState(false)
const[city,setCity]=useState([])
const[country,setCountry]=useState()
    const[loading,setLoading]=useState(true);
    const[submitLoading,setSubmitLoading]=useState(false);

   let history= useNavigate();
     


   let phoneRegEx =/^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;
   let emailRegx =/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
            let numRegEx= /^[0-9]+$/;
            let taxReg= /^(3)[0-9]{13}(3)$/;
            let postReg= /^[0-9]{5}$/;
            
   const Schema= yup.object().shape({
    fullName: yup.string().required(("validation.name_required")),
     phone: yup.string().required(("validation.phone_required")).matches(phoneRegEx,{message:("validation.phone_not_valid")}),
   
       email: yup.string().required(("validation.email_required")).matches(emailRegx,{message:("validation.invalid_email")}),  
       taxNumber: yup.string().required(("validation.tax_required")).matches(taxReg,{message:("validation.ivalidTax")}),
     
     commericalRegisterNo: yup.string().required(("validation.commerical_required")).matches(numRegEx,{message:("validation.numbers")}),
     
     organizationName: yup.string().required(("validation.organization_required")),
     cityId: yup.string().required(("validation.city_required")),
   
     streetName: yup.string().required(("validation.street_required")),
   
     postalCode: yup.string().matches(postReg,("validation.postalMin")),
   
     address: yup.string().required(("validation.address_required")),
   
     })
   
   const formik = useFormik({
     initialValues:{
       fullName:localStorage.getItem("customerName"),
       phone:localStorage.getItem("customerPhone"),
       email:"",
       taxNumber:"",
       commericalRegisterNo:"",
       organizationName:"",
       cityId:"",
   
       streetName:"",
   
       postalCode:"",
   
       address:"",
   
     },
     validationSchema: Schema,
     onSubmit,
     
     });

     

    
      //countries=> takes user token and returns countries and the cities inside them
      //set initial chosen country
      useEffect(() => {
   
        const token= localStorage.getItem("token");
      
        
      countries(token).then((response) => {
        setCountry(response.data.data)
        setCity(response.data.data[0].cities)


      setLoading(false)
      }).catch(function(error){
      if (error.response.status === 401) {
         history("/")
      }
      
      });
      },[]) 

//on changing chosen country
// selected city is reset to initial value
//countries api is called to fetch cities of selected country
      function handleCountryChange(e){
        var dropDown = document.getElementById("city");
        dropDown.selectedIndex = 0;
        var dropDown2 = document.getElementById("branch");
        dropDown2.selectedIndex = 0;
      
        const token= localStorage.getItem("token");
      
        
const index= JSON.parse(e.target.value).index

  
        countries(token).then((response) => {
         setCity(response.data.data[index].cities)
        setLoading(false)
        }).catch(function(error){
        if (error.response.status === 401) {
           history("/")
        }
        
        });

      } 
 


      console.log(formik.errors)


function onSubmit(e){
  
  setSubmitLoading(true)
   
    const token= localStorage.getItem("token");
 
    
    let values = {
      fullName: formik.values.fullName,
      phone:  formik.values.phone,
      email:  formik.values.email,
      taxNumber:  formik.values.taxNumber,
      commericalRegisterNo: formik.values.commericalRegisterNo,
      organizationName:formik.values.organizationName,
      cityId: formik.values.cityId,
      streetName: formik.values.streetName,
      postalCode: formik.values.postalCode,
      address: formik.values.address,
      userId: localStorage.getItem("customerId")

   };
 


    addOrganization(token,values).then((response) => {
      

if(localStorage.getItem("prev")=="tax"){
            history("/customer-invoice")   
            
}
else{
  history("/add-order")   
}
      

    }).catch(function (error) {
        if (error.response.status === 401) {
            history("/")
        }
      


    })

};



    return (
      <div >
         <div className='processNav' >
          <Link to="/customer-orders">
<div>
<img style={{cursor:"pointer"}} src={icn1}/>

<p>{t("addOrder.profile")}</p>
</div></Link>
<div className='dottedLine'></div>
<Link to="/add-order">
<div>
<img style={{cursor:"pointer"}}  src={icn2}/>
<p  style={{color:"white"}}>{t("addOrder.details")}</p></div>
</Link>
<div className='dottedLine'></div>
<div>
<img src={icn3}/>
<p style={{color:"white"}}>{t("addOrder.menu")}</p></div>

<div className='dottedLine'></div>
<div>
<img src={icn4}/>
<p style={{color:"white"}}>{t("addOrder.send")}</p></div>
  </div>
          {errModal?<ErrorModal setModal={setErrModal} msg={i18n.language=="ar"?arMsg:enMsg}/>:null}
          {loading?
               <div style={{height:"80vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:

<div style={{marginTop:"2em",paddingBottom:"2em"}} className='container'>
          
<CustomerData loading={loading}  active={5}/>

     

<div style={{maxWidth:"700px",margin:"4em auto"}} className='customerBody addressAdd'>
   
{submitLoading?
               <div style={{height:"80vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={submitLoading}  size={30} /></div>:null}

<div style={submitLoading?{display:"none"}:null}>
<p>{t("addOrder.addTax")}</p>
<form className='taxForm'  onSubmit={formik.handleSubmit}>

<div className='container-fluid'>

<div className='row'>
   <div className='col-12'>


<div className='row'>

<div className='col-6'>
<label>{t("addOrder.name")}</label>
<input placeholder= {t("addOrder.name")}  value={formik.values.fullName} id="fullName" name="fullName"  required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.fullName && formik.touched.fullName?"invalid":null}/>
        <span>{formik.errors.fullName && formik.touched.fullName?t(`${formik.errors.fullName}`):null}</span>
                            
</div>
 
<div className='col-6'>
<label>{t("addOrder.phone")}</label>
<input placeholder= {t("addOrder.phone")} value={formik.values.phone} id="phone" name="phone"  required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.phone && formik.touched.phone?"invalid":null}/>
        <span>{formik.errors.phone && formik.touched.phone?t(`${formik.errors.phone}`):null}</span>
</div>
<div className='col-6'>
<label>{t("addOrder.email")}</label>
<input placeholder= {t("addOrder.email")} value={formik.values.email} id="email" name="email"  required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.email && formik.touched.email?"invalid":null}/>
        <span>{formik.errors.email && formik.touched.email?t(`${formik.errors.email}`):null}</span>

</div>

<div className='col-6'>
<label>{t("addOrder.taxNumber")}</label>
<input placeholder= {t("addOrder.taxNumber")}  value={formik.values.taxNumber} id="taxNumber" name="taxNumber"  required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.taxNumber && formik.touched.taxNumber?"invalid":null}/>
        <span>{formik.errors.taxNumber && formik.touched.taxNumber?t(`${formik.errors.taxNumber}`):null}</span>

</div>
<div className='col-6'>
<label>{t("addOrder.commercial")}</label>
<input placeholder= {t("addOrder.commercial")}   value={formik.values.commericalRegisterNo} id="commericalRegisterNo" name="commericalRegisterNo"  required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.commericalRegisterNo && formik.touched.commericalRegisterNo?"invalid":null}/>
        <span>{formik.errors.commericalRegisterNo && formik.touched.commericalRegisterNo?t(`${formik.errors.commericalRegisterNo}`):null}</span>

</div>
<div className='col-6'>
<label>{t("addOrder.orgName")}</label>
<input placeholder= {t("addOrder.orgName")}  value={formik.values.organizationName} id="organizationName" name="organizationName"  required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.organizationName && formik.touched.organizationName?"invalid":null}/>
        <span>{formik.errors.organizationName && formik.touched.organizationName?t(`${formik.errors.organizationName}`):null}</span>

</div>

<div className='col-6'>
<label>{t("addOrder.street")}</label>
<input placeholder= {t("addOrder.street")}  value={formik.values.streetName} id="streetName" name="streetName"  required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.streetName && formik.touched.streetName?"invalid":null}/>
        <span>{formik.errors.streetName && formik.touched.streetName?t(`${formik.errors.streetName}`):null}</span>

</div>
<div className='col-6'>
<label>{t("addOrder.postal")}</label>
<input placeholder= {t("addOrder.postal")}  value={formik.values.postalCode} id="postalCode" name="postalCode"   onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.postalCode && formik.touched.postalCode?"invalid":null}/>
        <span>{formik.errors.postalCode && formik.touched.postalCode?t(`${formik.errors.postalCode}`):null}</span>

</div>


<div className='col-6'>
<label>{t("dashboard.country")}</label>
<select  onChange={(e)=>handleCountryChange(e)} >
    
{country && country.map((data, index) => 

<option value={JSON.stringify({index: index, arName: data.arName, enName:data.enName})} key={data.id}>{i18n.language=="ar"?data.arName:data.enName}</option>

)}
</select>

</div>

<div className='col-6'>
<label>{t("profile.city")}</label>
<select defaultValue="" value={formik.values.cityId} id="cityId" name="cityId"  required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.cityId && formik.touched.cityId?"invalid selectCity":"selectCity"}>

    <option value="" disabled>{t("profile.choose")}</option>
{city && city.map((data, index) => 

<option key={index}  value={data.id}  >{i18n.language=="ar"?data.arName:data.enName} </option>

)}
</select>
<span>{formik.errors.cityId && formik.touched.cityId?t(`${formik.errors.cityId}`):null}</span>

</div>

 

<div className='col-12'>
<label>{t("addOrder.organizationAddress")}</label>
<textarea placeholder= {t("addOrder.organizationAddress")}  value={formik.values.address} id="address" name="address"  required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.address && formik.touched.address?"invalid addressNotes":"addressNotes"}/>
        <span>{formik.errors.address && formik.touched.address?t(`${formik.errors.address}`):null}</span>

</div>




<button type='submit' id="addAddressBtn">{t("profile.save")}</button>


</div>

</div>




</div>

</div>
</form></div></div>

</div>

  
   }</div>
    );
  }
   
  export default AddTax;
  
  