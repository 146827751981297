
import React, {useState,useRef} from 'react';
import { RiArrowRightSFill,RiArrowLeftSFill } from 'react-icons/ri';
import img1 from '../../Images/file1.jpeg'
import img2 from '../../Images/file2.jpeg'
import img3 from '../../Images/file3.jpeg'

import { useTranslation } from 'react-i18next';
import { BeatLoader } from 'react-spinners';
 import {BsUpload} from "react-icons/bs";
import * as yup from "yup"
import {useFormik} from "formik";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import ImageModal from '../../Components/ImageModal';
import { GrDocumentPdf } from 'react-icons/gr';
import { BsQuestionCircleFill } from 'react-icons/bs';
import { register3 } from '../../api';
function RegisterStep3() {
  
    
    const { t, i18n } = useTranslation();
    const [loading,setLoading]=useState(false);
const [msgAr, setMsgAr]=useState();
const [msgEn, setMsgEn]=useState();
const [errorMsgModel, setErrorMsgModel]=useState(false);
const [modal,setModal]=useState(false);
const [img, setImg]=useState();


      const history=useNavigate();
  
      const Schema= yup.object().shape({
        documents: yup.string().required(("register3.required_documents")),
   commReg: yup.string().required(("register3.required_CommReg")),
        chamber: yup.string().required(("register3.required_chamber")),
        certification: yup.string().required(("register3.required_certification")),
        bank: yup.string().required(("register3.required_bank")),
        certificate: yup.string().required(("register3.certificate")),
  
      })
      


      const formik = useFormik({
      initialValues:{
        documents: '',
        commReg: '',
        chamber:'',
        certification:'',
        bank:'',
        certificate:'',

     
      },
      validationSchema: Schema,
      onSubmit
      });
   
      const documentsRef=useRef();
      const commRegRef=useRef();
      const chamberRef=useRef();
      const bankRef=useRef();
      const certificateRef=useRef();
      const certificationRef=useRef();
    
        
     

//On submitting for registration (takes:name,phone,countryId,password,password confirmation and agreement to terms (bool))
//user is given a token that is stored to be used in /verify where the user is redirected

      async function onSubmit(event){
        setLoading(true);

        const formData = new FormData()
       
  formData.append('documents',documentsRef.current.files[0],documentsRef.current.files[0].name)
  formData.append('registrationTaxCertificate',certificationRef.current.files[0])
  formData.append('commercialRegistration',commRegRef.current.files[0])
  formData.append('chamberCommerce',chamberRef.current.files[0])
  formData.append('zakatIncomeCertificate',certificateRef.current.files[0])
  formData.append('bankAccountDocument',bankRef.current.files[0])

    

        register3(localStorage.getItem("token"),localStorage.getItem("externalId"),formData).then((response) => {
 
          history("/external-user"); 


}).catch(function (error) {

                                     
  setMsgAr("حدث خطأ   ");
  setMsgEn("Something went wrong");
  setErrorMsgModel(true);

  setLoading(false);
                                 

      })}


 
   return (
        <div>
          {modal?<ImageModal img={img} setModal={setModal}/>:null}
           <div className=' container '>
<div className='navigateSteps'>
<Link to='/register-1' >{t("register.step1")} </Link>
{i18n.language=="ar"? 
<RiArrowLeftSFill size={25}/>: <RiArrowRightSFill size={25}/>} 
<Link to='/register-2' >{t("register2.step2")} </Link>
{i18n.language=="ar"?
<RiArrowLeftSFill size={25}/>: <RiArrowRightSFill size={25}/>}
<Link to='/register-3' style={{color:'#E47100'}}>{t("register3.step3")} </Link>

  </div></div>
        <div className='loginContainer container ' style={{paddingTop:"2em",paddingBottom:"0"}}>
 
<div className='login' style={{height:"auto"}}>
{loading?
      <div style={{height:"110vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:null}
<div style={loading?{display:"none"}:null}  className='row'>
<div className='col'>
    <div className='loginTitle'>
<p >{t("register3.step3")} </p></div>
<div className='container-fluid userDataExt'style={{padding: "2em 1em"}} >
<form className="row flexEnd" onSubmit={formik.handleSubmit} enctype="multipart/form-data">

    <div className='col-5'>
    
    <div className="inputDiv">

    <label>  {t("register3.commReg")} <BsQuestionCircleFill onClick={()=> {setImg(img2); setModal(true);}}/></label>
<div  className={i18n.language=="ar"?'uploadInp':'uploadInp uploadInpEn'}> 
<input type="file" accept=".pdf, image/jpeg, image/png, image/gif" placeholder={t("register3.commReg")} value={formik.values.commReg} id="commReg" name="commReg"  ref={commRegRef}  onChange={formik.handleChange}  className={formik.errors.commReg && formik.touched.commReg ?"invalid":null}/>
<BsUpload size={20} color="#ACACAC"/>

</div>

<span>{formik.errors.commReg && formik.touched.commReg?t(`${formik.errors.commReg}`):null}</span>
</div></div>
<div className='col-1'></div>

<div className='col-5'>
    
    <div className="inputDiv">
<label>  {t("register3.chamber")} <BsQuestionCircleFill onClick={()=> {setImg();  setModal(true)}}/></label>
<div  className={i18n.language=="ar"?'uploadInp':'uploadInp uploadInpEn'}> 
<input type="file" accept=".pdf, image/jpeg, image/png, image/gif" placeholder={t("register3.chamber")} value={formik.values.chamber} id="chamber" name="chamber"  ref={chamberRef}  onChange={formik.handleChange}  className={formik.errors.chamber && formik.touched.chamber ?"invalid":null}/>
<BsUpload size={20} color="#ACACAC"/>

</div>

<span>{formik.errors.chamber && formik.touched.chamber?t(`${formik.errors.chamber}`):null}</span>
</div></div>

<div className='col-5'>
    
    <div className="inputDiv">
<label>  {t("register3.certificate")} <BsQuestionCircleFill onClick={()=>{setImg();  setModal(true)}}/></label>
<div  className={i18n.language=="ar"?'uploadInp':'uploadInp uploadInpEn'}> 
<input type="file" accept=".pdf, image/jpeg, image/png, image/gif" placeholder={t("register3.certificate")} value={formik.values.certificate} id="certificate" name="certificate"  ref={certificateRef}  onChange={formik.handleChange} className={formik.errors.certificate && formik.touched.certificate ?"invalid":null}/>
<BsUpload size={20} color="#ACACAC"/>

</div>

<span>{formik.errors.certificate && formik.touched.certificate?t(`${formik.errors.certificate}`):null}</span>
</div></div>
<div className='col-1'></div>

<div className='col-5'>
    
    <div className="inputDiv">
    <label>  {t("register3.bank")} <BsQuestionCircleFill onClick={()=> {setImg(img1); setModal(true);}}/></label>
<div  className={i18n.language=="ar"?'uploadInp':'uploadInp uploadInpEn'}> 
<input type="file" accept=".pdf, image/jpeg, image/png, image/gif" placeholder={t("register3.bank")} value={formik.values.bank} id="bank" name="bank"  ref={bankRef}  onChange={formik.handleChange} className={formik.errors.bank && formik.touched.bank ?"invalid":null}/>
<BsUpload size={20} color="#ACACAC"/>

</div>

<span>{formik.errors.bank && formik.touched.bank?t(`${formik.errors.bank}`):null}</span>
</div></div>
<div className='col-5'>
    
    <div className="inputDiv">
    <label>  {t("register3.certification")} <BsQuestionCircleFill onClick={()=> {setImg(img3); setModal(true);}}/></label>
<div  className={i18n.language=="ar"?'uploadInp':'uploadInp uploadInpEn'}> 
<input type="file" accept=".pdf, image/jpeg, image/png, image/gif" placeholder={t("register3.certification")} value={formik.values.certification} id="certification" name="certification"  ref={certificationRef}  onChange={formik.handleChange} className={formik.errors.certification && formik.touched.certification ?"invalid":null}/>
<BsUpload size={20} color="#ACACAC"/>

</div>

<span>{formik.errors.certification && formik.touched.certification?t(`${formik.errors.certification}`):null}</span>
</div></div>
<div className='col-1'></div>

<div className='col-5'>
    
    <div className="inputDiv">
<label>  {t("register3.documents")} <BsQuestionCircleFill onClick={()=>{setImg(); setModal(true)}}/></label>
<div  className={i18n.language=="ar"?'uploadInp':'uploadInp uploadInpEn'}> 
<input type="file" accept=".pdf, image/jpeg, image/png, image/gif" placeholder={t("register3.documents")} value={formik.values.documents} id="documents" name="documents"  ref={documentsRef}  onChange={formik.handleChange}   className={formik.errors.documents && formik.touched.documents ?"invalid":null}/>
<BsUpload size={20} color="#ACACAC"/>

</div>
<span>{formik.errors.documents && formik.touched.documents?t(`${formik.errors.documents}`):null}</span>
</div></div>

{i18n.language=="ar"?
<span>{errorMsgModel? msgAr:null}</span>:
<span>{errorMsgModel? msgEn:null}</span>}
<div className='navigateReg'>
<Link to="/register-2"><button className='cancelEdit'> {t("orders.prev")}</button>
</Link>
<span style={{margin:"0 1em",display:"inline-block"}}></span>
<button type="submit" className="dataEdit" > {t("orders.next")}</button>

</div>

</form>

</div>

</div>

</div>
</div>
</div>
<div style={{height:"6em"}}>

</div>
</div>
    );
}

export default RegisterStep3;