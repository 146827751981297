import React, { useState, forwardRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaChevronDown } from 'react-icons/fa'; // Optional: For dropdown arrow icon

// Custom Toggle Component
const CustomToggle = forwardRef(({ children, onClick, isHovered, setIsHovered, isRTL }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    onMouseEnter={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}
    style={{
      color: isHovered ? '#fff' : '#E47100',
      backgroundColor: isHovered ? '#E47100' : 'transparent',
      border: '2px solid #E47100',
      borderRadius: '4px',
      padding: '0.5rem 1rem',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: isRTL ? 'flex-end' : 'flex-start',
      transition: 'background-color 0.3s, color 0.3s',
      direction: isRTL ? 'rtl' : 'ltr',
      minWidth: '120px', // Ensures consistency
      minHeight: '10px',
      userSelect: 'none',
      marginTop: '13px'
    }}
    aria-haspopup="true"
    aria-expanded="false"
    role="button"
    tabIndex="0"
    onKeyPress={(e) => {
      if (e.key === 'Enter') onClick(e);
    }}
  >
    <span>{children}</span>
    <FaChevronDown
      style={{
        marginLeft: isRTL ? '0' : '8px',
        marginRight: isRTL ? '8px' : '0',
        transition: 'transform 0.3s',
        transform: isHovered ? 'rotate(180deg)' : 'rotate(0deg)',
      }}
    />
  </div>
));

const EnhancedDropdownButton = ({
  selectedPrice,
  tablePrices,
  handlePriceSelect,
}) => {
  const { i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';

  // State for button hover
  const [isButtonHovered, setIsButtonHovered] = useState(false);

  // State for dropdown item hover
  const [hoveredItem, setHoveredItem] = useState(null);

  // Styles for Dropdown.Menu
  const dropdownMenuStyle = {
    backgroundColor: '#fff',
    border: '1px solid #E47100',
    borderRadius: '4px',
    marginTop: '0.5rem',
    minWidth: '150px',
    zIndex: 1000, // Ensure it appears above other elements
  };

  // Styles for Dropdown.Item
  const dropdownItemStyle = (price) => ({
    color: hoveredItem === price ? '#fff' : '#E47100',
    backgroundColor: hoveredItem === price ? '#E47100' : '#fff',
    padding: '0.5rem 1rem',
    cursor: 'pointer',
    transition: 'background-color 0.3s, color 0.3s',
    textAlign: isRTL ? 'right' : 'left',
    display: 'flex',
    alignItems: 'center',
    justifyContent: isRTL ? 'flex-end' : 'flex-start',
  });

  return (
    <Dropdown align={isRTL ? 'end' : 'start'}>
      <Dropdown.Toggle
        as={CustomToggle}
        isHovered={isButtonHovered}
        setIsHovered={setIsButtonHovered}
        isRTL={isRTL}
      >
        {`${selectedPrice} ${isRTL ? 'ريال' : 'SAR'}`}
      </Dropdown.Toggle>

      <Dropdown.Menu
        style={dropdownMenuStyle}
        aria-label="Price options"
      >
        {tablePrices.map((price, index) => (
          <Dropdown.Item
            as="div"
            key={index}
            onClick={() => handlePriceSelect(price)}
            style={dropdownItemStyle(price)}
            onMouseEnter={() => setHoveredItem(price)}
            onMouseLeave={() => setHoveredItem(null)}
            role="option"
            aria-selected={selectedPrice === price}
            tabIndex="0"
            onKeyPress={(e) => {
              if (e.key === 'Enter') handlePriceSelect(price);
            }}
          >
            {`${price} ${isRTL ? 'ريال' : 'SAR'}`}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default EnhancedDropdownButton;
