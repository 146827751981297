 
import React, {useState,useRef,useEffect} from 'react';
import { RiArrowRightSFill,RiArrowLeftSFill } from 'react-icons/ri';

import { useTranslation } from 'react-i18next';
import { BeatLoader } from 'react-spinners';
 import {BsUpload} from "react-icons/bs";
import * as yup from "yup"
import {useFormik} from "formik";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import ImageModal from '../../Components/ImageModal';
import {GrDocumentPdf}  from 'react-icons/gr';
import { BsQuestionCircleFill } from 'react-icons/bs';
import { countries,companyActivities,updateBasicData} from '../../api';

function BasicData(props) {
    const { t, i18n } = useTranslation();
    const [loading,setLoading]=useState(true);
const [msgAr, setMsgAr]=useState();
const [msgEn, setMsgEn]=useState();
const [errorMsgModel, setErrorMsgModel]=useState(false);
const [msgArp, setMsgArp]=useState();
const [msgEnp, setMsgEnp]=useState();
const [edit, setEdit]=useState(false);
const [errorMsgModelp, setErrorMsgModelp]=useState(false);
const [activities,setActivities]=useState([]);

const [city,setCity]=useState([]);
const [validation,setValidation]=useState(
  {
    name: {
      err:false,
      text:""
    },
    phone: {
      err:false,
      text:""
    }, facilityName: {
      err:false,
      text:""
    }, activityId: {
      err:false,
      text:""
    },
    cityId: {
      err:false,
      text:""
    }
  
  })
const token= localStorage.getItem("token");

      const history=useNavigate();
  
      let phoneRegEx =/^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;
      let nameRegEx= /^[\u0621-\u064Aa-zA-Z\-_\s]+$/;

      const Schema= yup.object().shape({
      phone: yup.string().required(("validation.phone_required")).matches(phoneRegEx,("validation.phone_not_valid")),
      name: yup.string().required(("validation.name_required")).matches(nameRegEx,{message:("validation.name_not_valid")}).min(3, ("validation.name_atleast")),
      company: yup.string().required(("Login.required_company")).matches(nameRegEx,{message:("validation.name_not_valid")}),
      activity: yup.string().required(("Login.required_companyActiv")),
      city: yup.string().required(("Login.required_city")),


      })
      

      
  
      const formik = useFormik( {
          
      initialValues:{
        name: props.data.user.name,
       phone: props.data.user.phone,
       company:props.data.facilityName,
       activity:props.data.externalSaleType=="prepayment"? parseInt(props.data.clientActivity.id): props.data.facilityActivity!==null? parseInt(props.data.facilityActivity.id):null,
       city:parseInt(props.data.city.id),
       password:null,
       password_confirmation:null
      },
      validationSchema: Schema,
      enableReinitialize: true,

      onSubmit
      });
   
      const passRef=useRef();
          const phoneRef=useRef();
          const passConfirmRef=useRef();
          const nameRef=useRef();

          const cityRef=useRef();
          const companyRef=useRef();
          const activityRef=useRef();




          useEffect(() => {


            companyActivities(token).then(response => {

                setActivities(response.data)
                     
                     })
            countries(token).then(response => {
         
         
        setCity(response.data.data[0].cities)
        setLoading(false)
             
             })
       
                    },[]);




      async function onSubmit(event){
const token=localStorage.getItem("token")
const id=props.data.id

     setLoading(true);
        const data={
     
      
          name:nameRef.current.value,
          phone:phoneRef.current.value,
          facilityName:companyRef.current.value,
          activityId: parseInt(activityRef.current.value),
          cityId: parseInt(cityRef.current.value),
  
        }
        updateBasicData(token,id,data,i18n.language).then((response) => {

     setLoading(false)
    
        props.setUpdate(!props.update)
       
setEdit(false)
 

}).catch(function (error) {
  let errorArr=error.response.data.errors;

  Object.keys(errorArr).forEach((key) => {
 
   if (validation.hasOwnProperty(key)) {
     setValidation((prevState) => ({
       ...prevState,
       [key]: {
         err: true,
         text: errorArr[key][0],
       },
     }));
   }
 });
 setLoading(false)
      }
                                    
                                    )}



  useEffect(() => {
  
  formik.setValues({
    name: props.data.user.name,
    phone: props.data.user.phone,
    company:props.data.facilityName,
    activity:props.data.externalSaleType=="prepayment"? parseInt(props.data.clientActivity.id): props.data.facilityActivity!==null? parseInt(props.data.facilityActivity.id):null,
    city:parseInt(props.data.city.id),
    password:null,
    password_confirmation:null
  });
  setEdit(false); 

}, [props]);


 
   return (
    <div className='container-fluid'>

 {loading?
    <div style={{height:"50vh"}} className='loader'>
    <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:null}

 <form className={edit?"row":"row disabledForm"} style={loading?{display:"none"}:null} onSubmit={formik.handleSubmit}>

        <div className='col-sm-5 col-6'>
        <div className="inputDiv">
    <label> {t("Login.yourName")}</label>
    <input type="text" placeholder= {t("Login.yourName")} value={formik.values.name} id="name" name="name"  ref={nameRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.name && formik.touched.name) || validation.name.err==true?"invalid":null}/>
<span>{formik.errors.name && formik.touched.name?t(`${formik.errors.name}`):null}</span>
<span>{validation.name.err=true? validation.name.text:null}</span>

    </div></div>
    <div className='col-1 removeCol'></div>
    <div className='col-sm-5 col-6'>
    
    <div className="inputDiv">
    <label> {t("register2.mobile")}</label>

    <input type="text" placeholder={t("register2.mobile")} value={formik.values.phone} id="phone" name="phone"  ref={phoneRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.phone && formik.touched.phone) || validation.phone.err==true?"invalid":null}/>
<span>{formik.errors.phone && formik.touched.phone?t(`${formik.errors.phone}`):null}</span>
<span>{validation.phone.err=true? validation.phone.text:null}</span>

    </div></div>
    <div className='col-sm-5 col-6'>
    
    <div className="inputDiv">
    <label> {t("register.company")}</label>
    <input type="text" placeholder={t("Login.company")} value={formik.values.company} id="company" name="company"  ref={companyRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.company && formik.touched.company) || validation.facilityName.err== true ?"invalid":null}/>
<span>{formik.errors.company && formik.touched.company?t(`${formik.errors.company}`):null}</span>
<span>{validation.facilityName.err=true? validation.facilityName.text:null}</span>

    </div></div>
    <div className='col-1 removeCol'>
    </div>
    <div className='col-sm-5 col-6'>
    
    <div className="inputDiv">
    <label> {t("register.companyActiv")}</label>
    <select defaultValue={parseInt(formik.values.activity)}  value={formik.values.activity} id="activity" name="activity"  ref={activityRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.activity && formik.touched.activity) ||validation.activityId.err==true?"invalid chosenSelect":"chosenSelect"}>
<option selected disabled value="">{t("Login.companyActivChoose")}</option>

{activities && activities.map((data,index)=>


<option key={index}  value={data.id}  >{i18n.language=="ar"?data.name_ar:data.name_en} </option>

  
  )
}

</select>
<span>{formik.errors.activity && formik.touched.activity?t(`${formik.errors.activity}`):null}</span>
<span>{validation.activityId.err=true? validation.activityId.text:null}</span>

    
    </div>
    </div>
    <div className='col-sm-5 col-6'>
    
    <div className="inputDiv">
    <label> {t("register.city")}</label>
    <select disabled={localStorage.getItem("userType")=="prepayment"} value={formik.values.city} id="city" name="city"  ref={cityRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.city && formik.touched.city) ||validation.cityId.err==true?"invalid chosenSelect":"chosenSelect"}>
<option selected disabled value="">{t("Login.cityChoose")}</option>
{city && city.map((data,index)=>


<option key={index}  value={data.id}  >{i18n.language=="ar"?data.arName:data.enName} </option>

  
  )
} 

</select>

<span>{formik.errors.city && formik.touched.city?t(`${formik.errors.city}`):null}</span>
<span>{validation.cityId.err=true? validation.cityId.text:null}</span>

    </div></div>
    <div className='col-1 removeCol'>
    </div>
    
    <div> 
      
   </div>
    {i18n.language=="ar"?
<span>{errorMsgModel? msgAr:null}</span>:
<span>{errorMsgModel? msgEn:null}</span>}
{edit?
    <div className="formBtns" style={{marginTop:"1em"}}>
    <button type="submit"  className="dataEdit">{t("profile.save")}</button>
    <span> </span>


    <button type="button" className="cancelEdit" onClick={()=> props.setUpdate(!props.update)}>  {t("profile.cancel")}</button></div>
   :
   <div className="formBtns" style={{marginTop:"1em"}}>

   <button type="button" className="dataEdit"  onClick={(e)=>{e.preventDefault(); setEdit(true)}}>{t("profile.edit")}</button>   
   </div>

  
}
  
    </form></div>

    );
}

export default BasicData;