import '../Styles/CustomerOrders.css';

import Navbar from '../Components/Navbar';
import { useTranslation } from 'react-i18next';
import { FiEdit } from 'react-icons/fi';
import { BsGlobe2,BsInfoCircle } from 'react-icons/bs';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { RiDeleteBin5Line } from 'react-icons/ri';
import bckg from "../Images/order.svg"
import DelAddress from '../Components/DelAddress';
import ErrorModal from '../Components/ErrorModal';
import { Link } from 'react-router-dom';
import CustomerData from '../Components/CustomerData';
import {useState,useEffect}  from 'react';
import { useNavigate } from "react-router-dom";
import { customer,customerAddresses,delAddresses } from '../api';
import { BeatLoader } from 'react-spinners';
import { generatePath } from 'react-router-dom';
import icn1 from "../Images/customerProfile.svg"
import icn2 from "../Images/orderDetailsIconInactive.svg"
import icn3 from "../Images/menuIconUnactive.svg"
import icn4 from "../Images/basketIconUnactive.svg"
function CustomerAddress(props) {
   const [modal,setModal]=useState(false)
    const { t, i18n } = useTranslation();
    const[arMsg,setArMsg]=useState(false)
    const[enMsg,setEnMsg]=useState(false)
const[errModal,setErrModal]=useState(false)
const[del,setDel]=useState(false)
    const[loading,setLoading]=useState(true);
    
    const [id,setId]=useState()
    const [addresses,setAddresses]=useState([])
    const customerId= localStorage.getItem("customerId");
   let history= useNavigate();
     
  
      //customerAddresses=> takes customer id and user token and returns customer addresses
   useEffect(() => {
   
       const token= localStorage.getItem("token");
     
       
 
      
     customerAddresses(token,customerId).then((response) => {
      setAddresses(response.data.data)
     
     setLoading(false)
     }).catch(function(error){
     if (error.response.status === 401) {
        history("/")
     }
     else if(error.response.status === 403){
      props.setErr(true)
      props.setMsg(t('validation.noPermission'))
      props.setStyle({top:"50%"})
      }
          
      
      history("/customer-orders")
     });
      
     },[del]) 



     //on clicking delete the id of the chosen address, and confirmation message appears
     //on confirming delete =>  delAddresses is called (takes token and address id) and deletes address
     //setDel(!del) is used to rerender useEffect to update display
     function handleDel(){
      const token= localStorage.getItem("token");
     
       setLoading(true)
      delAddresses(token,id).then((response) => {
        if (response.data.message === "success") {
          setDel(!del)
       
      } else {
   
setErrModal(true)
setArMsg( "حدث خطأ")
setEnMsg("Something went wrong")
setLoading(false)
setTimeout(function() {
      
  setErrModal(false)
}, 2500)
      } 
       }).catch(function(error){
       if (error.response.status === 401) {
          history("/")
       }
       else{
         setLoading(false)
       }
       
       });

     }
   

       // takes address id and redirects to that specific address
  const editAddress = (id) => {
localStorage.setItem("prevPath","cstmr")
    history(generatePath("/edit-address/:id", { id }));
    
  };
    return (
      <div >
         <div className='processNav' >
          <Link to="/customer-orders">
<div>
<img style={{cursor:"pointer"}} src={icn1}/>

<p>{t("addOrder.profile")}</p>
</div></Link>
<div className='dottedLine'></div>
<Link to="/add-order">
<div>
<img style={{cursor:"pointer"}}  src={icn2}/>
<p  style={{color:"white"}}>{t("addOrder.details")}</p></div>
</Link>
<div className='dottedLine'></div>
<div>
<img src={icn3}/>
<p style={{color:"white"}}>{t("addOrder.menu")}</p></div>

<div className='dottedLine'></div>
<div>
<img src={icn4}/>
<p style={{color:"white"}}>{t("addOrder.send")}</p></div>
  </div>
          {errModal?<ErrorModal setModal={setErrModal} msg={i18n.language=="ar"?arMsg:enMsg}/>:null}
          {modal?<DelAddress handleDel={handleDel} setModal={setModal}/>:null}
          {loading?
               <div style={{height:"80vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:

<div style={{marginTop:"2em"}} className='container'>
          
<CustomerData loading={loading} active={1}/>

     

<div className='customerBody' style={{paddingBottom:"0"}}>
    <div className='addressTop' style={{margin:"1em 0"}}>
<p style={{marginTop:"0"}}></p>
<Link to="/add-address" onClick={()=>localStorage.setItem("prev","address")}><button>{t("profile.add_address")}<span></span><AiOutlinePlusCircle className='add-address' size={20}/></button></Link>
</div>
</div>

  <div style={{paddingBottom:"10em"}} className="row gy-4">

  {addresses && addresses.map((data, index) => 
  
  <div className="col-md-4 col-sm-6" key={index}>
  <div className="p-3 address">
      <p className='address-name'>{data.title}</p>
      <p className='address-st'>{i18n.language=="ar"? data.arAddress: data.enAddress}</p>
      <p className='address-st'> {t("profile.branch")} : {i18n.language=="ar"? data.branch.arName: data.branch.enName}</p>

      <button onClick={()=> {setModal(true)
      setId(data.id)

      }}> <RiDeleteBin5Line/> {t("profile.del")}</button>
          <button style={{color:"#ffc107"}} onClick={()=>{editAddress(data.id) }} > <FiEdit/> {t("profile.edit")}</button> 
 
  </div>
</div>
  
  
  )}
  {addresses.length==0?
     <p style={{textAlign:"center"}} className="question-title">
             {t("addOrder.noResult")}</p>:null}

  
</div>
       </div>}</div>
    );
  }
  
  export default CustomerAddress;
  
  