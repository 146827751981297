import '../Styles/CustomerOrders.css';

import Navbar from '../Components/Navbar';
import { useTranslation } from 'react-i18next';

import ErrorModal from '../Components/ErrorModal';
 
import CustomerData from '../Components/CustomerData';
import {useState,useEffect,useRef}  from 'react';
import { useNavigate } from "react-router-dom";
import { editAddress,customerAddresses,areaByBranch,branches} from '../api';
 
import { BeatLoader } from 'react-spinners';
import Select from "react-select";
import {useFormik} from "formik";
import * as yup from "yup"
import { useParams } from 'react-router-dom';
import icn1 from "../Images/customerProfile.svg"
import icn2 from "../Images/orderDetailsIconInactive.svg"
import icn3 from "../Images/menuIconUnactive.svg"
import icn4 from "../Images/basketIconUnactive.svg"
import { Link } from 'react-router-dom';
function EditAddress() {

  const { t, i18n } = useTranslation();

  const[arMsg,setArMsg]=useState()
  const[enMsg,setEnMsg]=useState()
const[errModal,setErrModal]=useState(false)
const[areas,setAreas]=useState([])
const[defArea,setDefArea]=useState()
const[branch,setBranch]=useState([])

const[selectedArea,setSelectedArea]=useState(null)
const[areaValue,setAreaValue]=useState(null)
const [selectedBranch,setSelectedBranch]=useState("");
const {id}=useParams();


  const[loading,setLoading]=useState(true);
  const[submitLoading,setSubmitLoading]=useState(false);
  const[address,setAddress]=useState();
  
   const arRef=useRef()
   const enRef=useRef()
   const titleRef=useRef()

  const customerId= localStorage.getItem("customerId");
 let history= useNavigate();
   





 

   //customerAddresses=> takes customer id and user token and returns customer addresses

    //countries=> takes user token and returns countries
    useEffect(() => {
 
      const token= localStorage.getItem("token");
      customerAddresses(token,customerId).then((response) => {
   
        const add =response.data.data.filter(obj => {
          return obj.id === parseInt(id)
        })

        setAddress(add[0])
        setSelectedBranch(add[0].branchId)
   
        branches(token,add[0].area.cityId).then((response) => {
      setBranch(response.data.data)
 
    }).catch(function(error){
    if (error.response.status === 401) {
       history("/")
    }
    
    });

    areaByBranch(token,add[0].branchId).then((response) => {
if(response.data.data.length>0){
      let items = [];  
             
      for (let i = 0; i < response.data.data.length; i++) {  
          let obj = {};
          obj.label =  response.data.data[i].arName +' - ('+ t("areas.ordinary") + response.data.data[i].ordinaryDeliveryPrice +' ) - (   '+ t("areas.fast")+response.data.data[i].fastDeliveryPrice +' )';
          obj.value =  response.data.data[i].id;        
          items.push(obj);   
           //creating  options dynamically based on what props are currently passed to the parent component
      }
      let defArea;
      if( response.data.data.some(el => el.id === add[0].area.id)){
        defArea  =response.data.data.filter(obj => {
        return obj.id === parseInt(add[0].area.id)
      })}
      else{
        defArea=null
      }
   
 if(defArea){
 setDefArea(
 defArea[0].arName+' - ('+ t("areas.ordinary") + defArea[0].ordinaryDeliveryPrice+' ) - (   '+ t("areas.fast") +defArea[0].fastDeliveryPrice +' )'

 )
 setSelectedArea(defArea[0].id)

}
 else{
  setDefArea(i18n.language=="ar"?"اختر...":"...Select")

 }
 setAreas(items)
 setLoading(false)
}
else{
  setDefArea(i18n.language=="ar"?"اختر...":"...Select")
  setLoading(false)
}


     


     
    
    }).catch(function(error){
    if (error.response.status === 401) {
       history("/")
    }
    
    });



      }).catch(function(error){
      if (error.response.status === 401) {
         history("/")
      }
      
      });
  
    },[]) 


 



//takes(title, area id, branch id (required)) 
//and (floor no., building no., street name, appartment no. and address notes (not required))
//if success user is redirected to customer addresses
function onSubmit(e)

{
 
e.preventDefault()


if (selectedArea === "" || selectedArea==null) {
  setArMsg(  "اختر الحي اولا")
  setEnMsg( "Select Area First")
    setErrModal(true);
    setSubmitLoading(false)
    setTimeout(function() {
    
      setErrModal(false)
    }, 2500)

}
else{

setSubmitLoading(true)
 
  const token= localStorage.getItem("token");

let data={
title:titleRef.current.value,
enAddress:enRef.current.value,
arAddress:arRef.current.value,
 branchId:parseInt(selectedBranch),
  areaId:parseInt(selectedArea)
}

  editAddress(token,id,data).then((response) => {
      if (response.data.message === "success") {
      if(localStorage.getItem("prevPath")=="order"){
          history("/add-order")     
          
      }
      else{
        history("/customer-addresses") 
      }

      } else {
          setArMsg(   "حدث خطأ")
  setEnMsg( "Something went wrong")
    setErrModal(true);
    setSubmitLoading(false)
    setTimeout(function() {
    
      setErrModal(false)
    }, 2500)
      
      } 

  }).catch(function (error) {
      if (error.response.status === 401) {
          history("/")
      }
    


  })
}
};


function handleAreaChange(e){
if(e){
  setAreaValue(e)
  setSelectedArea(e.value);
}
else{
  setAreaValue(e)
  setSelectedArea(null);
}

}

function handleBranchChange(e){
  const token= localStorage.getItem("token");
  setSelectedArea(null)
  setAreaValue(null)
 /*
  const el= document.getElementById('react-select-2-placeholder')
  if(el){
    el.innerHTML='...Select';}*/
    setDefArea("...Select")
  //setSelectedArea(e);
  //setAreaValue(e.value) 
  setSelectedBranch(e.target.value)

areaByBranch(token,e.target.value).then((response) => {
  let items = [];  
         
  for (let i = 0; i < response.data.data.length; i++) {  
      let obj = {};
      obj.label =  response.data.data[i].arName +' - ('+ t("areas.ordinary") + response.data.data[i].ordinaryDeliveryPrice +' ) - (   '+ t("areas.fast")+response.data.data[i].fastDeliveryPrice +' )';
      obj.value =  response.data.data[i].id;        
      items.push(obj);   
       //creating  options dynamically based on what props are currently passed to the parent component
  }
  setAreas(items)
 

}).catch(function(error){
if (error.response.status === 401) {
   history("/")
}

});


}
    return (
      <div >
         <div className='processNav' >
          <Link to="/customer-orders">
<div>
<img style={{cursor:"pointer"}} src={icn1}/>

<p>{t("addOrder.profile")}</p>
</div></Link>
<div className='dottedLine'></div>
<Link to="/add-order">
<div>
<img style={{cursor:"pointer"}}  src={icn2}/>
<p  style={{color:"white"}}>{t("addOrder.details")}</p></div>
</Link>
<div className='dottedLine'></div>
<div>
<img src={icn3}/>
<p style={{color:"white"}}>{t("addOrder.menu")}</p></div>

<div className='dottedLine'></div>
<div>
<img src={icn4}/>
<p style={{color:"white"}}>{t("addOrder.send")}</p></div>
  </div>
          {errModal?<ErrorModal setModal={setErrModal} msg={i18n.language=="ar"?arMsg:enMsg}/>:null}
          {loading?
               <div style={{height:"80vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:

<div style={{marginTop:"2em",paddingBottom:"8em"}} className='container'>
          
<CustomerData loading={loading}  active={1}/>

     

<div style={{marginBottom:"0"}} className='customerBody addressAdd'>
   
{submitLoading?
               <div style={{height:"80vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={submitLoading}  size={30} /></div>:null}

<div style={submitLoading?{display:"none"}:null}>
<p>{t("profile.edit_address")}</p>

<form onSubmit={(e)=>onSubmit(e)}>
<div className='row'>

<div className='col-12'>
<label>{t("profile.title")}</label>

<select defaultValue={address.title} id="title" style={{width:"100%"}} ref={titleRef}  name="title" required={true} >
<option value="" disabled>{t("profile.choose")}</option>
                                                <option value="الاستراحة">الاستراحة</option>
                                                <option value="العمل">العمل</option>
                                                <option value="المنزل">المنزل</option>
                                            </select>
                      
</div>
 
<div className='col-6'>
<label>{t("profile.branch")}</label>
<select defaultValue={address.branchId} id="branch" required={true} onChange={(e)=>handleBranchChange(e)}>
{branch?  branch.map((data, index) => 

<option value={data.id} key={data.id}>{i18n.language=="ar"?data.arName:data.enName} {(data.deliveryOption !== 1)?  t("addOrder.noDelivery") : ""}</option>

):null}</select>
</div>

<div className={i18n.language=="ar"?'col-6':'col-6 selectEn'}>
<label>{t("profile.area")}</label>
<Select isClearable  
required={true}
value={areaValue}  
onChange={(e)=>  handleAreaChange(e)}
placeholder={defArea}
  options={areas}/>


</div>




<div className='col-12'>
<label>{t("profile.address_details") +" "+"("+t("profile.ar")+")"}</label>
<textarea ref={arRef} defaultValue={address.arAddress}/>
</div>
<div className='col-12'>
<label>{t("profile.address_details") +" "+"("+t("profile.en")+")"}</label>
<textarea ref={enRef} defaultValue={address.enAddress}/>
</div>
<button type='submit'>{t("profile.edit")}</button>


</div>
</form></div>
</div>

  
       </div>}</div>
    );
  }
   
  export default EditAddress;
  
  