import "../Styles/Offers.css";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { BeatLoader } from "react-spinners";
import { banners } from "../api";
import { Swiper, SwiperSlide } from "swiper/react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { GrClear } from "react-icons/gr";
// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";

function Offers() {
  const { t, i18n } = useTranslation();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [lang, setLang] = useState(i18n.language === "ar");
  const [originalBanner, setOriginalBanner] = useState([]); // To store the original fetched data
  const [filteredBanner, setFilteredBanner] = useState([]); // To store the filtered data
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("all");

  const categories = [
    { id: "all", labelEn: "All", labelAr: "الكل" },
    { id: "offers", labelEn: "Offers", labelAr: "العروض" },
    { id: "new", labelEn: "New Alromansiah", labelAr: "جديد الرومانسية" },
    { id: "juices", labelEn: "Juices", labelAr: "العصائر" },
    { id: "calories", labelEn: "Calories", labelAr: "السعرات الحرارية" },
    { id: "snacks", labelEn: "Awafi", labelAr: "عوافي" },
  ];

  const handleSelect = (id) => {
    setSelectedCategory(id);

    if (id === "all") {
      setFilteredBanner(originalBanner); 
    } else {
      const category = categories.find(cat => cat.id === id);
      const categoryName = category.labelAr;
      const newFilter = originalBanner.filter((res) => res.link === categoryName);
      setFilteredBanner(newFilter);
    }
  };

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem("token");

    banners(token, i18n.language).then((response) => {
      setLoading(false);
      setOriginalBanner(response.data.data); // Store the original data
      setFilteredBanner(response.data.data); // Initially, show all data
      setLang(i18n.language === "ar");
    });
  }, [i18n.language]);

  return (
    <div className="questions">
      {loading ? (
        <div style={{ height: "60vh" }} className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <>
          <div className="categories-offers">
            {categories.map((category) => (
              <div
                key={category.id}
                className={`cate-item ${
                  selectedCategory === category.id ? "cate-selected" : "cate-default"
                }`}
                onClick={() => handleSelect(category.id)}
              >
                <p>{lang ? category.labelAr : category.labelEn}</p>
              </div>
            ))}
          </div>
          {filteredBanner.length === 0 ? (
            <div className="noQuestions">
              <GrClear />
              <p style={{ textAlign: "center" }} className="question-title">
                {t("addOrder.noResult")}
              </p>
            </div>
          ) : null}
          {isOpen && (
            <Lightbox
              mainSrc={filteredBanner[index].image}
              nextSrc={filteredBanner[(index + 1) % filteredBanner.length].image}
              prevSrc={filteredBanner[(index + filteredBanner.length - 1) % filteredBanner.length].image}
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() =>
                setIndex((index + filteredBanner.length - 1) % filteredBanner.length)
              }
              onMoveNextRequest={() => setIndex((index + 1) % filteredBanner.length)}
            />
          )}

          <div className="container-fluid">
            <div className="row gy-4">
              {filteredBanner &&
                filteredBanner.map((data, index) => (
                  <div className="col-md-4 col-sm-6" key={index}>
                    <div
                      className="offerImg"
                      onClick={() => {
                        setIsOpen(true);
                        setIndex(index);
                      }}
                    >
                      <div
                        className="customerSharesImg"
                        style={{ backgroundImage: `url(${data.image})` }}
                      ></div>
                      {/* {data.link !== null && data.link !== "" ? <p>{data.link}</p> : null} */}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Offers;
