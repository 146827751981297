import '../Styles/Dashboard.css';

import { useTranslation } from 'react-i18next';
import { useState } from 'react';
function QuantityInput(props) {
   
    const { t, i18n } = useTranslation();
const [quantity,setQuantity]=useState(props.availableQuantity)
  
function handleChange(value){
    if(value>=0.1 || value == 0){
        
        props.handleChangeQuan(value,props.id);
        
        setQuantity(value) } 
    else{
        return null
    }
}

    return (
        <input type="number" value={quantity} onChange={(e)=>handleChange(e.target.value) }/>
        
  
    );
  }
  
  export default QuantityInput;
  
  