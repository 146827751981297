import '../Styles/Dashboard.css';
import { useTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input';
import { useState } from 'react';
import { otp } from '../api';
function Verify(props) {
   
  const { t, i18n } = useTranslation();
 
  const [otpVerify,setOtp]=useState("");
  const [msgAr, setMsgAr]=useState();
  const [loading, setLoading]=useState(false);

  const [msgEn, setMsgEn]=useState();
  const [errorMsgModel, setErrorMsgModel]=useState(false);
  

  const handleChange = (otpVerify) =>{ setOtp( otpVerify );

   } 

   function handleOtp(){
    setLoading(true)
      const token=localStorage.getItem("token");
   
      otp(token,props.id,otpVerify.toString(),i18n.language).then((response) => {
       
        props.setVerify(!props.verify)
      props.setModal(false)
     
    }).catch(function (error) {
     
       
      
setMsgAr(error.response.data.error);
setMsgEn(error.response.data.error);
setErrorMsgModel(true);
                                    
setLoading(false);

    })
    }
          async function onSubmit(event){
        
                                     }

  return (
      <div >
       
      <div onClick={()=>props.setModal(false)}  className="modalOverlay"></div>
      <div style={{height:'unset'}} className="modal-content errModal usrType" >

<p className='alrt' style={{textAlign:'start'}}>{t("register2.authorize")}</p>


<div style={{direction:"ltr",width:"fit-content"}} className='clientCode otp'>
<OtpInput
   value={otpVerify}
   onChange={(otpVerify)=>handleChange(otpVerify)}
      numInputs={5} 
     
    />
</div>


{i18n.language=="ar"?
<span className='err' style={{margin:"1em"}}> {errorMsgModel? msgAr:null}</span>:
<span className='err' style={{margin:"1em"}}>{errorMsgModel? msgEn:null}</span>}
<button  disabled={loading} className='confirm' onClick={handleOtp} style={{width:"20%"}}>{t("Login.confirm")}</button>
<span style={{margin:"0 1em",display:"inline-block"}}></span>
<button   onClick={()=>props.setModal(false)}  className="cancelEdit" >{t("menu.cancel")}</button>

      </div>
    </div>

  );
}

export default Verify;

