import '../Styles/Areas.css';

import Navbar from '../Components/Navbar';
import { useTranslation } from 'react-i18next';
import { areas,cities,branches, allBranches} from '../api';
import { BeatLoader } from 'react-spinners';
import { useState,useEffect,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import { AiOutlineSearch } from 'react-icons/ai';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import PaginationItem from '@mui/material/PaginationItem';
import {GrPowerReset}  from "react-icons/gr";
import { propTypes } from 'react-bootstrap/esm/Image';

function Areas(props) { 
    
    const { t, i18n } = useTranslation();
const [loading,setLoading]=useState(true)
const[tableLoading,setTableLoading]=useState(false)

const[city,setCity]=useState()
const[cityId,setCityId]=useState()
const[branchId,setBranchId]=useState()

const [area,setArea]=useState([])
const [brnchs, setBrnchs] = useState([])
const [allBrnchs, setAllBrnchs] = useState([])
const [searchTerm,SetSearchTerm]=useState('');
const [areaValue,setAreaValue]=useState("")
const[sortAreas,setSortAreas]=useState()
const[noArea,setNoArea]=useState(false)
let history = useNavigate();
const[page,setPage]=useState(1)
const[resp,setResp]=useState()
const nameRef=useRef()

const token= localStorage.getItem("token");

    

//cities  returns all cities
//allBranches returns all branches
//areas takes cityId,branchId(for filtering so initially sent as null to show all data),page

useEffect(() => {

  
  cities(token).then((response) => {
    setCity(response.data.data)
  
  }).catch(function(error){
  if (error.response.status === 401) {
     history("/")
  }
  
  
  });
  allBranches(token).then((response) => {
    setAllBrnchs(response.data.data)
  
  }).catch(function(error){
  if (error.response.status === 401) {
     history("/")
  }
  
  
  });
   
  areas(token,null,null,1).then((response) => {
    setResp(response.data.data)
    setArea(Object.values(response.data.data.data))

  
    setLoading(false)
  }).catch(function(error){
    if (error.response.status === 401) {
       history("/")
    }
 
});
 
       
  
},[])




//on changing city
//selected branch is removed because branches depend on chosen city
//branches api call takes chosen city id and return its branches
function handleCityChange(e){

  const token= localStorage.getItem("token");

  let selectElement=   document.getElementById("branch")
  selectElement.selectedIndex = null
  setBranchId(null)

  if(e.target.value=="all"){
   
    setCityId(null)
  }
  
  else{ 
        setCityId(e.target.value)
      
        branches(token,e.target.value).then((response) => {
          setBrnchs(response.data.data)
        
        }).catch(function(error){
        if (error.response.status === 401) {
           history("/")
        }
        
        
        });
    }


} 



//on page change or on filtering
//on page change => changes page
//on filtering => set page to page number 1
//areas api call can filter by (cityId,branchId and area name)
function changePage(page){
  window.scrollTo(0, 0);

  setLoading(true)
setPage(page)
areas(token,cityId,branchId,page,nameRef.current.value).then((response) => {
  setResp(response.data.data)

  if( response.data.data.toString()==[]){ //this will cause an error otherwise
    setArea([])
  

   }
   else{
  setArea(Object.values(response.data.data.data))
}

  setLoading(false)
}).catch(function(error){
  if (error.response.status === 401) {
     history("/")
  }

});

}


//clears input fields
//areas api is called to get areas with no filtres
//sets page to page number 1
function resetData(){


  var selectElement = document.getElementsByClassName("areaSelect");

  for (var i = 0; i < selectElement.length; i++) {
     selectElement[i].selectedIndex =null

  } 
  setBranchId(null)
  setCityId(null)
  setBrnchs([])
  nameRef.current.value=null;
  

  window.scrollTo(0, 0);

  setLoading(true)
setPage(1)
areas(token,null,null,1,null).then((response) => {
  setResp(response.data.data)

  if( response.data.data.toString()==[]){
    setArea([])
  

   }
   else{
  setArea(Object.values(response.data.data.data))
   }
  setLoading(false)
}).catch(function(error){
  if (error.response.status === 401) {
     history("/")
  }

});

}


    return (
      <div className='areaContainer' style={{padding:"0 2em",paddingBottom:"7em"}}>
          
          
          <div style={{marginTop:"1em"}} className='container-fluid'>

          <div className='container-fluid filtering'>
     

     <div className='row '>
     
     <div className='col-12'>
     <div className='container-fluid'>
     <div className='row gy-3'>
     <div className='col-12 filterTitile'>
       <p >{t("orders.filter")}</p>
     </div>
     
     <div className='col-md-3 col-sm-4 col-12'>
  
     <input ref={nameRef} placeholder={t("areas.srch")}/> 
     </div>
     <div className='col-md-2 col-sm-4 col-6'>
     <select  defaultValue="" className='areaSelect' onChange={(e)=>handleCityChange(e)}>
    <option value="" disabled>{t("areas.city")}</option>
    <option value="all" >{t("areas.all")}</option> 
{city && city.map((data, index) => 

<option value={data.id} key={data.id}>{i18n.language=="ar"?data.arName:data.enName}</option>

)} 
</select>

     </div>
     <div className='col-md-2 col-sm-4 col-6'>
     <select id="branch" className='areaSelect' defaultValue="" onChange={(e)=>{e.target.value=="all"?setBranchId(null):setBranchId(e.target.value)}}>
    <option value="" disabled>{t("profile.branch")}</option>
  {brnchs.length>0? <option value="all" >{t("areas.all")}</option> :null}

{brnchs && brnchs.map((data, index) => 

<option value={data.id} key={data.id}>{i18n.language=="ar"?data.arName:data.enName}</option>

)}
</select>
     </div>
     <div className='col-md-2 col-sm-4 col-6'>

    <button disabled={loading} onClick={()=>changePage(1)} className='filter areaFilter'>{t("orders.filter")}</button>

     </div>
     <div className='col-md-3 col-sm-5 col-6' style={{textAlign:"start"}}>
     <button  onClick={resetData} className='reset'>{t("offer.reset")} <GrPowerReset color='#ffff'/></button>

     </div>
     </div></div></div></div></div>

     <p className='title'>{t("areas.areas")}</p>

        {loading?
               <div style={{height:"60vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:

<>
        <div   id="wrap" className={'areas tableFixHead '}>
        
        <table  className='areaTable table'>
          <thead style={props.news==false?{top:"67px"}:{top:"90px"}}>

    <th>#</th>
    <th>{t("areas.area")}</th>
    <th>{t("areas.city")}</th>
    <th>{t("orders.branches")}</th>

    <th>{t("areas.ordinary")}</th>
    <th>{t("areas.fast")}</th>
    <th>{t("areas.min")}</th>
    <th>{t("areas.time")}</th>
    <th>{t("areas.distance")}</th>
</thead>

<tbody>
{noArea || area.length<=0?


<tr className='rowData'>

<td colSpan={9}>{t("addOrder.noResult")}</td>
</tr>
: null}


{area && area.filter((data)=>{
    if(searchTerm===""){ //for filtering products on search
        return data;
    } 
    else if(data.enName.toLowerCase().includes(searchTerm.toLowerCase())|| data.arName.toLowerCase().includes(searchTerm.toLowerCase())){
   
   return data
   }
  
  
 }).map((data,index)=>


<tr className='rowData' key={index}>

<td>{data.id}</td>
<td>{i18n.language=="ar"?data.arName:data.enName}</td>
<td>{i18n.language=="ar"?data.city.arName:data.city.enName}</td>
<td>
 {data.branches.map((data,index,arr)=>
<>
{i18n.language=="ar"?data.arName:data.enName}
  {index+1< arr.length? ' - ' : null}
  
  </>
)
} </td>

<td>{data.ordinaryDeliveryPrice}</td>
<td>{data.fastDeliveryPrice}</td>
<td>{data.minOrder}</td>
<td>{data.deliveryTime}</td>
<td>{data.distance}</td>


</tr>

  )
} 
</tbody>

        </table>
       
        </div>
        
        
        
        
        {noArea || area.length<=0? null:
       <Stack spacing={0}>
      <Pagination 
      renderItem={(item) => (
        <PaginationItem
          slots={{ previous: i18n.language=="ar"?'prevAr':"prevEn" , next: i18n.language=="ar"?'nextAr':"nextEn" }}
          {...item}
        />)}

      className={i18n.language=="ar"?'arArrow':null} defaultPage={1} page={page}  count={ resp.last_page} onChange={(e,value)=>{ changePage(value)}} shape="rounded" variant="outlined" 
      
      />
      </Stack>
      
    }
</>}</div>
       </div>
    );
  }
  
  export default Areas;
  
  