import '../Styles/CustomerOrders.css';

import Navbar from '../Components/Navbar';
import { useTranslation } from 'react-i18next';
import { FiEdit } from 'react-icons/fi';
import { BsGlobe2,BsInfoCircle } from 'react-icons/bs';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { RiDeleteBin5Line } from 'react-icons/ri';
import bckg from "../Images/order.svg"
import DelAddress from '../Components/DelAddress';
import ErrorModal from '../Components/ErrorModal';
import { Link } from 'react-router-dom';
import CustomerData from '../Components/CustomerData';
import {useState,useEffect}  from 'react';
import { useNavigate } from "react-router-dom";
import { customer,customerAddresses,delAddresses, getOrganizations } from '../api';
import { BeatLoader } from 'react-spinners';
import { generatePath } from 'react-router-dom';
import icn1 from "../Images/customerProfile.svg"
import icn2 from "../Images/orderDetailsIconInactive.svg"
import icn3 from "../Images/menuIconUnactive.svg"
import icn4 from "../Images/basketIconUnactive.svg"
function CustomerTax(props) {
   const [modal,setModal]=useState(false)
    const { t, i18n } = useTranslation();
    const[arMsg,setArMsg]=useState(false)
    const[enMsg,setEnMsg]=useState(false)
const[errModal,setErrModal]=useState(false)
    const[loading,setLoading]=useState(true);
    
    const [organizationArr,setOrganizationArr]=useState([])
    const customerId= localStorage.getItem("customerId");
   let history= useNavigate();
     
  
      //customerAddresses=> takes customer id and user token and returns customer addresses
   useEffect(() => {
   
       const token= localStorage.getItem("token");
     
       
 
      
       getOrganizations(token,customerId).then((response) => {
        setOrganizationArr(response.data.data)
     
     setLoading(false)
     }).catch(function(error){
     if (error.response.status === 401) {
        history("/")
     }
     else if(error.response.status === 403){
      props.setErr(true)
      props.setMsg(t('validation.noPermission'))
      props.setStyle({top:"50%"})
      }
          
      
      history("/customer-orders")
     });
      
     },[]) 


    return (
      <div >
         <div className='processNav' >
          <Link to="/customer-orders">
<div>
<img style={{cursor:"pointer"}} src={icn1}/>

<p>{t("addOrder.profile")}</p>
</div></Link>
<div className='dottedLine'></div>
<Link to="/add-order">
<div>
<img style={{cursor:"pointer"}}  src={icn2}/>
<p  style={{color:"white"}}>{t("addOrder.details")}</p></div>
</Link>
<div className='dottedLine'></div>
<div>
<img src={icn3}/>
<p style={{color:"white"}}>{t("addOrder.menu")}</p></div>

<div className='dottedLine'></div>
<div>
<img src={icn4}/>
<p style={{color:"white"}}>{t("addOrder.send")}</p></div>
  </div>
          {errModal?<ErrorModal setModal={setErrModal} msg={i18n.language=="ar"?arMsg:enMsg}/>:null}
          {loading?
               <div style={{height:"80vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:

<div style={{marginTop:"2em"}} className='container'>
          
<CustomerData loading={loading} active={5}/>

     

<div className='customerBody' style={{paddingBottom:"0"}}>
    <div className='addressTop' style={{margin:"1em 0"}}>
<p style={{marginTop:"0"}}></p>
<Link to="/add-tax" onClick={()=>localStorage.setItem("prev","tax")}><button>{t("addOrder.addTax")}<span></span><AiOutlinePlusCircle className='add-address' size={20}/></button></Link>
</div>
</div>

  <div style={{paddingBottom:"10em"}} className="row gy-4 ">

  {organizationArr && organizationArr.map((data, index) => 
  
  <div className="col-md-6 " key={index}>
  <div className="p-3  taxCard">
 
      <div className='row invoiceData taxData'>
      <div className='col-12 taxTitle '>
    <p >{t("addOrder.orgName")} : {data.organizationName} </p>

    </div>

      <div className='col-12'>
    <p >{t("addOrder.name")} :
    <span>{data.fullName}
      </span>  </p>

    </div>
    <div className='col-12'>
    <p >{t("addOrder.phone")} :<span>{data.phone}
      </span>  </p>

    </div>
    <div className='col-12'>
    <p >{t("addOrder.email")} : <span>
    {data.email}
      </span> </p>

    </div>
    <div className='col-12'>
    <p >{t("addOrder.commercial")} : <span>
    {data.commericalRegisterNo} 
      </span></p>

    </div>
    <div className='col-12'>
    <p >{t("addOrder.taxNumber")} :   <span>
    {data.taxNumber} 
      </span></p>

    </div>
    <div className='col-12'>
    <p >{t("addOrder.city")} : 
    <span>
    {i18n.language=="ar"?data.city.arName:data.city.enName} 
      </span>   </p>

    </div>
   
 
    <div className='col-12'>
    <p >{t("addOrder.street")} :     <span>


{data.streetName} </span></p>

    </div>
    {data.postalCode!==null && data.postalCode!==""?
    <div className='col-12'>
    <p >{t("addOrder.postal")} :    <span>
    {data.postalCode}
      </span> </p>

    </div>:null}
    <div className='col-12'>
    <p >{t("addOrder.organizationAddress")} : 
    <span>
      {data.address}</span> </p>

    </div>
</div>



 
  </div>
</div>
  
  
  )}
  {organizationArr.length==0?
     <p style={{textAlign:"center"}} className="question-title">
             {t("addOrder.noResult")}</p>:null}

  
</div>
       </div>}</div>
    );
  }
  
  export default CustomerTax;
  
  