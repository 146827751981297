import "../Styles/MyOrders.css";

import Navbar from "../Components/Navbar";
import { useTranslation } from "react-i18next";
import bckg from "../Images/order.svg";
import CleartCart from "../Components/CleartCart";
import status1Active from "../Images/status1-active.svg";
import status1UnActive from "../Images/status1-unactive.svg";
import status2Active from "../Images/status2-active.svg";
import status2UnActive from "../Images/status2-unactive.svg";
import status3Active from "../Images/status3-active.svg";
import status3UnActive from "../Images/status3-unactive.svg";
import status4Active from "../Images/status4-active.svg";
import status4UnActive from "../Images/status4-unactive.svg";
import { RiArrowRightSFill, RiArrowLeftSFill } from "react-icons/ri";
import { BeatLoader } from "react-spinners";
import { useState, useEffect, useRef } from "react";
import Toast from "react-bootstrap/Toast";

import {
  singleOrder,
  edit_order,
  Get_Basket,
  isAvailTable,
  orderBranches,
  accept_order,
  invoiceData,
  changePaymentMethod,
  callOrder,
} from "../api";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import ErrorModal from "../Components/ErrorModal";
import { MDBDataTable } from "mdbreact";
import { BsArrowRightShort } from "react-icons/bs";
import { BsArrowLeftShort } from "react-icons/bs";

function OrderDetails() {
  let history = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState();
  const [errModal, setErrModal] = useState(false);
  const [arMsg, setArMsg] = useState();
  const [enMsg, setEnMsg] = useState();
  const [status, setStatus] = useState();
  const [repeatType, setRepeatType] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [toast, setToast] = useState(null);
  const [notify, setNotify] = useState(false);
  const [openPay, setOpenPay] = useState(false);

  const [orderMeals, setOrderMeals] = useState([]);
  const [partialPayments, setPartialPayments] = useState([]);

  const [clearModal, setClearModal] = useState(false);
  const [basket, setBasket] = useState([]);
  const [timeDifference, setTimeDifference] = useState(false);
  const [createdAt, setCreatedAt] = useState();
  const [ws, setWs] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const modalRef = useRef(null);
  const [selectedPayment, setSelectedPayment] = useState("cash");
  const [refresh, setRefresh] = useState(false);

  const { id } = useParams();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    if (localStorage.getItem("type") === "cashier") {
      connectWebSocket();
      return () => {
        if (ws) {
          ws.close();
          console.log("WebSocket connection closed.");
        }
      };
    }
  }, []);

  const connectWebSocket = () => {
    const socket = new WebSocket("ws://localhost:7001/intersoft_integration");
    socket.onopen = () => {
      setWs(socket);
    };
    socket.onmessage = (evt) => {
      console.log("Message received:", evt.data);
      handlePosResponse(evt.data, localStorage.getItem("orderId"));
    };
    socket.onerror = (evt) => {
      console.error("WebSocket error:", evt.message);
      showError(
        "حدث خطأ في WebSocket. حاول مرة أخرى.",
        "WebSocket error occurred. Please try again."
      );
    };
    socket.onclose = () => {
      setStatus("disconnected");
      showError(
        "حدث خطأ في WebSocket. حاول مرة أخرى.",
        "WebSocket error occurred. Please try again."
      );
      console.log("WebSocket connection closed.");
      setWs(null);
    };
  };

  const handlePosResponse = (message, orderId) => {
    const transactionIdMatch = message.match(/<RRN>(.*?)<\/RRN>/);
    const transactionId = transactionIdMatch ? transactionIdMatch[1] : null;
    const cardSchemeMatch = message.match(/<CardScheme.*?English="(.*?)"/);
    const cardScheme = cardSchemeMatch ? cardSchemeMatch[1] : null;

    // Save CardScheme in local storage
    if (cardScheme) {
      localStorage.setItem("cardScheme", cardScheme);
    }

    if (message.includes('<Result Arabic="مقبولة" English="APPROVED"/>')) {
      if (transactionId) {
        acceptOrder(orderId, transactionId);
      } else {
        showError(
          "لم يتم العثور على معرف المعاملة.",
          "Transaction ID not found."
        );
      }
    } else if (
      message.includes('<Result Arabic="مرفوضة" English="DECLINED"/>')
    ) {
      showError("تم رفض الدفع.", "Payment declined.");
    } else {
      showError(
        "فشل الدفع. حاول مرة أخرى.",
        "Payment failed. Please try again."
      );
    }
  };
  const acceptOrder = (orderId, transactionId) => {
    const token = window.localStorage.getItem("token");

    const data = {
      transactionId: transactionId,
      cardType: localStorage.getItem("cardScheme"),
      platform: "agent",
    };

    accept_order(data, token, orderId)
      .then((response) => {
        handleOrderSuccess(response);
      })
      .catch((error) => {
        showError(
          "حدث خطأ في قبول الطلب. حاول مرة أخرى.",
          "Error accepting order. Please try again."
        );
        console.error("Error accepting order:", error);
      });
  };
  const handleOrderSuccess = (response) => {
    const token = window.localStorage.getItem("token");

    localStorage.removeItem("offer");
    localStorage.removeItem("orderDate");
    // localStorage.removeItem("customerId");
    localStorage.removeItem("orderTime");
    localStorage.removeItem("tableNum");
    localStorage.removeItem("displayId");
    localStorage.removeItem("orderType");
    localStorage.removeItem("now");
    localStorage.removeItem("orderSource");
    localStorage.removeItem("branchOrderTime");

    if (localStorage.getItem("type") !== "cashier") {
      localStorage.removeItem("branchId");
    }
    localStorage.removeItem("accountType");
    localStorage.removeItem("organizationId");
    localStorage.removeItem("orderDistrictId");
    localStorage.removeItem("addressId");
    localStorage.removeItem("externalOrderId");

    localStorage.setItem("orderId", response.data.data.orderId);

    localStorage.removeItem("notes");
    localStorage.removeItem("tableDiscount");
    localStorage.removeItem("tableDiscountType");
    localStorage.removeItem("tableAccount");

    localStorage.removeItem("PaymentMethodDisc");
    localStorage.removeItem("promoCode");

    localStorage.removeItem("notesCheckbox");

    localStorage.setItem("discount", "false");
    localStorage.removeItem("PaymentMethodDiscContd");
  };

  const showError = (arMsg, enMsg) => {
    setErrModal(true);
    setArMsg(arMsg);
    setEnMsg(enMsg);
    setTimeout(() => {
      setErrModal(false);
    }, 2000);
    setSubmitLoading(false);
  };
  const handlePosPayment = () => {
    if (ws) {
      ws.send(
        JSON.stringify({
          Command: "SALE",
          Amount: (order.originalPrice * 100).toString(),
          OriginalRRN: "",
          MaskedPan: "",
          PrintFlag: "1",
          AdditionalData: "",
        })
      );
    }
  };

  useEffect(() => {}, [refresh, order]);

  useEffect(() => {
    setSelectedPayment("cash");
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");

    singleOrder(token, id)
      .then((response) => {
        const createdDate = response.data.data.createdAt;
        const before = new Date(
          `${
            monthNames[parseInt(createdDate.slice(5, 7) - 1)]
          } ${createdDate.slice(8, 10)}, ${createdDate.slice(
            0,
            4
          )} ${createdDate.slice(11, 13)}:${createdDate.slice(14, 16)}`
        );
        setCreatedAt(before);
        if (diff_hours(before) > 20) {
          setTimeDifference(true);
        } else {
          setTimeDifference(false);
        }
        setPartialPayments(response.data.data.partialPayments);
        console.log(response.data.data.partialPayments);

        setOrder(response.data.data);
        console.log(response.data.data);

        setOrderMeals(response.data.data.products);

        let ordersArr = [];

        for (let i = 0; i < response.data.data.products.length; i++) {
          let extrasArArr = [];
          let extrasEnArr = [];
          for (
            let j = 0;
            j < response.data.data.products[i].extras.length;
            j++
          ) {
            extrasArArr.push(response.data.data.products[i].extras[j].arName);
            extrasEnArr.push(response.data.data.products[i].extras[j].enName);
          }

          let obj = {};

          obj.productArName = response.data.data.products[i].productArName;
          obj.productEnName = response.data.data.products[i].productEnName;
          obj.sizeArName = response.data.data.products[i].sizeArName;
          obj.sizeEnName = response.data.data.products[i].sizeEnName;

          obj.quantity = response.data.data.products[i].quantity;
          obj.price = response.data.data.products[i].price;
          obj.additionals =
            response.data.data.products[i].additionals.length > 0
              ? i18n.language == "ar"
                ? response.data.data.products[i].additionals[0].additionalArName
                : response.data.data.products[i].additionals[0].additionalEnName
              : [];
          obj.extras =
            response.data.data.products[i].extras.length > 0
              ? i18n.language == "ar"
                ? extrasArArr.toString()
                : extrasEnArr.toString()
              : [];

          ordersArr.push(obj);
        }

        setOrderMeals(ordersArr);

        Get_Basket(
          token,
          response.data.data.user.id,
          " ",
          localStorage.getItem("type")
        )
          .then((response) => {
            setBasket(response.data.data.cart.meals);

            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          history("/");
        }
      });
  }, [refresh]);

  const handlePrint = async () => {
    const invoiceData = await getInvoice();
    if (invoiceData) {
      console.log(invoiceData);
      history("/order/invoice", { state: { invoice: invoiceData } });
    }
  };

  function diff_hours(dt1) {
    var today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();
    let day = today.getDate();
    let hour = today.getHours();
    let minute = today.getMinutes();

    let dt2 = new Date(
      `${monthNames[month]} ${day}, ${year} ${hour}:${minute}`
    );

    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;

    return Math.abs(Math.round(diff));
  }

  function createCart() {
    console.log("1");
    localStorage.removeItem("cartItems");

    const customerId = localStorage.getItem("customerId");
    const userId = localStorage.getItem("userId");
    const isAuthenticated = customerId !== null || userId !== null;

    if (!isAuthenticated) {
      // Handle not authenticated case
      return;
    }

    let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];

    order.products.forEach((product) => {
      // Initialize base price as the product price
      let basePrice = 0;
      console.log(product);

      // Construct the size object
      const size = {
        sizeId: product.sizeId,
        arName: product.sizeArName,
        enName: product.sizeEnName,
        price: parseFloat(product.unitPriceWithTax) || 0,
      };
      console.log(`Size price: ${size.price}`);

      // Initialize totalPrice with the base price
      let totalPrice = basePrice;

      // Add the size price to totalPrice only once
      totalPrice += size.price;

      // Initialize the selectedAdditionalObj
      let selectedAdditionalObj = null;
      let additionals = [];

      if (product.additionals && product.additionals.length > 0) {
        additionals = product.additionals.map(
          (additional) => additional.additionalId
        );

        const additional = product.additionals.find(
          (additional) => additional.additionalId
        );

        if (additional) {
          console.log(`Additional price before: ${totalPrice}`);
          totalPrice += parseFloat(additional.price) || 0;
          selectedAdditionalObj = {
            arName: additional.additionalArName,
            calories: additional.calories || "0",
            canAddToCart: true,
            enName: additional.additionalEnName,
            id: additional.additionalId,
            index: 1, // Adjust this as needed based on your logic
            price: additional.price,
            quantity: additional.quantity || 1, // Adjust this as needed based on your logic
          };
          console.log(`Total price after adding additional: ${totalPrice}`);
        }
      }

      // Construct data object
      const data = {
        branchId: parseInt(localStorage.getItem("branchId")),
        typeId: parseInt(localStorage.getItem("orderType")),
        addressId:
          localStorage.getItem("orderType") === "2"
            ? parseInt(localStorage.getItem("addressId"))
            : null,
        platform: "agent",
        appVersion: "1.0.0",
        productId: product.productId,
        quantity: product.quantity,
        userId: customerId !== null ? customerId : userId,
        sizeId: product.sizeId,
        sized: size,
        selectedAddtionObj: selectedAdditionalObj,
        additionals: additionals,
        extras: product.extras || [],
        accountType: localStorage.getItem("type"),
        arCurrency: "SAR",
        arDescription: product.productArDescription || "",
        arName: product.productArName || "",
        category: product.category || "Example Category",
        active: true,
        applications: [],
        featured: false,
        featuredImage: product.image,
        id: product.productId,
        image: product.image,
        price: totalPrice,
        name: product.productEnName,
        nameTranslations: {
          en: product.productEnName,
          ar: product.productArName || "",
        },
        popupMessageAr: "Example message in Arabic",
        popupMessageEn: "Example message in English",
        popupShow: false,
        products: [],
        description: product.productEnDescription || "",
        enCurrency: "SAR",
        enDescription: product.productEnDescription || "",
        enName: product.productEnName,
        firstPeriodTimeFrom: null,
        firstPeriodTimeTo: null,
        secondPeriodTimeFrom: null,
        secondPeriodTimeTo: null,
        sizes: product.sizes,
        calories: product.calories,
        canAddToCart: true,
        isAdditionalRequired: false,
        isDefault: true,
        sizeFactor: 1,
        typeArName: "Example Type Ar",
        typeEnName: "Example Type En",
        typeId: product.typeId,
        paymentMethod: order.paymentMethod,
      };

      // Check if the item already exists in the cart
      const existingItemIndex = cartItems.findIndex(
        (item) =>
          item.productId === data.productId &&
          item.sizeId === data.sizeId &&
          JSON.stringify(item.additionals) === JSON.stringify(data.additionals)
      );

      if (existingItemIndex > -1) {
        // Item exists, update the quantity
        cartItems[existingItemIndex].quantity += data.quantity;
      } else {
        // Item doesn't exist, add it to the cart
        cartItems.push(data);
      }
    });

    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }

  function handleEditForCashier(edit_type) {
    localStorage.removeItem("cancelOrderId");

    setLoading(true);
    setClearModal(false);
    const token = localStorage.getItem("token");

    edit_order(token, order.id, order.user.id).then((response) => {
      if (response.data.data["table"]) {
        localStorage.setItem(
          "selectedTable",
          response.data.data["table"]["table"]["name"]
        );
        localStorage.setItem(
          "selectedTableId",
          response.data.data["table"]["table"]["id"]
        );
        localStorage.setItem(
          "selectedPriceTable",
          response.data.data["table"]['tablePrice']
        );

        
      }

      localStorage.setItem("discount", "false");
      localStorage.removeItem("PaymentMethodDisc");
      localStorage.removeItem("PaymentMethodDiscContd");
      localStorage.removeItem("promoCode");
      localStorage.removeItem("notes");
      localStorage.removeItem("notesCheckbox");
      localStorage.removeItem("tableDiscount");
      localStorage.removeItem("tableDiscountType");
      localStorage.removeItem("tableAccount");
      localStorage.removeItem("tableNum", order.tableId);
      localStorage.removeItem("displayId", order.tableDisplayId);

      localStorage.setItem("customerId", order.user.id);
      localStorage.setItem("customerName", order.user.name);
      localStorage.setItem("customerNotes", order.user.notes);
      localStorage.setItem("customerPhone", order.user.phone);
      localStorage.setItem("customerPhoto", order.user.photo);
      localStorage.setItem("customerAwafi", order.user.joinLoyalty);

      localStorage.setItem("arAddress", order.addressArAddress);
      localStorage.setItem("enAddress", order.addressEnAddress);

      localStorage.setItem("branchNameAr", order.branchArName);
      localStorage.setItem("branchNameEn", order.branchEnName);

      localStorage.setItem("branchId", order.branchId);
      localStorage.setItem("now", order.now);
      localStorage.setItem("orderDate", order.date);
      localStorage.setItem("orderTime", order.time);
      localStorage.setItem("editPaymentMethod", order.paymentMethod);
      localStorage.setItem("editNotes", order.notes);

      if (edit_type == "cancel") {
        console.log("set_orderID");
        localStorage.setItem("cancelOrderId", order.id);
      }
      localStorage.setItem(
        "orderSourceObj",
        JSON.stringify({
          id: 6,
          name: "Agent",
          code: 5,
          active: 1,
          allowPaid: 0,
          allowCash: 1,
          allowPaymentOnline: 1,
        })
      );
      localStorage.setItem("orderSource", 5);
      localStorage.setItem("orderType", order.typeId);
      if (order.typeId == 2) {
        localStorage.setItem("addressId", order.addressId);
      }

      if (order.typeId == 5 && order.statusId == 9) {
        localStorage.setItem("cancelOrderId", order.id);

        createCart();
        history("/menu");
        localStorage.setItem("tableNum", 0);
        localStorage.setItem("displayId", 0);
      } else if (order.typeId == 5) {
        localStorage.setItem("cancelOrderId", order.id);

        createCart();
        history("/menu");

        // isAvailTable(token, parseInt(order.branchId), parseInt(order.tableId))
        //   .then((response) => {
        //     if (response.data.data == true) {
        //       localStorage.setItem("tableNum", order.tableId);
        //       localStorage.setItem("displayId", order.tableDisplayId);
        //       localStorage.setItem("cancelOrderId", order.id);

        //       createCart();
        //       history("/menu");
        //     } else {
        //       setArMsg(
        //         `الجلسه ${order.tableDisplayId} غير متاحه حاليا٬ يمكنك اختيار جلسه آخرى`
        //       );
        //       setEnMsg(
        //         `Table ${order.tableDisplayId} isn’t available now, you can choose another table.`
        //       );

        //       setErrModal(true);

        //       setLoading(false);
        //       setTimeout(function () {
        //         history("/add-order");
        //       }, 2700);
        //     }
        //   })
        //   .catch(function (error) {
        //     if (error.response.status === 401) {
        //       history("/");
        //     }
        //   });
      } else {
        localStorage.setItem("cancelOrderId", order.id);

        createCart();
        history("/menu");
      }
    });
  }

  function handleEdit(edit_type) {
    localStorage.removeItem("cancelOrderId");

    if (diff_hours(createdAt) > 20 && edit_type == "cancel") {
      setTimeDifference(true);
    } else {
      setLoading(true);
      setClearModal(false);
      const token = localStorage.getItem("token");

      edit_order(token, order.id, order.user.id).then((response) => {
        localStorage.setItem("discount", "false");
        localStorage.removeItem("PaymentMethodDisc");
        localStorage.removeItem("PaymentMethodDiscContd");
        localStorage.removeItem("promoCode");
        localStorage.removeItem("notes");
        localStorage.removeItem("notesCheckbox");
        localStorage.removeItem("tableDiscount");
        localStorage.removeItem("tableDiscountType");
        localStorage.removeItem("tableAccount");
        localStorage.removeItem("tableNum", order.tableId);
        localStorage.removeItem("displayId", order.tableDisplayId);

        localStorage.setItem("customerId", order.user.id);
        localStorage.setItem("customerName", order.user.name);
        localStorage.setItem("customerNotes", order.user.notes);
        localStorage.setItem("customerPhone", order.user.phone);
        localStorage.setItem("customerPhoto", order.user.photo);
        localStorage.setItem("customerAwafi", order.user.joinLoyalty);

        localStorage.setItem("arAddress", order.addressArAddress);
        localStorage.setItem("enAddress", order.addressEnAddress);

        localStorage.setItem("branchNameAr", order.branchArName);
        localStorage.setItem("branchNameEn", order.branchEnName);

        localStorage.setItem("branchId", order.branchId);
        localStorage.setItem("now", order.now);
        localStorage.setItem("orderDate", order.date);
        localStorage.setItem("orderTime", order.time);
        localStorage.setItem("editPaymentMethod", order.paymentMethod);
        localStorage.setItem("editNotes", order.notes);

        if (edit_type == "cancel") {
          console.log("set_orderID");
          localStorage.setItem("cancelOrderId", order.id);
        }
        localStorage.setItem(
          "orderSourceObj",
          JSON.stringify({
            id: 6,
            name: "Agent",
            code: 5,
            active: 1,
            allowPaid: 0,
            allowCash: 1,
            allowPaymentOnline: 1,
          })
        );
        localStorage.setItem("orderSource", 5);
        localStorage.setItem("orderType", order.typeId);
        if (order.typeId == 2) {
          localStorage.setItem("addressId", order.addressId);
        }

        if (order.typeId == 5 && order.statusId == 9) {
          history("/menu");
          localStorage.setItem("tableNum", 0);
          localStorage.setItem("displayId", 0);
        } else if (order.typeId == 5) {
          isAvailTable(token, parseInt(order.branchId), parseInt(order.tableId))
            .then((response) => {
              if (response.data.data == true) {
                localStorage.setItem("tableNum", order.tableId);
                localStorage.setItem("displayId", order.tableDisplayId);

                history("/menu");
              } else {
                setArMsg(
                  `الجلسه ${order.tableDisplayId} غير متاحه حاليا٬ يمكنك اختيار جلسه آخرى`
                );
                setEnMsg(
                  `Table ${order.tableDisplayId} isn’t available now, you can choose another table.`
                );

                setErrModal(true);

                setLoading(false);
                setTimeout(function () {
                  history("/add-order");
                }, 2700);
              }
            })
            .catch(function (error) {
              if (error.response.status === 401) {
                history("/");
              }
            });
        } else {
          history("/menu");
        }
      });
    }
  }

  const data = {
    columns: [
      {
        label: t("singleOrder.name"),
        field: i18n.language == "ar" ? "productArName" : "productEnName",
        sort: "asc",
      },
      {
        label: t("menu.size"),
        field: i18n.language == "ar" ? "sizeArName" : "sizeEnName",
        sort: "asc",
      },
      {
        label: t("cookingTypes"),
        field:
          i18n.language == "ar" ? "cookingTypeArName" : "cookingTypeEnName",
        sort: "asc",
      },

      {
        label: t("menu.quanitity"),
        field: "quantity",
        sort: "asc",
      },

      {
        label: t("singleOrder.price"),
        field: "price",
        sort: "asc",
      },
      {
        label: t("menu.additionals"),
        field: "additionals",
        sort: "asc",
      },

      {
        label: t("addOrder.extras"),
        field: "extras",
        sort: "asc",
      },
    ],
    rows: orderMeals,
  };

  const choosePay = async () => {
    console.log("updated");
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const paymentMethod =
        selectedPayment === "shabka" ? "interasoft" : selectedPayment;

      console.log(selectedPayment);

      // Update payment method
      const data = { paymentMethod };
      const response = await changePaymentMethod(token, order.id, data);
      console.log(response.data.data);

      // Handle post-payment actions based on the selected payment method
      switch (selectedPayment) {
        case "shabka":
          handlePosPayment();
          break;
        case "cash":
          console.log("should be updated");
          history(`/my-order/${order.id}`);
          break;
        case "partial":
          history(`/partial-payment/${order.id}`);
          break;
        default:
          break;
      }

      // Refresh the state and close the payment dialog
      setRefresh((prev) => !prev);
      setOpenPay(false);
    } catch (err) {
      console.error("Error updating payment method:", err);
    } finally {
      setLoading(false);
    }
  };
  const handleLabelClick = (value) => {
    // Check the input and trigger the onChange event manually
    setSelectedPayment(value);
    localStorage.setItem("selectPayment", value);
  };

  const shouldShowCallOrderButton = (order) => {
    return (
      parseInt(order.statusId) !== 4 &&
      parseInt(order.statusId) !== 5 &&
      parseInt(order.typeId) === 5 &&
      order.tableId !== null
    );
  };

  const getInvoice = async () => {
    if (order) {
      const token = localStorage.getItem("token");
      console.log(`here is the id${order.id}`);
      try {
        const res = await invoiceData(order.id, token);
        setInvoice(res.data.data);
        return res.data.data;
      } catch (error) {
        console.error("Error fetching invoice data:", error);
        return null;
      }
    }
    return null;
  };

  const handleCallOrder = (orderId) => {
    const body = {
      orderId: orderId,
    };
    callOrder(body, localStorage.getItem("token"))
      .then((res) => {
        console.log(res.data.data);
        setNotify(true);
        setToast(
          i18n.language == "ar" ? "لقد تم نداء الطلب" : "Order has been called"
        );
      })
      .catch((err) => {
        console.log(err);
        setNotify(true);
        setToast("حدث خطآ");
      });
  };

  useEffect(() => {
    console.log("test");
    setSelectedPayment("cash");
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setOpenPay(false);
      }
    };

    if (openPay) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openPay, setOpenPay]);

  return (
    <div>
      <Toast
        onClose={() => setNotify(false)}
        show={notify}
        delay={5000}
        autohide
        className="bg-success"
      >
        <Toast.Body>{toast}</Toast.Body>
      </Toast>
      {openPay && (
        <>
          <div className="modalOverlay">
            <div
              ref={modalRef}
              style={{ "max-height": "34vh" }}
              className="modal-content"
            >
              <p
                style={{
                  display: "",
                  justifyContent: "",
                }}
                className="cartDivTitle"
              >
                {t("menu.paymentMethod")}
              </p>

              <div style={{ marginTop: "2vh" }} className="paymentType">
                <input
                  type="radio"
                  name="payment"
                  value="cash"
                  id="cash"
                  defaultChecked
                  onChange={(e) => {
                    setSelectedPayment(e.target.value);
                  }}
                />

                <label
                  htmlFor="cash"
                  onClick={() => handleLabelClick("cash")}
                  style={
                    i18n.language == "ar"
                      ? { marginRight: "0.5em" }
                      : { marginLeft: "0.5em" }
                  }
                >
                  {t("menu.cash")}
                </label>
              </div>
              <div className="paymentType">
                <input
                  type="radio"
                  name="payment"
                  value="shabka"
                  id="shabka"
                  onChange={(e) => {
                    setSelectedPayment(e.target.value);
                  }}
                />

                <label
                  htmlFor="shabka"
                  onClick={() => handleLabelClick("shabka")}
                  style={
                    i18n.language == "ar"
                      ? { marginRight: "0.5em" }
                      : { marginLeft: "0.5em" }
                  }
                >
                  {t("shabka")}
                </label>
              </div>
              <div className="paymentType">
                <input
                  type="radio"
                  name="payment"
                  value="partial"
                  id="partial"
                  onChange={(e) => {
                    setSelectedPayment(e.target.value);
                  }}
                />
                <label
                  htmlFor="partial"
                  onClick={() => handleLabelClick("partial")}
                  style={
                    i18n.language == "ar"
                      ? { marginRight: "0.5em" }
                      : { marginLeft: "0.5em" }
                  }
                >
                  {t("partial")}
                </label>
              </div>
              <div className="backBtn1">
                <button style={{}} onClick={choosePay}>
                  {t("pay1")}
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {clearModal ? (
        <CleartCart
          handleClear={() => {
            handleEdit("repeatType");
          }}
          setModal={setClearModal}
        />
      ) : null}
      {errModal ? (
        <ErrorModal
          setModal={setErrModal}
          msg={i18n.language == "ar" ? arMsg : enMsg}
        />
      ) : null}
      {loading ? (
        <div style={{ height: "60vh" }} className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <div
          className="container"
          style={{ marginTop: "2em", paddingBottom: "70px" }}
        >
          <div
            style={{ marginTop: "0", marginBottom: "1em" }}
            className={i18n.language == "ar" ? "backBtn" : "backBtn backBtnEn"}
          >
            {localStorage.getItem("type") == "cashier" &&
              parseInt(order.statusId) == 6 && (
                <button
                  style={{ margin: "5px" }}
                  onClick={() => setOpenPay(true)}
                >
                  {t("changePaymentMethod")}
                </button>
              )}

            <button style={{ margin: "5px" }} onClick={() => history(-1)}>
              {i18n.language == "ar" ? (
                <BsArrowRightShort size={30} />
              ) : (
                <BsArrowLeftShort size={30} />
              )}
              {t("orders.back")}
            </button>
          </div>

          {localStorage.getItem("type") == "cashier" &&
          order.paymentMethod === "cash" &&
          (parseInt(order.statusId) == 1 || parseInt(order.statusId) == 2) ? (
            <div style={{ textAlign: "end" }}>
              <button
                className="editOrder"
                onClick={() => {
                  handleEditForCashier("repeat");
                }}
              >
                {t("singleOrder.edit")}
              </button>
            </div>
          ) : null}

          {localStorage.getItem("type") === "cashier" &&
          (order.paymentMethod === "intrasoft" ||
            (order.paymentMethod === "tamara" &&
              parseInt(order.statusId) === 6)) ? (
            <div style={{ textAlign: "end" }}>
              <button
                className="editOrder"
                onClick={() => {
                  handleEditForCashier("repeat");
                }}
              >
                {t("singleOrder.edit")}
              </button>
            </div>
          ) : null}

          {localStorage.getItem("type") == "cashier" &&
            order.paymentMethod == "partial" &&
            // Map through order.partialPayments and check conditions
            (order.partialPayments.every(
              (payment) => payment.paymentMethod == "cash"
            )
              ? parseInt(order.statusId) == 2 || parseInt(order.statusId) == 1
              : true) && (
              <div style={{ textAlign: "end" }}>
                <button
                  className="editOrder"
                  onClick={() => {
                    handleEditForCashier("repeat");
                  }}
                >
                  {t("singleOrder.edit")}
                </button>
              </div>
            )}

          <div className="orderData ">
            {((parseInt(order.statusId) === 1 &&
              order.paymentMethod === "cash") ||
              (parseInt(order.statusId) === 2 &&
                order.paymentMethod === "cash") ||
              (parseInt(order.statusId) === 6 &&
                order.paymentMethod === "tap") ||
              (parseInt(order.statusId) === 9 &&
                order.paymentMethod === "cash")) &&
            order.source == "Agent" &&
            !timeDifference &&
            order.editOrder == 1 ? (
              <div style={{ textAlign: "end" }}>
                <button
                  className="editOrder"
                  onClick={
                    basket.length > 0
                      ? () => {
                          setClearModal(true);
                          setRepeatType("cancel");
                        }
                      : () => {
                          handleEdit("cancel");
                        }
                  }
                >
                  {t("singleOrder.edit")}
                </button>
              </div>
            ) : null}

            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                width: "100%",
                alignItems: "flex-end",
                alignContent: "flex-start",
              }}
            >
              {shouldShowCallOrderButton(order) && (
                <div style={{ textAlign: "end", margin: "10px" }}>
                  <button
                    onClick={(orderId) => handleCallOrder(order.id)}
                    className="editOrder"
                  >
                    {t("callOrder")}
                  </button>
                </div>
              )}

              {parseInt(order.statusId) !== 6 ? (
                <div style={{ textAlign: "end", margin: "10px" }}>
                  <button
                    className="editOrder"
                    onClick={
                      localStorage.getItem("type") == "cashier"
                        ? handlePrint
                        : basket.length > 0
                        ? () => {
                            setClearModal(true);
                            setRepeatType("repeat");
                          }
                        : () => {
                            handleEdit("repeat");
                          }
                    }
                  >
                    {localStorage.getItem("type") == "cashier"
                      ? t("print")
                      : t("singleOrder.repeat_order")}
                  </button>
                </div>
              ) : null}
            </div>

            <div className="container-fluid">
              <div className="row orderDetails">
                <div className="col-sm-6">
                  <ul>
                    <li>
                      <span>{t("profile.orderId") + ":"}</span> {"#" + order.id}
                    </li>
                    <li>
                      <span>{t("singleOrder.name") + ":"}</span>{" "}
                      {order.user.name}
                    </li>
                    <li>
                      <span>{t("singleOrder.phone") + ":"}</span>{" "}
                      {order.user.phone}
                    </li>
                    <li>
                      <span>{t("singleOrder.branch") + ":"}</span>{" "}
                      {i18n.language == "ar"
                        ? order.branchArName
                        : order.branchEnName}
                    </li>
                    <li>
                      <span>{t("addOrder.type") + ":"}</span>{" "}
                      {order.typeId == 1
                        ? t("addOrder.branch")
                        : order.typeId == 5
                        ? t("menu.tables")
                        : t("addOrder.home")}
                    </li>

                    <li>
                      <span>{t("orders.creator") + ":"}</span>{" "}
                      {order.creatorName}
                    </li>
                  </ul>
                </div>
                <div className="col-sm-6">
                  <ul>
                    {order.typeId == 2 ? (
                      <li style={{ maxWidth: "85%" }}>
                        <span>{t("profile.address_details") + ":"}</span>{" "}
                        {i18n.language == "ar"
                          ? order.addressArAddress
                          : order.addressEnAddress}
                      </li>
                    ) : null}
                    {order.typeId == 2 ? (
                      <li>
                        <span>{t("singleOrder.driver")}</span>{" "}
                        {order.driverPhone}
                      </li>
                    ) : null}
                    <li>
                      <span>{t("addOrder.status") + ":"}</span>{" "}
                      {parseInt(order.statusId) == 9
                        ? t("waitingSession")
                        : parseInt(order.statusId) == 1
                        ? t("singleOrder.order_accepted")
                        : parseInt(order.statusId) == 2
                        ? order.typeId == 2
                          ? t("singleOrder.order_InProgress")
                          : t("singleOrder.order_InProgressBranch")
                        : parseInt(order.statusId) == 3
                        ? t("singleOrder.order_with_driver")
                        : parseInt(order.statusId) == 6
                        ? t("singleOrder.waiting")
                        : order.typeId == 2
                        ? t("singleOrder.done_order")
                        : t("singleOrder.done_orderBranch")}
                    </li>

                    <li>
                      <span>{t("orders.sync") + ":"}</span>{" "}
                      {order.sync == 1
                        ? t("orders.synced")
                        : t("orders.not-synced")}
                    </li>
                    <li>
                      <span>{t("orders.creation") + ":"}</span>{" "}
                      {order.createdAt}
                    </li>
                    <li>
                      <span>{t("singleOrder.order_time") + ":"}</span>{" "}
                      {order.now
                        ? t("addOrder.now")
                        : order.date + " " + order.time}
                    </li>
                  </ul>
                </div>
                <div className="col-12 borderHr">
                  <ul>
                    <li>
                      <span> {t("singleOrder.notes")}</span> {order.notes}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="invoiceTable">
            <p className="invoice">{t("singleOrder.invoice")}</p>
            <table className="allOrders" style={{ width: "100%" }}>
              <tr>
                <th>{t("singleOrder.price")}</th>
                <th> {t("tablePrice")} </th>
                <th>{t("singleOrder.deliveryFees")}</th>
                <th>{t("singleOrder.disc_mount")}</th>
                <th>{t("menu.walletDisc")}</th>
                {order.paymentMethod === "expirable_wallet" && (
                  <th>{t("paidFromAwafi")}</th>
                )}

                <th>{t("orders.orderTotal")}</th>
                <th>{t("orders.paymentMethod")}</th>
              </tr>

              <tr>
                <td>
                  {order.originalPrice} &nbsp;
                  {i18n.language == "ar" ? order.arCurrency : order.enCurrency}
                </td>
                <td>
                  {order.tablePrice} &nbsp;
                  {i18n.language == "ar" ? order.arCurrency : order.enCurrency}
                </td>
                <td>
                  {order.deliveryPrice} &nbsp;
                  {i18n.language == "ar" ? order.arCurrency : order.enCurrency}
                </td>
                <td>
                  {order.discount} &nbsp;
                  {i18n.language == "ar" ? order.arCurrency : order.enCurrency}
                </td>

                <td>{order.walletPay}</td>
                {order.paymentMethod === "expirable_wallet" && (
                  <td>
                    {order.totalPrice}{" "}
                    {i18n.language == "ar"
                      ? order.arCurrency
                      : order.enCurrency}
                  </td>
                )}
                <td>
                  {order.paymentMethod === "expirable_wallet"
                    ? 0
                    : order.totalPrice}{" "}
                  &nbsp;
                  {i18n.language == "ar" ? order.arCurrency : order.enCurrency}
                </td>

                <td>
                  {order.paymentMethod == "partial"
                    ? t("partial")
                    : order.paymentMethod == "tamara"
                    ? t("tamara")
                    : order.paymentMethod == "intrasoft"
                    ? t("shabka")
                    : order.paymentMethod == "cash"
                    ? t("menu.cash")
                    : order.paymentMethod == "tap"
                    ? t("menu.credit")
                    : order.paymentMethod == "paid"
                    ? t("singleOrder.paid")
                    : order.paymentMethod == "checkout"
                    ? "Checkout"
                    : t("profile.wallet")}{" "}
                  {order.paymentMethod == "tap"
                    ? order.statusId !== 6
                      ? "-" + t("singleOrder.paid")
                      : "-" + t("singleOrder.unpaid")
                    : null}
                  {order.paymentMethod == "wallet" &&
                  order.remainPaymentMethod !== ""
                    ? order.remainPaymentMethod == "cash"
                      ? "/ " + t("menu.cash")
                      : "/ " + t("menu.credit")
                    : null}
                </td>
              </tr>
            </table>
          </div>

          {order.tableDisplayId != null ? (
            <div style={{ marginTop: "60px" }} className="invoiceTable">
              <p className="invoice">{t("tabless")}</p>
              <table className="allOrders" style={{ width: "100%" }}>
                <tr>
                  <th>{t("tableNumber")}</th>
                  <th>{t("tableCondiditon")} </th>
                  <th> {t("tableType")}</th>
                  <th> {t("tablePrice")} </th>
                </tr>

                <tr
                  style={{
                    textAlign: i18n.language == "ar" ? "right" : "left",
                  }}
                >
                  <td>{order.tableDisplayId} &nbsp;</td>
                  <td>{order.tableIsVip == 0 ? t("normall") : t("vipp")}</td>
                  <td>
                    {" "}
                    {order.tableType == "families"
                      ? t("familes")
                      : t("individul")}{" "}
                  </td>
                  <td>
                    {order.tablePrice}{" "}
                    {i18n.language === "ar"
                      ? order.arCurrency
                      : order.enCurrency}
                  </td>
                </tr>
              </table>
            </div>
          ) : null}

          {partialPayments.length !== 0 && (
            <div style={{ marginTop: "60px" }} className="invoiceTable">
              <p className="invoice">{t("previousPayment")}</p>
              <table className="allOrders" style={{ width: "100%" }}>
                <tr>
                  <th>{t("amountPaid")}</th>
                  <th>{t("menu.paymentMethod")}</th>
                  <th> {t("TransactionId")}</th>
                  <th> {t("paymentStatus")}</th>

                  {/* <th>{t("numberOfRemaining")}</th>
                  <th>{t("remainingAmount")}</th> */}
                </tr>

                {partialPayments.map((payment, index) => {
                  console.log(payment);
                  return (
                    <tr
                      key={index}
                      style={{
                        textAlign: i18n.language == "ar" ? "right" : "left",
                      }}
                    >
                      <td>
                        {payment.amount} &nbsp;
                        {i18n.language === "ar"
                          ? order.arCurrency
                          : order.enCurrency}
                      </td>
                      <td>
                        {payment.paymentType ?? payment.paymentMethod} &nbsp;
                      </td>
                      <td>{payment.transactionId} &nbsp;</td>
                      <td>
                        {payment.isSuccess == 1 ? t("paySucess") : t("payFail")}{" "}
                        &nbsp;
                      </td>

                      {/* <td>{payment.remainingPartialPaymentsAttempts} &nbsp;</td>
                        <td>{payment.remainingPaymentAmount} &nbsp;</td> */}
                    </tr>
                  );
                })}
              </table>
            </div>
          )}

          <div
            style={{ marginTop: "2em" }}
            className={
              i18n.language == "ar"
                ? "container-fluid pagAr orderDet"
                : "container-fluid orderDet"
            }
          >
            <p
              className="invoice"
              style={{ marginTop: "1em", marginBottom: "0" }}
            >
              {t("singleOrder.orderMeal")}
            </p>

            <div className="row">
              <div
                className={
                  orderMeals.length == 0
                    ? i18n.language == "ar"
                      ? " emptyAr empty col-lg-12 col-md-12 col-sm-12 col-xs-12"
                      : "  empty col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    : "col-lg-12 col-md-12 col-sm-12 col-xs-12"
                }
              >
                <MDBDataTable
                  entries={50}
                  entriesOptions={[50, 100]}
                  infoLabel={[
                    t("orders.info1"),
                    t("orders.info2"),
                    t("orders.info3"),
                    t("orders.info4"),
                  ]}
                  searchLabel={t("dashboard.search")}
                  paginationLabel={[t("orders.prev"), t("orders.next")]}
                  entriesLabel={t("orders.show")}
                  striped
                  hover
                  noBottomColumns={true}
                  data={data}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default OrderDetails;
