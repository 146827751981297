import "../Styles/Navbar.css";
import arLogo from "../Images/arLogo.svg";
import enLogo from "../Images/enLogo.svg";
import { useTranslation } from "react-i18next";
import { AiOutlineSearch } from "react-icons/ai";
import { useState, useRef, useEffect, useCallback } from "react";
import { NavLink } from "react-router-dom";
import img from "../Images/defaultImage.jpeg";
import LogoutModal from "./LogoutModal";
import { search, getNews } from "../api";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ErrorModal from "../Components/ErrorModal";
import Ticker from "react-ticker";
import { RxDotFilled } from "react-icons/rx";
import { HiOutlineChatBubbleLeftRight } from "react-icons/hi2";
import { IoDocuments } from "react-icons/io5";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";

import { countUnread } from "../api";
import io from "socket.io-client";

import { AiOutlineMenu } from "react-icons/ai";

const socket = io("https://dev.alromansiah.com/", {
  transports: ["polling", "websocket"],
  auth: {
    userName: localStorage.getItem("userName")
      ? localStorage.getItem("userName").toUpperCase()
      : null,
    userID: localStorage.getItem("userId"),
    userType: "cs",
  },
});

function Navbar(props) {
  const [lang, setLang] = useState("ar");
  const { t, i18n } = useTranslation();
  const phoneRef = useRef();
  const userName = localStorage.getItem("userName");
  const [msgAr, setMsgAr] = useState();
  const [msgEn, setMsgEn] = useState();
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [toggle, setToggle] = useState(false);
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);

  const [headline, setHeadline] = useState([
    {
      text: "هناك خبر الاخبار خوابر",
      date: "2023/01/23",
    },
    {
      text: "سبونجبوب يقتل شمشون عن عمد؟؟؟",
      date: "2023/01/23",
    },
    {
      text: "مريم عايزة تروح",
      date: "2023/01/23",
    },
  ]);

  const location = useLocation();

  const [errorMsgModel, setErrorMsgModel] = useState(false);
  let history = useNavigate();

  //const photo=  localStorage.getItem("userPhoto");
  const [modal, setModal] = useState(false);

  let phoneRegEx =
    /^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const changeLanguage = (e) => {
    var language = e.target.value;
    i18n.changeLanguage(language);
    localStorage.setItem("language", JSON.stringify(language));
    setLang(language);

    if (language === "en") {
      document.getElementById("root").style.direction = "ltr";
    } else {
      document.getElementById("root").style.direction = "rtl";
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      history("/");
    }
    setNews([]);

    getNews(localStorage.getItem("token"))
      .then((response) => {
        setNews(response.data.data);
        console.log(response.data.data);
        props.setNews(response.data.data.length == 0 ? false : true);
        setLoading(false);
      })
      .catch(function (error) {});
  }, [location]);

  /* useEffect(() => {
        const token= localStorage.getItem("token");
   
  countUnread(token,localStorage.getItem("userId")).then((response) => {
    setCount(response.data)
   // props.setChatCount(filteredData.length)
    setLoading(false)
  }).catch((err)=>{
    console.log(err)
  })
},[location])*/

  useEffect(() => {
    console.log(`count-chats-${localStorage.getItem("userId")}`);
    socket.on(`count-chats-${localStorage.getItem("userId")}`, handleCount);

    return () => {
      socket.off(`count-chats-${localStorage.getItem("userId")}`, handleCount);
    };
  }, [socket]);

  const handleCount = useCallback((data) => {
    setCount(data);
    console.log(data);
  }, []);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {},
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  function handleHome() {
    if (localStorage.getItem("type") == "tablesManager") {
      history("/add-reservation");
    } else if (localStorage.getItem("type") == "productionManger") {
      return null;
    } else {
      history("/dashboard");
    }
  }
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("language"));
    i18n.changeLanguage(data);
    setLang(data);

    if (i18n.language === "en") {
      document.getElementById("root").style.direction = "ltr";
    } else if (i18n.language === "ar") {
      document.getElementById("root").style.direction = "rtl";
    } else {
      document.getElementById("root").style.direction = "rtl";
    }

    if (JSON.parse(localStorage.getItem("language")) === "ar") {
      document.title = " الرومانسية كاشير";
    } else {
      document.title = "AlRomansiah Cashier";
    }
  }, [lang]);

  //takes phone number
  //if phone exists customer data is stored and user is redirected to add an order
  //if not found user is redirected to add a new customer

  function handleSubmit(e) {
    e.preventDefault();
    const token = localStorage.getItem("token");

    let data = {
      phone: phoneRef.current.value,
    };

    if (phoneRegEx.test(phoneRef.current.value)) {
      search(token, data)
        .then((response) => {
          if (response.data.message === "success") {
            //  this.clearBasket();
            if (response.data.data.length > 0) {
              localStorage.setItem("customerId", response.data.data[0].id);
              localStorage.setItem("customerName", response.data.data[0].name);
              localStorage.setItem(
                "customerNotes",
                response.data.data[0].notes
              );
              localStorage.setItem(
                "customerPhone",
                response.data.data[0].phone
              );
              localStorage.setItem(
                "customerPhoto",
                response.data.data[0].photo
              );
              localStorage.setItem(
                "customerAwafi",
                response.data.data[0].joinLoyalty
              );
              history("/add-order");
              /*this.setState({
                 customerFoundRedirect:true,
                 customerPhone: response.data.data[0].phone,
         
               }); */
            } else {
              localStorage.setItem("customerId", "");
              localStorage.setItem("customerName", "");
              localStorage.setItem("customerNotes", "");
              localStorage.setItem("customerPhone", JSON.stringify(data));
              localStorage.setItem("customerPhoto", "");
              localStorage.setItem("customerAwafi", "");
              history("/add-customer");
              /*this.setState({
                 customerNotFoundRedirect:true
               }); */
            }
          } else {
            setErrorMsgModel(true);
            setMsgAr("لم يتم تأكيد هذا الحساب من فضلك ادخل رمز التأكيد");
            setMsgEn("This account is not verified please enter your code");
            setTimeout(function () {
              setErrorMsgModel(false);
            }, 2500);
          }
        })
        .catch(function (error) {
          if (error.response.status === 422) {
            setErrorMsgModel(true);
            setMsgAr("خطأ في رقم الهاتف");
            setMsgEn("Invalid phone");
            setTimeout(function () {
              setErrorMsgModel(false);
            }, 2500);
          } else if (error.response.status === 401) {
            history("/");
          }
        });
    } else {
      setErrorMsgModel(true);
      setMsgAr("خطأ في رقم الهاتف");
      setMsgEn("Invalid phone");
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2500);
    }
  }

  return (
    <div className="stickyTop">
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <div style={{ color: "black", fontFamily: "Cairo" }}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns:
                  i18n.language === "ar" ? "auto 150px" : "150px auto",
                gap: "10px",
                direction: i18n.language === "ar" ? "rtl" : "ltr", // Ensure correct direction
              }}
            >
              <label
                style={{
                  fontWeight: "bold",
                  textAlign: i18n.language === "ar" ? "right" : "left", // Align text properly
                }}
              >
                {t("addOrder.invoices")}
              </label>
              <span
                onClick={() => handleCopy("300057172200003")}
                style={{
                  cursor: "pointer",
                  textAlign: i18n.language === "ar" ? "left" : "right", // Align text properly
                }}
              >
                300057172200003
              </span>

              <label
                style={{
                  fontWeight: "bold",
                  textAlign: i18n.language === "ar" ? "right" : "left", // Align text properly
                }}
              >
                {t("commercNumberr")}
              </label>
              <span
                onClick={() => handleCopy("1010214603")}
                style={{
                  cursor: "pointer",
                  textAlign: i18n.language === "ar" ? "left" : "right", // Align text properly
                }}
              >
                1010214603
              </span>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <div className="container-fluid ">
        {errorMsgModel ? (
          <ErrorModal
            setModal={setErrorMsgModel}
            msg={i18n.language == "ar" ? msgAr : msgEn}
          />
        ) : null}

        {modal ? <LogoutModal setModal={setModal} /> : null}
        <div className="myNav row">
          <div style={{ padding: "0" }} className="col-2 ">
            <div
              className="navLogoContainer"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div
                onClick={handleHome}
                className="navLogo"
                style={
                  i18n.language === "ar"
                    ? {
                        backgroundImage: `url(${arLogo})`,
                        backgroundPosition: "right",
                        marginTop: "2vh",
                      }
                    : {
                        backgroundImage: `url(${enLogo})`,
                        backgroundPosition: "left",
                      }
                }
              ></div>
              <IoDocuments
                onClick={handleClickOpen}
                size={30}
                color="white"
                style={{ marginTop: "2vh" }}
              />
            </div>
          </div>

          <div
            className={
              toggle
                ? "mobileLinks toggOpen col-10 alignUser screens"
                : "mobileLinks toggClose col-10 alignUser screens"
            }
          >
            {/* <div
              style={{ marginTop: "2vh", color: "white", fontFamily: "Cairo" }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label
                    style={
                      i18n.language == "ar"
                        ? { marginLeft: "2vh", fontWeight: 'bold' }
                        : { marginRight: "5vh", fontWeight: 'bold' }
                    }
                  >
                    {t("addOrder.invoices")} :
                  </label>
                  <span
                    onClick={() => handleCopy("300057172200003")}
                    style={{
                      marginLeft: i18n.language === "ar" ? "4vw" : "0",
                      marginRight: i18n.language === "en" ? "4vw" : "0",
                    }}
                  >
                    300057172200003
                  </span>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label
                    style={
                      i18n.language == "ar"
                        ? { marginLeft: "2.5vh", fontWeight: 'bold' }
                        : { marginRight: "2.5vh", fontWeight: 'bold' }
                    }
                  >
                    {t("commercNumberr")} :
                  </label>
                  <span
                    onClick={() => handleCopy("1010214603")}
                    style={{
                      marginLeft: i18n.language === "ar" ? "4vw" : "0",
                      marginRight: i18n.language === "en" ? "4vw" : "0",
                    }}
                  >
                    1010214603
                  </span>
                </div>
              </div>
            </div> */}

            {localStorage.getItem("type") == "productionManger" ||
            localStorage.getItem("type") == "tablesManager" ? null : (
              <>
                {localStorage.getItem("type") == "cashier" ? null : (
                  <>
                    <NavLink
                      to="/chats/0"
                      onClick={() => {
                        localStorage.removeItem("pageUsr");
                        localStorage.removeItem("filterChat");
                      }}
                      className="showChat chatSvg"
                      data-count={count}
                    >
                      <HiOutlineChatBubbleLeftRight color="white" size={25} />
                    </NavLink>
                    <NavLink to="/external-sales" style={{ margin: " 0 1em" }}>
                      {t("externalSales.externalSales")}
                    </NavLink>
                  </>
                )}
                <NavLink style={{ margin: "0 8px" }} to="/calories">{t("profile.calories")}</NavLink>

                <NavLink style={{ margin: "0 8px" }} to="/avaliable-offers">{t("offers")}</NavLink>
                <NavLink style={{ margin: "0 8px" }} to="/promo-codes">{t("promoCode")}</NavLink>

                
                <NavLink to="/offers" style={{ margin: "0 8px" }}>
                  {t("profile.offers")}
                </NavLink>
                
                <NavLink to="/questions">{t("profile.questions")}</NavLink>
              </>
            )}

            <select
              value={lang}
              onChange={(e) => changeLanguage(e)}
              style={{ margin: "0 1em" }}
            >
              <option value={"en"}> English </option>
              <option value={"ar"}> العربية </option>
            </select>
            <div className="imgAlign">
              <div className="imgBorder">
                <NavLink to="/profile">
                  <div
                    className="profileImage"
                    style={{
                      cursor: "pointer",
                      backgroundImage: `url(${img})`,
                    }}
                  ></div>
                </NavLink>
              </div>
              <div
                className="navName"
                style={
                  i18n.language == "ar"
                    ? { marginLeft: "5vw", textAlign: "start" }
                    : { marginRight: "5vw", textAlign: "start" }
                }
              >
                <NavLink to="/profile">
                  <p
                    style={
                      i18n.language == "en"
                        ? { marginLeft: "0.5em", marginRight: "auto" }
                        : null
                    }
                    className="usrName"
                  >
                    {userName}
                  </p>
                </NavLink>
              </div>
            </div>

            <p
              style={
                i18n.language == "en"
                  ? { marginLeft: "0.5em" }
                  : { marginRight: "0.5em" }
              }
              className="logout"
              onClick={() => setModal(true)}
            >
              {t("profile.logout")}
            </p>
          </div>
          <div className="col-10 toggle ">
            <button onClick={() => setToggle(!toggle)}>
              <AiOutlineMenu size={30} />
            </button>
          </div>
        </div>
      </div>
      {loading === false && news.length > 0 && (
        <Ticker direction={i18n.language === "ar" ? "toRight" : "toLeft"}>
          {({ index }) => (
            <p key={index}>
              {news.map((data, index) => (
                <span key={index} style={{ color: data.color }}>
                  <RxDotFilled />
                  {i18n.language === "ar" ? data.newAr : data.newEn}
                </span>
              ))}
            </p>
          )}
        </Ticker>
      )}
    </div>
  );
}

export default Navbar;
