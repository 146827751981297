 
import React, {useState,useRef,useEffect} from 'react';
import { RiArrowRightSFill,RiArrowLeftSFill } from 'react-icons/ri';
import img1 from '../../Images/file1.jpeg'
import img2 from '../../Images/file2.jpeg'
import img3 from '../../Images/file3.jpeg'
import { useTranslation } from 'react-i18next';
import { BeatLoader } from 'react-spinners';
 import {BsUpload} from "react-icons/bs";
import * as yup from "yup"
import {useFormik} from "formik";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import ImageModal from '../../Components/ImageModal';
import {GrDocumentPdf}  from 'react-icons/gr';
import { BsQuestionCircleFill } from 'react-icons/bs';
import { register3 } from '../../api';
function RegisterStep3(props) {
 
    const { t, i18n } = useTranslation();
    const [loading,setLoading]=useState(false);
const [msgAr, setMsgAr]=useState();
const [msgEn, setMsgEn]=useState();
const [errorMsgModel, setErrorMsgModel]=useState(false);
const [editForm,setEditForm]=useState(false);

const [modal,setModal]=useState(false);
const [img, setImg]=useState();
const [data, setData]=useState(props.data.attachments);

      const history=useNavigate();
  
      const Schema= yup.object().shape({
        documents: yup.string(),
        commReg: yup.string(),
        chamber: yup.string(),
        certification: yup.string(),
        bank: yup.string(),
        certificate: yup.string(),
      
      })
      


      useEffect(() => {
        setData(props.data.attachments);
        setLoading(false)
      }, [props]);

      const formik = useFormik({
      initialValues:{
        documents: '',
        commReg: '',
        chamber:'',
        certification:'',
        bank:'',
        certificate:'',

     
      },
      validationSchema: Schema,
      onSubmit
      });
   
      const documentsRef=useRef();
      const commRegRef=useRef();
      const chamberRef=useRef();
      const bankRef=useRef();
      const certificateRef=useRef();
      const certificationRef=useRef();
    
       

 

 

       async function onSubmit(event){
        setLoading(true);

        const formData = new FormData()

      if( documentsRef.current.files.length!==0) {
          formData.append('documents',documentsRef.current.files[0])}

          if( certificationRef.current.files.length!==0) {
            formData.append('registrationTaxCertificate',certificationRef.current.files[0])}

            if( commRegRef.current.files.length!==0) {
                formData.append('commercialRegistration',commRegRef.current.files[0])}

                if( chamberRef.current.files.length!==0) {
                    formData.append('chamberCommerce',chamberRef.current.files[0])}

                    if( certificateRef.current.files.length!==0) {
                        formData.append('zakatIncomeCertificate',certificateRef.current.files[0])}

                        if( bankRef.current.files.length!==0) {
                            formData.append('bankAccountDocument',bankRef.current.files[0])}

      
   register3(localStorage.getItem("token"),localStorage.getItem("externalId"),formData,i18n.language).then((response) => {
       

props.setUpdate(!props.update)
setEditForm(false);

}).catch(function (error) {
 
                                 
    setMsgAr("حدث خطأ   ");
    setMsgEn("Something went wrong");
  setErrorMsgModel(true);
                                   
 setLoading(false);
                                 

      })
    }
 
 
   return (
    <div className={i18n.language=="ar"?'regStep3Ar':'regStep3'}>
    {modal?<ImageModal img={img} setModal={setModal}/>:null}
    {loading?
<div style={{height:"110vh"}} className='loader'>
<BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:null}
<div className='container-fluid'>
<form className={editForm?"row flexEnd":"row flexEnd disabledForm"}  style={loading?{display:"none"}:null} onSubmit={formik.handleSubmit}>


<div className='col-sm-5 col-6'>

<div className={formik.values.commReg==""?"inputDiv":"inputDiv fileUploaded"}>

<label>  {t("register3.commReg")} <BsQuestionCircleFill onClick={()=> {setImg(img2); setModal(true);}}/></label>
<div  className={i18n.language=="ar"?'uploadInp':'uploadInp uploadInpEn'}> 
<input type="file" accept=".pdf, image/jpeg, image/png, image/gif" placeholder={t("register3.commReg")} value={formik.values.commReg} id="commReg" name="commReg"  ref={commRegRef}  onChange={formik.handleChange}     className={formik.errors.commReg && formik.touched.commReg ?"invalid":null}/>
<BsUpload size={20} color="#ACACAC"/>

</div>
<a target="_blank" href={data.commercialRegistration}>
{data.commercialRegistration.slice(-3) ==="pdf"?
<GrDocumentPdf size={25} />:
<img src={data.commercialRegistration} style={{maxHeight:"30px",marginTop:"0.5em"}}/>
}


</a>
<span>{formik.errors.commReg && formik.touched.commReg?t(`${formik.errors.commReg}`):null}</span>
</div></div>
<div className='col-1 removeCol'></div>

<div className='col-sm-5 col-6'>

<div className={formik.values.chamber==""?"inputDiv":"inputDiv fileUploaded"}>
<label>  {t("register3.chamber")} <BsQuestionCircleFill onClick={()=>{setImg(); setModal(true)}}/></label>
<div  className={i18n.language=="ar"?'uploadInp':'uploadInp uploadInpEn'}> 
<input type="file" accept=".pdf, image/jpeg, image/png, image/gif" placeholder={t("register3.chamber")} value={formik.values.chamber} id="chamber" name="chamber"  ref={chamberRef}  onChange={formik.handleChange}    className={formik.errors.chamber && formik.touched.chamber ?"invalid":null}/>
<BsUpload size={20} color="#ACACAC"/>

</div>
<a target="_blank" href={data.chamberCommerce}>

{data.chamberCommerce.slice(-3) ==="pdf"?
    <GrDocumentPdf size={25} />:
    <img src={data.chamberCommerce} style={{maxHeight:"30px",marginTop:"0.5em"}}/>
    }

</a>
<span>{formik.errors.chamber && formik.touched.chamber?t(`${formik.errors.chamber}`):null}</span>
</div></div>

<div className='col-sm-5 col-6'>

<div className={formik.values.certificate==""?"inputDiv":"inputDiv fileUploaded"}>
<label>  {t("register3.certificate")} <BsQuestionCircleFill onClick={()=>{setImg(); setModal(true)}}/></label>
<div  className={i18n.language=="ar"?'uploadInp':'uploadInp uploadInpEn'}> 
<input type="file" accept=".pdf, image/jpeg, image/png, image/gif" placeholder={t("register3.certificate")} value={formik.values.certificate} id="certificate" name="certificate"  ref={certificateRef}    onChange={formik.handleChange} className={formik.errors.certificate && formik.touched.certificate ?"invalid":null}/>
<BsUpload size={20} color="#ACACAC"/>

</div>
<a target="_blank" href={data.zakatIncomeCertificate}>
    
{data.zakatIncomeCertificate.slice(-3) ==="pdf"?
    <GrDocumentPdf size={25} />:
    <img src={data.zakatIncomeCertificate} style={{maxHeight:"30px",marginTop:"0.5em"}}/>
    }
</a>
<span>{formik.errors.certificate && formik.touched.certificate?t(`${formik.errors.certificate}`):null}</span>
</div></div>
<div className='col-1 removeCol'></div>

<div className='col-sm-5 col-6'>

<div className={formik.values.bank==""?"inputDiv":"inputDiv fileUploaded"}>
    <label>  {t("register3.bank")} <BsQuestionCircleFill onClick={()=> {setImg(img1); setModal(true);}}/></label>
<div  className={i18n.language=="ar"?'uploadInp':'uploadInp uploadInpEn'}> 
<input type="file" accept=".pdf, image/jpeg, image/png, image/gif" placeholder={t("register3.bank")} value={formik.values.bank} id="bank" name="bank"  ref={bankRef}  onChange={formik.handleChange}    className={formik.errors.bank && formik.touched.bank ?"invalid":null}/>
<BsUpload size={20} color="#ACACAC"/>

</div>
<a target="_blank" href={data.bankAccountDocument}>
{data.bankAccountDocument.slice(-3) ==="pdf"?
    <GrDocumentPdf size={25} />:
    <img src={data.bankAccountDocument} style={{maxHeight:"30px",marginTop:"0.5em"}}/>
    }

</a>
<span>{formik.errors.bank && formik.touched.bank?t(`${formik.errors.bank}`):null}</span>
</div></div>
<div className='col-sm-5 col-6'>

<div className={formik.values.certification==""?"inputDiv":"inputDiv fileUploaded"}>
    <label>  {t("register3.certification")} <BsQuestionCircleFill onClick={()=> {setImg(img3); setModal(true);}}/></label>
<div  className={i18n.language=="ar"?'uploadInp':'uploadInp uploadInpEn'}> 
<input type="file" accept=".pdf, image/jpeg, image/png, image/gif" placeholder={t("register3.certification")} value={formik.values.certification} id="certification" name="certification"  ref={certificationRef}    onChange={formik.handleChange} className={formik.errors.certification && formik.touched.certification ?"invalid":null}/>
<BsUpload size={20} color="#ACACAC"/>

</div>
<a target="_blank"  href={data.registrationTaxCertificate}>
{data.registrationTaxCertificate.slice(-3) ==="pdf"?
    <GrDocumentPdf size={25} />:
    <img src={data.registrationTaxCertificate} style={{maxHeight:"30px",marginTop:"0.5em"}}/>
    }

</a>
<span>{formik.errors.certification && formik.touched.certification?t(`${formik.errors.certification}`):null}</span>
</div></div>
<div className='col-1 removeCol'></div>

<div className='col-sm-5 col-6'>

<div className={formik.values.documents==""?"inputDiv":"inputDiv fileUploaded"}>
<label>  {t("register3.documents")} <BsQuestionCircleFill onClick={()=>{setImg(); setModal(true)}}/></label>
<div  className={i18n.language=="ar"?'uploadInp':'uploadInp uploadInpEn'}> 
<input type="file" accept=".pdf, image/jpeg, image/png, image/gif" placeholder={t("register3.documents")} value={formik.values.documents} id="documents" name="documents"  ref={documentsRef}  onChange={formik.handleChange}   className={formik.errors.documents && formik.touched.documents ?"invalid":null}/>
<BsUpload size={20} color="#ACACAC"/>

</div>
<a target="_blank" href={data.documents}>
{data.documents.slice(-3) ==="pdf"?
    <GrDocumentPdf size={25} />:
    <img src={data.documents} style={{maxHeight:"30px",marginTop:"0.5em"}}/>
    }
</a>
<span>{formik.errors.documents && formik.touched.documents?t(`${formik.errors.documents}`):null}</span>
</div></div>


{i18n.language=="ar"?
<span>{errorMsgModel? msgAr:null}</span>:
<span>{errorMsgModel? msgEn:null}</span>}

{editForm?

<div style={{marginTop:"2em"}} className="formBtns">
<button type="submit" className="dataEdit">{t("profile.save")}</button>
<span> </span>
<button type="button" className="cancelEdit" onClick={()=> {formik.resetForm();setEditForm(false) }}>  {t("profile.cancel")}</button></div>
:
<div className="formBtns" style={{marginTop:"1em"}}>
  
<button type="button" className="dataEdit"  onClick={(e)=> {e.preventDefault(); setEditForm(true)}}>{t("profile.edit")}</button>   
</div>
}

</form>

</div></div>


    );
}

export default RegisterStep3;