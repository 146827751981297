import '../Styles/Areas.css';

import Navbar from '../Components/Navbar';
import { useTranslation } from 'react-i18next';
import * as yup from "yup"
import { useRef,useEffect,useState } from 'react';
import { useNavigate } from "react-router-dom";
import {useFormik} from "formik";
import { BeatLoader } from 'react-spinners';
import noResults from "../Images/noResults.svg"
import { countries,addUser } from '../api';

function AddCustomer() {
   
    const { t, i18n } = useTranslation();
    const phone=JSON.parse(localStorage.getItem("customerPhone"));
    const [country, setCountry]=useState([]);
    let history= useNavigate()
    const [selectedCountry, setSelectedCountry]=useState();
    const [msgAr, setMsgAr]=useState();
    const [msgEn, setMsgEn]=useState();
    const [loading, setLoading]=useState(true);
    const[submitLoading,setSubmitLoading]=useState(false);
  const [errorMsgModel, setErrorMsgModel]=useState(false);
  const nameRef=useRef();
  const phoneRef=useRef();

    let phoneRegEx =/^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;
 

    const Schema= yup.object().shape({
        name: yup.string().required(("validation.cstmrName_required")),
        phone: yup.string().required(("validation.cstmrPhone_required")).matches(phoneRegEx,("validation.cstmrPhone_not_valid")),
     
        })
        
        
        const formik = useFormik({
            initialValues:{
              name:"",
              phone:phone.phone,
            },
            validationSchema: Schema,
          onSubmit,
         }); 
           
//takes token and calls api to get all countries
//sets default selected country
         useEffect(() => {

            const token= localStorage.getItem("token");
          
            
            
            countries(token).then((response) => {
                setCountry(response.data.data)
                setSelectedCountry(response.data.data[0].id)
       
              setLoading(false)
            }).catch(function(error){
              if (error.response.status === 401) {
                 history("/")
              }
           
          });
           
           
          },[]) 

//takes name,phone and country id
//if success => stores client data and redirects to the client's profile
           function onSubmit(event){
            setSubmitLoading(true)
            const token= localStorage.getItem("token");
          
let data={
    name: nameRef.current.value,
    phone: phoneRef.current.value,
    countryId: selectedCountry


}

            addUser(token,data).then((response) => {
                if (response.data.message === "success") {
                    localStorage.setItem("customerId", response.data.data.id);
                    localStorage.setItem("customerName", response.data.data.name);
                    localStorage.setItem("customerPhone", response.data.data.phone);
                    localStorage.setItem("customerPhoto", response.data.data.photo);
                    history("/add-order")             
                } else {
                    setErrorMsgModel(true)
                    setMsgAr("حدث خطأ")
                    setMsgEn("Something went wrong")
               setSubmitLoading(false)
                }                      
            }).catch(function(error){
                if(error.response.data.error === "phone_exist"){
                    setErrorMsgModel(true)
                    setMsgAr("هذا الرقم مسجل من قبل")
                    setMsgEn("This phone is exist")
                    setSubmitLoading(false)
            
                }
                else if (error.response.status === 401) {
                    history("/")
                 
                }
                else {
                    setErrorMsgModel(true)
                    setMsgAr("حدث خطأ")
                    setMsgEn("Something went wrong")
                    setSubmitLoading(false)
            
                   }
           
          });

             }
          

    return (
      <div > 
          

{loading?
               <div style={{height:"80vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
          <div style={{marginTop:"2em",paddingBottom:"7em"}} className='container'>
       <div className='noResult'>  
<img src={noResults} />
<p>{t("dashboard.notFound")} {phone.phone}</p>
<p>{t("dashboard.fill")}</p>
</div>   
      <div className='editCstmr'>

{submitLoading?
               <div style={{height:"60vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={submitLoading}  size={30} /></div>:
      <form  onSubmit={formik.handleSubmit}>
 
<p>{t("dashboard.addCstmr")}</p>
<div>
<label> {t("profile.cstmrName")}</label>
<input  id="name" style={{width:"100%"}}   value={formik.values.name}  name="name"  ref={nameRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.name && formik.touched.name || errorMsgModel?"invalid":null}/>
<span className='validation'>{formik.errors.name && formik.touched.name?t(`${formik.errors.name}`):null}</span>
 
<label>  {t("dashboard.cstmrPhone")}</label>
<input type="phone"   value={formik.values.phone} id="phone" name="phone"  ref={phoneRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.phone && formik.touched.phone || errorMsgModel?"invalid":null}/>

<span className='validation'>{formik.errors.phone && formik.touched.phone?t(`${formik.errors.phone}`):null}</span>
<label>  {t("dashboard.country")}</label>
<select value={selectedCountry} onChange={(e)=>{setSelectedCountry(e.target.value)
}
}>

{country && country.map((data,index)=>
<option value={data.id} key={data.id}> {i18n.language=="ar"?data.arName:data.enName}</option>

)}


</select>
</div>
{i18n.language=="ar"?
<span style={{textAlign:"center"}}  className='validation'>{errorMsgModel? msgAr:null}</span>:
<span style={{textAlign:"center"}} className='validation' >{errorMsgModel? msgEn:null}</span>}
<button type='submit'>{t("profile.save")}</button>
</form>}
      </div>
       </div>}</div>
    );
  }
  
  export default AddCustomer;
  
  