import "../Styles/Dashboard.css";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import {
  changeStatus,
  changeExternalCategories,
  getProducts,
  updateExternalProducts,
} from "../api";
import { BeatLoader } from "react-spinners";
import Select from "react-select";

function UserType(props) {
  const { t, i18n } = useTranslation();
  const [type, setType] = useState("");
  const [msgAr, setMsgAr] = useState("");
  const [msgEn, setMsgEn] = useState("");
  const [loading, setLoading] = useState(false);
  const [chosenCategs, setChosenCategs] = useState([]);
  const [products, setProducts] = useState([]);
  const [chosenProducts, setChosenProducts] = useState([]);
  const [disabled, setDisabled] = useState(true);

  const [err, setErr] = useState(false);

  const codeRef = useRef();

  function getProductsByCategory(arr) {
    const token = localStorage.getItem("token");

    getProducts(token, `[${arr}]`).then((response) => {
      let items = [];

      for (let i = 0; i < response.data.data.length; i++) {
        let obj = {};
        obj.label =
          i18n.language == "ar"
            ? response.data.data[i].arName
            : response.data.data[i].enName;
        obj.value = response.data.data[i].id;

        items.push(obj);
        //creating  options dynamically based on what props are currently passed to the parent component
      }
      setProducts(items);
      setChosenProducts((prev) =>
        prev.filter((product) =>
          items.some((item) => item.value == product.value)
        )
      );
      setDisabled(false);
    });
  }

  //changeStatus api takes data depending on request type
  //rejected=> type (approved/rejected)
  //approved=> type (approved/rejected), external user type (postpaid/prepayment),code
  function handleApprove() {
    const token = localStorage.getItem("token");
    setLoading(true);

    let arr = [];
    for (let i = 0; i < chosenProducts.length; i++) {
      arr.push(chosenProducts[i].value);
    }

    if (chosenCategs.length == 0 && !(props.externalStatus == "rejected")) {
      setMsgAr("من فضلك اختر  تصنيفات للمستخدم");
      setMsgEn("Please choose categories for user");
      setErr(true);
      setLoading(false);
    } else if (arr.length == 0 && !(props.externalStatus == "rejected")) {
      setMsgAr("من فضلك اختر  منتجات للمستخدم");
      setMsgEn("Please choose products for user");
      setErr(true);
      setLoading(false);
    } else if (props.externalStatus == "rejected") {
      let data = {
        status: props.externalStatus,
      };

      changeStatus(props.id, data, token).then((response) => {
        props.setModal(false);
        props.setUpdate(!props.update);
      });
    } else {
      setErr(false);
      if (type == "") {
        setMsgAr("من فضلك اختر  نوع المستخدم");
        setMsgEn("Please enter user type");
        setErr(true);
        setLoading(false);
      } else if (codeRef.current.value == "") {
        setMsgAr("من فضلك إدخل كود المستخدم");
        setMsgEn("Please enter user code");
        setErr(true);
        setLoading(false);
      } else {
        let data = {
          status: props.externalStatus,
          externalSalesType: type,
          code: codeRef.current.value,
        };

        let categories = {
          categories: chosenCategs,
        };
        let products = {
          products: arr,
        };
        changeExternalCategories(token, props.id, categories, i18n.language)
          .then((response) => {
            updateExternalProducts(token, props.id, products).then(
              (response) => {
                changeStatus(props.id, data, token, i18n.language)
                  .then((response) => {
                    props.setModal(false);
                    props.setUpdate(!props.update);
                  })
                  .catch((error) => {
                    setMsgAr(error.response.data.errors.code);
                    setMsgEn(error.response.data.errors.code);
                    setErr(true);
                    setLoading(false);
                  });
              }
            );
          })
          .catch((error) => {
            setMsgAr(error.response.data.errors.categories);
            setMsgEn(error.response.data.errors.categories);
            setErr(true);
            setLoading(false);
          });
      }
    }
  }

  function handleCategs(e) {
    setDisabled(true);
    let arr = [];
    for (let i = 0; i < e.length; i++) {
      arr.push(e[i].value);
    }
    setChosenCategs(arr);
    getProductsByCategory(arr);
  }

  function handleProducts(e) {
    setChosenProducts(e);
  }
  return (
    <div>
      <div onClick={() => props.setModal(false)} className="modalOverlay"></div>
      <div
        style={{
          width: "100vh",
          maxHeight: "62vh",
          maxWidth: "70vw",
          overflowY: "scroll",
        }}
        className="modal-content errModal usrType"
      >
        {props.externalStatus !== "rejected" ? (
          <p
            className="alrt"
            style={{ color: "#E47100", fontSize: "26px", marginBottom: "36px" }}
          >
            {t("externalSales.usrType1")}
          </p>
        ) : null}

        {props.externalStatus !== "rejected" ? (
          <p className="alrt" style={{ textAlign: "start" }}>
            {t("externalSales.usrType")}
          </p>
        ) : null}
        {props.externalStatus == "rejected" ? (
          <p className="alrt">{t("externalSales.declineUsr")}</p>
        ) : null}
        {props.externalStatus !== "rejected" ? (
          <div className="row">
            <div className="col-6 alignChoice">
              <input
                type="radio"
                value="postpaid"
                name="type"
                onChange={(e) => setType(e.target.value)}
              />
              <label>{t("externalSales.type1")}</label>
            </div>
            <div className="col-6 alignChoice">
              <input
                type="radio"
                value="prepayment"
                name="type"
                onChange={(e) => setType(e.target.value)}
              />
              <label>{t("externalSales.type2")}</label>
            </div>
            <div className="col-6">
              <div className="clientCode">
                <label>{t("advPayment.code")}</label>
                <input type="number" ref={codeRef} />
              </div>
            </div>
            <div className="col-12">
              <div className="clientCode">
                <label>{t("advPayment.category")}</label>

                <Select
                  required={true}
                  isMulti={true}
                  onChange={(e) => handleCategs(e)}
                  placeholder={i18n.language == "ar" ? "اختر..." : "Select..."}
                  options={props.categArr}
                />
              </div>
            </div>

            <div className="col-12">
              <div className="clientCode">
                <label>{t("advPayment.products")}</label>

                <Select
                  required={true}
                  isMulti={true}
                  value={chosenProducts}
                  isDisabled={disabled}
                  onChange={(e) => handleProducts(e)}
                  placeholder={i18n.language == "ar" ? "اختر..." : "Select..."}
                  options={products}
                />
              </div>
            </div>
          </div>
        ) : null}

        {i18n.language == "ar" ? (
          <span
            style={err ? { textAlign: "center", margin: "1em" } : null}
            className="validation"
          >
            {err ? msgAr : null}
          </span>
        ) : (
          <span
            style={err ? { textAlign: "center", margin: "1em" } : null}
            className="validation"
          >
            {err ? msgEn : null}
          </span>
        )}

        {loading ? (
          <div style={{ height: "3em" }} className="loader">
            <BeatLoader color={"#E47100"} size={20} />
          </div>
        ) : (
          <>
            <button
              onClick={handleApprove}
              className="confirm"
              style={err ? { width: "25%", marginTop: "0" } : { width: "25%" }}
            >
              {t("Login.confirm")}
            </button>
            <span style={{ margin: "0 1em", display: "inline-block" }}></span>
            <button
              onClick={() => props.setModal(false)}
              className="cancelEdit"
            >
              {t("menu.cancel")}
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default UserType;
