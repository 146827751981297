import { Outlet,Navigate } from "react-router-dom";
//if user doesn't have a token they're redirected to login screen
function PrivateRoutes (){

let auth= localStorage.getItem("token")?true:false

return(
    auth?<Outlet/>:<Navigate to="/"/>
)
}
export default PrivateRoutes