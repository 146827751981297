import '../Styles/AddReservation.css';

import Navbar from '../Components/Navbar';
import { useTranslation } from 'react-i18next';
import { RiArrowRightSFill,RiArrowLeftSFill } from 'react-icons/ri';
import orangeCircle from "../Images/orange-circle.svg";
import whiteCircle from "../Images/white-circle.svg";
import tabelsAvilable from "../Images/tabelsAvilable.svg";
import tableUnavilable from "../Images/tableUnavilable.svg";
import {useState,useEffect,useRef}  from 'react';
import { useNavigate } from "react-router-dom";
import { closeTable,openTable,tables,allBranches} from '../api';
import { BeatLoader } from 'react-spinners';
import ErrorModal from '../Components/ErrorModal';

import { Link } from 'react-router-dom';
function AddReservation() {
    
    const { t, i18n } = useTranslation();
const[loading,setLoading]=useState(true);
const[tableLoading,setTableLoading]=useState(false);
const[branches,setBranches]=useState([]);
const[tbls,setTbls]=useState([]);
   let history=useNavigate()
   const[reserveLoad,setReserveLoad]=useState(false)
const[arMsg,setArMsg]=useState()
const[enMsg,setEnMsg]=useState()
const[errModal,setErrModal]=useState(false)
const[close,setClose]=useState(false)

const numRef = useRef()
const brnchRef = useRef()

//allBranches api call to get all branches
//tables api call takes first branch id to get its tables
    
      useEffect(() => {
   
        const token= localStorage.getItem("token");
      
        allBranches(token).then((response) => {
          setBranches(response.data.data)
          if(response.data.data.length > 0){

            tables(token,response.data.data[0].id,"all").then((response) => {
setTbls(response.data.data)
  
      setLoading(false)})}
      }).catch(function(error){
      if (error.response.status === 401) {
         history("/")
      }
      else{
      setArMsg(error.response.data.error)
      setEnMsg( error.response.data.error)
        setErrModal(true);
        setLoading(false)
        setTimeout(function() {
      
            setErrModal(false)
          }, 2500)}
      
      });
       
      },[close]) 

/*

function handleClose(){
  const token= localStorage.getItem("token");

  setReserveLoad(true)

  if(numRef.current.value === ""){

    setArMsg("من فضلك اختر رقم الجلسة")
    setEnMsg("Please choose the table number")
      setErrModal(true);
      setReserveLoad(false)
      setTimeout(function() {
    
          setErrModal(false)
        }, 2500)
}



else{
 

let data={};

closeTable(token,numRef.current.value,data).then((response) => {
  if (response.data.message === "success") {
 
    setClose(!close) 
    setReserveLoad(false)         
  } else {
    setArMsg(response.data.error)
    setEnMsg(response.data.error)
      setErrModal(true);
      setReserveLoad(false)
      setTimeout(function() {
    
          setErrModal(false)
        }, 2500)
  }                      
}).catch(function(error){
  if (error.response.status === 401) {
   history("/")
  }
  setArMsg(error.response.data.error)
  setEnMsg( error.response.data.error)
    setErrModal(true);
    setReserveLoad(false)
    setTimeout(function() {
  
        setErrModal(false)
      }, 2500)
})
}
};
*/

//opens a reserved table
//takes table number
/* NOTICE: table number (the number displayed on table) is different from table ID */
function handleOpen(){
  const token= localStorage.getItem("token");

  
  setReserveLoad(true)

  if(numRef.current.value === ""){

    setArMsg("من فضلك اختر رقم الجلسة")
    setEnMsg("Please choose the table number")
      setErrModal(true);
      setReserveLoad(false)
      setTimeout(function() {
    
          setErrModal(false)
        }, 2500)
}else{
let data={};


openTable(token,numRef.current.value,data).then((response) => {
  let selectElement=   document.getElementById("tableId")
selectElement.selectedIndex = null
  if (response.data.message === "success") {
  

 
    setClose(!close) //to fire useEffect to rerender tables so the table's colour changes
    setReserveLoad(false)   
         
  } else if(response.data.error=="already_free"){
    setArMsg("عفوا هذه الجلسة متاحة بالفعل") 
    setEnMsg("This table is already available")
      setErrModal(true);
      setReserveLoad(false)
      setTimeout(function() {
    
          setErrModal(false)
        }, 2500)
  } 
  
  else {
    setArMsg(response.data.error)
    setEnMsg(response.data.error)
      setErrModal(true);
      setReserveLoad(false)
      setTimeout(function() {
    
          setErrModal(false)
        }, 2500)
  }                      
}).catch(function(error){
  if (error.response.status === 401) {
   history("/")
  }

 else if(error.response.data.error=="already_free"){
    setArMsg("عفوا هذه الجلسة متاحة بالفعل") 
    setEnMsg("This table is already available")
      setErrModal(true);
      setReserveLoad(false)
      setTimeout(function() {
    
          setErrModal(false)
        }, 2500)
  }
  else{
    console.log(error.response.data.error)
  setArMsg(error.response.data.error)
  setEnMsg( error.response.data.error)
    setErrModal(true);
    setReserveLoad(false)
    setTimeout(function() {
  
        setErrModal(false)
      }, 2500)}
})
}
};


//on changing branch
//table number input field is emptied because tables change according to branch
//tables api takes branch id to get the branche's tables
 function handleChangeBranch(id){
   
  const token= localStorage.getItem("token");
  let selectElement=   document.getElementById("tableId")
  selectElement.selectedIndex = null
 

  setTableLoading(true);
 

  tables(token,id).then((response) => {
    setTbls(response.data.data)
          
          setTableLoading(false)}).catch(function(error){
            if (error.response.status === 401) {
               history("/")
            }
      
            setArMsg(error.response.data.error,)
            setEnMsg( error.response.data.error,)
              setErrModal(true);
              setTableLoading(false)
              setTimeout(function() {
            
                  setErrModal(false)
                }, 2500)
            
            });
 }

    return (
      <div >
        
          {errModal?<ErrorModal setModal={setErrModal} msg={i18n.language=="ar"?arMsg:enMsg}/>:null}

          {loading?
               <div style={{height:"80vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
          <div className='container-fluid'>
      <div className='row'>
      <div className='col-3'>
           <div style={i18n.language=="ar"?{left:"1em"}:{right:"1em"}}  className='tableReserve'>
           {reserveLoad?
               <div style={{height:"66vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={reserveLoad}  size={30} /></div>:null}
               <div style={reserveLoad?{display:"none"}:null}>
               <label>{t("tables.chooseBranch")}</label>

               <select defaultValue={branches[0].id} id="branch" ref={brnchRef} onChange={(e)=>handleChangeBranch(e.target.value)} >
{branches?  branches.map((data, index) => 

<option value={data.id} key={data.id}>{i18n.language=="ar"?data.arName:data.enName} </option>

):null}

</select> 

             <label>{t("tables.chooseNo")}</label>
             <select  defaultValue={""} ref={numRef} id="tableId" >
<option value={""} disabled>{t("profile.choose")}</option>

             {tbls && tbls.map((data, index) => 
              <option value={data.id} key={data.id}>{data.tableName}</option>


)}
             </select>

      

<div className='row'>
  {/*
    <div className='col'>
    <button onClick={handleClose} className='closeTable'>{t("tables.close")}</button>
             </div>*/}
    <div className='col'>
    <button  onClick={handleOpen} className='openTable'>
    {t("tables.open")} 
</button>
</div>


</div>

               </div></div>
           </div>  
           
       <div style={i18n.language=="ar"?{paddingLeft:"2em"}:{paddingRight:"2em"}} className='col-9 '>
         <div className='spaceBTn' style={{alignItems:"baseline"}}>
       <div className='navigateOrder'>
<Link to=''>{t("tables.home")}</Link>

{i18n.language=="ar"?
<RiArrowLeftSFill/>: <RiArrowRightSFill/>}
<a >{t("tables.tables")}</a>
 
  </div>
  <Link to="/wait-list"><button className='waitBtn'>{t("menu.waiting")}</button></Link>


</div>
  <div className='tables'>
  {tableLoading?
               <div style={{height:"20vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={tableLoading}  size={30} /></div>:

<div className='row gy-4'>

{tbls && tbls.map((data, index) => 

<div className='col-2' key={data.id}> 

<div  className='avail' style={data.state=="Free" ? { backgroundImage: `url(${tableUnavilable})` }:{ backgroundImage: `url(${tabelsAvilable})`,color:"white"}}>{data.tableName}</div>
</div>
)}


</div> 


}

  </div>



<div className='availability'>
<div className='row'>
<div className='col'>
    <div>
<img style={i18n.language=="ar"?{marginLeft:"0.5em",float:"right"}:{marginRight:"0.5em",float:"left"}} src={orangeCircle}/>
<p>{t("tables.reserved")}</p>
</div>
</div>
<div className='col'>
<img  style={i18n.language=="ar"?{marginLeft:"0.5em",float:"right"}:{marginRight:"0.5em",float:"left"}} src={whiteCircle}/>
<p>{t("tables.avail")}</p>

</div>
</div>

</div>

           </div>   
        
          </div>
       </div>}</div>
    );
  }
  
  export default AddReservation;
  
  