import "../Styles/Questions.css";
import { AiOutlineSearch } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { BeatLoader } from "react-spinners";
import { questions } from "../api";
import Accordion from "react-bootstrap/Accordion";
import { IoIosArrowDown } from "react-icons/io";
import { GrClear } from "react-icons/gr";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

function Questions() {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [question, setQuestion] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem("token");

    questions(token, i18n.language).then((response) => {
      setLoading(false);
      setQuestion(response.data.data);
    });
  }, [i18n.language]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredQuestions = question.filter(
    (q) =>
      q.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
      q.answer.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      {loading ? (
        <div style={{ height: "60vh" }} className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <div className="questions">
          <p className="screenTitle">{t("profile.questions")}</p>

          <div
            className="srchField"
            style={{
              position: "relative",
              display: "inline-block",
            }}
          >
            <TextField
              style={{
                width: "40vw",
                height: "7vh",
                marginBottom: "5vh",
                borderRadius: "10px",
              }}
              variant="outlined"
              placeholder={t("dashboard.search")}
              value={searchTerm}
              onChange={(e) => {
                handleSearch(e);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setSearchTerm("")}
                      edge="end"
                      aria-label="clear search"
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <Accordion>
            {filteredQuestions &&
              filteredQuestions.map((data, index) => (
                <>
                  {data.isActive == 1 ? (
                    <Accordion.Item
                      eventKey={index}
                      key={index}
                      style={
                        index == 0
                          ? { borderRadius: " 0.25rem  0.25rem 0 0" }
                          : null
                      }
                      className={
                        i18n.language == "ar" ? "accordAr" : "accordEn"
                      }
                    >
                      <Accordion.Header>
                        <p className="question-title">{data.question} </p>
                        <div>
                          <IoIosArrowDown size={20} />
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="question-answer">{data.answer}</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : null}
                </>
              ))}
          </Accordion>

          {filteredQuestions.length == 0 ? (
            <div className="noQuestions">
              <GrClear />
              <p style={{ textAlign: "center" }} className="question-title">
                {t("addOrder.noResult")}
              </p>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
}

export default Questions;
