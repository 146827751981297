import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Login from "./Pages/Login";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dashboard from "./Pages/Dashboard";
import Areas from "./Pages/Areas";
import AddOrder from "./Pages/AddOrder";
import Menu from "./Pages/Menu";
import AddReservation from "./Pages/AddReservation";
import CustomerOrders from "./Pages/CustomerOrders";
import CustomerComplaints from "./Pages/CustomerComplaints";
import CustomerAwafiWallet from "./Pages/CustomerAwafiWallet";
import CustomerPoints from "./Pages/CustomerPoints";
import CustomerAddress from "./Pages/CustomerAddress";
import AddAddress from "./Pages/AddAddress";
import AddComplaint from "./Pages/AddComplaint";
import EditProfile from "./Pages/EditProfile";
import AddCustomer from "./Pages/AddCustomer";
import Navbar from "./Components/Navbar";
import OrderSuccess from "./Pages/OrderSuccess";
import EditAddress from "./Pages/EditAddress";
import { BeatLoader } from "react-spinners";
import Toast from "react-bootstrap/Toast";
import WaitList from "./Pages/WaitList";
import OrdersList from "./Pages/OrdersList";
import OrderDetails from "./Pages/OrderDetails";
import RegAwafi from "./Pages/RegAwafi";
import Offers from "./Pages/Offers";
import Questions from "./Pages/Questions";
import Calories from "./Pages/Calories";
import ExternalSales from "./Pages/ExternalSales";
import ExternalUser from "./Pages/ExternalUser";
import RegisterStep1 from "./Pages/ExternalRegister/RegisterStep1";
import RegisterStep2 from "./Pages/ExternalRegister/RegisterStep2";
import RegisterStep3 from "./Pages/ExternalRegister/RegisterStep3";
import AdvPaymentReg from "./Pages/ExternalRegister/AdvPaymentReg";
import PrivateRoutes from "./utils/PrivateRoutes";
import ErrorModal from "./Components/ErrorModal";
import Chat from "./Pages/Chat";
import UserChat from "./Pages/UserChat";
import Quantity from "./Pages/Quantity";
import NotFound from "./Pages/NotFound";
import CustomerTax from "./Pages/CustomerTax";
import AddTax from "./Pages/AddTax";
import CustomerWallet from "./Pages/CustomerWallet";
import UserProfile from "./Pages/UserProfile";
import PaymentPos from "./Pages/PaymentPos";
import MenuCashier from "./Pages/MenuCashier";
import PartialPayment from './Pages/partialPayment'

import PromoCodes from "./Pages/PromoCodes.js"
import AvaliableOffers from "./Pages/AvaliableOffers.js"



import InvoicePage from "./Pages/invoice";

function App() {
  const { t, i18n } = useTranslation();
  const [notify, setNotify] = useState(false);
  const [news, setNews] = useState(false);
  const [chatCount, setChatCount] = useState(0);
  const [err, setErr] = useState(false);
  const [msg, setMsg] = useState("");
  const [style, setStyle] = useState(null);
  var dt = new Date();
  const year = dt.getFullYear();
  const [toast, setToast] = useState({ title: "", body: "" });

  const userType = localStorage.getItem("type");

  /*function handleMessage() {
onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  setToast({
    title:payload.notification.title,
    body: payload.notification.body,
  })
  setNotify(true)
  // ...
});
}
handleMessage();
 
*/

  document.addEventListener("wheel", function (event) {
    if (document.activeElement.type === "number") {
      document.activeElement.blur();
    }
  });

  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker
        .register("/service-worker.js")
        .then((registration) => {
          console.log("SW registered: ", registration);
        })
        .catch((registrationError) => {
          console.log("SW registration failed: ", registrationError);
        });
    });
  }

  useEffect(() => {
    if (i18n.language === "en") {
      document.getElementById("root").style.direction = "ltr";
    } else if (i18n.language === "ar") {
      document.getElementById("root").style.direction = "rtl";
    } else {
      document.getElementById("root").style.direction = "rtl";
    }
  }, []);

  return (
    <BrowserRouter>
      <div className="App" style={{ position: "relative", minHeight: "100vh" }}>
        <Routes>
          <Route exact path="/order/invoice" element={<InvoicePage />} />

          <Route element={<PrivateRoutes />}>
            <Route
              element={
                <>
                  <Navbar
                    setNews={setNews}
                    setChatCount={setChatCount}
                    chatCount={chatCount}
                  />
                  {err ? <ErrorModal style={style} msg={msg} /> : null}

                  <Toast
                    onClose={() => setNotify(false)}
                    show={notify}
                    delay={7000}
                    autohide
                  >
                    <Toast.Header>
                      <p>{toast.title}</p>
                    </Toast.Header>
                    <Toast.Body>{toast.body}</Toast.Body>
                  </Toast>

                  <Outlet />
                </>
              }
            >
              <Route
                element={
                  <>
                    <Outlet />

                    <div className="footerLink" id="footer">
                      <p>
                        {t("Login.copy_write_parg")}
                        <span
                          style={
                            i18n.language == "ar"
                              ? { marginRight: "5px" }
                              : { marginLeft: "5px" }
                          }
                        >
                          © {year}{" "}
                        </span>
                      </p>

                      <p>
                        {t("Login.powered")}{" "}
                        <a
                          target="_blank"
                          style={
                            i18n.language == "ar"
                              ? { marginRight: "5px" }
                              : { marginLeft: "5px" }
                          }
                          href="https://www.bdaiat.com/"
                        >
                          {" "}
                          {t("Login.bdaiat")}
                        </a>
                      </p>
                    </div>
                  </>
                }
              >
                <Route exact path="/areas" element={<Areas news={news} />} />
                {localStorage.getItem("token") ? (
                  <Route path="*" element={<NotFound />} />
                ) : null}

                <Route
                  exact
                  path="/chats/:id"
                  element={
                    <Chat
                      setMsg={setMsg}
                      setStyle={setStyle}
                      setErr={setErr}
                      setChatCount={setChatCount}
                    />
                  }
                />
                <Route
                  exact
                  path="/chat/:id"
                  element={
                    <UserChat
                      setMsg={setMsg}
                      setStyle={setStyle}
                      setErr={setErr}
                    />
                  }
                />

                <Route exact path="/order/invoice" element={<InvoicePage />} />

                <Route
                  exact
                  path="/dashboard"
                  element={
                    <Dashboard
                      setMsg={setMsg}
                      setStyle={setStyle}
                      setErr={setErr}
                    />
                  }
                />
                <Route exact path="/add-customer" element={<AddCustomer />} />
                <Route exact path="/add-order" element={<AddOrder />} />
                <Route exact path="/quantity" element={<Quantity />} />
                <Route exact path="/promo-codes" element={<PromoCodes />} />
                <Route exact path="/avaliable-offers" element={<AvaliableOffers />} />

                

                

                <Route exact path="/order-success" element={<OrderSuccess />} />
                <Route exact path="/external-user" element={<ExternalUser />} />
                <Route exact path="/register-1" element={<RegisterStep1 />} />
                <Route exact path="/register-2" element={<RegisterStep2 />} />
                <Route exact path="/register-3" element={<RegisterStep3 />} />
                <Route exact path="/adv-payment" element={<AdvPaymentReg />} />

                <Route
                  exact
                  path="/external-sales"
                  element={
                    <ExternalSales
                      setMsg={setMsg}
                      setStyle={setStyle}
                      setErr={setErr}
                    />
                  }
                />

                <Route
                  exact
                  path="/customer-orders"
                  element={
                    <CustomerOrders
                      setErr={setErr}
                      setMsg={setMsg}
                      setStyle={setStyle}
                    />
                  }
                />
                <Route
                  exact
                  path="/profile"
                  element={<UserProfile setMsg={setMsg} setErr={setErr} />}
                />
                <Route
                  exact
                  path="/customer-complaints"
                  element={
                    <CustomerComplaints
                      setMsg={setMsg}
                      setStyle={setStyle}
                      setErr={setErr}
                    />
                  }
                />
                <Route
                  exact
                  path="/customer-points"
                  element={
                    <CustomerPoints
                      setMsg={setMsg}
                      setStyle={setStyle}
                      setErr={setErr}
                    />
                  }
                />
                <Route
                  exact
                  path="/customer-addresses"
                  element={
                    <CustomerAddress
                      setMsg={setMsg}
                      setStyle={setStyle}
                      setErr={setErr}
                    />
                  }
                />
                <Route
                  exact
                  path="/customer-invoice"
                  element={
                    <CustomerTax
                      setMsg={setMsg}
                      setStyle={setStyle}
                      setErr={setErr}
                    />
                  }
                />
                <Route
                  exact
                  path="/add-tax"
                  element={
                    <AddTax
                      setMsg={setMsg}
                      setStyle={setStyle}
                      setErr={setErr}
                    />
                  }
                />

                <Route exact path="/add-address" element={<AddAddress />} />
                <Route
                  exact
                  path="/edit-address/:id"
                  element={<EditAddress />}
                />
                <Route
                  exact
                  path="/customer-awafi-wallet"
                  element={
                    <CustomerAwafiWallet
                      setMsg={setMsg}
                      setStyle={setStyle}
                      setErr={setErr}
                    />
                  }
                />
                <Route
                  exact
                  path="/customer-wallet"
                  element={
                    <CustomerWallet
                      setMsg={setMsg}
                      setStyle={setStyle}
                      setErr={setErr}
                    />
                  }
                />
                <Route
                  exact
                  path="/partial-payment/:id"
                  element={
                    <PartialPayment
                      setMsg={setMsg}
                      setStyle={setStyle}
                      setErr={setErr}
                    />
                  }
                />
                <Route
                  exact
                  path="/add-complaint"
                  element={
                    <AddComplaint
                      setMsg={setMsg}
                      setStyle={setStyle}
                      setErr={setErr}
                    />
                  }
                />
                <Route exact path="/edit-customer" element={<EditProfile />} />
                <Route
                  exact
                  path="/add-reservation"
                  element={<AddReservation />}
                />
                <Route exact path="/my-order/:id" element={<OrderDetails />} />
                <Route
                  exact
                  path="/my-orders"
                  element={
                    <OrdersList
                      setMsg={setMsg}
                      setStyle={setStyle}
                      setErr={setErr}
                    />
                  }
                />
                <Route exact path="/awafi-register" element={<RegAwafi />} />
                <Route exact path="/questions" element={<Questions />} />
                <Route exact path="/offers" element={<Offers />} />
                <Route exact path="/calories" element={<Calories />} />
                <Route exact path="/payment-pos" element={<PaymentPos />} />
              </Route>
              <Route
                exact
                path="/menu"
                element={
                  userType === "cashier" ? (
                    <MenuCashier
                      setMsg={setMsg}
                      setStyle={setStyle}
                      setErr={setErr}
                    />
                  ) : (
                    <Menu setMsg={setMsg} setStyle={setStyle} setErr={setErr} />
                  )
                }
              />
            </Route>
          </Route>
          <Route exact path="/wait-list" element={<WaitList />} />

          <Route
            element={
              <>
                <Outlet />

                <div className="footerLink" id="footer">
                  <p>
                    {t("Login.copy_write_parg")}
                    <span
                      style={
                        i18n.language == "ar"
                          ? { marginRight: "5px" }
                          : { marginLeft: "5px" }
                      }
                    >
                      © {year}{" "}
                    </span>
                  </p>

                  <p>
                    {t("Login.powered")}{" "}
                    <a
                      target="_blank"
                      style={
                        i18n.language == "ar"
                          ? { marginRight: "5px" }
                          : { marginLeft: "5px" }
                      }
                      href="https://www.bdaiat.com/"
                    >
                      {" "}
                      {t("Login.bdaiat")}
                    </a>
                  </p>
                </div>
              </>
            }
          >
            <Route exact path="/" element={<Login />} />
            {localStorage.getItem("token") ? null : (
              <Route path="*" element={<NotFound />} />
            )}
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
