import React, { useState, useEffect } from "react";
import "../Styles/UserProfile.css";
import { getUserProfile, getStimulatingProduct } from "../api";
import { useTranslation } from "react-i18next";
import Toast from "react-bootstrap/Toast";
import { BeatLoader } from "react-spinners";
import { MDBDataTable } from "mdbreact";
import { Pagination, Stack, PaginationItem } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";

const UserProfile = () => {
  const [avgInvoicePrice, setAvgInvoicePrice] = useState(null);
  const [stimulatingOrdersCount, setStimulatingOrdersCount] = useState(null);
  const [totalOrders, setTotalOrders] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [toast, setToast] = useState(null);
  const [notify, setNotify] = useState(false);
  const [loading, setLoading] = useState(true);
  const [stimulatingProduct, setStimulatingProduct] = useState([]);

  const { t, i18n } = useTranslation();
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchData();
    fetchStimulatingProduct();
  }, []);

  const fetchData = async (fromFilter, toFilter) => {
    const formatDate = (date) => date.toISOString().split("T")[0];
    let from = fromFilter;
    let to = toFilter;

    if (!from || !to) {
      const today = new Date();
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 2);
      from = formatDate(firstDayOfMonth);
      to = formatDate(today);
      setFromDate(from);
      setToDate(to);
    }
    try {
      const res = await getUserProfile(token, from, to);
      if (res && res.data && res.data.data) {
        setAvgInvoicePrice(res.data.data.avgInvoicePrice);
        setStimulatingOrdersCount(res.data.data.stimulatingOrdersCount);
        setTotalOrders(res.data.data.totalOrders);
        setLoading(false);
      }
    } catch (error) {
      setNotify(true);
      setToast(error.response.data.errors["to"][0]);
      console.error(error.response.data.errors["to"][0]);
    }
  };

  const fetchStimulatingProduct = async (fromFilter, toFilter) => {
    const formatDate = (date) => date.toISOString().split("T")[0];
    let from = fromFilter;
    let to = toFilter;

    if (!from || !to) {
      const today = new Date();
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 2);
      from = formatDate(firstDayOfMonth);
      to = formatDate(today);
      setFromDate(from);
      setToDate(to);
    }
    try {
      const stimProucts = await getStimulatingProduct(token, from, to);
      if (stimProucts && stimProucts.data && stimProucts.data.data) {
        setStimulatingProduct(stimProucts.data.data);
        setLoading(false);
      }
    } catch (error) {
      setNotify(true);
      setToast(error.response.data.errors["to"][0]);
      console.error(error.response.data.errors["to"][0]);
    }
  };

  const handleFilter = () => {
    fetchData(fromDate, toDate);
    fetchStimulatingProduct(fromDate, toDate);
  };

  const handleAccordionChange = (event, expanded) => {
    if (expanded) {
      fetchStimulatingProduct();
    }
  };

  const data = {
    columns: [
      {
        label: t("offer.id1"),
        field: "id",
        sort: "asc",
        width: 270,
      },
      {
        label: t("offer.stimalte_Product"),
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: t("orders.order_count"),
        field: "product_count",
        sort: "asc",
        width: 150,
      },
    ],
    rows: stimulatingProduct.map((product) => ({
      id: product.product_id,
      name: product.name,
      product_count: product.total_count
    }))
  };

  return (
    <div className="container">
      {/* Toast for showing error messages */}
      <Toast
        onClose={() => setNotify(false)}
        show={notify}
        delay={5000}
        autohide
        className="bg-danger"
      >
        <Toast.Body>{toast}</Toast.Body>
      </Toast>
      <div className="date-picker">
        {/* Date input fields and filter button */}
        <div className="date-input">
          <label style={{ fontFamily: "cairo" }} htmlFor="from">
            {t("orders.from")}
          </label>
          <input
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            className="datepicker"
            type="date"
            id="from"
            name="from"
          />
        </div>
        <div className="date-input">
          <label style={{ fontFamily: "cairo" }} htmlFor="to">
            {t("orders.to")}
          </label>
          <input
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            className="datepicker"
            type="date"
            id="to"
            name="to"
          />
        </div>
        <button
          style={{ fontFamily: "cairo" }}
          onClick={handleFilter}
          type="button"
        >
          {t("orders.filter")}
        </button>
      </div>
      {loading ? (
        <div style={{ height: "110vh" }} className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <>
          {/* Data display */}
          <div className="data-container">
            <div style={{ paddingTop: "4vh" }} className="data-item">
              <p className="data-title">{t("orders.orders")}</p>
              <p className="data-value">
                {totalOrders && totalOrders} {t("orders.order")}{" "}
              </p>
            </div>
            <div style={{ paddingTop: "4vh" }} className="data-item">
              <p className="data-title">{t("orders.stimulating")}</p>
              <p className="data-value">
                {stimulatingOrdersCount && stimulatingOrdersCount}{" "}
                {t("orders.meal")}
              </p>
            </div>
            <div style={{ paddingTop: "4vh" }} className="data-item">
              <p className="data-title">{t("orders.averageInvoice")}</p>
              <p className="data-value">
                {avgInvoicePrice && avgInvoicePrice}{" "}
                {i18n.language == "ar" ? "ريال" : "SAR"}
              </p>
            </div>
          </div>

          <Accordion style={{ marginTop: "7vh" }} onChange={handleAccordionChange}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <p
                style={{
                  color: "orange",
                  fontFamily: "cairo",
                  fontWeight: "bold",
                }}
              >
                {t("orders.stimulating")}
              </p>
            </AccordionSummary>

            <AccordionDetails>
              <MDBDataTable
                entries={50}
                entriesOptions={[50]}
                infoLabel={[
                  t("orders.info1"),
                  t("orders.info2"),
                  t("orders.info3"),
                  t("orders.info4"),
                ]}
                searchLabel={t("dashboard.search")}
                paginationLabel={[t("orders.prev"), t("orders.next")]}
                entriesLabel={t("orders.show")}
                striped
                hover
                noBottomColumns={true}
                data={data}
              />
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </div>
  );
};

export default UserProfile;
