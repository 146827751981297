import "../Styles/CustomerOrders.css";

import Navbar from "../Components/Navbar";
import { useTranslation } from "react-i18next";
import { FiEdit } from "react-icons/fi";
import { BsGlobe2, BsInfoCircle } from "react-icons/bs";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import bckg from "../Images/order.svg";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { listExternalUsers, externalCategories } from "../api";
import { BeatLoader } from "react-spinners";
import { useParams, generatePath } from "react-router-dom";
import CustomerData from "../Components/CustomerData";
import { MDBDataTable } from "mdbreact";
import UserType from "../Components/UserType";
import { useCallback } from "react";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import PaginationItem from "@mui/material/PaginationItem";

function ExternalSales(props) {
  const { t, i18n } = useTranslation();
  const [tableLoading, setTableLoading] = useState(false);
  const [categArr, setCategArr] = useState([]);

  const [loading, setLoading] = useState(true);
  const [approve, setApprove] = useState(false);
  const [update, setUpdate] = useState(false);
  const [page, setPage] = useState(1);
  const [resp, setResp] = useState();

  const [externalStatus, setExternalStatus] = useState();
  const [id, setId] = useState();

  const [users, setUsers] = useState([]);

  let history = useNavigate();

  //listExternalUsers=> returns list of external users
  //onClicking name id is stored and agent is redirected to chosen external user's profile
  //depending on status either buttons are displayed (if status is pending) else status is displayed
  //onClicking buttons (type of button action is set (approved/rejected), external user id is set and modal is opened)
  useEffect(() => {
    const token = localStorage.getItem("token");

    externalCategories(token).then((response) => {
      let items = [];

      for (let i = 0; i < response.data.data.length; i++) {
        let obj = {};
        obj.label =
          i18n.language == "ar"
            ? response.data.data[i].arName
            : response.data.data[i].enName;
        obj.value = response.data.data[i].id;

        items.push(obj);
        //creating  options dynamically based on what props are currently passed to the parent component
      }
      setCategArr(items);
    });

    listExternalUsers(token, page)
      .then((response) => {
        setResp(response.data);
        let compArr = [];
        for (let i = 0; i < response.data.data.length; i++) {
          let obj = {};

          obj.name = (
            <p
              onClick={() => {
                localStorage.setItem("externalId", response.data.data[i].id);
                history("/external-user");
              }}
              className="usrLink"
            >
              {response.data.data[i].user.name}
            </p>
          );
          obj.phone = response.data.data[i].user.phone;
          obj.facility = response.data.data[i].facilityName;
          obj.activity =
            i18n.language == "ar"
              ? response.data.data[i].facilityActivity.activityNameAr
              : response.data.data[i].facilityActivity.activityNameEn;
          obj.city =
            i18n.language == "ar"
              ? response.data.data[i].city.arName
              : response.data.data[i].city.enName;
          obj.type =
            response.data.data[i].externalSaleType == "postpaid"
              ? t("externalSales.type1")
              : response.data.data[i].externalSaleType == "prepayment"
              ? t("externalSales.type2")
              : t("externalSales.new");

              obj.actions = (
                <div
                  className="actionBtn"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {response.data.data[i].status === "pending" ? (
                    <>
                      <button
                        onClick={() => {
                          myFunction(response.data.data[i].id, "approved", true);
                        }}
                      >
                        {t("externalSales.approve")}
                      </button>
                      <span style={{ margin: "0.5em" }}></span>
                      <button
                        onClick={() => {
                          myFunction(response.data.data[i].id, "rejected", true);
                        }}
                        className="decline"
                      >
                        {t("externalSales.decline")}
                      </button>
                    </>
                  ) : response.data.data[i].status === "rejected" ? (
                    <>
                    <button
                      onClick={() => {
                        myFunction(response.data.data[i].id, "approved", true);
                      }}
                    >
                      {t("externalSales.approve")}
                    </button>
                    <span style={{ margin: "0.5em" }}></span>
                    <p
                      style={
                        response.data.data[i].status === "approved"
                          ? { color: "#E47100" }
                          : { color: "rgb(220, 43, 43)" }
                      }
                      className="status"
                    >
                      {response.data.data[i].status === "approved"
                        ? t("externalSales.approved")
                        : t("externalSales.declined")}
                    </p>
                    
                  </>
                  ) : (
                    <p
                      style={
                        response.data.data[i].status === "approved"
                          ? { color: "#E47100" }
                          : { color: "rgb(220, 43, 43)" }
                      }
                      className="status"
                    >
                      {response.data.data[i].status === "approved"
                        ? t("externalSales.approved")
                        : t("externalSales.declined")}
                    </p>
                  )}
                </div>
              );
              

          compArr.push(obj);
        }

        setUsers(compArr);

        setLoading(false);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          history("/");
        } else if (error.response.status === 403) {
          props.setErr(true);
          props.setMsg(t("validation.noPermission"));
          props.setStyle({ top: "50%" });

          setTimeout(function () {
            props.setErr(false);
            history("/dashboard");
          }, 2500);
        }
      });
  }, [update, page, i18n.language]);

  const myFunction = useCallback((id, status, bool) => {
    setId(id);
    setExternalStatus(status);
    setApprove(bool);
  }, []);

  const data = {
    columns: [
      {
        label: t("externalSales.yourName"),
        field: "name",
        sort: "asc",
      },
      {
        label: t("externalSales.byPhone"),
        field: "phone",
        sort: "asc",
      },
      {
        label: t("externalSales.company"),
        field: "facility",
        sort: "asc",
      },

      {
        label: t("externalSales.companyActiv"),
        field: "activity",
        sort: "asc",
      },
      {
        label: t("externalSales.city"),
        field: "city",
        sort: "asc",
      },
      {
        label: t("externalSales.clientType"),
        field: "type",
        sort: "asc",
      },
      {
        label: "",
        field: "actions",
        sort: "asc",
      },
    ],
    rows: users,
  };

  function changePage(value) {
    const token = localStorage.getItem("token");
    window.scrollTo(0, 0);
    setTableLoading(true);
    setPage(value);

    listExternalUsers(token, value).then((response) => {
      setResp(response.data);
      if (response.data.toString() == []) {
        setUsers([]);
        setTableLoading(false);
      } else {
        const result = Object.values(response.data.data);

        let compArr = [];
        for (let i = 0; i < result.length; i++) {
          let obj = {};
          obj.name = (
            <p
              onClick={() => {
                localStorage.setItem("externalId", result[i].id);
                history("/external-user");
              }}
              className="usrLink"
            >
              {result[i].user.name}
            </p>
          );
          obj.phone = result[i].user.phone;
          obj.facility = result[i].facilityName;
          obj.activity =
            i18n.language == "ar"
              ? result[i].facilityActivity.activityNameAr
              : result[i].facilityActivity.activityNameEn;
          obj.city =
            i18n.language == "ar"
              ? result[i].city.arName
              : result[i].city.enName;
          obj.type =
            response.data.data[i].externalSaleType == "postpaid"
              ? t("externalSales.type1")
              : response.data.data[i].externalSaleType == "prepayment"
              ? t("externalSales.type2")
              : t("externalSales.new");

          obj.actions = (
            <div className="actionBtn">
              {result[i].status == "pending" ? (
                <>
                  <button
                    onClick={() => {
                      myFunction(result[i].id, "approved", true);
                    }}
                  >
                    {t("externalSales.approve")}
                  </button>
                  <span style={{ margin: "0.5em" }}></span>{" "}
                  <button
                    onClick={() => {
                      myFunction(result[i].id, "rejected", true);
                    }}
                    className="decline"
                  >
                    {t("externalSales.decline")}
                  </button>
                </>
              ) : (
                <p
                  style={
                    result[i].status !== "approved"
                      ? { color: "rgb(220, 43, 43)" }
                      : { color: "#E47100" }
                  }
                  className="status"
                >
                  {result[i].status == "approved"
                    ? t("externalSales.approved")
                    : t("externalSales.declined")}
                </p>
              )}
            </div>
          );

          compArr.push(obj);
        }

        setUsers(compArr);
        setTableLoading(false);
      }
    });
  }

  return (
    <div>
      {approve ? (
        <UserType
          categArr={categArr}
          setModal={setApprove}
          setUpdate={setUpdate}
          update={update}
          externalStatus={externalStatus}
          id={id}
        />
      ) : null}
      {loading ? (
        <div style={{ height: "80vh" }} className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <div style={{ marginTop: "2em" }} className="container">
          <p
            style={{ marginBottom: "0", textAlign: "start" }}
            className="screenTitle"
          >
            {t("externalSales.externalSales")}
          </p>

          <div className="customerBody externalUsers">
            {tableLoading ? (
              <div style={{ height: "30vh" }} className="loader">
                <BeatLoader
                  color={"#E47100"}
                  loading={tableLoading}
                  size={30}
                />
              </div>
            ) : (
              <>
                <div
                  className={
                    i18n.language == "ar"
                      ? "container-fluid pagAr"
                      : "container-fluid"
                  }
                >
                  <div
                    className={
                      i18n.language == "ar"
                        ? "row orderTable compTable"
                        : "row tableEn orderTable compTableEn"
                    }
                  >
                    <div
                      className={
                        users.length == 0
                          ? i18n.language == "ar"
                            ? " emptyAr empty col-lg-12 col-md-12 col-sm-12 col-xs-12"
                            : "  empty col-lg-12 col-md-12 col-sm-12 col-xs-12"
                          : "col-lg-12 col-md-12 col-sm-12 col-xs-12"
                      }
                    >
                      <MDBDataTable
                        entries={15}
                        entriesOptions={[15]}
                        infoLabel={[
                          t("orders.info1"),
                          t("orders.info2"),
                          t("orders.info3"),
                          t("orders.info4"),
                        ]}
                        searchLabel={t("dashboard.search")}
                        paginationLabel={[t("orders.prev"), t("orders.next")]}
                        entriesLabel={t("orders.show")}
                        striped
                        hover
                        noBottomColumns={true}
                        data={data}
                      />
                    </div>
                  </div>
                </div>
                <Stack spacing={0}>
                  <Pagination
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{
                          previous: i18n.language == "ar" ? "prevAr" : "prevEn",
                          next: i18n.language == "ar" ? "nextAr" : "nextEn",
                        }}
                        {...item}
                      />
                    )}
                    className={i18n.language == "ar" ? "arArrow" : null}
                    defaultPage={1}
                    page={page}
                    count={resp.last_page}
                    onChange={(e, value) => {
                      changePage(value);
                    }}
                    shape="rounded"
                    variant="outlined"
                  />
                </Stack>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ExternalSales;
