import React, { useState, useEffect } from 'react';
import '../Styles/pos.css';

const PaymentPos = () => {
  const [ws, setWs] = useState(null);
  const [status, setStatus] = useState('disconnected');
  const [amount, setAmount] = useState('');

  useEffect(() => {
    return () => {
      if (ws) {
        ws.close();
        console.log('WebSocket connection closed.');
      }
    };
  }, [ws]);

  const connectWebSocket = () => {
    const socket = new WebSocket('ws://localhost:7001/intersoft_integration');
    socket.onopen = () => {
      setStatus('connected');
      console.log('WebSocket connection established.');
      setWs(socket);
    };
    socket.onmessage = evt => {
      console.log('Message received:', evt.data);
    };
    socket.onerror = evt => {
      console.error('WebSocket error:', evt.message);
    };
    socket.onclose = () => {
      setStatus('disconnected');
      console.log('WebSocket connection closed.');
      setWs(null);
    };
  };

  const disconnectWebSocket = () => {
    if (ws) {
      ws.close();
    }
  };

  const sendPayment = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({ Command: "SALE", Amount: amount }));
      setAmount('');
    } else {
      console.error('WebSocket connection is closed.');
    }
  };

  return (
    <div className="app">
      <div id="custmDiv">
        <button onClick={connectWebSocket} style={{ display: status === 'disconnected' ? 'block' : 'none' }}>Connect</button>
        <button onClick={disconnectWebSocket} style={{ display: status === 'connected' ? 'block' : 'none' }}>Disconnect</button>
        <div style={{ float: 'right' }}>
          <span className={status === 'connected' ? 'ConnectedCircle' : 'DisconnectedCircle'}></span>
          <span>{status.charAt(0).toUpperCase() + status.slice(1)}.</span>
        </div>
      </div>
      {status === 'connected' && (
        <div id="Box">
          <div id="Pay">
            <input type="number" placeholder="Enter Amount: 000.01" value={amount} onChange={e => setAmount(e.target.value)} />
            <button onClick={sendPayment}>Pay</button>
          </div>
          <div id="result">
            <button onClick={() => console.log('Clear')}>Clear</button>
            <textarea id="output" style={{ width: '100%' }} rows="20"></textarea>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentPos;
