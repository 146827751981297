import notFound from"../Images/404.png";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'; 

function NotFound(){
 const { t, i18n } = useTranslation();

        return (
          <div className={localStorage.getItem("token")?"vertAlign":"fullScreen"}>
   

<div style={{marginTop:"3em",paddingBottom:"5em",height:"100%"}} className=' notFountContainer orderSuccess'>
  

  <div className='shareForm foundUrl'  style={{ backgroundImage: `url(${notFound})`}}>

  <p>{t("Login.notFound")}</p>

</div></div>

    
                </div>
           
        )
    }


    export default NotFound;
