import '../Styles/Areas.css';

import Navbar from '../Components/Navbar';
import { useTranslation } from 'react-i18next';

import { editCustomer } from '../api';
import { BeatLoader } from 'react-spinners';
import * as yup from "yup"
import { useRef,useEffect,useState } from 'react';
import { useNavigate } from "react-router-dom";
import {useFormik} from "formik";
import ErrorModal from '../Components/ErrorModal';
function EditProfile() {
   
    const { t, i18n } = useTranslation();

   const[loading,setLoading]=useState(false);
  const  customerName=   localStorage.getItem("customerName");
  let history= useNavigate()
  const  customerPhone=  localStorage.getItem("customerPhone");
  const  customerPhoto=  localStorage.getItem("customerPhoto");
  const  customerNotes=  localStorage.getItem("customerNotes");
  const[arMsg,setArMsg]=useState()
const[enMsg,setEnMsg]=useState()
const[errModal,setErrModal]=useState(false)
  const[notes,setNotes]=useState(customerNotes)
  const customerId= localStorage.getItem("customerId");
  const nameRef=useRef();
  const Schema= yup.object().shape({
    name: yup.string().required(("validation.cstmrName_required")),
  
    })
    
    
    const formik = useFormik({
        initialValues:{
          name: customerName,
    
        },
        validationSchema: Schema,
      onSubmit,
     }); 

function onSubmit(){
  const token= localStorage.getItem("token");
          
            
setLoading(true)

let values={

  name: nameRef.current.value,
  notes: notes,

}


 //takes customer name and notes
 //redirects to customer's orders screen if success
editCustomer(token,customerId,values).then((response) => {
  if (response.data.message === "success") {
      localStorage.setItem("customerName", values.name);
      localStorage.setItem("customerNotes", values.notes);
     history("/customer-orders")              
  } else {
    setArMsg(   "حدث خطأ")
    setEnMsg( "Something went wrong")
      setErrModal(true);
      setLoading(false)
      setTimeout(function() {
      
        setErrModal(false)
      }, 2500)
  }                      
}).catch(function(error){
  setArMsg(   "حدث خطأ")
  setEnMsg( "Something went wrong")
    setErrModal(true);
    setLoading(false)
    setTimeout(function() {
    
      setErrModal(false)
    }, 2500)
  
})
}
   
    return (
      <div >
        
          {errModal?<ErrorModal setModal={setErrModal} msg={i18n.language=="ar"?arMsg:enMsg}/>:null}
          <div style={{height:"80vh"}} className='container'>
           
      <div className='editCstmr'>

      {loading?
               <div style={{height:"60vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
<form onSubmit={formik.handleSubmit}>
<p>{t("profile.editCstmr")}</p>
<div>
<label> {t("profile.cstmrName")}</label>
<input  id="name" style={{width:"100%"}}   value={formik.values.name}  name="name"  ref={nameRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.name && formik.touched.name || errModal?"invalid":null}/>
<span className='validation'>{formik.errors.name && formik.touched.name?t(`${formik.errors.name}`):null}</span>
 

<label> {t("profile.cstmrNotes")}</label>
<input value={notes} onChange={(e)=>setNotes(e.target.value)}/>


</div>
<button type="submit">{t("profile.save")}</button>
</form>}
      </div>
       </div></div>
    );
  }
  
  export default EditProfile;
  
  