 
import React, {useState,useRef,useEffect} from 'react';
import { RiArrowRightSFill,RiArrowLeftSFill } from 'react-icons/ri';
import img1 from '../../Images/file1.jpeg'
import img2 from '../../Images/file2.jpeg'
import img3 from '../../Images/file3.jpeg'
import { useTranslation } from 'react-i18next';
import { BeatLoader } from 'react-spinners';
 import {BsUpload} from "react-icons/bs";
import * as yup from "yup"
import {useFormik} from "formik";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import ImageModal from '../../Components/ImageModal';
import {GrDocumentPdf}  from 'react-icons/gr';
import { BsQuestionCircleFill } from 'react-icons/bs';
import { register3 } from '../../api';
import { getDocuments } from '../../api';
import { MDBDataTable } from 'mdbreact';
import { IoDocumentAttachOutline} from "react-icons/io5";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import PaginationItem from '@mui/material/PaginationItem';
import UploadFile from '../../Components/UploadFile';



function Documents(props) {
 
    const { t, i18n } = useTranslation();
    const [loading,setLoading]=useState(true);
const [msgAr, setMsgAr]=useState();
const [msgEn, setMsgEn]=useState();
const [errorMsgModel, setErrorMsgModel]=useState(false);
const [upload,setUpload]=useState(false);
const [docs,setDocs]=useState([]);
const [page,setPage]=useState(1);
const [resp,setResp]=useState();
const [id,setId]=useState();

const [modal,setModal]=useState(false);
const [img, setImg]=useState();
const [update, setUpdate]=useState();

      const history=useNavigate();
      const token= localStorage.getItem("token");

      
      function handleDate(orderDate){
        const isoDate = orderDate;
    const date = new Date(isoDate);
    
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    }
      

      useEffect(() => {
        getDocuments(token,props.data.id,1).then((response)=>{
            setResp(response.data.data)

            let arr = [];

  for (let i = 0; i < response.data.data.data.length; i++) {  
      let obj = {};
      obj.createdAt = handleDate( response.data.data.data[i].createdAt);        
      obj.type =   response.data.data.data[i].type =="other"?t("docs.type1"):response.data.data.data[i].type =="association request"? t("docs.type2"):t("docs.type3"); 
      obj.note =  response.data.data.data[i].note==null?t("docs.none"):response.data.data.data[i].note;  
      obj.attachments =
      
      response.data.data.data[i].attachments==null?t("docs.noDocs"):
      response.data.data.data[i].attachments.map((data,index)=>
      <a  key={index} className='docLink' href={data.attachment} target="_blank">
      <IoDocumentAttachOutline style={{cursor:"pointer"}} size={20}/>

      </a>

      )

      obj.actions = <button className='upload' onClick={()=>{ setId(response.data.data.data[i].id);setModal(true)}}>{t("docs.upload")}</button>

      arr.push(obj);  } 


            setDocs(arr)
            setLoading(false)

        })
      }, [update]);

    
const data = {
    columns: [
      {
        label: t("docs.date"),
        field: 'createdAt',
        sort: 'asc',
   
      },
      {
        label: t("docs.type"),
        field: 'type',
        sort: 'asc',
      
      },
      {
        label: t("docs.notes"),
        field: 'note',
        sort: 'asc',
      
      },
  
      {
        label: t("docs.doc"),
        field: 'attachments',
        sort: 'asc',
        
      
      },
    
      {
        label: t("docs.upload"),
        field: 'actions',
        sort: 'asc',
        
      
      },
  
    
    
    
     
    ],    rows: docs}
  
    function changePage(value){
      const token= localStorage.getItem("token");
      window.scrollTo(0, 0);

setLoading(true)  
setPage(value)
    
     
getDocuments(token,props.data.id,value).then((response) => {
    setResp(response.data.data)

    let arr = [];

for (let i = 0; i < response.data.data.data.length; i++) {  
let obj = {};
obj.createdAt = handleDate( response.data.data.data[i].createdAt);        
obj.type =   response.data.data.data[i].type =="other"?t("docs.type1"):response.data.data.data[i].type =="Association request"? t("docs.type2"):t("docs.type3"); 
obj.note =  response.data.data.data[i].note==null?t("docs.none"):response.data.data.data[i].note;  
obj.attachments =

response.data.data.data[i].attachments==null?t("docs.noDocs"):
response.data.data.data[i].attachments.map((data,index)=>
<a  key={index} className='docLink' href={data.attachment} target="_blank">
<IoDocumentAttachOutline style={{cursor:"pointer"}} size={20}/>

</a>

)

obj.actions = <button className='upload' onClick={()=>{ setId(response.data.data.data[i].id);setModal(true)}}>{t("docs.upload")}</button>

arr.push(obj);  } 


    setDocs(arr)
    setLoading(false)

 
    })}
    
    
   return (
    <div className={i18n.language=="ar"?'regStep3Ar docsContainer':'docsContainer regStep3'}>
    {modal?<UploadFile type="" setUpdate={setUpdate} update={update} id={id} setModal={setModal}/>:null}
    {upload?<UploadFile type="upload" setUpload={setUpload} setUpdate={setUpdate} update={update}  setModal={setModal}/>:null}
   
    {loading?
<div style={{height:"40vh"}} className='loader'>
<BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
<div className='container-fluid'>
  <div className='uploadBtn'>
  <button onClick={()=>setUpload(true)}>{t("docs.upload")}</button>

    </div>
{docs.length>0?
     <>
     <div className={i18n.language=="ar"?'container-fluid pagAr':"container-fluid"}>
               <div className={i18n.language=="ar"?"row orderTable compTable":"row tableEn orderTable compTableEn"}>
               <div className={ docs.length==0?i18n.language=="ar"?" emptyAr empty col-lg-12 col-md-12 col-sm-12 col-xs-12":"  empty col-lg-12 col-md-12 col-sm-12 col-xs-12":"col-lg-12 col-md-12 col-sm-12 col-xs-12" }>
                                     <MDBDataTable
                                      entries={15}
                                      entriesOptions={[15]}
                                      infoLabel={[t("orders.info1"), t("orders.info2"), t("orders.info3"), t("orders.info4")]} 
                                      searchLabel={t("dashboard.search")}
                                      paginationLabel={ [t("orders.prev"), t("orders.next")]}
                                      entriesLabel={t("orders.show")}
                                          striped
                                          hover
                                          noBottomColumns={true}
                                          data={data}
                                     />  
                                 </div>
                             </div>
                             </div>
                      {resp.last_page>1?         <Stack spacing={0}>
           <Pagination 
           renderItem={(item) => (
             <PaginationItem
               slots={{ previous: i18n.language=="ar"?'prevAr':"prevEn" , next: i18n.language=="ar"?'nextAr':"nextEn" }}
               {...item}
             />)}
         
           className={i18n.language=="ar"?'arArrow':null} defaultPage={1} page={page}  count={resp.last_page} onChange={(e,value)=>{ changePage(value)}} shape="rounded" variant="outlined" 
           
           />
           </Stack>:null}
           
           </>:
          <div className='noProd'>
          <p>{t("addOrder.noResult")}</p>
            </div>
}


</div>}</div>


    );
}

export default Documents;