import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';

import { FiEdit } from 'react-icons/fi';
import { RiDeleteBin5Line } from 'react-icons/ri';

function InvoiceCard(props) {
    const { t, i18n } = useTranslation();



  
  
    

    
     
   return(
<div style={{display:"block",marginTop:"1em"}} className='col-lg-6 col-12'>
<div className="invoiceCard">


<div className='cardTop'>
    


<input  onChange={(e)=>{localStorage.setItem("organizationId",e.target.value); props.setActiveTax(props.data.id)}} checked={props.data.id==props.activeTax}  id={"invoice"+props.data.id}  className={i18n.language=="ar"?'mrginAr':'mrginEn'}  style={i18n.language=="ar"?{marginLeft:"1em"}:{marginRight:"1em"}}  value={props.data.id} type="radio"  name="organization" />
<label  htmlFor={"invoice"+props.data.id} >  {props.data.organizationName}   </label>
</div> 


<div className='row invoiceData '>
    <div className='col-12'>
    <p >{t("addOrder.name")} : {props.data.fullName} </p>

    </div>
    <div className='col-12'>
    <p >{t("addOrder.phone")} : {props.data.phone} </p>

    </div>
    <div className='col-12'>
    <p >{t("addOrder.email")} : {props.data.email} </p>

    </div>
    <div className='col-12'>
    <p >{t("addOrder.commercial")} : {props.data.commericalRegisterNo} </p>

    </div>
    <div className='col-12'>
    <p >{t("addOrder.taxNumber")} :  {props.data.taxNumber} </p>

    </div>
    <div className='col-12'>
    <p >{t("addOrder.city")} :   {i18n.language=="ar"?props.data.city.arName:props.data.city.enName}  </p>

    </div>
   
 
    <div className='col-12'>
    <p >{t("addOrder.street")} : {props.data.streetName} </p>

    </div>
    {props.data.postalCode!== null && props.data.postalCode!==""?
    <div className='col-12'>
    <p >{t("addOrder.postal")} : {props.data.postalCode} </p>

    </div>:null}
    <div className='col-12'>
    <p >{t("addOrder.organizationAddress")} : {props.data.address} </p>

    </div>

</div>





</div>
</div>

);
}

export default InvoiceCard;